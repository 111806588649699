import { useAddParticipant } from 'hooks/useAddParticipant';
import { useForm } from 'react-hook-form';

import { useStudyStore } from 'store/StudyStore';

const AddNewPatient = () => {
  const mutation = useAddParticipant();

  const {
    register,
    clearErrors,
    watch,
    setError,
    formState: { errors },
    resetField,
  } = useForm();

  const { loggedInStudyUser } = useStudyStore();

  const handleKeyDown = (e: any) => {
    const name = watch('name');
    const email = watch('email');

    if (e.key === 'Enter') {
      if (!name) {
        setError('name', {});
      } else {
        clearErrors('name');
      }

      if (!email) {
        setError('email', {});
      } else {
        clearErrors('email');
      }

      if (name && email && !errors.email && !errors.name) {
        mutation.mutate({
          centerId: String(loggedInStudyUser?.centerId), // change to dynamic center id
          firstName: name,
          email,
        });
        resetField('name');
        resetField('email');
      }
    }
  };

  return (
    <tr className='cursor-pointer hover:bg-slate-200 h-[36px] bg-transparent overflow-hidden rounded border-[#DDDDDD] border-b-[1px]'>
      <td className='rounded-l '></td>
      <td className=''></td>

      <td className='pr-4 text-left text-[#6C757D] text-sm table-cell'>
        <input
          {...register('name', { required: true, maxLength: 30 })}
          id='name'
          type='text'
          placeholder='Add Name Patient'
          className={`h-[28px] border-[0.5px] rounded px-3 py-[6px] font-sans font-normal border-[#DDDDDD] placeholder:text-[#DDDDDD] focus:outline-none ${errors.name ? 'border-[red]' : ''}`}
          onKeyDown={handleKeyDown}
        />
      </td>

      <td className='pr-4 text-left  text-[#6C757D] text-sm table-cell'>
        <input
          type='email'
          id='email'
          placeholder='Add Email Address'
          className={`h-[28px] border-[0.5px] rounded px-3 py-[6px] font-sans font-normal border-[#DDDDDD] placeholder:text-[#DDDDDD] focus:outline-none ${errors.email ? 'border-[red]' : ''}`}
          {...register('email', {
            required: 'required',
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: 'Entered value does not match email format',
            },
          })}
          onKeyDown={handleKeyDown}
        />
      </td>

      <td className=''></td>
      <td className=''></td>
      <td className='rounded-r '></td>
    </tr>
  );
};

export default AddNewPatient;
