import { TextareaHTMLAttributes, useEffect } from 'react';
import clsx from 'classnames';
import { FieldErrors, FieldValues, UseControllerProps, useController } from 'react-hook-form';
import { CustomControl } from './CustomControl';

interface IProps
  extends UseControllerProps<any>,
    Omit<
      TextareaHTMLAttributes<HTMLTextAreaElement>,
      'defaultValue' | 'disabled' | 'className' | 'name' | 'onMouseLeave'
    > {
  label?: string;
  className?: string;
  readOnly?: boolean;
  errors?: FieldErrors<FieldValues>;
  customControl?: CustomControl;
  textClass?: string;
  placeholder?: string;
  onKeyPress?: (e: any) => void;
}

function TextArea({
  label,
  className,
  textClass,
  customControl,
  name,
  rows,
  onBlur,
  errors,
  readOnly,
  onKeyPress,
  control,
  defaultValue,
  shouldUnregister,
  rules,
  cols,
  placeholder,
}: IProps) {
  const props = customControl
    ? customControl
    : useController({ name, control, defaultValue, rules, shouldUnregister }).field;

  const errorMsg = errors?.[name]?.message as string | undefined;

  function handleChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
    props.onChange(event.target.value);
  }

  useEffect(() => {
    if (props?.value === null || !props?.value) {
      props.onChange('');
    }
  }, [props?.value]);

  return (
    <div className={clsx('flex flex-col', className)}>
      {label && (
        <label
          className='text-left text-base text-blue-ocean-deep font-semibold pb-2'
          htmlFor={`textArea-${name}`}
        >
          {label}
        </label>
      )}
      <textarea
        className={`flex w-full p-2 border ${readOnly ? 'cursor-not-allowed focus:outline-none' : 'cursor-pointer'} ${errors?.[name]?.message ? 'border-error' : ' border-blue-ocean-deep'} focus:!outline-none !ring-0 !outline-none rounded-[0.625rem] resize-none text-gray-dark ${textClass}`}
        title={name}
        name={name}
        readOnly={readOnly}
        placeholder={placeholder}
        onBlur={onBlur}
        onKeyPress={onKeyPress}
        defaultValue={props?.value}
        id={`textArea-${name}`}
        rows={rows || 10}
        cols={cols || 2}
        onChange={handleChange}
        {...(customControl ? { value: props?.value } : {})}
      />
      {errorMsg ? <div className='text-error text-left'>{errorMsg}</div> : ''}
    </div>
  );
}

export default TextArea;
