/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react';
import { sortItems } from 'utils/array';
import { SortableTree } from './DragDropTree/SortableTree';

import './drag.css';
interface IProps {
  showFolderName?: boolean;
  droppedItems: any;
  renderRow?: React.ReactNode | any;
  classNames?: {
    container?: string;
    rowClassNames?: string;
  };
  handleRowClick?: any;
  handleEditTitle?: any;
  handleSwapItemsApi: any;
  handleRemoveFolder: any;
  setFolderItemType: any;
  handleCreateFolder: (folderName: string) => void;
  createQuestionnaireItem: (name: string) => void;
}

const DragAndDropList = React.forwardRef<HTMLInputElement, IProps>((props, ref) => {
  const [items, setItems] = useState<any>(null);

  const {
    droppedItems = [],
    handleRowClick,
    handleEditTitle,
    handleSwapItemsApi = () => {},
    handleRemoveFolder,
    handleCreateFolder,
    createQuestionnaireItem,
  } = props;
  useEffect(() => {
    setItems(droppedItems);
  }, [droppedItems]);

  return (
    <div className='relative scroll-container max-h-[64vh] flex flex-col justify-start items-end pl-2' id='scrollableDiv'>
      <div ref={ref} className='py-1 ltr w-[287px] flex flex-col pr-4' >
        <SortableTree
          handleEditTitle={handleEditTitle}
          collapsible
          indicator
          removable
          defaultItems={items ? sortItems([...items]) : null}
          handleRowClick={handleRowClick}
          createQuestionnaireItem={createQuestionnaireItem}
          handleCreateFolder={handleCreateFolder}
          handleRemoveFolder={handleRemoveFolder}
          handleSwapItemsApi={handleSwapItemsApi}
        />
      </div>
    </div>
  );
});
export default DragAndDropList;
