import { useMutation, useQuery } from '@tanstack/react-query';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { forgetPassword } from 'services/api/auth';
import { retrieveCenters } from 'services/api/study';
import { useParams } from 'react-router-dom';

export const useForgetPassword = () => {
  const { studyId } = useParams();
  const intl = useIntl();

  const { refetch } = useQuery({
    queryKey: ['centers'],
    queryFn: () => studyId && retrieveCenters(studyId),
  });

  const mutation = useMutation<string, unknown, string, unknown>((email) => forgetPassword(email), {
    onSuccess: () => {
      toast.success(intl.formatMessage({ id: 'site.requestSendMessage' }));
      refetch();
    },
    onError(e: any) {
      toast.error(e?.response?.data?.message);
    },
  });

  return mutation;
};
