import clsx from 'classnames';
import { format } from 'date-fns';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ITableHeaderItem, ITableProps } from './types';
import { useIntl } from 'react-intl';
import { FormProvider, useForm } from 'react-hook-form';
import Input from '../../../../../components/common/TextInput';
import { Calendar, Checkbox, FileUpload } from '../../../../../components/common';
import { FiChevronDown, FiFilePlus } from 'react-icons/fi';
import { LuFileCheck } from 'react-icons/lu';
import { useOutsideClick } from '../../../../../hooks/useOutsideClick';
import usePermissions from '../../../../../hooks/usePermissions';
import { useStudyStore } from '../../../../../store/StudyStore';
import useAddPatientDataForm from '../../../../../hooks/useAddPatientDataForm';
import { useParams } from 'react-router-dom';
import TableSelect from './TableSelect';
import { useCentersStore } from '../../../../../store/centers';

const DataTable: React.FC<ITableProps> = ({ title, columns, headerType }: ITableProps) => {
  const { participantId } = useParams();
  const methods = useForm();
  const intl = useIntl();
  const [headerItems, setHeaderItems] = useState<ITableHeaderItem[]>([]);
  const [sortedData, setSortedData] = useState<any>(columns);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isError, setIsError] = useState(false);
  const values: any = methods.watch();
  const containerRef = useRef<HTMLDivElement>(null);
  const tableRowRef: any = useRef<HTMLDivElement>(null);
  const [isEdit, setIsEdit] = useState(-1);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [isSelectedRow, setIsSelectedRow] = useState(-1);
  const { isFreezeStudy } = usePermissions();
  const { selectedCenter } = useCentersStore();
  const { loggedInStudyUser } = useStudyStore();

  const {
    medicalHistroyMutation, editMedicalHistroyMutation, deleteMedicalHistroyMutation,
    surgicalHistroyMutation, editSurgicalHistroyMutation, deleteSurgicalHistroyMutation,
    diseaseHistoryMutation, editDiseaseHistoryMutation, deleteDiseaseHistoryMutation,
    symptomsMutation, editSymptomsMutation, deleteSymptomsMutation,
    concoMedicationMutation, editConcoMedicationMutation, deleteConcoMedicationMutation,
    biologicalDataMutation, editBiologicalDataMutation, deleteBiologicalDataMutation,
    editBiologicalFileMutation
  } = useAddPatientDataForm();

  const isSiteStaff = loggedInStudyUser?.role?.category === 'SITE_STAFF' && `${selectedCenter}` === `${loggedInStudyUser?.centerId}` && !isFreezeStudy();

  const medicalHistoryTypeOpts = [
    { label: 'patient-profile.bloodLymphaticSystem', value: 'Blood and lymphatic system' },
    { label: 'patient-profile.cardiac', value: 'Cardiac' },
    { label: 'patient-profile.endocrineDisorders', value: 'Endocrine disorders' },
    { label: 'patient-profile.gastrointestinalDisorders', value: 'Gastrointestinal disorders' },
    { label: 'patient-profile.immuneSystemDisorders', value: 'Immune system disorders' },
    { label: 'patient-profile.infectionsInfestations', value: 'Infections and infestations' },
    { label: 'patient-profile.metabolismNutritionDisorders', value: 'Metabolism and nutrition disorders' },
    {
      label: 'patient-profile.musculoskeletalConnectiveTissueDisorders',
      value: 'Musculoskeletal and connective tissue disorders',
    },
  ];

  const diseaseHistoryStatusOpts = [
    { label: 'allStudies.onGoing', value: 'ongoing' },
    { label: 'patient-profile.healed', value: 'healed' },
  ];

  const routeOpts = [
    { label: 'patient-profile.oral', value: 'oral' },
    { label: 'patient-profile.intravenous', value: 'intravenous' },
    { label: 'patient-profile.subcutaneous', value: 'subcutaneous' },
    { label: 'patient-profile.intramuscular', value: 'intramuscular' },
  ];

  const discontinuationReasonOpts = [
    { label: 'patient-profile.recovery', value: 'recovery' },
    { label: 'patient-profile.complication', value: 'complication' },
    { label: 'patient-profile.patientForgetting', value: 'patient forgetting' },
    { label: 'patient-profile.changeTreatment', value: 'change of treatment' },
    { label: 'patient-profile.doseAdjustment', value: 'dose adjustment' },
  ];

  const adverseHeaderItems: ITableHeaderItem[] = [
    {
      title: 'patient.name',
      name: 'name',
      width: 'w-[44rem]',
    },
    {
      title: 'patient-profile.center',
      name: 'center',
      width: 'w-[7rem]',
    },
    {
      title: 'query-dashboard.visit',
      name: 'visit',
      width: 'w-[7.5rem]',
    },
    {
      title: 'patient-status.date',
      name: 'date',
      type: 'date',
      width: 'w-[7rem]',
    },
  ];

  const medicalHistoryHeaderItems: ITableHeaderItem[] = [
    {
      title: 'patient-profile.verbatim',
      name: 'name',
      type: 'text',
      width: 'w-[11.6rem]',
    },
    {
      title: 'patient-profile.startDate',
      name: 'startDate',
      type: 'date',
      width: 'w-[7rem]',
    },
    {
      title: 'patient-profile.type',
      name: 'type',
      type: 'select',
      width: 'w-[7.5rem]',
    },
    {
      title: 'patient-profile.confirmationDate',
      name: 'confirmationDate',
      type: 'date',
      width: 'w-[7rem]',
    },
  ];

  const surgicalHistoryHeaderItems: ITableHeaderItem[] = [
    {
      title: 'patient-profile.verbatim',
      name: 'name',
      type: 'text',
      width: 'w-[11.6rem]',
    },
    {
      title: 'patient-status.date',
      name: 'date',
      type: 'date',
      width: 'w-[7rem]',
    },
    {
      title: 'patient-profile.indication',
      name: 'indication',
      type: 'text',
      width: 'w-[6.6rem]',
    },
  ];

  const diseaseHistoryHeaderItems: ITableHeaderItem[] = [
    {
      title: 'patient.name',
      name: 'name',
      type: 'text',
      width: 'w-[13rem]',
    },
    {
      title: 'patient-profile.startDate',
      name: 'startDate',
      type: 'date',
      width: 'w-[7rem]',
    },
    {
      title: 'patient-profile.endDate',
      name: 'endDate',
      type: 'date',
      width: 'w-[7rem]',
    },
    {
      title: 'patient.status',
      name: 'status',
      type: 'select',
      width: 'w-[4.3rem]',
    },
  ];

  const symptomsHeaderItems: ITableHeaderItem[] = [
    {
      title: 'patient.name',
      name: 'name',
      type: 'text',
      width: 'w-[20rem]',
    },
    {
      title: 'patient-profile.startDate',
      name: 'startDate',
      type: 'date',
      width: 'w-[7rem]',
    },
  ];

  const concoMedicationHeaderItems: ITableHeaderItem[] = [
    {
      title: 'patient.name',
      name: 'name',
      type: 'text',
      width: 'w-[12rem]',
    },
    {
      title: 'patient-profile.tradeName',
      name: 'tradeName',
      type: 'text',
      width: 'w-[13.75rem]',
    },
    {
      title: 'patient-profile.startDate',
      name: 'startDate',
      type: 'date',
      width: 'w-[7rem]',
    },
    {
      title: 'patient-profile.route',
      name: 'route',
      type: 'select',
      width: 'w-[6.8rem]',
    },
    {
      title: 'patient-profile.discontinuationReason',
      name: 'discontinuationReason',
      type: 'select',
      width: 'w-[7.5rem]',
    },
    {
      title: 'patient-profile.dosage',
      name: 'dosage',
      type: 'text',
      width: 'w-[6.25rem]',
    },
    {
      title: 'patient-profile.important',
      name: 'important',
      type: 'checkbox',
      width: 'w-[3rem]',
    },
  ];

  const biologicalDataHeaderItems: ITableHeaderItem[] = [
    {
      title: 'patient.name',
      name: 'name',
      type: 'text',
      width: 'w-[13.75rem]',
    },
    {
      title: 'patient-status.date',
      name: 'date',
      type: 'date',
      width: 'w-[7rem]',
    },
    {
      title: 'patient-profile.type',
      name: 'type',
      type: 'text',
      width: 'w-[8.8rem]',
    },
    {
      title: 'patient-profile.results',
      name: 'results',
      type: 'text',
      width: 'w-[32.6rem]',
    },
    {
      title: 'patient-profile.file',
      name: 'file',
      type: 'file',
      width: 'w-[2rem]',
    },
  ];

  useEffect(() => {
    switch (headerType) {
      case 'adverseEvent':
        setHeaderItems(adverseHeaderItems);
        break;
      case 'medicalHistory':
        setHeaderItems(medicalHistoryHeaderItems);
        break;
      case 'surgicalHistory':
        setHeaderItems(surgicalHistoryHeaderItems);
        break;
      case 'diseaseHistory':
        setHeaderItems(diseaseHistoryHeaderItems);
        break;
      case 'symptoms':
        setHeaderItems(symptomsHeaderItems);
        break;
      case 'concoMedication':
        setHeaderItems(concoMedicationHeaderItems);
        break;
      case 'biologicalData':
        setHeaderItems(biologicalDataHeaderItems);
        break;
      default:
        setHeaderItems(adverseHeaderItems);
    }
  }, [headerType]);

  const getOpts = (name = '') => {
    switch (headerType) {
      case 'medicalHistory':
        return medicalHistoryTypeOpts;
      case 'diseaseHistory':
        return diseaseHistoryStatusOpts;
      case 'concoMedication':
        return name.includes('route') ? routeOpts : discontinuationReasonOpts;
      default:
        return [];
    }
  };

  const getBtnClass = (name = '') => {
    switch (headerType) {
      case 'medicalHistory':
        return '!w-[10rem]';
      case 'diseaseHistory':
        return '!w-[5rem]';
      case 'concoMedication':
        return name.includes('route') ? '!w-[7rem]' : '!w-[7.5rem]';
      default:
        return '';
    }
  };

  useEffect(() => {
    setSortedData(columns);
  }, [columns]);

  const onSuccess = () => {
    isEdit === -1 && methods?.reset();
    setIsError(false);
    setIsSelectedRow(-1);
  }

  const formatDate = (date: string) => {
    return date && format(new Date(date), 'yyyy-MM-dd');
  }

  const onSubmit: any = (data: any) => {
    if (!isSiteStaff) {
      return 0;
    }
    if ((isEdit === -1 && !data?.name) || data[`name-${isEdit}`] === '') {
      setIsError(true);
      return 0;
    }
    switch (headerType) {
      case 'medicalHistory':
        if (isEdit > -1 && data[`name-${isEdit}`] !== '') {
          try {
            editMedicalHistroyMutation.mutate({
              id: isEdit,
              data: {
                patientId: parseInt(`${participantId}`, 10),
                name: data[`name-${isEdit}`],
                startDate: formatDate(data[`startDate-${isEdit}`]),
                type: data[`type-${isEdit}`],
                confirmationDate: formatDate(data[`confirmationDate-${isEdit}`]),
              },
            }, { onSuccess });
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        } else if (data?.name) {
          try {
            medicalHistroyMutation.mutate({
              patientId: parseInt(`${participantId}`, 10),
              name: data?.name,
              startDate: formatDate(data?.startDate),
              type: data?.type,
              confirmationDate: formatDate(data?.confirmationDate),
            }, { onSuccess });
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        }
        break;
      case 'surgicalHistory':
        if (isEdit > -1 && data[`name-${isEdit}`] !== '') {
          try {
            editSurgicalHistroyMutation.mutate({
              id: isEdit,
              data: {
                patientId: parseInt(`${participantId}`, 10),
                name: data[`name-${isEdit}`],
                date: formatDate(data[`date-${isEdit}`]),
                indication: data[`indication-${isEdit}`],
              },
            }, { onSuccess });
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        } else if (data?.name) {
          try {
            surgicalHistroyMutation.mutate({
              patientId: parseInt(`${participantId}`, 10),
              name: data?.name,
              date: formatDate(data?.date),
              indication: data?.indication,
            }, { onSuccess });
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        }
        break;
      case 'concoMedication':
        if (isEdit > -1 && data[`name-${isEdit}`] !== '') {
          try {
            editConcoMedicationMutation.mutate({
              id: isEdit,
              data: {
                patientId: parseInt(`${participantId}`, 10),
                name: data[`name-${isEdit}`],
                tradeName: data[`tradeName-${isEdit}`],
                startDate: formatDate(data[`startDate-${isEdit}`]),
                route: data[`route-${isEdit}`],
                discontinuationReason: data[`discontinuationReason-${isEdit}`],
                dosage: data[`dosage-${isEdit}`],
                important: data[`important-${isEdit}`],
              },
            }, { onSuccess });
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        } else if (data?.name) {
          try {
            concoMedicationMutation.mutate({
              patientId: parseInt(`${participantId}`, 10),
              name: data?.name,
              tradeName: data?.tradeName,
              startDate: formatDate(data?.startDate),
              route: data?.route,
              discontinuationReason: data?.discontinuationReason,
              dosage: data?.dosage,
              important: data?.important,
            }, { onSuccess });
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        }
        break;
      case 'diseaseHistory':
        if (isEdit > -1 && data[`name-${isEdit}`] !== '') {
          try {
            editDiseaseHistoryMutation.mutate({
              id: isEdit,
              data: {
                patientId: parseInt(`${participantId}`, 10),
                name: data[`name-${isEdit}`],
                startDate: formatDate(data[`startDate-${isEdit}`]),
                endDate: formatDate(data[`endDate-${isEdit}`]),
                status: data[`status-${isEdit}`],
              },
            }, { onSuccess });
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        } else if (data?.name) {
          try {
            diseaseHistoryMutation.mutate({
              patientId: parseInt(`${participantId}`, 10),
              name: data?.name,
              startDate: formatDate(data?.startDate),
              endDate: formatDate(data?.endDate),
              status: data?.status,
            }, { onSuccess });
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        }
        break;
      case 'symptoms':
        if (isEdit > -1 && data[`name-${isEdit}`] !== '') {
          try {
            editSymptomsMutation.mutate({
              id: isEdit,
              data: {
                patientId: parseInt(`${participantId}`, 10),
                name: data[`name-${isEdit}`],
                startDate: formatDate(data[`startDate-${isEdit}`]),
              },
            }, { onSuccess });
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        } else if (data?.name) {
          try {
            symptomsMutation.mutate({
              patientId: parseInt(`${participantId}`, 10),
              name: data?.name,
              startDate: formatDate(data?.startDate),
            }, { onSuccess });
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        }
        break;
      case 'biologicalData':
        if (isEdit > -1 && data[`name-${isEdit}`] !== '') {
          try {
            editBiologicalDataMutation.mutate({
              id: isEdit,
              data: {
                patientId: parseInt(`${participantId}`, 10),
                name: data[`name-${isEdit}`],
                date: formatDate(data[`date-${isEdit}`]),
                type: data[`type-${isEdit}`],
                results: data[`results-${isEdit}`],
              },
            }, { onSuccess });
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        } else if (data?.name) {
          try {
            const newData = {
              patientId: parseInt(`${participantId}`, 10),
              name: data?.name,
              date: formatDate(data?.date),
              type: data?.type,
              results: data?.results,
            };
            const formData = new FormData();
            data?.file && formData.append('file', data.file);
            formData.append('request', new Blob([JSON.stringify(newData)], { type: 'application/json' }));

            biologicalDataMutation.mutate(formData, { onSuccess });
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        }
        break;
      default:
        return 0;
    }
  };

  const handleUpload = (e: any, name: string) => {
    methods.setValue(name, e);
    if (isEdit > -1) {
      try {
        const formData: any = new FormData();
        e && formData.append('file', e);
        editBiologicalFileMutation.mutate({
          data: formData ,
          id: isEdit,
        }, { onSuccess });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    } else {
      onSubmit?.({ ...values, [name]: e });
    }
  };

  const handleDownload = (downloadLink: string) => {
    const a = document.createElement('a');
    a.href = downloadLink;
    a.click();
  };

  const checkImportant = () => {
    if (values?.name && values?.tradeName && values?.startDate && values?.route && values?.discontinuationReason && values?.dosage) {
      return true;
    }
    return values?.important
  }

  const renderCol = (type: string, name: string, id: number) => {
    switch (type) {
      case 'text':
        return <Input
          name={name}
          register={methods.register}
          inputClassName={`h-7 !w-full !py-0 !rounded placeholder:text-sm placeholder:!text-gray-light ${isError && isEdit === id && name.includes('name') && (values[name] === '' || !values[name]) ? '!border-error' : '!border-blue-normal-35'}`}
          className="!mt-0"
          onKeyDown={(e: any) => {
            if (e.key === 'Enter') {
              isEdit === -1 && onSubmit?.({ ...values, [name]: e.target.value })
              e.target.blur(e.target.value);
            }
          }}
          onBlur={(e: any) => isEdit !== -1 ? onSubmit?.({ ...values, [name]: e.target.value }) : null}
          placeholder={intl.formatMessage({ id: 'patient-profile.add' })}
        />;
      case 'select':
        return <TableSelect
          control={methods.control}
          name={name}
          btnClass={getBtnClass(name)}
          placeholder={intl.formatMessage({ id: 'patient-profile.add' })}
          onBlur={(e: any) => isEdit !== -1 ? onSubmit?.({ ...values, [name]: e }) : null}
          options={getOpts(name)}
        />
      case 'file':
        return <FileUpload
          register={methods?.register}
          label=""
          onFileUpload={(e: any) => handleUpload(e, name)}
          name={name}
          btnClass="!bg-transparent z-10 !min-w-max !py-0 !h-5"
          noSize={true}
          noFileName={true}
          fileIcon={values[name] ? <LuFileCheck className="text-xl" /> : <FiFilePlus className='text-xl' />}
        />;
      case 'date':
        return <Calendar
          control={methods.control}
          className="flex-1 !border !border-blue-normal-35 no-border-mui !my-0 !rounded !h-[28px] [&>div]:h-[26px] [&>div>div]:h-[25px]  [&>div]:!bg-white [&>div>div>div>button>svg]:!stroke-[3px] [&>div>div>div>button>svg]:!text-xl [&>div>div>div>button>svg]:!text-blue-ocean-deep [&>div>div>fieldset]:!rounded [&>div>div>input]:!px-1 [&>div>div>input]:!py-0 [&>div>div>input]:!placeholder-gray-light [&>div>div>input]:!h-[25px]"
          label=""
          setIsDatePickerOpen={setIsDatePickerOpen}
          placeholder={intl.formatMessage({ id: 'patient-profile.add' })}
          name={name}
          type="date"
          slots={{
            openPickerIcon: FiChevronDown,
          }}
          onBlur={(e) =>  isEdit !== -1 ? onSubmit?.({ ...values, [name]: e }) : null}
          format='dd/MM/yyyy'
          inputDisable={true}
        />;
      case 'checkbox':
        return <Checkbox
          name={name}
          label=""
          control={{
            checked: headerType === 'concoMedication' ? (checkImportant() || values[name]) : values[name],
            value: values[name],
            onChange: (e: any) => {
              methods.setValue(name, e.target.checked);
              isEdit !== -1 && onSubmit?.({ ...values, [name]: e.target.checked });
            }
          }}
          mainClass="!my-0"
          inputClass="!h-5 !w-5 checked:outline outline-1 rounded-lg !accent-base-white !text-gray-dark"
        />;
      default:
        return 0;
    }
  };

  const handleOutsideClick = useCallback(
    (event: any, clickedOut: boolean) => {
      if (clickedOut && !isDatePickerOpen) {
        onSubmit?.(values);
        setIsExpanded(false);
        setIsSelectedRow(-1);
        setIsEdit(-1);
      }
    },
    [values],
  );

  const handleTableRef = useCallback(
    (event: any, clickedOut: boolean) => {
      if (clickedOut && !isDatePickerOpen && isEdit === -1) {
        onSubmit?.(values);
      }
    },
    [values],
  );

  useOutsideClick(containerRef, handleOutsideClick);
  useOutsideClick(tableRowRef, handleTableRef);

  const getLabel = (item: any, key: any) => {
    const opts = getOpts(key);
    return opts.find((r: any) => r.value === item[key])?.label || '';
  }

  const handleIsEdit = (item: any) => {
    if (!isSiteStaff || headerType === 'adverseEvent') {
      return 0;
    }
    setIsEdit(item?.id);
    Object.keys(item).forEach((key) => {
      item[key] && methods.setValue(`${key}-${item?.id}`, item[key]);
    });
  };

  const handleDelete = () => {
    switch (headerType) {
      case 'medicalHistory':
        try {
          deleteMedicalHistroyMutation.mutate(isSelectedRow, { onSuccess });
        } catch (error) {
          console.error('Error fetching data:', error);
        }
        break;
      case 'surgicalHistory':
        try {
          deleteSurgicalHistroyMutation.mutate(isSelectedRow, { onSuccess });
        } catch (error) {
          console.error('Error fetching data:', error);
        }
        break;
      case 'concoMedication':
        try {
          deleteConcoMedicationMutation.mutate(isSelectedRow, { onSuccess });
        } catch (error) {
          console.error('Error fetching data:', error);
        }
        break;
      case 'diseaseHistory':
        try {
          deleteDiseaseHistoryMutation.mutate(isSelectedRow, { onSuccess });
        } catch (error) {
          console.error('Error fetching data:', error);
        }
        break;
      case 'symptoms':
        try {
          deleteSymptomsMutation.mutate(isSelectedRow, { onSuccess });
        } catch (error) {
          console.error('Error fetching data:', error);
        }
        break;
      case 'biologicalData':
        try {
          deleteBiologicalDataMutation.mutate(isSelectedRow, { onSuccess });
        } catch (error) {
          console.error('Error fetching data:', error);
        }
        break;
      default:
        return 0;
    }
  }

  const keyPress = useCallback(
    (e: any) => {
      if (e?.key === 'Backspace' && isSelectedRow > -1) {
        handleDelete();
      }
    },
    [isSelectedRow]
  );

  useEffect(() => {
    document.addEventListener('keydown', keyPress)
    return () => document.removeEventListener('keydown', keyPress);
  }, [keyPress]);

  return (
    <div className="mt-4">
      <p className="text-lg font-semibold text-left text-blue-ocean-deep pl-4">{title}</p>
      <div ref={containerRef} onClick={() => setIsExpanded(true)}
           className={`border border-purple overflow-auto rounded-lg mt-1 block px-3 w-full ${isExpanded ? 'max-h-[32rem] h-max min-h-[10rem]' : 'max-h-[10rem] h-[10rem]'}`}>
        <FormProvider {...methods}>
          <form>
            <table className="min-w-full h-max">
              <thead className="sticky top-0 bg-white z-1">
              <tr>
                {headerItems.map((r, i: number) => (
                  <th
                    key={i}
                    className={clsx(
                      'text-left pt-2 pb-1 pr-2 cursor-pointer text-blue-ocean-deep font-medium',
                      r.width, isExpanded && i === 0 && 'pl-2'
                    )}
                  >
                    <div className="flex items-center">
                      {intl.formatMessage({ id: r.title })}
                    </div>
                  </th>
                ))}
              </tr>
              </thead>
              <tbody className="group">
              {isSiteStaff && isExpanded && headerType !== 'adverseEvent' && (
                <tr
                  ref={tableRowRef}
                  onClick={() => {
                    setIsSelectedRow(-2);
                    setIsEdit(-1);
                  }}
                  className={`align-middle ${isSelectedRow === -2 ? '' : '!bg-blue-very-light-30'} bg-blue-very-light border border-blue-normal-35 rounded-lg px-2 h-[2.25rem] cursor-pointer`}>
                  {headerItems && headerItems?.length > 0 && headerItems.map((header, i: number) => {
                    const newWidth = header?.width && header?.width?.split('[')[1]?.split(']')[0];

                    return (
                      <td key={header?.name}
                          style={{ maxWidth: `${newWidth}`, width: `${newWidth}` }}
                          className={`overflow-visible ${i === 0 && 'pl-2'} pr-2 group text-gray-dark text-sm py-1 text-left table-cell`}>
                        {isSelectedRow === -2 && renderCol(header?.type || '', header?.name || '', -1)}
                        {isSelectedRow !== -2 && i === 0 && renderCol(header?.type || '', header?.name || '', -1)}
                      </td>
                    )
                  })}
                </tr>
              )}
              {sortedData && sortedData?.length > 0 && sortedData?.map((item: any, i: number) => (
                <tr
                  key={item.id}
                  onClick={() => {
                    setIsSelectedRow(item?.id);
                    isEdit === -1 && methods?.reset();
                  }}
                  className={`align-middle ${isSelectedRow === item?.id ? '!bg-blue-very-light' : 'bg-base-white' } hover:bg-blue-very-light-30 cursor-pointer ${sortedData?.length - 1 > i && 'border-b'} border-gray-light`}
                >
                  {headerItems && headerItems?.length > 0 && headerItems.map((header, i: number) => {
                    const newWidth = header?.width && header?.width?.split('[')[1]?.split(']')[0];
                    const foundLabel = getLabel(item, header?.name);

                    return (
                      <td key={header?.name} onDoubleClick={() => handleIsEdit(item)}
                          className={`text-gray-dark text-sm py-2.5 text-left table-cell ${i === 0 && 'pl-2'} ${i === headerItems?.length -1 ? 'pr-2' : isEdit === -1 ? 'pr-12' : 'pr-2'}`}>
                        {isEdit === item?.id ? (
                          <>{renderCol(header?.type || '', `${header?.name}-${item?.id}` || '', item?.id)}</>
                        ) : (
                          <>
                            {header?.type === 'checkbox' ? (
                              <Checkbox
                                control={{ checked: item[header?.name as any], value: item[header?.name as any], onChange: () => null }}
                                label=""
                                name={header?.name}
                                mainClass="!my-0"
                                inputClass="!h-5 !w-5 checked:outline outline-1 rounded-lg !accent-base-white !text-gray-dark"
                              />
                            ) : header?.type === 'file' ? (
                              <FileUpload
                                register={methods?.register}
                                label=""
                                onFileUpload={(e: any) => item[header?.name as any] ? '' : handleUpload(e, header?.name || '')}
                                name={header?.name}
                                btnClass="!bg-transparent z-10 !min-w-max !h-[10px] !p-0"
                                noSize={true}
                                disabled={isEdit === -1}
                                noFileName={true}
                                fileIcon={item[header?.name as any] ? <LuFileCheck onClick={() => handleDownload(item[header?.name as any])} className="text-xl" /> : <FiFilePlus className='text-xl' />}
                              />
                            ) : (
                              <>
                                {header?.type === 'date' ? item[header.name as any] && format(new Date(item[header.name as any]), 'dd/MM/yyyy') || '' : (
                                  <p className={`${isSelectedRow === item?.id ? 'break-words' : 'truncate' }`} style={{ maxWidth: newWidth }}>
                                    {header?.type === 'select' ? foundLabel ? intl.formatMessage({ id : `${foundLabel}` }) : '' : item[header.name as any]}
                                  </p>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </td>
                    );
                  })}
                </tr>
              ))}
              </tbody>
            </table>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default DataTable;
