import { useCallback, useRef, useState, memo, useEffect } from 'react';
import clsx from 'classnames';
import { FiChevronDown } from 'react-icons/fi';
import { FieldErrors, FieldValues, UseControllerProps, useController } from 'react-hook-form';
import { useOutsideClick } from 'hooks/useOutsideClick';
import { GoPlusCircle } from 'react-icons/go';
import { LuCheckCircle } from 'react-icons/lu';
import { IoClose } from 'react-icons/io5';
import { CustomControl } from './CustomControl';

interface Option {
  label: string;
  value: string | number;
}

interface IProps extends UseControllerProps<any> {
  placeholder?: string;
  options: Option[];
  customControl?: CustomControl;
  label?: string;
  disabled?: boolean;
  className?: string;
  buttonClassName?: string;
  errors?: FieldErrors<FieldValues>;
  optionsClassName?: string;
  mainClass?: string;
  onBlur?: any;
  optWrapperClassName?: string;
}

function SelectToAdd({
  placeholder,
  options,
  label,
  disabled,
  defaultValue,
  errors,
  customControl,
  buttonClassName,
  onBlur,
  className,
  optWrapperClassName,
  mainClass,
  ...rest
}: IProps) {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const labelRef = useRef<HTMLLabelElement>(null);
  const [lastIndex, setLastIndex] = useState(20);
  const [tags, setTags] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [filteredOptions, setFilteredOptions] = useState<Option[]>(options);
  const props = customControl ? customControl : useController(rest).field;
  const errorMsg = props.value ? '' : errors?.[rest.name]?.message as string | undefined;

  const scrollContainerRef = useRef(null);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    const inputValue = event.target.value.toLowerCase();

    setFilteredOptions(
      options.filter((option) => option.label.includes(inputValue)),
    );
    setIsOpen(true);
  };

  useEffect(() => {
    if (inputValue) {
      const searchList = options.filter((option) => option.label.includes(inputValue.toLowerCase()))
      searchList && setFilteredOptions(searchList.slice(0, lastIndex));
      return;
    }
    setFilteredOptions(options.slice(0, lastIndex));
  }, [options, lastIndex, inputValue]);

  useEffect(() => {
    if (defaultValue && defaultValue?.length > 0) {
      setTags(defaultValue)
    }
  }, [defaultValue]);

  const updateProps = (tagList: string[]) => {
    setTags([...tagList]);
    props.onChange([...tagList]);
    onBlur && onBlur?.([...tagList]);
  }

  const AddCondition = (value: string) => {
    if (tags.length === 0 || value && !tags.find(tg => tg === value)) {
      const tagList = tags.length === 0 ? [value] : [...tags, value]
      updateProps(tagList);
    }
    toggleOpen()
  }

  const RemoveCondition = (value: string) => {
    const newTagList = tags.filter(tag => tag !== value);
    updateProps(newTagList);
    toggleOpen()
  }

  function toggleOpen() {
    setIsOpen((prevValue) => !prevValue);
  }

  const handleOutsideClick = useCallback(
    (event: MouseEvent | React.MouseEvent<HTMLElement, MouseEvent>, clickedOut: boolean) => {
      if (clickedOut && event.target !== labelRef.current) {
        setIsOpen(false);
      }
    },
    [setIsOpen],
  );

  const handleScroll = () => {
    const element: any = scrollContainerRef.current;
    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
      setLastIndex(lastIndex + 20);
    }
  };

  useOutsideClick(containerRef, handleOutsideClick);

  return (
    <>
      <div className={className}>
        {label && (
          <label
            htmlFor={`button-${rest.name}`}
            ref={labelRef}
            className='text-left w-full text-blue-ocean-deep mb-2 inline-block leading-6 font-semibold'
          >
            {label}
          </label>
        )}
        <div
          ref={containerRef}
          className={clsx(
            `rounded-[0.625rem] flex items-center relative max-h-[3rem] text-blue-ocean-deep  ${errors?.[rest.name]?.message ? 'border-error' : 'border-blue-ocean-deep'}`,
            isOpen ? 'border border-b-transparent rounded-b-none open' : 'border',
            mainClass,
          )}
        >
          <button
            type='button'
            disabled={disabled}
            id={`button-${rest.name}`}
            onClick={toggleOpen}
            className={clsx(
              `flex flex-1 gap-x-4 justify-between items-center p-3 rounded-[0.625rem] ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'
              }`,
              buttonClassName,
            )}
          >
            <input type='text' className={`w - full ${disabled ? '!cursor-not-allowed !text-gray-dark !placeholder:text-gray-dark' : 'text-blue-ocean-deep placeholder:text-blue-ocean-deep'} text-base leading-6 border-none focus-visible:outline-none placeholder:text-base `} value={inputValue} placeholder={placeholder} onChange={handleInputChange} />
            <FiChevronDown className={clsx('transition-all', isOpen && '-rotate-180')} />
          </button>
          <div
            onScroll={handleScroll}
            ref={scrollContainerRef}
            className={clsx(
              'absolute flex z-10 box-content max-h-[11.4rem] no-scrollbar bg-base-white overflow-hidden rounded-b-xl w-full origin-top top-full -left-[2px] right-0 bottom-0',
              isOpen ? 'min-h-fit border overflow-y-auto' : 'h-0 hidden',
              isOpen && optWrapperClassName,
              filteredOptions && filteredOptions?.length > 4 ? 'h-[11.4rem]' : 'h-auto',
            )}
          >
            <ul className='w-full'>
              {filteredOptions.map((option) => (
                <li
                  onClick={() => AddCondition(`${option.value}`)}
                  className='hover:bg-blue-white-ice truncate transition-colors px-3 py-2 cursor-pointer text-left flex justify-between items-center'
                  key={option?.value}
                  data-value={option?.value}
                >
                  <p className='text-wrap'>{option.label}</p>
                  <div>
                    {tags.includes(`${option.value}`) ? <LuCheckCircle className='text-blue-normal' /> : <GoPlusCircle onClick={() => AddCondition(`${option.value}`)} />}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      {tags.length > 0 && <div className='mt-5 flex flex-wrap gap-3 w-full'>
        {tags.map((tag, index) => (
          <div key={index} className={`flex items-center gap-2.5 text-blue-ocean-deep  rounded-[5.625rem] pl-4 py-1 pr-2 w-fit ${disabled ? 'bg-gray-light' : 'bg-yellow'}`}>
            <p>{options?.find((opt) => `${opt.value}` === `${tag}`)?.label}</p>
            <IoClose className={`w-5 h-5 ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`} onClick={() => disabled ? '' : RemoveCondition(tag)} />
          </div>
        ))}
      </div>}
      {errorMsg ? <div className='text-error text-left'>{errorMsg}</div> : ''}
    </>
  );
}

export default memo(SelectToAdd);
