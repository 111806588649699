import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { InputProps, SchemaForm } from '../../../types/form';
import {
  TextInput,
  Select,
  CheckBoxList,
  FileUpload,
  Radio,
  Rating,
  Calendar,
} from 'components/common';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import QuestionHeader from 'components/shared/QuestionnaireField/components/QuestionHeader';
import QuestionInstruction from 'components/shared/QuestionnaireField/components/QuestionInstruction';
import clsx from 'classnames';
interface Props {
  onSubmit: (data: unknown) => void;
  labelButtonSubmit?: string;
  isLoading?: boolean;
  titleForm?: string;
  initialValues?: unknown;
  validationSchema: SchemaForm;
  inputs: InputProps[];
  className?: string;
  btnClassName?: string;
  titleClassName?: string;
  extraContent?: React.ReactNode;
  noSubmitBtn?: boolean;
}

const Form = ({ ...props }: Props) => {
  const {
    initialValues,
    inputs,
    isLoading,
    onSubmit,
    validationSchema,
    titleForm,
    labelButtonSubmit = 'Submit',
    className,
    titleClassName,
    btnClassName,
    extraContent,
    noSubmitBtn = false,
  } = props;

  const formMethods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: { ...(initialValues as any) },
  });
  const {
    register,
    formState: { errors },
    setValue,
    trigger,
  } = formMethods;
  const createInputs = () =>
    inputs.map(({ disabled, ...inputProps }: any) => {
      const formControlProps = { register, errors, setValue, trigger, disabled };
      switch (inputProps.type) {
        case 'text':
          return <TextInput {...inputProps} key={inputProps.name} {...formControlProps} />;
        case 'select':
          return <Select {...inputProps} key={inputProps.name} {...formControlProps} />;
        case 'checkbox':
          return <CheckBoxList {...inputProps} key={inputProps.name} {...formControlProps} />;
        case 'file':
          return <FileUpload {...inputProps} key={inputProps.name} {...formControlProps} />;
        case 'radio':
          return <Radio {...inputProps} key={inputProps.name} {...formControlProps} />;
        case 'rating':
          return <Rating {...inputProps} key={inputProps.name} {...formControlProps} />;
        case 'date':
          return <Calendar {...inputProps} key={inputProps.name} {...formControlProps} />;
        // components added for preview questionnaire
        case 'instruction':
          return (
            <CKEditor
              editor={DecoupledEditor}
              {...inputProps}
              key={inputProps.name}
              {...formControlProps}
            />
          );

        case 'header-preview':
          return <QuestionHeader {...inputProps} key={inputProps.name} />;
        case 'instruction-preview':
          return <QuestionInstruction {...inputProps} key={inputProps.name} />;

        default:
          return <TextInput {...inputProps} key={inputProps.name} {...formControlProps} />;
      }
    });

  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={formMethods.handleSubmit(onSubmit)}
        className={clsx(
          'bg-secondary rounded-md p-10 pt-5  flex flex-col gap-2 w-full min-h-[390px]',
          className,
        )}
      >
        {titleForm && <h5 className={clsx('text-2xl pb-2 mb-2 ', titleClassName)}>{titleForm}</h5>}

        <section className='flex-1 flex flex-col gap-1'>
          {createInputs()}
          {extraContent}
        </section>

        {!noSubmitBtn && (
          <div className={clsx('flex justify-end', btnClassName)}>
            <button
              disabled={isLoading}
              className='bg-blue-ocean-deep transition-opacity text-white w-fit py-[10px] px-5 rounded-[32px] hover:opacity-90 active:opacity-100 font-bold mt-4'
              type='submit'
            >
              {labelButtonSubmit}
            </button>
          </div>
        )}
      </form>
    </FormProvider>
  );
};
export default Form;
