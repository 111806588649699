import { useEffect, useState } from 'react';
import { StarOutlinedIcon, StarFilledIcon } from '../Icons';
import { SvgIcon } from '@mui/material';
import clsx from 'classnames';
import { FieldErrors, FieldValues, UseControllerProps, useController } from 'react-hook-form';
import { CustomControl } from './CustomControl';

const StarCountEnum: any = {
  THREE: 3,
  FIVE: 5,
  TEN: 10,
};

interface IProps extends UseControllerProps<any> {
  customControl?: CustomControl;
  name: string;
  errors?: FieldErrors<FieldValues>;
  onBlur?: (e: any) => void;
  onFocus?: any;
  disabled?: boolean;
  stars?: string;
  defaultValue?: string;
  className?: string;
  iconClass?: string;
  noZero?: boolean;
}

const RatingStar = ({
  customControl,
  name,
  onBlur,
  stars,
  control,
  defaultValue,
  disabled,
  className,
  iconClass,
  onFocus,
  noZero,
}: IProps) => {
  const props = customControl
    ? customControl
    : useController({ control, name, defaultValue, disabled })?.field;
  const [rating, setRatings] = useState<number>(-1);
  const [selectedRating, setSelectedRating] = useState<number>(-1);

  useEffect(() => {
    if (props?.value) {
      setRatings(props?.value);
      setSelectedRating(props?.value);
    } else if (!props?.value) {
      setRatings(-1);
      setSelectedRating(-1);
    }
  }, [props?.value]);

  const handleMouseEnter = (index: number) => {
    if (disabled) return;
    setRatings(index + 1);
  };

  const handleMouseLeave = () => {
    if (disabled) return;
    setRatings(rating === 1 ? -1 : rating - 1);
  };

  const handleClick = (index: number) => {
    onFocus?.();
    if (noZero && selectedRating === 1) return;
    if (disabled) return;
    setRatings(index);
    setSelectedRating(selectedRating === 1 ? -1 : index + 1);
    props?.onChange(selectedRating === 1 ? -1 : index + 1);
    onBlur?.(selectedRating === 1 ? -1 : index + 1);
  };

  return (
    <div
      onMouseLeave={() => setRatings(selectedRating)}
      className={`flex pr-4 gap-1 relative items-center w-fit mt-2 ml-auto ${className}`}
    >
      {[...Array(stars ? StarCountEnum[stars] : 5)].map((_, index) => (
        <div key={index}>
          {rating > 0 && rating > index ? (
            <SvgIcon
              className={clsx(
                `!w-4 !h-4 text-blue-normal ${iconClass}`,
                disabled ? 'cursor-not-allowed' : 'cursor-pointer',
              )}
              component={StarFilledIcon}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
              onClick={() => handleClick(index)}
            />
          ) : (
            <SvgIcon
              className={`${disabled ? 'cursor-not-allowed' : 'cursor-pointer'} !w-4 !h-4 text-transparent ${iconClass}`}
              component={StarOutlinedIcon}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
              onClick={() => handleClick(index)}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default RatingStar;
