import { useCallback, useEffect, useRef, useState } from 'react';
import { FiAlertCircle, } from 'react-icons/fi';
import QueryInput from './QueryInput';
import { IQueryForm, addQuestionQuery, getQuestionQueries } from 'services/api/questionQuery';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useQuestionnaireStore } from 'store/QuestionnaireStore';
import { useOutsideClick } from 'hooks/useOutsideClick';
import { useLocation, useParams } from 'react-router-dom';
import { IQueryQuestion, useQueryQuestionStore } from 'store/queryQuestionStore';
import QueryBox from './QueryBox';
import { useCentersStore } from 'store/centers';
import { toast } from 'react-toastify';
import { useStudyStore } from 'store/StudyStore';
import { useIntl } from 'react-intl';
import usePermissions from '../../../../hooks/usePermissions';

interface IQProps {
  id: number;
  methods?: any;
}

const QueryQuestionnaire = ({ id, methods }: IQProps) => {
  const intl = useIntl();
  const containerRef = useRef<HTMLDivElement>(null);
  const { studyId, participantId} = useParams();
  const { state } = useLocation();
  const [showQuery, setShowQuery] = useState(false);
  const [queryList, setQueryList] = useState<IQueryQuestion[]>();
  const [isAction, setIsAction] = useState(false);
  const { stepsData } = useQuestionnaireStore();
  const { queryQuestions } = useQueryQuestionStore();
  const { selectedCenter } = useCentersStore();
  const { isFreezeStudy, hasNotAccess } = usePermissions();
  const { loggedInStudyUser } = useStudyStore();

  const isSiteStaff = loggedInStudyUser?.role?.category === 'SITE_STAFF' && `${selectedCenter}` === `${loggedInStudyUser?.centerId}`;

  const { refetch } = useQuery({
    queryKey: ['queries'],
    queryFn: () => studyId && participantId && getQuestionQueries(parseInt(`${studyId}`, 10), parseInt(`${selectedCenter}`, 10) || 1, parseInt(`${participantId}`, 10)),
  });

  useEffect(() => {
    setQueryList(queryQuestions && queryQuestions?.length > 0 && queryQuestions.filter((item: IQueryQuestion) => `${item.baseQuestionId}` === `${id}`));
    if(state?.baseQuestionId && `${state.baseQuestionId}` === `${id}`) {
      setQueryList(queryQuestions && queryQuestions?.length > 0 && queryQuestions.filter((item: IQueryQuestion) => `${item.baseQuestionId}` === `${state?.baseQuestionId}`));
    }
  }, [queryQuestions, state, id])

  const { mutate: sendQuery } = useMutation<
    any,
    unknown,
    IQueryForm,
    unknown
  >(
    (data: IQueryForm) => addQuestionQuery(data),
    {
      onSuccess: () => {
        methods.setValue(`query${id}`, '');
        refetch();
      },
    },
  );

  const checkUnResolved = () => {
    const dataList = queryQuestions && queryQuestions?.length > 0 && queryQuestions.filter((item: IQueryQuestion) => `${item.baseQuestionId}` === `${id}`);
    return dataList?.length > 0 && dataList.filter((item: IQueryQuestion) => !item.resolved)?.length > 0;
  }

  const onSubmitQuery = (value: string) => {
    if (value &&  loggedInStudyUser?.id !== -1) {
      sendQuery({
        baseQuestionId: id,
        patientStepId: stepsData?.stepId || 0,
        folderId: stepsData?.folder?.parentFolderId || stepsData?.folder?.id || null,
        centerUserId: parseInt(`${loggedInStudyUser?.id}`, 10) || 1,
        comment: value,
      })
    } else if (value) {
      toast.error(intl.formatMessage({ id: 'query.queryError' }))
    }
  }

  useEffect(()=> {
    if (loggedInStudyUser?.role?.permissions) {
      const foundPermision = isSiteStaff || (!isSiteStaff && !hasNotAccess('ADD_QUERIES'))
      if (foundPermision && !isFreezeStudy()) {
        setIsAction(true);
      }
    }
  }, [isSiteStaff, loggedInStudyUser, isFreezeStudy])

  useEffect(() => {
    if (state && (`${state?.baseQuestionId}` === `${id}`)) {
      setShowQuery(true);
    }
  }, [state, id])

  const handleOutsideClick = useCallback((e: any, clickedOut: boolean) => {
    if (clickedOut) {
      methods.setValue(`query${id}`, '');
      setShowQuery(false);
    }
  },
    [setShowQuery],
  );

  useOutsideClick(containerRef, handleOutsideClick);

  return (
    <div ref={containerRef} className='relative flex items-center'>
      <FiAlertCircle onClick={() => setShowQuery(!showQuery)} className={`w-3 h-3 ${checkUnResolved() ? 'text-error opacity-100' : 'text-font'} cursor-pointer group-hover:opacity-100 ${showQuery ? 'opacity-100' : 'opacity-0'}`} />
      {showQuery &&
        <div className={`absolute left-4 z-20 ${queryList && queryList.length > 0 && 'mt-12'}`}>
          {queryList && queryList.length > 0 && (
            <div className='bg-gray-light w-[18rem] max-h-[19.3rem] overflow-auto rounded h-auto p-1.5 mb-2 border-gray-light border'>
              {queryList?.map((query: IQueryQuestion, i: number) => {
                if (`${query?.baseQuestionId}` === `${id}`) {
                  return (
                    <QueryBox
                      key={i} query={query} id={id}
                      showQuery={showQuery} setShowQuery={setShowQuery}
                      methods={methods} refetch={refetch}
                      isAction={isAction}
                    />
                  );
                }
              })}
            </div>
          )}
          {isAction && (<div className='w-[17rem] ml-2.5 z-20 bg-white rounded shadow'>
            <QueryInput name={`query${id}`} onSubmitQuery={(e: any) => onSubmitQuery(e)} placeholder='Create a query' methods={methods} />
          </div>)}
        </div>
      }
    </div>
  );
}

export default QueryQuestionnaire;