import React, { useState, useCallback, useRef, useEffect } from 'react'; // useState, useRef, useCallback
import clsx from 'classnames';
import { IQuestion } from 'types/questionnaire';
import { useQuestionnaireStore } from 'store/QuestionnaireStore';
import { ToggleButton, TextInput } from 'components/common';
import { useOutsideClick } from 'hooks/useOutsideClick';

let localState: any = {};

interface IProps {
  showScore?: boolean;
  onSaveQuestion: any;
  question: IQuestion;
  setOptionalPropsPopover: any;
}

const ScaleProps: React.FC<IProps> = ({ onSaveQuestion, question, showScore }: IProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const { addQuestionError } = useQuestionnaireStore();
  const [state, setState] = useState<any>({
    startValue: '',
    endValue: '',
    increment: '',
    startLegend: '',
    endLegend: '',
  });
  const [scoreChecked, setScoreChecked] = useState<boolean>(question?.isScored ?? false);

  const handleOutsideClick = useCallback(() => {
    
    onSaveQuestion({
      optionalProps: {
        isScored: scoreChecked,
        startValue: state.startValue,
        endValue: state.endValue,
        increment: state.increment,
        startLegend: state.startLegend,
        endLegend: state.endLegend,
      },
    });
  }, [setScoreChecked, state]);

  useOutsideClick(containerRef, handleOutsideClick);
  localState = { ...state, scoreChecked: scoreChecked };

  useEffect(() => {
    setState({
      ...state,
      startValue:
        question?.startValue || Number(question?.startValue) == 0 ? question?.startValue : '',
      endValue: question?.endValue || Number(question?.endValue) == 0 ? question?.endValue : '',
      increment: question?.increment ?? '',
      startLegend: question?.startLegend ?? '',
      endLegend: question?.endLegend ?? '',
    });
    return () => {
      console.log('localState: ', localState);
      if (
        +localState?.startValue < +localState?.endValue &&
        !isNaN(localState?.startValue) &&
        !isNaN(localState?.endValue)
      )
        onSaveQuestion({
          optionalProps: {
            isScored: localState?.scoreChecked,
            startValue: localState?.startValue,
            endValue: localState?.endValue,
            increment: localState?.increment,
            startLegend: localState?.startLegend,
            endLegend: localState?.endLegend,
          },
        });
    };
  }, []);

  const checkValue = (value: string, type: string) => {
    let numValue = parseFloat(value);
    numValue = Math.floor(numValue);

    if (type === 'start') {
      return numValue >= -100 && numValue <= 100;
    } else if (type === 'end') {
      return numValue >= -100 && numValue <= 100;
    } else {
      return numValue >= 1 && numValue <= (state.endValue - state.startValue ?? 20);
    }
  };

  return (
    <div
      ref={containerRef}
      className={clsx(
        'w-[207px] absolute z-10 bg-white rounded-[12px] top-0 mt-6 mr-0 -right-[10px] border-[1px] border-purple',
        addQuestionError
          ? '[&>*]:!text-error-feedback [&>button]:!border-error-feedback border-error-feedback'
          : 'border-blue-normal',
      )}
    >
      <div className='flex justify-start items-center flex-col pt-4 px-4'>
        <div className='flex justify-between items-center gap-x-[6px]'>
          <TextInput
            control={{
              value: state.startValue,
              onChange: (e: any) => {
                if (checkValue(e.target.value, 'start')) {
                  setState({ ...state, startValue: `${parseInt(e.target.value)}` });
                } else if (e.target.value === '-' || e.target.value === '') {
                  setState({ ...state, startValue: e.target.value });
                }
              },
            }}
            name={'startValue'}
            placeholder={'Start value'}
            noTranslation={true}
            className={clsx(
              'h-full !mt-0 [&>input]:h-[24px] [&>input]:mt-0 [&>input]:!p-0 pt-0 pb-0 [&>input]:rounded [&>input]:text-xs [&>input]:!text-gray-medium [&>input]:!pl-[2px]',
              state.startValue > state.endValue &&
                '[&>input]:border-1 [&>input]:!border-danger-100',
            )}
            inputClassName='!border-gray-300 w-[82px]'
          />
          <span className='text-dark'>-</span>
          <TextInput
            control={{
              value: state.endValue,
              onChange: (e: any) => {
                if (checkValue(e.target.value, 'end')) {
                  setState({ ...state, endValue: `${parseInt(e.target.value)}` });
                } else if (e.target.value === '-' || e.target.value === '') {
                  setState({ ...state, endValue: e.target.value });
                }
              },
            }}
            name={'endValue'}
            placeholder={'End value'}
            noTranslation={true}
            className={clsx(
              'h-full !mt-0 [&>input]:h-[24px] [&>input]:mt-0 [&>input]:!p-0 pt-0 pb-0 [&>input]:rounded [&>input]:text-xs [&>input]:!text-gray-medium [&>input]:!pl-[2px]',
              state.startValue > state.endValue &&
                '[&>input]:border-1 [&>input]:!border-danger-100',
            )}
            inputClassName='!border-gray-300 w-[82px]'
          />
        </div>
        <div className='mt-4 flex justify-between items-center gap-x-[6px]'>
          <TextInput
            control={{
              value: state.startLegend,
              onChange: (e: any) => {
                const value = e.target.value;
                setState({ ...state, startLegend: value });
              },
            }}
            name={'startLegend'}
            placeholder={'Start Legend'}
            noTranslation={true}
            className={clsx(
              'h-full !mt-0 [&>input]:h-[24px] [&>input]:mt-0 [&>input]:!p-0 pt-0 pb-0 [&>input]:rounded [&>input]:text-xs [&>input]:!text-gray-medium [&>input]:!pl-[2px]',
            )}
            inputClassName='!border-gray-300 w-[82px]'
          />
          <span className='text-dark'>-</span>

          <TextInput
            control={{
              value: state.endLegend,
              onChange: (e: any) => {
                const value = e.target.value;

                setState({ ...state, endLegend: value });
              },
            }}
            name={'endLegend'}
            placeholder={'End Legend'}
            noTranslation={true}
            className={clsx(
              'h-full !mt-0 [&>input]:h-[24px] [&>input]:mt-0 [&>input]:!p-0 pt-0 pb-0 [&>input]:rounded [&>input]:text-xs [&>input]:!text-gray-medium [&>input]:!pl-[2px]',
            )}
            inputClassName='!border-gray-300 w-[82px]'
          />
        </div>
        <div className='flex justify-center items-center mt-2 gap-x-3 pb-2 '>
          <span className='text-xs text-gray-medium'>Increments of</span>
          <TextInput
            control={{
              value: state.increment,
              onChange: (e: any) => {
                if (checkValue(e.target.value, 'increment')) {
                  setState({ ...state, increment: `${parseInt(e.target.value)}` });
                } else if (e.target.value === '') {
                  setState({ ...state, increment: e.target.value });
                }
              },
            }}
            name={'incrementValue'}
            placeholder={''}
            noTranslation={true}
            className={clsx(
              'h-[22px] !w-[35px] !mt-0 [&>input]:h-[24px] [&>input]:mt-0 [&>input]:!p-0 pt-0 pb-0 [&>input]:rounded [&>input]:text-xs [&>input]:!text-gray-medium [&>input]:!pl-[2px]',
            )}
            inputClassName='!border-gray-300 !w-[35px]'
          />
        </div>
        {showScore ? (
          <div className='w-full pb-2 px-3 max-h-[211px] overflow-y-auto '>
            <div className='flex justify-between items-center  px-[10%]'>
              <span className='text-gray-medium'>score</span>
              <ToggleButton
                onChange={(e) => {
                  setScoreChecked(e);
                }}
                defaultChecked={scoreChecked}
                checked={scoreChecked}
                className='h-5 [&>div>.toggle__dot]:!w-3 [&>div>.toggle__dot]:!h-3'
              />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ScaleProps;
