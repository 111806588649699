import { useMutation, useQuery } from '@tanstack/react-query';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { deleteParticipant, retrieveCenters } from 'services/api/study';
import { useParams } from 'react-router-dom';

interface IPatientDelete {
  centerId: number | string;
  id: number | string;
}

export const useDeleteParticipant = () => {
  const { studyId } = useParams();
  const intl = useIntl();
  const { refetch } = useQuery({
    queryKey: ['centers'],
    queryFn: () => studyId && retrieveCenters(studyId),
  });

  const mutation = useMutation<any, unknown, IPatientDelete, unknown>(
    ({ centerId, id }: IPatientDelete) => deleteParticipant(centerId, id),
    {
      onSuccess: () => {
        toast.success(intl.formatMessage({ id: 'patient.participantDeleteMsg' }));
        refetch();
      },
      onError(e: any) {
        toast.error(e?.response?.data?.message);
      },
    },
  );

  return mutation;
};
