import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  FunctionComponent,
  ReactNode,
} from 'react';
import { useOutsideClick } from 'hooks/useOutsideClick';
import { TextInput } from 'components/common';

type WrapperComponentProps = any;
type WrappedComponentProps = any;

interface RenameQuestionnaireProps {
  WrapperComponent?: FunctionComponent<WrapperComponentProps>;
  handleCallBack?: any;
  children: ReactNode;
  props?: WrapperComponentProps & WrappedComponentProps;
  defaultValue?: any;
  defaultIsOpen?: boolean;
  inputPlaceHolder?: string;
}

const RenameQuestionnaire: FunctionComponent<RenameQuestionnaireProps> = ({
  handleCallBack = () => {},
  children,
  defaultValue,
  defaultIsOpen = false,
  inputPlaceHolder = '',
}) => {
  const [isOpen, setIsOpen] = useState<any>(null);
  const [value, setValue] = useState(defaultValue);
  const containerRef = useRef<HTMLDivElement>(null);
  const labelRef = useRef<HTMLLabelElement>(null);

  const handleOutsideClick = useCallback(
    (event: MouseEvent | React.MouseEvent<HTMLElement, MouseEvent>, clickedOut: boolean) => {
      if (clickedOut && event.target !== labelRef.current) {
        setIsOpen(false);
      }
    },
    [setIsOpen, value],
  );

  useEffect(() => {
    if (isOpen == false) {
      handleCallBack(value);
    }
  }, [isOpen]);

  useEffect(() => {
    if (defaultIsOpen) {
      setIsOpen(true);
    }
  }, [defaultIsOpen]);

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      // handleCallBack(value);
      setIsOpen(false);
    }
  };

  useOutsideClick(containerRef, handleOutsideClick);

  return (
    <div className='w-full'>
      {isOpen ? (
        <div ref={containerRef} className='w-full'>
          <TextInput
            control={{
              value: value,
              onChange: (e: any) => setValue(e.target.value),
            }}
            name={'title1'}
            placeholder={inputPlaceHolder ?? 'Type message'}
            noTranslation={true}
            className='h-full w-full !mt-0 [&>input]:h-[24px] [&>input]:mt-0 [&>input]:pl-2 pt-0 pb-0 [&>input]:rounded-sm'
            inputClassName='border-none'
            onKeyDown={handleKeyDown}
          />
        </div>
      ) : (
        <div
          id='doubleClick'
          onDoubleClick={(e: any) => {
            e.stopPropagation();
            setIsOpen(!isOpen);
          }}
        >
          {children}
        </div>
      )}
    </div>
  );
};

export default RenameQuestionnaire;
