import React, { useState, useRef, useEffect, useCallback } from 'react';
import { createPortal } from 'react-dom';

interface IProps {
  children?: React.ReactNode;
  text?: React.ReactNode;
  className?: any;
  position?: string;
  mainClass?: string;
  arrowClass?: string;
}

const Tooltip = ({ children, text, position = 'top', className, mainClass, arrowClass }: IProps) => {
  const [visible, setVisible] = useState(false);
  const [tooltipStyle, setTooltipStyle] = useState({});
  const tooltipRef: any = useRef();
  const targetRef: any = useRef();

  const calculateTooltipPosition = useCallback(() => {
    if (targetRef.current && tooltipRef.current) {
      const targetRect = targetRef.current.getBoundingClientRect();
      const tooltipRect = tooltipRef.current.getBoundingClientRect();
      const offset = 4; // distance from the target element

      const styles: any = {
        top: {
          top: `${targetRect.top - tooltipRect.height - offset}px`,
          left: `${targetRect.left + (targetRect.width - tooltipRect.width) / 2}px`,
        },
        bottom: {
          top: `${targetRect.bottom + offset}px`,
          left: `${targetRect.left + (targetRect.width - tooltipRect.width) / 2}px`,
        },
        left: {
          top: `${targetRect.top + (targetRect.height - tooltipRect.height) / 2}px`,
          left: `${targetRect.left - tooltipRect.width - offset}px`,
        },
        right: {
          top: `${targetRect.top + (targetRect.height - tooltipRect.height) / 2}px`,
          left: `${targetRect.right + offset}px`,
        },
      };
      setTooltipStyle(styles[position]);
    }
  }, [position]);

  const hideTooltip = () => setVisible(false);

  const handleMouseEnter = () => {
    setVisible(true);
  };

  useEffect(() => {
    if (visible) {
      calculateTooltipPosition();
    }
  }, [visible, calculateTooltipPosition]);

  useEffect(() => {
    window.addEventListener('resize', calculateTooltipPosition);
    return () => {
      window.removeEventListener('resize', calculateTooltipPosition);
    };
  }, [calculateTooltipPosition]);

  return (
    <div className={`relative flex flex-col items-center ${mainClass}`} ref={targetRef} onMouseEnter={handleMouseEnter} onMouseLeave={hideTooltip}>
      {text && visible && createPortal(
        <div
          ref={tooltipRef}
          className={`absolute z-50 items-center p-2 text-xs text-white bg-blue-oil rounded ${className}`}
          style={{ ...tooltipStyle, position: 'fixed' }}
        >
          {text}
          <div
            className={`absolute w-3 h-3 bg-blue-oil ${position === 'top' ? '-bottom-1.5 translate-x-1/2 rotate-45' : ''}
                        ${position === 'bottom' ? 'top-0 translate-x-1/2 rotate-45' : ''}
                        ${position === 'left' ? 'right-0 translate-y-1/2 rotate-45' : ''}
                        ${position === 'right' ? '-left-[0.4rem] translate-y-1/2 rotate-45' : ''}
                        ${arrowClass}`}
          ></div>
        </div>,
        document.body
        )}
      <div className='w-full'>
        {children}
      </div>
    </div>
  );
};

export default Tooltip;