import DragDropList from '../StudyBuilder/components/DragableQuestionsContainer/DragDropList';
import { ITask } from 'types/flow';
import { useState, useEffect } from 'react';
import { insertAtIndex } from 'utils/array';
import { getMaxSortId } from 'utils/number';
import { IQuestion } from 'types/questionnaire';
import { useQuestionnaire } from './useQuestionnaire';
import { useParams } from 'react-router-dom';

interface IProps {
  selectedTask: ITask | null;
  refetchFlows?: any;
}
const CompleteFeasibilityContent = ({ selectedTask, refetchFlows }: IProps) => {
  const [questions, setQuestions] = useState(selectedTask?.questionnaire?.questions ?? []);
  const {
    handleAddQSToQuestionnaire,
    handleRemoveQuestionApi,
    handleDuplicateQuestion,
    handleReorderQuestions,
  } = useQuestionnaire({
    selectedQuestionnairy: { questionnaire: selectedTask?.questionnaire },
    setQuestions,
    questions,
    refetchFlows,
  });
  const { questionnaireId: questionnaireIdStr } = useParams();
  const qssId = questionnaireIdStr?.split('-')?.[1];
  useEffect(() => {
    if (selectedTask?.questionnaire) {
      setQuestions(selectedTask?.questionnaire?.questions);
    }
  }, [selectedTask]);

  useEffect(() => {
    const fndEmptyItem: any = questions?.findIndex((it: IQuestion) => !it?.questionTitle);

    if (fndEmptyItem === -1) {
      setQuestions(
        insertAtIndex(
          [...questions],
          {
            id: 0,
            questionTitle: '',
            sortOrder: getMaxSortId(questions) + 1,
            qssId: qssId,
          },
          questions?.length - 1,
        ),
      );
    }
    const tmpQuestions: any = [...questions];
    tmpQuestions.map((qs: IQuestion) => {
      if (qs?.parentQuestionId) {
        tmpQuestions.forEach((el: any, folderIndx: number) => {
          if (qs?.parentQuestionId === el?.id) {
            tmpQuestions[folderIndx]['children'] = el['children']
              ? tmpQuestions[folderIndx]['children']?.findIndex((it: any) => it.id === qs.id) < 0
                ? [...el['children'], qs]
                : [...el['children']]
              : [qs];
          }
        });
      }
    });

    // set sub title questions
    tmpQuestions.map((qs: IQuestion) => {
      if (qs?.titleQuestionId) {
        tmpQuestions.forEach((el: any, folderIndx: number) => {
          if (qs?.titleQuestionId === el?.id) {
            tmpQuestions[folderIndx]['titleQuestions'] = el['titleQuestions']
              ? tmpQuestions[folderIndx]['titleQuestions']?.findIndex(
                  (it: any) => it.id === qs.id,
                ) < 0
                ? [...el['titleQuestions'], qs]
                : [...el['titleQuestions']]
              : [qs];
          }
        });
      }
    });
  }, [questions, selectedTask]);
  const handleOnSaveQuestion = (
    tmpQuestion: IQuestion & { optionalProps?: any; qssId?: any },
    isSubtitle?: boolean,
  ) => {
    const { id, questionTitle, optionalProps = {} } = tmpQuestion;
    const tmpQuestions = isSubtitle ? questions : questions;
    const fndItem: any = tmpQuestions?.find((it: IQuestion) => it?.id === id && it?.id !== 0);

    if (fndItem) {
      handleAddQSToQuestionnaire({
        id: `${id}`,
        questionTitle: questionTitle,
        selectionType: tmpQuestion?.questionType ? tmpQuestion?.questionType : 'TEXT_INPUT',
        required: tmpQuestion?.required,
        maxCharacters: 100,
        ...(optionalProps?.isScored != null ? { isScored: optionalProps?.isScored } : {}),
        inputPlaceHolder: 'placeholder ...',
        sortOrder: fndItem?.sortOrder,
        qssId: tmpQuestion?.qssId,
        optionalProps: {
          ...optionalProps,
          ...(tmpQuestion?.subtitle ? { subtitle: tmpQuestion?.subtitle } : {}),
        },
        ...(tmpQuestion?.parentQuestionId
          ? { parentQuestionId: tmpQuestion?.parentQuestionId }
          : {}),
        ...(tmpQuestion?.titleQuestionId ? { titleQuestionId: tmpQuestion?.titleQuestionId } : {}),
      });
    } else {
      handleAddQSToQuestionnaire({
        questionTitle: questionTitle,
        selectionType: tmpQuestion?.questionType ? tmpQuestion?.questionType : 'TEXT_INPUT',
        required: tmpQuestion?.required,
        qssId: tmpQuestion?.qssId,
        maxCharacters: 100,
        ...(optionalProps?.isScored != null ? { isScored: optionalProps?.isScored } : {}),
        inputPlaceHolder: 'placeholder ...',
        optionalProps: {
          ...optionalProps,
          ...(tmpQuestion?.subtitle ? { subtitle: tmpQuestion?.subtitle } : {}),
        },
        ...(tmpQuestion?.parentQuestionId
          ? { parentQuestionId: tmpQuestion?.parentQuestionId }
          : {}),
        ...(tmpQuestion?.titleQuestionId ? { titleQuestionId: tmpQuestion?.titleQuestionId } : {}),
      });
    }
  };
  return (
    <div className='mt-10'>
      <h3 className='text-left text-base text-blue-oil font-light leading-6 mb-3'>Questionnaire</h3>
      <div className='bg-white'>
        <DragDropList
          questions={questions
            ?.filter((qs: IQuestion) => !qs.parentQuestionId && !qs?.titleQuestionId)
            ?.sort((a: any, b: any) => a.sortOrder - b.sortOrder)
            .map((qs: IQuestion) => ({ ...qs, qssId: qssId }))}
          repeatedQuestion={true}
          allQuestions={questions}
          filterRepeatedInput={true}
          className=' [&>div>div>.value]:bg-white rounded-[4px]'
          isTitleQuestion={false}
          filterTitleInput={false}
          filterRequired={false}
          onRemoveQuestionApi={handleRemoveQuestionApi}
          onDuplicateQuestion={handleDuplicateQuestion}
          handleOnSaveQuestion={handleOnSaveQuestion}
          handleReorderQuestionsFC={handleReorderQuestions}
          workflowQuestionnaire={selectedTask?.questionnaire}
          handleWorkflowOnSaveQuestion={handleOnSaveQuestion}
          shouldFetchSteps={false}
          shouldFetchSF={false}
        />
      </div>
    </div>
  );
};

export default CompleteFeasibilityContent;
