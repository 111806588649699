import React, { useState } from 'react'; // useState, useRef, useCallback
import clsx from 'classnames';
import { IQuestion } from 'types/questionnaire';
import { useQuestionnaireStore } from 'store/QuestionnaireStore';
import { FiStar } from 'react-icons/fi';
import { ToggleButton } from 'components/common';

enum RATING_TYPES {
  THREE = 'THREE',
  FIVE = 'FIVE',
  TEN = 'TEN',
}

interface IProps {
  showScore?: boolean;
  onSaveQuestion: any;
  question: IQuestion;
  setOptionalPropsPopover: any;
}

const RatingProps: React.FC<IProps> = ({ showScore, onSaveQuestion, question }: IProps) => {
  const { addQuestionError } = useQuestionnaireStore();
  const [value, setValue] = useState<any>(question?.stars);
  const [scoreChecked, setScoreChecked] = useState<boolean>(question?.isScored ?? false);
  // if (!showScore) return null;
  return (
    <div
      className={clsx(
        'w-[175px] absolute z-10 bg-white rounded-[12px] top-0 mt-6 mr-0 -right-[10px] border-[1px] border-purple',
        addQuestionError
          ? '[&>*]:!text-error-feedback [&>button]:!border-error-feedback border-error-feedback'
          : 'border-blue-normal',
      )}
    >
      <div className='flex justify-start items-center flex-col'>
        <div className='w-full pt-1 pb-2 px-3 max-h-[211px] overflow-y-auto'>
          {options.map((option: { title: string; value: string }) => (
            <div
              onClick={() => {
                setValue(option.value);
                onSaveQuestion({
                  optionalProps: {
                    stars: option.value ?? question?.stars,
                    isScored: question?.isScored,
                  },
                });
              }}
              className={clsx(
                'flex justify-center hover:bg-blue-ocean-light items-center relative border-2 w-full h-[30px] gap-x-1  rounded-[4px] my-2',
                option.value === value ? 'bg-blue-ocean-light' : '',
                addQuestionError && option.value === value
                  ? 'border-error-feedback [&>span]:text-error-feedback'
                  : 'border-gray-300',
              )}
              key={option.value}
            >
              <FiStar
                className={
                  addQuestionError && option.value === value
                    ? 'text-error-feedback w-4 h-4'
                    : 'text-gray-medium w-4 h-4'
                }
              />
              <span className=' text-center text-gray-medium'>{option.title}</span>
            </div>
          ))}
          {showScore ? (
            <div className='flex justify-between items-center px-1'>
              <span className='text-gray-medium'>score</span>
              <ToggleButton
                onChange={(e) => {
                  setScoreChecked(e);

                  onSaveQuestion({
                    optionalProps: {
                      stars: question?.stars,
                      isScored: e,
                    },
                  });
                }}
                defaultChecked={scoreChecked}
                checked={scoreChecked}
                className='h-5 [&>div>.toggle__dot]:!w-3 [&>div>.toggle__dot]:!h-3'
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default RatingProps;

const options = [
  { title: '3 stars', value: RATING_TYPES.THREE },
  { title: '5 stars', value: RATING_TYPES.FIVE },
  { title: '10 stars', value: RATING_TYPES.TEN },
];
