export const swapElements = async (newArr: any, index1: number, index2: number) => {
  const arr = [...newArr];
  if (index1 < 0 || index1 >= arr.length || index2 < 0 || index2 >= arr.length) {
    return;
  }

  // Swap sortOrder values at the specified indices
  const tempSortOrder = arr[index1].sortOrder;
  arr[index1].sortOrder = arr[index2].sortOrder;
  arr[index2].sortOrder = tempSortOrder;

  return arr;
};
interface Item {
  // Define the structure of your objects here
}

export function insertAtIndex<T extends Item>(items: T[], newItem: T, index: number): T[] {
  const newItems = [...items]; // Create a copy of the array
  if (index === newItems.length - 1) {
    return newItems.concat(newItem);
  } else if (index < 0 || index > newItems.length) {
    throw new Error('Invalid index'); // Handle invalid index
  }

  newItems.splice(index, 0, newItem); // Insert at the specified index

  return newItems;
}

interface Item {
  // Define the structure of your objects here
}

export function substituteValuesOfArray<T extends Item>(
  items: T[],
  sourceIndex: number,
  destIndex: number,
  key: keyof T,
): T[] {
  const newItems = [...items]; // Create a copy of the array

  if (sourceIndex !== -1 && destIndex !== -1) {
    const tempValue = newItems[sourceIndex][key];
    newItems[sourceIndex][key] = newItems[destIndex][key];
    newItems[destIndex][key] = tempValue;
  } else {
    throw new Error('Source or destination item not found'); // Handle missing items
  }

  return newItems;
}

interface ObjectItem {
  [key: string]: any;
}

export const hasObjectWithKeyValue = (objects: ObjectItem[], key: string, value: any): boolean => {
  return objects.some((object) => object[key] === value);
};

interface IItem {
  id: string;
  children: IItem[];
}
export function findItemById<T extends IItem>(data: T[], targetId: string): T | undefined {
  // export const findItemById = (data: Item[], targetId: string): Item | undefined => {
  for (const item of data) {
    if (item.id === targetId) {
      return item;
    }
    if (item?.children?.length > 0) {
      const foundItem: any = findItemById(item.children, targetId);
      if (foundItem) {
        return foundItem;
      }
    }
  }
  return undefined;
}

// interface ISortItem {
//   id: string;
//   content: string;
//   sortOrder: number;
//   children: ISortItem[];
// }

// export interface ISortItem {
//   id: UniqueIdentifier;
//   content: string;
//   children: ISortItem[];
//   collapsed?: boolean;
//   sortOrder: number;
// }

export const sortItems = (items: any[]): any[] => {
  // Sort items based on sortOrder
  items.sort((a, b) => a.sortOrder - b.sortOrder);

  // Recursively sort children
  items.forEach((item) => {
    if (item?.children?.length > 0) {
      item.children = sortItems(item.children);
    }
  });

  return items;
};

export const updateSortOrder = (items: any[], startOrder: number): any => {
  let order = startOrder;
  items.forEach((item) => {
    item.sortOrder = order++;
    if (item.children && item.children.length > 0) {
      return updateSortOrder(item.children, order);
    }
  });

  return items;
};

export const alphaSort = (arrList: any, type: string, arrkey: string, prop2 = '') => {
  return arrList
    .sort((a: any, b: any) => {
      if (type === 'up') {
        if (prop2 ? a[arrkey][prop2] > b[arrkey][prop2] : a[arrkey] > b[arrkey]) return -1;
        if (prop2 ? a[arrkey][prop2] < b[arrkey][prop2] : a[arrkey] < b[arrkey]) return 1;
        return 0;
      } else {
        if (prop2 ? a[arrkey][prop2] < b[arrkey][prop2] : a[arrkey] < b[arrkey]) return -1;
        if (prop2 ? a[arrkey][prop2] > b[arrkey][prop2] : a[arrkey] > b[arrkey]) return 1;
        return 0;
      }
    })
    .map((a: any) => a);
};

// Define a generic type for the key
type KeyType<T> = keyof T;

export const areDifferentArrays = <T>(array1: T[], array2: T[], key: KeyType<T>): boolean => {
  if (array1.length !== array2.length) {
    return true; // If the lengths are different, the arrays are different
  }

  const array1Keys = array1.map((item) => item[key]);
  const array2Keys = array2.map((item) => item[key]);

  return !array1Keys.every((key, index) => key === array2Keys[index]);
};

export const areArraysEqual = <T>(array1: T[], array2: T[]): boolean => {
  if (array1.length !== array2.length) {
    return false;
  }

  for (let i = 0; i < array1.length; i++) {
    if (array1[i] !== array2[i]) {
      return false;
    }
  }

  return true;
};
