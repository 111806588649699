import { axiosClient } from 'config/axios';
import { IAppointmentForm } from 'pages/Study/ParticipantPages/ParticipantAppointment/components/NewMeetingModal';
import { ICenter } from 'types/centers';
import axios from 'axios';

export interface IAppointment {
  id: number,
  appointmentLocationType: string,
  centerUserTimeslot: {
      id: number,
      beginDate: string,
      endDate: string,
      available: false,
      createdAt: string,
      updatedAt: string
  },
  centerUser: ICenter,
  appointment: {
      id: number,
      appointmentType: string,
      appointmentStatus: string,
      videoChannel: string,
      organizer: string,
      title: string,
      note: string,
      patient: any,
      createdAt: string,
      updatedAt: string
  },
  createdAt: string,
  updatedAt: string
}

export const MeetingStatus = {
  CONFIRMED: 'accept',
  TENTATIVE: 'tentative',
  DECLINED: 'decline'
};

export const MeetingStatusR = {
  CONFIRMED: 'CONFIRMED',
  TENTATIVE: 'TENTATIVE',
  DECLINED: 'DECLINED'
};

export const fetchSlots = async (patientId: number, studyUserId: number, date: any) => {
  const { data } = await axiosClient.get(`patients/${patientId}/centers/users/${studyUserId}/slots/dates/${date}`);
  return data;
};

export const addAppointments = async (payload: IAppointmentForm) => {
  const { data } = await axiosClient.post('appointments/clinicians', payload);
  return data;
};

export const fetchAppointments: any = async (patientId: number, studyUserId: number) => {
  const { data } = await axiosClient.get(`appointments/clinicians/patients/${patientId}/users/${studyUserId}`);
  // const { data } = await axiosClient.get('doctors/appointments');
  return data;
};

export const deleteAppointment = async (id: number) => {
  const { data } = await axiosClient.delete(`appointments/clinicians/${id}`);
  // const { data } = await axiosClient.get('doctors/appointments');
  return data;
};

export const editAppointment = async (id: number, payload: any) => {
  const { data } = await axiosClient.put(`appointments/clinicians/${id}`, payload);
  // const { data } = await axiosClient.get('doctors/appointments');
  return data;
};

export const respondAppointment = async (id: number, status: string) => {
  const { data } = await axiosClient.put(`appointments/clinicians/${id}/respond?appointmentStatus=${status}`);
  // const { data } = await axiosClient.get('doctors/appointments');
  return data;
};

export const endAppointment = async (id: number) => {
  const { data } = await axiosClient.put(`appointments/clinicians/${id}/end`);
  // const { data } = await axiosClient.get('doctors/appointments');
  return data;
};

export const getAppointmentVideo = async (appId: number | string) => {
  const { data } = await axiosClient.get(`appointments/${appId}/videos`);
  return data;
};

export const getPatientSteps = async (patientId: number | string) => {
  const { data } = await axiosClient.get(`patients/${patientId}/steps`);
  return data;
};

export const getPatientData = async (id: number | string) => {
  const { data } = await axiosClient.get(`patients/${id}`);
  return data;
};

export const getUserStatusAgora = async (appid: string, uid: any, channelName: string) => {
  const { data } = await axios.get(`https://api.agora.io/dev/v1/channel/user/property/${appid}/${uid}/${channelName}`, {
    headers: {
      Authorization: `Basic ${process.env.REACT_APP_AGORA_BASIC_AUTH}`
    }
  });
  return data;
}