import { useEditParticipant } from 'hooks/useEditParticipant';
import { useEffect, useRef, useState } from 'react';

type EditTextFieldProps = {
  setEditMode: React.Dispatch<
    React.SetStateAction<{
      firstName: boolean;
      phoneNumber: boolean;
      icfVersion: boolean;
    }>
  >;
  editMode: any;
  name: string;
  item: any;
  currentValue: string;
};

const EditTextField = ({ setEditMode, name, editMode, item, currentValue }: EditTextFieldProps) => {
  const ref = useRef<HTMLInputElement>(null);
  const [error, setError] = useState(false);
  const [value, setValue] = useState(currentValue || '');
  const mutation = useEditParticipant();

  useEffect(() => {
    if (editMode[name]) {
      document.addEventListener('click', handleClickOutside, true);
      return () => {
        document.removeEventListener('click', handleClickOutside, true);
      };
    }
  });

  const sendUpdateData = () => {
    if (name === 'firstName' && item?.userProfile?.firstName !== value) {
      mutation.mutate({
        id: item?.id,
        email: item?.userProfile?.userAccount?.email,
        phoneNumber: item?.userProfile?.userAccount?.phoneNumber,
        icfVersion: item?.icfVersion,
        [name]: value,
      });
    }

    if (name === 'phoneNumber' && item?.userProfile?.userAccount?.phoneNumber !== value) {
      mutation.mutate({
        id: item?.id,
        firstName: item?.userProfile?.firstName,
        email: item?.userProfile?.userAccount?.email,
        icfVersion: item?.icfVersion,
        [name]: value,
      });
    }

    if (name === 'icfVersion' && item?.icfVersion !== value) {
      mutation.mutate({
        id: item?.id,
        firstName: item?.userProfile?.firstName,
        email: item?.userProfile?.userAccount?.email,
        phoneNumber: item?.userProfile?.userAccount?.phoneNumber,
        [name]: value,
      });
    }
  }

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      if (!value && name !== 'phoneNumber') {
        setError(true);
        return;
      }
      setError(false);
      sendUpdateData();
      setEditMode({ ...editMode, [name]: false });
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      sendUpdateData();
      setEditMode({ ...editMode, [name]: false });
    }
  };

  return (
    <input
      ref={ref}
      type={`${name === 'email' ? 'email' : (name === 'phoneNumber') ? 'number' : 'text'}`}
      className={`h-[28px] [&::-webkit-inner-spin-button]:appearance-none border-[0.5px] rounded px-3 py-[6px] font-sans font-normal border-[#0973D4] placeholder:text-[#DDDDDD] focus:outline-none ${error ? 'border-[red]' : ''}`}
      placeholder={`${name === 'firstName' ? 'Add Name Patient' : name === 'email' ? 'Add Email Address' : `Add ${name}`}`}
      onKeyDown={handleKeyDown}
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
      }}
    />
  );
};

export default EditTextField;
