import { useState, useEffect } from 'react';
import RoleCard from './RoleCard';
import { IRoleCard, IPermissionItem } from 'types/roles';
import Permissions from '../Permissions';
import { FormattedMessage } from 'react-intl';

interface IProps {
  permissions: IPermissionItem[] | undefined;
  roles: IRoleCard[];
  selectedPermissions: number[];
  setSelectedPermissions: any;
  rolesRefetch: any;
}

const Roles: React.FC<IProps> = ({
  selectedPermissions,
  setSelectedPermissions,
  permissions,
  roles = [],
  rolesRefetch
}: IProps) => {
  const [selectedRole, setSelectedRole] = useState<number | null>(null);
  const [filteredPermissions, setFilteredPermissions] = useState<IPermissionItem[] | undefined>([]);

  const getFirstItemFromList = (items: any[]): any | null => {
    if (items.length === 0 || !Array.isArray(items[0]?.list) || items[0].list.length === 0) {
      return null;
    }

    return items[0].list[0];
  };

  useEffect(() => {
    if (permissions && permissions?.length > 0 && selectedRole) {
      const foundRole = roles.find((r: IRoleCard) => {
        if (r?.list?.length > 0 && r?.list?.find((rlist: any) => `${rlist.id}` === `${selectedRole}`)) {
          return r;
        } else if (r?.list?.length === 0 && `${r?.list[0].id}` === `${selectedRole}`) {
          return r;
        }
      });
      const siteStaffPermissions = [
        'VIEW_OTHER_PATIENTS',
        'ECONSENT',
        'ADD_SITE_STAFF'
      ]
      const siteStaffilterPermissions = permissions.filter((p: IPermissionItem) => {
        if (siteStaffPermissions.find((sp: any) => sp === p.name)) return p;
      });
      const filteredPermissions = permissions.filter((p: IPermissionItem) => {
        if (p.name === 'ADD_SITE_STAFF' || (!siteStaffPermissions.find((sp: any) => sp === p.name))) return p;
      });

      setFilteredPermissions(foundRole?.category === 'SITE_STAFF' ? siteStaffilterPermissions : filteredPermissions);
    }
  }, [permissions, selectedRole, roles]);

  useEffect(() => {
    if (!selectedRole) {
      const firstRole = getFirstItemFromList(roles);

      if (firstRole?.id) {
        setSelectedPermissions(firstRole?.permissions.map((p: IPermissionItem) => p.id));

        setSelectedRole(firstRole?.id);
      }
    }
  }, [roles]);

  const emptyList =
    roles[0]?.list?.length == 0 &&
    roles[1]?.list?.length == 0 &&
    roles[2]?.list?.length == 0 &&
    roles[3]?.list?.length == 0;

  return (
    <div className='bg-white mt-4 mb-6 border-[1px] border-purple rounded-lg '>
      <h2 className='font-sans p-6 text-blue-ocean-deep text-xl font-medium text-left leading-[34px] border-b-1 border-b-purple'>
        <FormattedMessage id="users.roles" />
      </h2>
      <div className='flex pt-[23px] mb-8 pr-11'>
        <div className='w-[285px] mr-[43px] flex flex-col justify-start pl-[30px] items-start h-[500px] overflow-auto'>
          {roles.map((roleCard: IRoleCard, index: number) => (
            <RoleCard
              key={roleCard.id}
              selectedRole={selectedRole}
              setSelectedRole={setSelectedRole}
              barColor={barColors[index]}
              {...roleCard}
              setSelectedPermissions={setSelectedPermissions}
              selectedPermissions={selectedPermissions}
              rolesRefetch={rolesRefetch}
            />
          ))}
        </div>
        {filteredPermissions?.length && !emptyList ? (
          <div className='flex-1'>
            <Permissions
              roles={roles}
              list={filteredPermissions}
              selectedPermissions={selectedPermissions}
              setSelectedPermissions={setSelectedPermissions}
              selectedRole={selectedRole}
              rolesRefetch={rolesRefetch}
              selectedRoleTitle={''} // {findItemById(roles1, selectedRole)?.name ?? ''}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Roles;
export const barColors = [
  'bg-blue-ocean-deep',
  'bg-blue-normal',
  'bg-blue-caribbean',
  'bg-rose-light',
];
// const roles1: IRoleCard[] = [
//   {
//     // name: 'General admin',
//     category: 'Full Administrative ',
//     permissions: [],
//     id: 1,
//     list: [
//       {
//         name: 'General admin',
//         category: 'Full Administrative ',
//         permissions: [
//           { id: 1, name: 'View dashboard' },
//           { id: 2, name: 'Add dashboard' },
//           { id: 3, name: 'View dashboard' },
//           { id: 4, name: 'Add dashboard' },
//         ],
//         id: 1,
//       },
//       {
//         name: 'General admin 1',
//         category: 'Full Administrative ',
//         permissions: [
//           { id: 1, name: 'View users' },
//           { id: 2, name: 'Add users' },
//         ],
//         id: 2,
//       },
//     ],
//   },
//   {
//     // name: 'Data activities ',
//     category: 'Data activities  ',
//     permissions: [],
//     id: 2,
//     list: [
//       {
//         name: 'Data Manager',
//         category: 'Data activities  ',
//         permissions: [
//           { id: 1, name: 'View queries' },
//           { id: 2, name: 'Add queries' },
//           { id: 3, name: 'Delete queries' },
//           { id: 4, name: 'View queries' },
//           { id: 5, name: 'Add queries' },
//           { id: 6, name: 'Delete queries' },
//         ],
//         id: 3,
//       },
//       {
//         name: 'Test permission',
//         category: 'Data activities  ',
//         permissions: [{ id: 1, name: 'View patients' }],
//         id: 4,
//       },
//     ],
//   },
//   {
//     category: 'Clinical Operations',
//     permissions: [],
//     id: 2,
//     list: [
//       {
//         name: 'CRA',
//         category: 'CRA',
//         permissions: [
//           { id: 1, name: 'View queries' },
//           { id: 2, name: 'Add queries' },
//           { id: 3, name: 'Delete queries' },
//           { id: 4, name: 'View queries' },
//           { id: 5, name: 'Add queries' },
//           { id: 6, name: 'Delete queries' },
//         ],
//         id: 5,
//       },
//       {
//         name: 'Clinical study manager',
//         category: 'Clinical study manager',
//         permissions: [{ id: 1, name: 'View patients' }],
//         id: 6,
//       },
//     ],
//   },
//   {
//     category: 'Site staff',
//     permissions: [],
//     id: 2,
//     list: [
//       {
//         name: 'Investigator',
//         category: 'Investigator',
//         permissions: [
//           { id: 1, name: 'View queries' },
//           { id: 2, name: 'Add queries' },
//           { id: 3, name: 'Delete queries' },
//           { id: 4, name: 'View queries' },
//           { id: 5, name: 'Add queries' },
//           { id: 6, name: 'Delete queries' },
//         ],
//         id: 7,
//       },
//       {
//         name: 'Site nurse',
//         category: 'Site nurse',
//         permissions: [{ id: 1, name: 'View patients' }],
//         id: 8,
//       },
//       {
//         name: 'Site admin',
//         category: 'Site admin',
//         permissions: [{ id: 1, name: 'View patients' }],
//         id: 9,
//       },
//       {
//         name: 'Research technician',
//         category: 'Research technician',
//         permissions: [{ id: 1, name: 'View patients' }],
//         id: 10,
//       },
//       {
//         name: 'Guest user test A',
//         category: 'Guest user test A',
//         permissions: [{ id: 1, name: 'View patients' }],
//         id: 10,
//       },
//     ],
//   },
// ];
