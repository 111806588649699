import { PolygonCheck } from 'components/common/Icons'
import { format } from 'date-fns';
import { FormattedMessage, useIntl } from 'react-intl'
import { useQuestionnaireStore } from 'store/QuestionnaireStore';

const MeetingCompleteCard = ({ meetingData, isSiteStaff }: any) => {
  const intl = useIntl();
  const { setIsDoneMeeting } = useQuestionnaireStore();

  return (
    <div onClick={() => setIsDoneMeeting(0)} className='w-[20.813rem] pt-[1.375rem] pb-[1.063rem] px-4 shadow bg-white rounded-xl flex flex-col justify-center items-center'>
      <PolygonCheck className='!w-[9.25rem] !h-[9.25rem]' />
      <p className='font-serif text-[32px] leading-[2.75rem] mt-[1.063rem] text-center text-blue-ocean-deep'>
        <FormattedMessage id="appointment.done" />
      </p>
      {isSiteStaff && meetingData?.endDate && `${new Date(meetingData?.endDate)}` !== 'Invalid Date'  ? (
        <p className='text-lg leading-[1.5rem] max-w-[13.313rem] text-center text-gray-dark tracking-[0.15p] mt-3'>
          <FormattedMessage id="appointment.meetingCompleteOn" /> <span className='font-bold'>{format(new Date(meetingData?.endDate), 'dd/MM/yyyy')}</span> at <span className='font-bold'>{format(new Date(meetingData?.endDate), 'HH:mm')}</span>.
        </p>
      ) : (
        <>
          {meetingData && meetingData?.endDate ? (
            <p className='text-lg leading-[1.5rem] max-w-[13.313rem] text-center text-gray-dark tracking-[0.15p] mt-3' dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: `appointment.meeting${meetingData?.meetingNo}Complete` }) }}></p>
          ) : (
            <p className='text-lg leading-[1.5rem] max-w-[13.313rem] text-center text-gray-dark tracking-[0.15p] mt-3' dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'appointment.consentFormSigned' }) }}></p>
          )}
        </>
      )}
    </div>
  )
}

export default MeetingCompleteCard