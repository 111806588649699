import { axiosClient } from 'config/axios';
import { IIdVerification } from 'types/step';
import { throwError } from 'utils/throwError';
const DEFAULT_RESOURCE = '/id-verification';

export const get = async () => {
  try {
    const { data } = await axiosClient.get<IIdVerification[]>(`${DEFAULT_RESOURCE}`);
    return data;
  } catch (err) {
    throwError(err);
  }
};
