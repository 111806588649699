import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FiLoader, FiMail, FiUserCheck } from 'react-icons/fi';
import { FormProvider, useForm } from 'react-hook-form';
import { patientRecruiments } from '../../../../services/api/patients';
import { format } from 'date-fns';
import { IoIosCheckmarkCircleOutline } from 'react-icons/io';

const PatientRecruitment = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<any>(null);

  useEffect(() => {
    setIsLoading(false);
    setData(patientRecruiments);
  }, [patientRecruiments]);

  const intl = useIntl();
  const methods = useForm();

  const renderStatus = (status: string) => {
    switch (status) {
      case 'INVITED':
        return <div className='flex items-center'>
          <FiMail className='text-blue-ocean-deep w-3.5	h-3.5 mr-2'/>
          <p><FormattedMessage id={`patient-recruitment.${status}`} /></p>
        </div>
      case 'ACCEPTED':
        return <div className='flex items-center'>
          <FiUserCheck className='text-blue-normal w-3.5 h-3.5 mr-2'/>
          <p><FormattedMessage id={`patient-recruitment.${status}`} /></p>
        </div>
      case 'ACTIVE':
        return <div className='flex items-center'>
          <IoIosCheckmarkCircleOutline className='text-green-success w-3.5 h-3.5 mr-2'/>
          <p><FormattedMessage id={`patient-recruitment.${status}`} /></p>
        </div>
    }
  }

  const renderEligibility = (eligible: boolean) => {
    if (eligible) {
      return <div className='p-1.5 bg-green-confirmation rounded-[2px]'><FormattedMessage id='yes' /></div>
    } else {
      return <div className='p-1.5 bg-error-feedback text-white rounded-[2px]'><FormattedMessage id='no' /></div>
    }
  }

  const headerItems: any = [
    {
      label: intl.formatMessage({ id: 'patient-enrollment.patientId' }),
      value: 'patientId',
    },
    {
      label: intl.formatMessage({ id: 'patient-recruitment.patientName' }),
      value: 'patientName'
    },
    {
      label: intl.formatMessage({ id: 'patient-recruitment.prescreenedPatients' }),
      value: 'prescreenedPatients',
      renderCol: (value: any, record: any) => {
        return (
          <div className='flex justify-between w-[150px] max-w-[150px] items-center'>
            {value}
            <div className='border border-blue-normal text-xs rounded-[2px] text-blue-normal p-1 ml-2'>{record?.prescreenedPatientsTotal}/10</div>
          </div>
        )
      }
    },
    {
      label: intl.formatMessage({ id: 'patient-recruitment.prescreenedOn' }),
      value: 'prescreenedOn'
    },
    {
      label: intl.formatMessage({ id: 'patient-recruitment.eligibility' }),
      value: 'eligibility',
      renderCol: (value: any) => renderEligibility(value)
    },
    {
      label: intl.formatMessage({ id: 'patient-recruitment.verifiedOn' }),
      value: 'verifiedOn',
      renderCol: (value: any) => format(new Date(value), 'dd MMM yyyy, HH:mm')
    },
    {
      label: intl.formatMessage({ id: 'patient-enrollment.site' }),
      value: 'site'
    },
    {
      label: intl.formatMessage({ id: 'patient-recruitment.investigatorName' }),
      value: 'investigatorName'
    },
    {
      label: intl.formatMessage({ id: 'patient-recruitment.inviteStatus' }),
      value: 'inviteStatus',
      renderCol: (value: any) => renderStatus(value)
    },
    {
      label: intl.formatMessage({ id: 'patient-recruitment.invitedOn' }),
      value: 'invitedOn',
      renderCol: (value: any) => format(new Date(value), 'dd MMM yyyy, HH:mm')
    },
  ];

  return (
    <div className='flex flex-col justify-start w-full' id='wrapper'>
      <div className='flex justify-between items-end'>
        <h1 className='text-left text-2xl mt-9 mb-4 pl-4 text-blue-ocean-deep font-sans text-[40px]'>
          <FormattedMessage id='patient-recruitment' />
        </h1>
      </div>
      <div className='bg-white mt-4 pt-0 rounded-lg w-full max-w-[calc(100vw_-_274px)] h-[calc(100vh_-_185px)]  overflow-auto no-scrollbar relative border-[1px] border-purple '>
        <img
          src='/assets/images/bg-logo.png'
          className='w-[413px] h-[378px] absolute bottom-[4.875rem] left-1/2 -translate-x-1/2'
          alt='logo'
        />
        <div className='relative pt-4'>
          {isLoading ? (
            <div className='p-4 text-center flex justify-center items-center w-full'>
              <FiLoader className='w-20 h-20 animate-spin place-self-center text-blue-ocean-deep' />
            </div>
          ) : (
            <div className='' id='form-container'>
              <div className='inline-block min-w-full relative'>
                <div className='h-[calc(100vh-230px)]'>
                  <FormProvider {...methods}>
                    <form>
                      <table className='mb-10 bg-white w-full min-w-full table-auto'>
                        <thead className='sticky top-0 bg-white'>
                          <tr>
                            {headerItems.map((item: any) => (
                              <th
                                key={item.label}
                                className='px-4 py-3 text-left font-semibold text-blue-ocean-deep text-base tracking-wider min-w-[160px] '
                              >
                                {item.label}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {data?.map((row: any) => (
                            <tr key={row.id} className='hover:bg-blue-very-light group'>
                              {headerItems.map((column: any) => (
                                <td
                                  key={column.label}
                                  className='px-4 py-3 text-left text-dark border-b text-sm border-gray-light' // Adjust styles as needed
                                >
                                  <div className='flex group'>
                                    {column?.renderCol
                                      ? column.renderCol?.(row[column.value], row)
                                      : row[column.value]}
                                  </div>
                                </td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </form>
                  </FormProvider>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PatientRecruitment;