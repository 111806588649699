import { addMonths, format } from 'date-fns';
import { FormattedMessage, useIntl } from 'react-intl';
import Select from 'react-select';

type ScheduleEproEndProps = {
  handleDaysChange: (e: any, type: string) => void;
  handleMutation: any;
  formMethods: any;
  hasEpro: any;
};

const ScheduleEproEnd = ({
  handleDaysChange,
  handleMutation,
  hasEpro,
  formMethods
}: ScheduleEproEndProps) => {
  const intl = useIntl();

  const {
    setValue,
    watch
  } = formMethods;

  const values = watch();
  const { endDate, ends, endNumber } = values;

  const afterTimeOptions = [
    { value: 'AFTER', label:  intl.formatMessage({ id: 'appointment.after' })},
    { value: 'ON_THIS_DAY', label:  intl.formatMessage({ id: 'appointment.onThisDay' })},
  ];

  const periodOptions = [
    { value: 'OCCURRENCES', label: intl.formatMessage({ id: 'appointment.occurrences' })},
    { value: 'DAYS', label:  intl.formatMessage({ id: 'appointment.days' })},
    { value: 'WEEKS', label:  intl.formatMessage({ id: 'appointment.weeks' })},
    { value: 'MONTHS', label:  intl.formatMessage({ id: 'appointment.months' })},
  ];

  const gettingLabelOptionsAfterTime = hasEpro?.ends ? afterTimeOptions?.filter(
    (item) => item.value === hasEpro?.ends,
  ) : [];

  const gettingLabelOptionsPeriod = hasEpro?.endCriteria ? periodOptions.filter(
    (item) => item.value === hasEpro?.endCriteria,
  ) : [];

  const handleAfterTimeChange = (e: any) => {
    setValue('endDate', `${format(addMonths(new Date(), 1), 'yyyy-MM-dd')}`)
    setValue('ends', e.value)
    handleMutation();
  };

  return (
    <>
      <div className='flex items-center  gap-3 mt-2'>
        <p className='text-[#737373] font-normal font-sans text-sm'>
          <FormattedMessage id="end" />
        </p>
        <Select
          options={afterTimeOptions}
          className='selectEpro text-sm w-[106px] selectEproRepeat text-left'
          defaultValue={{
            value: hasEpro?.repeat || 'AFTER',
            label: gettingLabelOptionsAfterTime && gettingLabelOptionsAfterTime?.length > 0 && gettingLabelOptionsAfterTime[0]?.label || intl.formatMessage({ id: 'appointment.after' }),
          }}
          components={{
            IndicatorSeparator: () => null,
          }}
          isSearchable={false}
          onChange={handleAfterTimeChange}
        />

        {ends === 'ON_THIS_DAY' ? (
          <input
            type='date'
            className='focus:outline-none w-[130px]  border-b-[0.5px] border-[#737373]'
            value={endDate}
            onBlur={handleMutation}
            onChange={(e) => setValue('endDate', e.target.value)}
          />
        ) : (
          <>
            <input
              className='w-[34px] h-[20px] focus:outline-none border-b-[0.5px] border-[#737373]'
              type='text'
              value={endNumber}
              onChange={(e) => handleDaysChange(e, 'endNumber')}
              min={1}
              onBlur={handleMutation}
              max={999}
            />
            <Select
              options={periodOptions}
              className='selectEpro text-sm w-[114px] selectEproRepeat text-left'
              defaultValue={{
                value: hasEpro?.repeat || 'OCCURRENCES',
                label: gettingLabelOptionsPeriod[0]?.label || intl.formatMessage({ id: 'appointment.occurrences' }),
              }}
              components={{
                IndicatorSeparator: () => null,
              }}
              isSearchable={false}
              onChange={(e: any) => {
                setValue('endCriteria', e.value)
                handleMutation();
              }}
            />
          </>
        )}
      </div>
    </>
  );
};

export default ScheduleEproEnd;
