import { axiosClient } from 'config/axios';
import {
  IStandardFormPayload,
  IStandardFormAnswerPayload,
  IStandardFormAnswerSendEmailPayload,
} from 'types/standardForm';

export const submitStandardFormApi = async ({ studyId, ...rest }: IStandardFormPayload) => {
  const { data } = await axiosClient.post<any>(
    `/standard-form/submit?studyUserId=${studyId ?? 1}`,
    rest,
  );
  return data;
};
export const getTerminationSubmission = async (questionnaireId = '', patientId = '') => {
  const { data } = await axiosClient.get(
    `/standard-form/termination-submission/questionnaires/${questionnaireId}/patients/${patientId}`,
  );
  return data;
};
export const getStandardFormsSummary = async (studyId = '', type = '') => {
  const { data } = await axiosClient.get(
    `/standard-form/submissions/studies/${studyId}${type ? `?type=${type}` : ''}`,
  );
  return data;
};

export const updateStandardFormAnswer = async ({
  answerId,
  ...rest
}: IStandardFormAnswerPayload) => {
  const { data } = await axiosClient.put<any>(`/standard-form/answers/${answerId ?? 1}`, rest);
  return data;
};

const headers = {
  'Content-Type': 'multipart/form-data',
};

export const standardFormSendEmail = async (submissionId: any, studyUserId: any, payload: any) => {
  const { data } = await axiosClient.put<IStandardFormAnswerSendEmailPayload>(`/standard-form/${submissionId}/sendEmail?studyUserId=${studyUserId}`, payload, { headers });
  return data;
};
