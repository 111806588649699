// ToggleButton.tsx
import React, { useState } from 'react';
import clsx from 'classnames';

interface ToggleButtonProps {
  checked?: boolean; // Controlled mode: Pass a checked value
  defaultChecked?: boolean; // Uncontrolled mode: Set an initial checked state
  onChange?: (isChecked: boolean) => void; // Callback for controlled mode
  className?: string;
  borderClass?: string;
  dotClass?: string;
  inputClassName?: string;
  name?: string;
}

const ToggleButton: React.FC<ToggleButtonProps> = ({
  className,
  checked,
  defaultChecked,
  onChange,
  borderClass,
  dotClass,
  inputClassName,
}) => {
  const [isChecked, setIsChecked] = useState(defaultChecked || checked || false);
  const handleChange = () => {
    const newCheckedState = !isChecked;
    setIsChecked(newCheckedState);

    if (onChange) {
      onChange(newCheckedState);
    }
  };

  return (
    <label className={clsx('flex items-center cursor-pointer', className)}>
      <div className='relative' dir='rtl'>
        <input
          type='checkbox'
          className={clsx('hidden', inputClassName)}
          checked={checked !== undefined ? checked : isChecked}
          onChange={handleChange}
        />
        <div
          className={`toggle__line transition duration-200 ease-in-out w-[1.833rem] h-4 rounded-full shadow-inner ${borderClass} ${
            isChecked || checked ? 'bg-blue-normal ' : 'bg-white border border-blue-normal'
          }`}
        ></div>
        <div
          className={`toggle__dot transition duration-200 ease-in-out absolute w-2 h-2 rounded-full shadow inset-y-0  top-1 ${dotClass} ${
            isChecked || checked ? ' bg-white  right-1' : 'bg-blue-normal  left-1'
          }`}
        ></div>
      </div>
    </label>
  );
};

export default ToggleButton;
