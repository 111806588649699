import { create } from 'zustand';
import { ICenter } from 'types/centers';
interface CenterState {
  selectedCenter: number | string;
  centers: ICenter[] | any;
  setSelectedCenter: (center: number | string) => void;
  setCenters: (item: ICenter[] | any) => void;
}

export const useCentersStore = create<CenterState>((set) => ({
  centers: [],
  selectedCenter: 'all',
  setCenters: (item) => set((state) => ({ ...state, centers: item })),
  setSelectedCenter: (center) => set((state) => ({ ...state, selectedCenter: center })),
}));
