import { FiLoader } from 'react-icons/fi';
import clsx from 'classnames';

interface FallbackProps {
  className?: string;
}

const FallbackLoading = ({ className }: FallbackProps) => {
  return (
    <div className={clsx('w-full h-full flex justify-center items-center', className)}>
      <FiLoader className='w-20 h-20 animate-spin place-self-center text-blue-ocean-deep' />
    </div>
  );
};

export default FallbackLoading;
