/* eslint-disable react/display-name */
import React, { useRef, useCallback, forwardRef, HTMLAttributes, useState } from 'react';
import classNames from 'classnames';
import { FiFolder, FiChevronDown } from 'react-icons/fi';
import type { UniqueIdentifier } from '@dnd-kit/core';
import { TextInput, ThreeDotsDropdown, Button, Modal } from 'components/common';
import { Action, Handle } from './components';
import styles from './TreeItem.module.css';
import { useOutsideClick } from 'hooks/useOutsideClick';
import { useQuestionnaireStore } from 'store/QuestionnaireStore';
import RenameQuestionnaire from '../../../../RenameQuestionnaire';
import { SvgIcon } from '@mui/material';
import { MoreIcon } from '../../../../../Icons';
import { FormattedMessage, useIntl } from 'react-intl';

export interface Props extends Omit<HTMLAttributes<HTMLLIElement>, 'id'> {
  childCount?: number;
  id: UniqueIdentifier;
  clone?: boolean;
  collapsed?: boolean;
  depth: number;
  disableInteraction?: boolean;
  disableSelection?: boolean;
  ghost?: boolean;
  handleProps?: any;
  indicator?: boolean;
  indentationWidth: number;
  value: string;
  onCollapse?(): void;
  onRemove?(): void;
  wrapperRef?(node: HTMLLIElement): void;
  questionnaire?: any;
  folder?: any;
  handleEditTitle?: any;
  handleRowClick: any;
  newItem?: boolean;
  newFolder?: boolean;
  sortOrder?: any;
  createQuestionnaireItem: any;
  handleCreateFolder: any;
  handleRemoveFolder: any;
  duplicateStepMutate?: any;
  isFirstWithDepth: boolean;
  isLastWithDepth: boolean;
  previousDepth?: number;
}

export const TreeItem = forwardRef<HTMLDivElement, Props>(
  (
    {
      childCount,
      clone,
      depth,
      disableSelection,
      disableInteraction,
      ghost,
      handleProps,
      indentationWidth,
      indicator,
      collapsed,
      onCollapse,
      // onRemove,
      style,
      value,
      wrapperRef,
      questionnaire,
      folder,
      handleEditTitle,
      handleRowClick,
      id,
      newItem,
      newFolder,
      createQuestionnaireItem,
      handleCreateFolder,
      handleRemoveFolder,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onRemove,
      duplicateStepMutate,
      isFirstWithDepth,
      isLastWithDepth,
      previousDepth,
      ...props
    },
    ref,
  ) => {
    console.log(
      'depth val: ',
      isLastWithDepth,
      isFirstWithDepth,
      depth,
      depth === 1,
      value,
      folder,
      childCount,
      questionnaire,
    );
    const [isOpen, setIsOpen] = useState<any>(false);
    const [disablePointer, setDisablePointer] = useState<boolean>(false);
    const { selectedQuestionnairy } = useQuestionnaireStore();
    const [hoverItem, setHoverItem] = useState<any>(false);
    const [hoverOnDownArrow, setHoverOnDownArrow] = useState<any>(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);
    const labelRef = useRef<HTMLLabelElement>(null);
    const [editValue, setEditValue] = useState(value);
    const intl = useIntl();

    const handleOutsideClick = useCallback(
      (event: MouseEvent | React.MouseEvent<HTMLElement, MouseEvent>, clickedOut: boolean) => {
        if (clickedOut && event.target !== labelRef.current) {
          setIsOpen(false);
          if (editValue == value) return;
          handleEditTitle(editValue, questionnaire, folder?.id);
        }
      },
      [setIsOpen, editValue],
    );
    const handleKeyDown = (event: any) => {
      if (event.key === 'Enter') {
        if (editValue == value) {
          setIsOpen(false);
          return;
        } else {
          handleEditTitle(editValue, questionnaire, folder?.id);
          setIsOpen(false);
        }
      }
    };

    const closeModal = () => {
      setIsModalOpen(false);
    };

    const getIcon = () => {
      if (!questionnaire?.id && folder?.id)
        return <FiFolder className='!m-0 w-5 h-5 text-blue-oil' />;
      return null;
    };

    useOutsideClick(containerRef, handleOutsideClick);

    return (
      <>
        <Modal
          className={{
            header:
              'text-[20px] text-blue-ocean-deep mt-3 px-4 font-medium flex !justify-start !items-start gap-x-2',
            content: 'w-[416px]',
          }}
          title={intl.formatMessage({ id: 'study-builder.tree-item.areYouSure' })}
          isOpen={isModalOpen}
          onClose={closeModal}
        >
          <div className='flex gap-x-4 pt-2 mt-4 mx-auto px-4'>
            <Button
              onClick={closeModal}
              className='border-2 border-blue-ocean-deep rounded-full text-base font-semibold px-[7px] py-[6px] text-blue-ocean-deep w-[184px] h-12'
            >
              <FormattedMessage id='no' />
            </Button>
            <Button
              onClick={() => {
                handleRemoveFolder(hoverItem ?? { id, questionnaire, folder });
                closeModal();
              }}
              className='hover:text-white bg-white border-blue-ocean-deep text-blue-ocean-deep hover:bg-blue-ocean-deep border-2 bg-blue-ocean-deep rounded-full text-base font-semibold px-[7px] py-[6px] w-[184px] h-12'
            >
              <FormattedMessage id='yes' />
            </Button>
          </div>
        </Modal>
        <li
          className={classNames(
            styles.Wrapper,
            clone && styles.clone,
            ghost && styles.ghost,
            indicator && styles.indicator,
            disableSelection && styles.disableSelection,
            disableInteraction && styles.disableInteraction,
            'relative ',
            depth === 0 && 'mb-4',
            folder?.id && 'bg-white ',
            depth === 1 && isFirstWithDepth && '-!mt-4',
            depth === 0 && folder?.id && '!rounded-lg',
            depth === 0 && folder?.id && !collapsed && '!rounded-b-none',
            Number(previousDepth ?? 0) > depth && depth !== 1 && '!mt-4',
          )}
          ref={wrapperRef}
          style={
            {
              '--spacing': `${(indentationWidth * depth) / 3}px`,
              marginTop:
                (depth > 0 && depth < 2 && isFirstWithDepth) ||
                (depth > (previousDepth ?? 0) && depth < 2)
                  ? '-16px'
                  : '',
            } as React.CSSProperties
          }
          {...props}
          onMouseEnter={() => setHoverItem({ folder, questionnaire, id })}
          onMouseLeave={() => setHoverItem(null)}
        >
          <div
            onMouseDown={
              newItem || newFolder || hoverOnDownArrow
                ? () => {}
                : () => {
                    localStorage.setItem('is-standard-form-page', 'false');
                    handleRowClick({ questionnaire, folder, id });
                  }
            }
            {...(disablePointer || isOpen || newItem || newFolder || hoverOnDownArrow
              ? {}
              : { ...{ onPointerDown: handleProps?.onPointerDown } })}
            className={classNames(
              styles.TreeItem,
              +questionnaire?.id === +selectedQuestionnairy?.questionnaire?.id ||
                (!questionnaire?.id && id == selectedQuestionnairy?.id)
                ? 'border-[1px] border-purple [&>span]:text-blue-normal !bg-blue-very-light'
                : 'bg-white',
              'cursor-pointer',
              isOpen && '!p-0 !rounded-sm',
              depth > 0 && !onCollapse && '!rounded-none',
            )}
            ref={ref}
            style={style}
          >
            {newFolder ? (
              <div className='w-full rounded-lg bg-white flex justify-start items-center'>
                <FiFolder className='w-6 h-6 text-blue-ocean-deep' />
                <RenameQuestionnaire
                  handleCallBack={(newTitle: string) => {
                    handleCreateFolder(newTitle);
                  }}
                  defaultValue={''}
                  defaultIsOpen={true}
                  inputPlaceHolder={intl.formatMessage({ id: 'study-builder.typeNameFolder' })}
                >
                  ...
                </RenameQuestionnaire>
                <FiChevronDown className='text-blue-ocean-deep transition-all  transform-gpu origin-center w-6 h-6' />
              </div>
            ) : newItem ? (
              <div className='w-full rounded-lg bg-white flex justify-start items-center'>
                <RenameQuestionnaire
                  handleCallBack={(newTitle: string) => {
                    createQuestionnaireItem(newTitle);
                  }}
                  defaultValue={''}
                  defaultIsOpen={true}
                  inputPlaceHolder={intl.formatMessage({
                    id: 'study-builder.typeNameQuestionnaire',
                  })}
                >
                  ...
                </RenameQuestionnaire>
              </div>
            ) : isOpen ? (
              <>
                <div ref={containerRef} className='w-full '>
                  <TextInput
                    control={{
                      value: editValue,
                      onChange: (e: any) => {
                        setEditValue(e.target.value);
                      },
                    }}
                    name={'title1'}
                    placeholder='questionnaire-field.data-form.typeMessage'
                    className='h-full w-full !mt-0 [&>input]:!pl-2 [&>input]:mt-0 [&>input]:h-9 [&>input]:!p-2  pt-0 pb-0 [&>input]:rounded-sm'
                    inputClassName='border-none'
                    onKeyDown={handleKeyDown}
                  />
                </div>
              </>
            ) : (
              <>
                <Handle
                  className='w-[95%] flex justify-start items-center'
                  {...{ ...(disablePointer || isOpen ? {} : handleProps), questionnaire, folder }}
                >
                  {getIcon()}
                  <span
                    onDoubleClick={(e: any) => {
                      e.stopPropagation();
                      handleRowClick({ questionnaire, folder, id });
                      setIsOpen(!isOpen);
                    }}
                    onMouseEnter={() => setDisablePointer(true)}
                    onMouseLeave={() => setDisablePointer(false)}
                    className={classNames(
                      styles.Text,
                      'hover:underline w-fit font-semibold cursor-default text-blue-oil',
                    )}
                  >
                    {value}
                  </span>
                </Handle>

                {onCollapse && (
                  <Action
                    onMouseEnter={() => setHoverOnDownArrow(true)}
                    onMouseLeave={() => setHoverOnDownArrow(false)}
                    onClick={() => onCollapse()}
                    className={classNames(styles.Collapse, collapsed && styles.collapsed)}
                  >
                    {collapseIcon}
                  </Action>
                )}
                {clone && childCount && childCount > 1 ? (
                  <span className={styles.Count}>{childCount}</span>
                ) : null}
              </>
            )}
          </div>
          {+hoverItem?.id === +id && !newFolder && (
            <>
              <ThreeDotsDropdown
                classes={{
                  container: '!absolute -right-[18px] top-[4px]',
                  button: '!px-0',
                  content:
                    'w-fit !bg-blue-oil rounded-[2px] !mt-0 !-right-[40px] w-[55px] -top-[7px] flex justify-center items-center',
                }}
                dotIcon={<SvgIcon component={MoreIcon} className='cursor-pointer ' />}
                customContent={
                  <div>
                    <div
                      className='  text-white text-[9px] px-[5px] py-[4px] cursor-pointer'
                      onClick={() => {
                        duplicateStepMutate(id);
                      }}
                    >
                      <FormattedMessage id='study-builder.duplicate' />
                    </div>
                    <div
                      className='  text-white text-[9px] px-[5px] py-[4px] cursor-pointer'
                      onClick={() => {
                        setIsModalOpen(true);
                      }}
                    >
                      <FormattedMessage id='delete' />
                    </div>
                  </div>
                }
              />
            </>
          )}
        </li>
      </>
    );
  },
);

const collapseIcon = (
  <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
    <path
      d='M8.00001 11.1999C7.53335 11.1999 7.06668 11.0199 6.71335 10.6666L2.36668 6.31993C2.17335 6.12659 2.17335 5.8066 2.36668 5.61326C2.56001 5.41993 2.88001 5.41993 3.07335 5.61326L7.42001 9.95993C7.74001 10.2799 8.26001 10.2799 8.58001 9.95993L12.9267 5.61326C13.12 5.41993 13.44 5.41993 13.6333 5.61326C13.8267 5.8066 13.8267 6.12659 13.6333 6.31993L9.28668 10.6666C8.93335 11.0199 8.46668 11.1999 8.00001 11.1999Z'
      fill='#012C54'
    />
  </svg>
);
