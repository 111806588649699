import Input from 'components/common/TextInput';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import React, { useEffect, useState } from 'react';
import { FiCalendar, FiTrash2, FiEdit3, FiClock, FiChevronDown } from 'react-icons/fi';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Button, Calendar, CKEditorComponent } from 'components/common';
import SelectTime from './SelectTime';
import NestedSelect from './NestedSelect';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';
import {
  addAppointments,
  deleteAppointment,
  editAppointment,
  fetchSlots,
} from 'services/api/appointment';
import { useStudyStore } from 'store/StudyStore';
import { format, isAfter, isSameDay, isSameHour } from 'date-fns';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';
import { disableWeekends } from 'utils/number';
import _ from 'lodash';

interface ModalProps {
  modalData?: any;
  onClose: () => void;
}
export interface IAppointmentForm {
  begin: string;
  end: string;
  startDate?: string;
  frequency?: number;
  title?: string;
  note?: string;
  time?: {
    begin: string;
    end: string;
  };
  appointmentLocationType: string;
  centerUserId?: number;
  repeat?: any;
  appointment: {
    appointmentType: string;
    appointmentStatus: string;
    organizer?: string;
    patientId?: number;
    title: string;
    note: string;
  };
}

const initialValues = {};

const NewMeetingModal: React.FC<ModalProps> = ({ onClose, modalData }) => {
  const [queryDate, setQueryDate] = useState<any>();
  const [isEdit, setIsEdit] = useState(false);
  const intl = useIntl();

  const validationSchema = Yup.object({
    title: Yup.string().required(intl.formatMessage({ id: 'error.titleIsRequired' })),
    time: Yup.object()
      .typeError(intl.formatMessage({ id: 'error.timeIsRequired' }))
      .required(),
  }).required();

  const methods = useForm<IAppointmentForm>({
    resolver: yupResolver(validationSchema) as any,
    defaultValues: { ...(initialValues as IAppointmentForm) },
  });

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = methods;
  const values = watch();

  const { loggedInStudyUser } = useStudyStore();
  const { participantId } = useParams();

  const { data, refetch } = useQuery({
    queryKey: ['slots'],
    queryFn: () =>
      queryDate &&
      fetchSlots(
        parseInt(`${participantId}`, 10),
        parseInt(`${loggedInStudyUser?.id}`, 10),
        format(queryDate, 'yyyy-MM-dd'),
      ),
    refetchOnMount: 'always',
  });

  const { mutate: postAppointment } = useMutation<any, unknown, IAppointmentForm, unknown>(
    (data: IAppointmentForm) => addAppointments(data),
    {
      onSuccess: () => {
        reset();
        onClose();
        toast.success(intl.formatMessage({ id: 'appointment.meetingAdd' }));
      },
      onError(e: any) {
        toast.error(e?.response?.data?.message);
      },
    },
  );

  const { mutate: updateAppointment } = useMutation<any, unknown, IAppointmentForm, unknown>(
    (data: IAppointmentForm) => editAppointment(modalData?.id, data),
    {
      onSuccess: () => {
        onClose();
        toast.success(intl.formatMessage({ id: 'appointment.meetingEdit' }));
      },
      onError(e: any) {
        toast.error(e?.response?.data?.message);
      },
    },
  );

  useEffect(() => {
    queryDate && refetch();
  }, [queryDate, loggedInStudyUser, modalData, participantId]);

  useEffect(() => {
    if (modalData?.date && loggedInStudyUser && participantId) {
      setQueryDate(modalData?.date);
    }
    if (modalData?.id && modalData?.title) {
      modalData?.start && setQueryDate(new Date(modalData?.start));
      setIsEdit(true);
      modalData?.title && setValue('title', modalData?.title);
      modalData?.appointment?.note && setValue('note', modalData?.appointment?.note);
      modalData?.start && setValue('startDate', `${new Date(modalData?.start)}`);
    }
  }, [loggedInStudyUser, modalData, participantId]);

  useEffect(() => {
    if (modalData?.date) {
      setValue('startDate', modalData?.date);
    }
  }, [modalData]);

  const onSubmit: SubmitHandler<IAppointmentForm> = (data: any) => {
    const centerUserId = loggedInStudyUser?.id;

    if (isEdit && data?.title && data?.time?.begin && data?.time?.end) {
      try {
        updateAppointment({
          begin: format(new Date(data?.time?.begin), 'yyyy-MM-dd\'T\'HH:mm:ss.SSS\'Z\''),
          end: format(new Date(data?.time?.end), 'yyyy-MM-dd\'T\'HH:mm:ss.SSS\'Z\''),
          appointmentLocationType: 'REMOTE',
          appointment: {
            appointmentType: 'NORMAL',
            appointmentStatus: 'PENDING',
            title: data?.title,
            note: data?.note,
          },
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    } else if (data?.title && data?.time?.begin && data?.time?.end) {
      try {
        let formattedWeeklyOnDays =
          data?.repeat === 'WEEK' || data?.repeat === 'WEEKLY' ? data?.weeklyOnDays?.length > 0 && data?.weeklyOnDays?.filter((item: any) => item !== '') : [];
        if (data?.repeat === 'WEEK' || data?.repeat === 'WEEKLY') {
          formattedWeeklyOnDays = data?.weeklyOnDays?.length > 0 ? _.uniq(data?.weeklyOnDays) : ['MONDAY']
        }
        let payload: any = {
          begin: format(new Date(data?.time?.begin), 'yyyy-MM-dd\'T\'HH:mm:ss.SSS\'Z\''),
          end: format(new Date(data?.time?.end), 'yyyy-MM-dd\'T\'HH:mm:ss.SSS\'Z\''),
          appointmentLocationType: 'REMOTE',
          centerUserId: centerUserId,
          appointment: {
            appointmentType: 'NORMAL',
            appointmentStatus: 'PENDING',
            organizer: 'SITE_STAFF',
            patientId: parseInt(`${participantId}`, 10),
            title: data?.title,
            note: data?.note,
          },
          repeat: (!data?.repeat || data?.endDate === '') ? null : {
            frequency: data?.frequency ? parseInt(`${data?.frequency}`, 10) : 1,
            base: data?.repeat,
            endDate: data?.endDate ? format(new Date(data?.endDate), 'yyyy-MM-dd\'T\'HH:mm:ss.SSS\'Z\'') : null,
          },
        };
        if (data?.repeat === 'WEEK' && data?.endDate) {
          payload = {
            ...payload,
            repeat: {
              ...payload.repeat,
              frequency: data?.frequency ? parseInt(`${data?.frequency}`, 10) : 1,
              weeklyOnDays: formattedWeeklyOnDays,
            },
          };
        }
        if (data?.customRepeat === 'DAY' && data?.endDate) {
          payload = {
            ...payload,
            repeat: {
              ...payload.repeat,
              frequency: data?.frequency ? parseInt(`${data?.frequency}`, 10) : 1,
              startDate: data?.startDate
            },
          };
        }
        postAppointment(payload);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  };

  const { mutate: delAppointment } = useMutation<any, unknown, any, unknown>(
    () => deleteAppointment(modalData?.id),
    {
      onSuccess: () => {
        onClose();
        toast.success(intl.formatMessage({ id: 'appointment.meetingDelete' }));
        refetch();
      },
      onError(e: any) {
        toast.error(e?.response?.data?.message);
      },
    },
  );

  const handleChange = (e: any) => {
    setValue('startDate', e);
    setQueryDate(e);
  };

  useEffect(() => {
    methods.setValue('frequency', 1);
  }, []);

  const getTimeSlots = () => {
    return isSameDay(new Date(queryDate), new Date())
      ? data?.availableTimeslots.filter((e: any) => {
          const startDate = new Date(e.begin);
          const nowDate = new Date();
          return isSameHour(startDate, nowDate) ?
            nowDate.getMinutes() >= 30 ? startDate.getMinutes() >= 30 && isSameHour(startDate, nowDate) : isSameHour(startDate, nowDate)
            : isAfter(startDate, nowDate)
        })
      : isAfter(new Date(queryDate), new Date()) ? data?.availableTimeslots : []
  }

  return (
    <div className='fixed top-[4.813rem] left-0 right-0 bottom-0 flex items-center justify-center z-50 shadow-md'>
      <div
        onClick={onClose}
        className='modal-overlay bg-gray-medium opacity-[0.87] fixed top-[4.813rem] left-0 right-0 bottom-0'
      />
      <div className='bg-white z-[60] w-full max-w-[76.875rem] px-[6.688rem] pt-[2.438rem] pb-[2.813rem]'>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit((data: any) => onSubmit(data))}>
            <div className='flex items-center justify-between'>
              <div className='flex items-center gap-3 w-fit'>
                <div className='w-8 h-8 bg-blue-ocean-deep rounded p-1'>
                  <FiCalendar className='text-white w-6 h-6' />
                </div>
                <p className='text-blue text-lg leading-6 font-medium'>
                  {`${
                    isEdit
                      ? `${intl.formatMessage({ id: 'appointment.editMeeting' })}`
                      : `${intl.formatMessage({ id: 'appointment.newMeeting' })}`
                  }`}
                </p>
              </div>
              {isEdit ? (
                modalData?.appointment?.organizer === 'SITE_STAFF' && (
                  <FiTrash2 onClick={delAppointment} className='w-6 h-6' />
                )
              ) : (
                <FiTrash2 onClick={onClose} className='w-6 h-6' />
              )}
            </div>
            <div className='flex items-center gap-3 mt-[1.313rem]'>
              <FiEdit3 className='w-6 h-6 text-purple' />
              <Input
                register={register}
                name='title'
                errors={errors}
                className='!mt-0 w-full'
                inputClassName='!border-purple !h-[30px] !rounded !text-gray-dark !text-sm'
              />
            </div>
            <div className='flex items-start gap-3 mt-8'>
              <FiClock className='w-6 h-6' />
              <Calendar
                customControl={{
                  value: new Date(`${values.startDate}`),
                  onChange: (e) => handleChange(e),
                }}
                name='startDate'
                label=''
                minDate={new Date()}
                errors={errors}
                className='no-border-mui border !my-0 border-gray-dark !rounded [&>div]!flex-reverse [&>div>div>input]:!text-left [&>div>div>input]:!py-1 [&>div>div>input]:!text-sm'
                placeholder='xx / xx / xxxx'
                format='dd/MM/yyyy'
                inputDisable={true}
                shouldDisableDate={(e: any) => disableWeekends(e)}
                type='date'
                slots={{
                  openPickerIcon: FiChevronDown,
                }}
              />
              <SelectTime
                defaultValue={
                  values?.time === undefined && modalData?.start && modalData?.end
                    ? {
                        begin: format(new Date(modalData?.start), 'yyyy-MM-dd\'T\'HH:mm:ss'),
                        end: format(new Date(modalData?.end), 'yyyy-MM-dd\'T\'HH:mm:ss'),
                      }
                    : {}
                }
                name='time'
                errors={errors}
                setValue={setValue}
                availableSlots={getTimeSlots() || []}
              />
              {!isEdit && <NestedSelect name='repeat' methods={methods} />}
            </div>
            <div className='mt-8'>
              <CKEditorComponent
                placeholder='Add details for this meeting'
                name='note'
                control={control}
              />
            </div>
            <div className='mt-8'>
              <Button
                type='submit'
                className='bg-blue-ocean-deep text-white hover:bg-blue-oil rounded-md mx-auto'
              >
                {`${
                  isEdit
                    ? `${intl.formatMessage({ id: 'appointment.editMeeting' })}`
                    : `${intl.formatMessage({ id: 'appointment.addMeeting' })}`
                }`}
              </Button>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default NewMeetingModal;
