import { FiFile, FiClock } from 'react-icons/fi';
import { CiLocationOn } from 'react-icons/ci';
import ProgressBar from '../ProgressBar';
import { useNavigate, NavigateFunction } from 'react-router-dom';
import { IStudy, StudyStatus } from 'types/study';
import { formatDistance, isBefore } from 'date-fns';
import { useQuery } from '@tanstack/react-query';
import { fetchStudyById } from 'services/api/study';
import { useStudyStore } from 'store/StudyStore';
import useAuthStore from 'store/authStore';
import { FormattedMessage, useIntl } from 'react-intl';

interface StudyCardProps {
  id: number;
  title: string;
  shortTitle: string;
  status: StudyStatus;
  completionDate?: string;
  enrollmentDate?: string;
  startDate?: string | Date;
  totalPatient?: number;
  numberOfPatient?: number;
  center: string;
  role: string;
  loggedInStudyUserRole?: string;
}

function StudyCard({
  title,
  shortTitle,
  status,
  enrollmentDate,
  totalPatient,
  numberOfPatient,
  center,
  role,
  id,
  completionDate,
   loggedInStudyUserRole,
}: StudyCardProps) {
  const intl = useIntl();
  const navigate = useNavigate();
  const current = totalPatient || 0;
  const max = numberOfPatient || 10;
  const { setCurrentStudy } = useStudyStore();
  const { userData } = useAuthStore();

  const { refetch } = useQuery<IStudy>({
    queryKey: ['study'],
    queryFn: () => fetchStudyById(id),
    refetchOnMount: 'always',
  });

  const handleNavigation = (navigate: NavigateFunction, id: number) => {
    refetch();
    console.log(title, shortTitle, 'title');
    setCurrentStudy({
      id,
      title,
      shortTitle,
      numberOfPatient,
      status,
      center,
      role
    });
    if (
      userData?.userAccount?.role?.name === 'ADMIN' &&
      userData?.userAccount?.role?.type === 'GENERAL'
    )
      navigate(`/study/${id}/users`);
    else if (status === 'EXECUTION' || status === 'FREEZE' || !status)
      navigate(`/study/${id}/summary`);
    else navigate(`/study/${id}/study-builder`);
  };

  return (
    <div
      onClick={() => handleNavigation(navigate, id)}
      className='cursor-pointer text-blue-oil bg-base-white text-sm px-6 pt-4 pb-7 rounded-xl w-full shadow'
    >
      <div className='flex justify-between items-center pb-4 border-b-[0.5px] border-[#CACACA]'>
        <h3 className='w-[60%] truncate text-ellipsis text-lg leading-6 text-left text-blue-oil block tracking-[0.00938rem] font-medium mr-14 max-w-[61%]'>
          {shortTitle}
        </h3>
        <div className='flex items-center gap-9'>
          {completionDate && isBefore(new Date(completionDate), new Date()) ? (
            <></>
          ) : (
            enrollmentDate &&
            (!isBefore(new Date(enrollmentDate), new Date()) ? (
              <p className='leading-[1.375rem] text-gray-dark'>
                <FormattedMessage id="allStudies.recruitingStartText" />{' '}
                <span className='font-semibold'>
                  {formatDistance(new Date(enrollmentDate), new Date(), {
                    addSuffix: true,
                  })}
                </span>
              </p>
            ) : (
              <p className='leading-[1.375rem] text-gray-dark'>
                <FormattedMessage id="allStudies.recruitingText" />{' '}<span className='font-semibold'><FormattedMessage id="allStudies.onGoing" /></span>
              </p>
            ))
          )}
          <div className='flex items-center gap-1'>
            <p className='leading-[1.375rem] text-gray-dark'>{intl.formatMessage({ id: `allStudies.${status}` })}</p>
            <span className='w-[0.6875rem] h-[0.6875rem] bg-success rounded-full'></span>
          </div>
        </div>
      </div>
      <div className='flex justify-between items-center flex-wrap gap-3 mt-5 text-gray-dark'>
        <div className='flex items-center gap-2'>
          <FiFile strokeWidth={1} className='w-[1.1875rem] h-[1.1875rem]' />
          <span className='leading-[1.375rem] w-fit max-w-[20rem] truncate text-left font-semibold'>
            {title}
          </span>
        </div>
        <div className='flex items-center gap-5 flex-wrap'>
          {center && (
            <div className='flex items-center gap-2'>
              <CiLocationOn strokeWidth={1} className='w-[1.1875rem] h-[1.1875rem]' />
              <span className='leading-[1.375rem] truncate font-semibold'>
                <FormattedMessage id="allStudies.yourSite" /> {center}
              </span>
            </div>
          )}
          <div className='flex items-center gap-2'>
            <FiClock strokeWidth={1} className='w-[1.1875rem] h-[1.1875rem]' />
            <span className='leading-[1.375rem] truncate font-semibold'>
              {completionDate && isBefore(new Date(completionDate), new Date()) ? (
                <><FormattedMessage id="allStudies.ended" /></>
              ) : (
                intl.formatMessage({ id: 'allStudies.remainingTime' }) + formatDistance(new Date(completionDate as any), new Date())
              )}
            </span>
          </div>
          <div className='flex items-center gap-2'>
            <img
              src={process.env.PUBLIC_URL + '/assets/images/study/user.svg'}
              className='w-[1.1875rem] h-[1.1875rem]'
              alt='user icon'
            />
            <span className='leading-[1.375rem] truncate font-semibold'><FormattedMessage id="allStudies.yourRole" /> {loggedInStudyUserRole}</span>
          </div>
        </div>
      </div>
      <div>
        <div className='flex justify-between mt-[1.125rem] text-gray-dark'>
          <span className='leading-[1.375rem]'><FormattedMessage id="allStudies.averageParticipant" /></span>
          <span className='leading-[1.375rem]'>
            {current} l {max} <FormattedMessage id="allStudies.inclusions" /> - {Number.parseFloat(((current / max) * 100).toFixed(1))}%
          </span>
        </div>
        <div className='mt-2.5'>
          <ProgressBar current={current} max={max} className='bg-rose-light' />
        </div>
      </div>
    </div>
  );
}

export default StudyCard;
