// Dropdown.tsx
import React, { useCallback, useRef, useState } from 'react';
import clsx from 'classnames';
import { useOutsideClick } from 'hooks/useOutsideClick';

interface DropdownProps {
  children?: React.ReactNode;
  onOptionClick?: (opt: string) => void;
  options: string[];
  icon?: string;
  buttonClassName?: string;
  className?: {
    popupClassName?: string;
  };
}

const Menu: React.FC<DropdownProps> = ({
  options,
  children,
  onOptionClick,
  icon,
  buttonClassName,
  className,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [, setSelectedOption] = useState(options[0]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option: string) => {
    setSelectedOption(option);
    onOptionClick?.(option);
    setIsOpen(false);
  };

  const handleOutsideClick = useCallback(
    (e: any, clickedOut: boolean) => {
      if (clickedOut) {
        setIsOpen(false);
      }
    },
    [setIsOpen],
  );

  useOutsideClick(containerRef, handleOutsideClick);

  return (
    <div ref={containerRef} className='relative inline-block text-left'>
      {children ? (
        <div onClick={toggleDropdown}>{children}</div>
      ) : (
        <button
          onClick={toggleDropdown}
          className={clsx(
            'flex items-start  justify-start w-full rounded-md shadow-sm px-4 py-2 text-sm font-medium text-gray-700 ',
            buttonClassName,
          )}
        >
          <img
            src={icon ?? process.env.PUBLIC_URL + '/assets/images/table/action_more.svg'}
            alt='action more'
          />
        </button>
      )}

      {isOpen && (
        <div
          className={clsx(
            'z-20 origin-top-right absolute min-w-[180px] right-4 w-max rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5',
            className?.popupClassName,
          )}
        >
          <div
            className='py-1'
            role='menu'
            aria-orientation='vertical'
            aria-labelledby='options-menu'
          >
            {options.map((option) => (
              <div
                key={option}
                onClick={() => handleOptionClick(option)}
                className='block text-blue-ocean-deep px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer'
                role='menuitem'
              >
                {option}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Menu;
