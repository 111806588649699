import { useEffect, Dispatch, SetStateAction } from 'react';
import DragDropList from './DragDropList';
import { reOrderSteps } from 'services/api/steps';
import { IRorderStepsForm, IStep } from 'types/step';
import { useMutation } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { IStandardForm } from 'types/study';
import { useIntl } from 'react-intl';

interface IProps {
  steps?: any;
  setPage: (state: string) => void;
  page: string;
  setFolderItemType: () => void;
  selectedQuestionnairy?: any;
  handleSelectQuestionnaire: (item: any, isFolder?: boolean, isConsentForm?: boolean) => void;
  refetchSteps?: any;
  handleEditTitle?: any;
  handleRemoveFolder: any;
  handleCreateFolder: (folderName: string) => void;
  createQuestionnaireItem: (name: string) => void;
  emptySteps: boolean;
  setShowPreview: Dispatch<SetStateAction<boolean>>;
  refetchStudy: any;
  standardForms: IStandardForm[] | [];
}
const LeftMenu: React.FC<IProps> = ({
  page,
  steps,
  selectedQuestionnairy,
  handleSelectQuestionnaire,
  setPage = () => {},
  setFolderItemType,
  refetchSteps,
  handleRemoveFolder,
  handleEditTitle,
  handleCreateFolder,
  createQuestionnaireItem,
  emptySteps,
  setShowPreview,
  refetchStudy,
  standardForms,
}: IProps) => {
  const { studyId, questionnaireId } = useParams();
  const intl = useIntl();
  const { mutate: reOrderStepsMutate } = useMutation<IStep, unknown, IRorderStepsForm, unknown>(
    (data: IRorderStepsForm) => reOrderSteps(data),
    {
      onSuccess() {
        refetchSteps();
      },
      onError(error: any) {
        toast.error(error?.response?.data?.message ?? intl.formatMessage({ id: 'error.reOrderError' }));
      },
    },
  );  
  const handleSwapItemsApi = (actiontype: string, data: any) => {
    if (actiontype === 'remove-folder') {
      const newSteps: any = steps?.map((sp: any) => {
        return {
          id: sp?.id,
          sortOrder: sp?.sortOrder,
          folderId: +data?.parentId === +sp?.id ? null : sp?.folder?.id,
          ...(sp?.questionnaire?.id && { questionnaireId: sp?.questionnaire?.id }),
          ...(sp?.idVerification?.id && { idVerificationId: sp?.idVerification?.id }),
        };
      });

      reOrderStepsMutate({ studyId: studyId ?? 1, newList: newSteps });
    } else if (actiontype === 'attach') {
      const newSteps: any = steps?.map((sp: any) => {
        return {
          id: sp?.id,
          sortOrder: sp?.sortOrder, // foundItem?.sortOrder ?? sp?.sortOrder,
          folderId: +sp?.id === +data.stepId ? data?.folder?.id : sp?.folder?.id,
          ...(sp?.questionnaire?.id && { questionnaireId: sp?.questionnaire?.id }),
          ...(sp?.idVerification?.id && { idVerificationId: sp?.idVerification?.id }),
          // ...(sp?.folder?.id && { folderId: sp?.folder?.id }),
        };
      });

      reOrderStepsMutate({ studyId: studyId ?? 1, newList: newSteps });
    } else if (actiontype === 'newList') {
      reOrderStepsMutate({ studyId: studyId ?? 1, newList: data });
    } else if (actiontype === 'update') {
      const newSteps: any = steps?.map((sp: any) => {
        const foundItem: any = data?.find((it: any) => +it.parentId === +sp.id);
        return {
          id: sp?.id,
          sortOrder: foundItem?.sortOrder ?? sp?.sortOrder,
          ...(sp?.questionnaire?.id && { questionnaireId: sp?.questionnaire?.id }),
          ...(sp?.idVerification?.id && { idVerificationId: sp?.idVerification?.id }),
          ...(sp?.folder?.id && { folderId: sp?.folder?.id }),
        };
      });
      reOrderStepsMutate({ studyId: studyId ?? 1, newList: newSteps });
    }
  };

  useEffect(() => {
    if (questionnaireId && steps?.length > 0) {
      const foundQuestionnaire: any = steps.find(
        (step: any) => +step?.questionnaire?.id === +questionnaireId.replace('q-', ''),
      );
      if (page !== 'add-questionnaire' && page !== 'standard-form') {
        handleSelectQuestionnaire(foundQuestionnaire);
      }
    }
  }, [questionnaireId, steps]);
  return (
    <DragDropList
      {...{
        steps,
        setPage,
        selectedQuestionnairy,
        handleSelectQuestionnaire,
        handleSwapItemsApi,
        handleEditTitle,
        handleRemoveFolder,
        setFolderItemType,
        handleCreateFolder,
        createQuestionnaireItem,
        emptySteps,
        page,
        setShowPreview,
        refetchStudy,
        standardForms,
      }}
    />
  );
};

export default LeftMenu;
