export const InfoIcon = () => {
  return (
    <svg className="shrink-0" xmlns='http://www.w3.org/2000/svg' width='6' height='6' viewBox='0 0 6 6' fill='none'>
      <g clipPath='url(#clip0_7169_16550)'>
        <path
          d='M2.99805 5.50098C4.37876 5.50098 5.49805 4.38169 5.49805 3.00098C5.49805 1.62026 4.37876 0.500977 2.99805 0.500977C1.61734 0.500977 0.498047 1.62026 0.498047 3.00098C0.498047 4.38169 1.61734 5.50098 2.99805 5.50098Z'
          stroke='#D40000'
          strokeWidth='0.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M3 2.00098V3.00098'
          stroke='#D40000'
          strokeWidth='0.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M3 3.99902H3.00327'
          stroke='#D40000'
          strokeWidth='0.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_7169_16550'>
          <rect width='6' height='6' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
