import { Button, Select } from '../../../components/common';
import { FC, Dispatch, SetStateAction } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';

interface IProps {
  status: any;
  setStatus: Dispatch<SetStateAction<any>>;
}

const FilterForm: FC<IProps> = ({
  status,
  setStatus,
}: IProps) => {
  const intl = useIntl();

  const handleReset = () => {
    setStatus('');
  };

  return (
    <div className='px-8 py-6 border-purple border-1 rounded-lg bg-white'>
      <div className='flex justify-between items-center'>
        <h3 className='text-grey-dark text-[18px] font-semibold'>
          <FormattedMessage id='audit-trial.filter-by' />
        </h3>
        <Button
          onClick={() => handleReset()}
          className='bg-white hover:bg-white font-light text-[14px] text-grey-dark'
        >
          <FormattedMessage id='audit-trial.reset' />
        </Button>
      </div>
      <p className='text-left mt-6 mb-3 text-grey-dark text-sm font-semibold'>
        <FormattedMessage id='patient.status' />
      </p>
      <Select
        className='min-w-[162px]'
        buttonClassName='bg-white h-[30px]'
        name='status'
        defaultValue={''}
        customControl={{
          value: status,
          onChange: (e: any) => setStatus(e),
        }}
        mainClass='rounded-md mt-3 border-blue-ocean-deep [&>div>ul]:!pl-0'
        optWrapperClassName='border border-blue-ocean-deep border-t-0'
        placeholder={intl.formatMessage({ id: 'patient.status' })}
        options={[
          { label: intl.formatMessage({ id: 'patient-recruitment.INVITED' }), value: 'INVITED' },
          { label: intl.formatMessage({ id: 'patient-recruitment.STARTED' }), value: 'STARTED' },
          { label: intl.formatMessage({ id: 'patient-recruitment.ACTIVE' }), value: 'ACTIVE' },
          { label: intl.formatMessage({ id: 'patient-recruitment.COMPLETED' }), value: 'COMPLETED' },
        ]}
        noTranslation={true}
      />
    </div>
  );
};

export default FilterForm;
