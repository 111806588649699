import { HeaderItem } from 'components/common/Table/Table.types';
import EproScheduleTable from './EproScheduleTable';
import { FormattedMessage, useIntl } from 'react-intl';
import { useEffect, useRef, useState } from 'react';
import { IAnswer } from 'types/step';
import { useQuestionnaireStore } from 'store/QuestionnaireStore';
import QuestionTable from './QuestionTable';
import { Button } from '../../../../../components/common';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getEproListApi, triggerEpro } from '../../../../../services/api/questionnaire';
import { useParams } from 'react-router-dom';
import { FiLoader } from 'react-icons/fi';
import { useStudyStore } from '../../../../../store/StudyStore';
import { createEproList } from '../../../../../utils/patients';
import { useParticipantStore } from '../../../../../store/participantStore';
import { useCentersStore } from '../../../../../store/centers';
import { fetchStepsByParticipantId } from '../../../../../services/api/steps';

const EproScheduleQuestionaire = ({ questionnaire }: any) => {
  const intl = useIntl();
  const { participantId } = useParams();
  const [eproList, setEproList] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isEproLoading, setIsEproLoading] = useState(false);
  const [isOneScroll, setIsOneScroll] = useState(false);
  const [selectedEpro, setSelectedEpro] = useState<any>();
  const [showEproCard, setShowEproCard] = useState(true);
  const [questionList, setQuestionList] = useState([]);
  const { stepEproList, steps, setStepEproList, setEproListPageNo, eproListPageNo, questStepId, setQuestStepId } = useQuestionnaireStore();
  const { selectedQuestionnaire } = useParticipantStore();
  const { loggedInStudyUser, currentStudy } = useStudyStore();
  const { selectedCenter } = useCentersStore();
  const scrollContainerRef: any = useRef(null);
  const isSiteStaff = loggedInStudyUser?.role?.category === 'SITE_STAFF';

  const { refetch: stepsRefetch} = useQuery({
    queryKey: ['stepsByStudyId', participantId],
    queryFn: () => fetchStepsByParticipantId(participantId as string),
  });

  const { mutate: triggerEproApi } = useMutation<any, unknown, number, unknown>(
    (id: number) => triggerEpro(id, parseInt(`${participantId}`, 10)),
    {
      onSuccess: (res: any) => {
        stepsRefetch();
        if (res && res?.length > 0) {
          const newOpts = res?.sort((a: any, b: any) => new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime()).filter((stp: any) => stp?.step?.questionnaire?.type === 'E_PRO');
          const epList: any = createEproList(newOpts, [], participantId, selectedQuestionnaire);
          if (epList && epList?.length > 0 && `${epList[0]?.patientId}` === `${participantId}` && `${epList[0]?.questionnaireId}` === `${selectedQuestionnaire}`) {
            setStepEproList([epList[0]]);
          }
        }
        setShowEproCard(false);
        setIsLoading(false);
      },
    },
  );

  const { data: eproListData, refetch: eproRefetch } = useQuery({
    queryKey: ['eproListData', questStepId],
    queryFn: () => questStepId > -1 && getEproListApi(questStepId, parseInt(`${participantId}`, 10), eproListPageNo, 10, ''),
  });

  useEffect(() => {
    eproRefetch();
    if (eproListPageNo === 0) {
      setStepEproList([]);
      setEproList([]);
      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollIntoView({ behavior: 'smooth' });
      }
      return;
    }
  }, [eproListPageNo]);

  const getQuestStepId = () => {
    const qStepData = steps?.find((stp: any) => stp?.type === 'QUESTIONNAIRE' && `${stp?.step?.questionnaire?.id}` === `${selectedQuestionnaire}`);
    setQuestStepId(qStepData?.step?.id);
  }

  useEffect(() => {
    setEproListPageNo(0);
  }, []);

  useEffect(() => {
    eproRefetch();
  }, [questStepId]);

  useEffect(() => {
    setEproListPageNo(0);
    getQuestStepId();
    setEproList([]);
    setStepEproList([]);
  }, [selectedQuestionnaire, participantId]);

  useEffect(() => {
    const qStepData = steps?.find((stp: any) => stp?.type === 'QUESTIONNAIRE' && `${stp?.step?.questionnaire?.id}` === `${selectedQuestionnaire}`);
    const foundOneStep = steps?.filter((stp: any) => stp?.type === 'QUESTIONNAIRE' && `${stp?.step?.id}` === `${qStepData?.id}`);
    if (foundOneStep && foundOneStep.length <= 1 && (qStepData?.dueDate === null || !qStepData?.dueDate)) {
      setShowEproCard(true);
      return;
    }
    setShowEproCard(false);
  }, [steps, selectedQuestionnaire]);

  const handleScroll = () => {
    const element: any = scrollContainerRef.current;
    if (eproListData && !isOneScroll && eproListData?.totalPages - 1 > eproListPageNo && Math.floor(element.scrollHeight - element.scrollTop) === element.clientHeight) {
      setIsEproLoading(true);
      setIsOneScroll(true);
      setEproListPageNo(eproListPageNo + 1);
    }
  };

  useEffect(() => {
    if (isEproLoading && stepEproList?.length === 0 && questStepId > -1) {
      eproRefetch();
    }
  }, [isEproLoading, stepEproList, questStepId]);

  const headerItems: HeaderItem[] = [
    {
      title: intl.formatMessage({ id: 'patient-status.occurrence' }),
      name: 'occurence',
    },
    {
      title: intl.formatMessage({ id: 'patient-status.progress' }),
      width: 'w-[400px]',
      name: 'progress',
    },
    {
      title: '',
      name: 'id',
    },
    {
      title: intl.formatMessage({ id: 'patient-profile.score' }),
      name: 'score',
    },
    {
      title: intl.formatMessage({ id: 'patient-status.date' }),
      name: 'date',
    },
  ];

  const questionheaderItems: HeaderItem[] = [
    {
      title: intl.formatMessage({ id: 'patient-status.question' }),
      name: 'occurence',
    },
    {
      title: intl.formatMessage({ id: 'patient-status.answer' }),
      name: 'progress',
    },
    {
      title: intl.formatMessage({ id: 'patient-profile.score' }),
      name: 'score',
    },
  ];

  const getAnswer = (qid: number, answers: IAnswer[]) => {
    const found: any = answers?.length > 0 && answers.find((ans) => ans.questionId === qid);
    return found;
  };

  const getStepEproList = () => {
    if (eproListData && eproListData?.content && eproListData?.content?.length > 0) {
      const newOpts = eproListData?.content?.sort((a: any, b: any) => new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime()).filter((stp: any) => stp?.step?.questionnaire?.type === 'E_PRO');
      const eprList: any = createEproList(newOpts, eproListPageNo === 0 ? [] : stepEproList, participantId, selectedQuestionnaire);
      if (eprList && eprList?.length > 0 && `${eprList[0]?.patientId}` === `${participantId}` && `${eprList[0]?.questionnaireId}` === `${selectedQuestionnaire}`) {
        setStepEproList([eprList[0]]);
      }
    }
  }

  useEffect(() => {
    if (stepEproList && stepEproList?.length > 0) {
      const newList = stepEproList?.filter(
        (stp: any) => `${stp?.questionnaireId}` === `${selectedQuestionnaire}`,
      );
      if (newList && newList?.length > 0) {
        setEproList([]);
        const epList = newList?.reduce((newItem: any, eproItem: any) => {
          eproItem?.eproRepeatation && eproItem?.eproRepeatation?.length > 0 && eproItem?.eproRepeatation?.map((rep: any) => {
            newItem = [...newItem, rep]
            return newItem;
          })
          return newItem;
        }, []);
        epList && epList?.length > 0 && setEproList(epList)
        setIsEproLoading(false);
        setIsOneScroll(false);
      }
    } else {
      if (eproListData && eproListData?.content && eproListData?.content?.length > 0 && stepEproList && stepEproList?.length === 0) {
        if (`${eproListData?.content[0]?.patientId}` != `${participantId}` || `${eproListData?.content[0]?.questionnaire?.id}` != `${selectedQuestionnaire}`) {
          getQuestStepId();
          return;
        }
        getStepEproList();
      }
    }
  }, [selectedQuestionnaire, stepEproList]);


  useEffect(() => {
    getStepEproList();
  }, [eproListData, participantId]);

  const findScore = (foundAnswer: any, quest: any) => {
    let totalScore: any = 0;
    if (quest?.questionType === 'TEXT_INPUT' || quest?.questionType === 'FILE_UPLOAD' || quest?.questionType === 'DATE_TIME') {
      totalScore = 'N/A';
    } else if (quest?.isScored && foundAnswer && foundAnswer?.answer) {
      if (quest?.isScored && quest?.questionOptions && quest?.questionOptions?.length > 0) {
        foundAnswer && quest?.questionOptions?.map((questOpt: any) => {
          if (foundAnswer?.optionId && foundAnswer?.optionId?.includes('[')) {
            const optId = foundAnswer?.optionId?.split('[')[1].split(']')[0];
            if (`${optId}` === `${questOpt?.id}` && questOpt?.score) {
              totalScore = totalScore + questOpt?.score;
            }
          }
        })
      } else {
        totalScore =  totalScore + parseInt(`${foundAnswer?.answer}`, 10);
      }
    }
    return totalScore;
  }

  useEffect(() => {
    if(questionnaire?.questions && questionnaire?.questions?.length > 0) {
      const titleQuestions = questionnaire?.questions.filter((q: any) => q?.titleQuestionId === null);
      const nonTitleQuestions = questionnaire?.questions.filter((q: any) => q?.titleQuestionId != null);
      const newQuestions = [ ...titleQuestions, ...nonTitleQuestions ];
      const newQuestList = newQuestions?.reduce((newQuest: any, quest: any) => {
        const foundAnswer = selectedEpro && selectedEpro?.progress > 0 && quest?.id && getAnswer(quest?.id, selectedEpro?.answers);

        if (quest?.titleQuestionId) {
          const questIndex = newQuest?.findIndex((q: any) => q?.id === quest?.titleQuestionId);
          if (questIndex > -1) {
            newQuest[questIndex].subQuestions.push({
              id: quest?.id,
              ...quest,
              question: quest?.questionTitle,
              answer: foundAnswer,
              score: findScore(foundAnswer, quest)
            });
          }
          return newQuest;
        }

        newQuest?.push({
          id: quest?.id,
          ...quest,
          question: quest?.questionTitle,
          answer: foundAnswer,
          subQuestions: [],
          score: findScore(foundAnswer, quest)
        })
        return newQuest;
      }, []);
      setQuestionList(newQuestList);
    } else { 
      setQuestionList([])
    }
  }, [questionnaire, stepEproList, selectedEpro]);

  const handleEproBtn = () => {
    setIsLoading(true);
    questStepId && triggerEproApi(questStepId);
  }

  const checkEproBtn = () => {
    return isSiteStaff && `${selectedCenter}` === `${loggedInStudyUser?.centerId}` && currentStudy?.status === 'EXECUTION'
  }

  const EproCard = () => {
    return (
      <div className='w-[17.813rem] mx-auto flex flex-col items-center'>
        <div style={{ boxShadow: '0px 0px 8px 4px rgba(62, 49, 108, 0.05)' }}
             className='bg-white rounded-[0.8rem]'>
          <div className='px-4 py-5 flex flex-col items-center'>
            <img src={process.env.PUBLIC_URL + '/assets/images/epro_icon.svg'} alt='epro icon' />
            <p
              className='text-blue-ocean-deep text-2xl mt-4'>{intl.formatMessage({ id: 'questionnaire-field.dataCollection' })}</p>
            <p className='text-center mt-2'>{intl.formatMessage({ id: 'questionnaire-field.dataCollectionMessage' })}</p>
          </div>
        </div>
        <Button onClick={handleEproBtn} disabled={isLoading || !checkEproBtn()} className='h-[40px] flex justify-center items-center w-[251px] mt-[1.68rem] hover:bg-blue-ocean-deep bg-blue-ocean-deep rounded-full text-white mx-auto'>
          {isLoading ? (
            <FiLoader className="w-5 h-5 mr-2 animate-spin self-center text-white" />
          ) : (
            <FormattedMessage id='questionnaire-field.SendePROpatient' />
          )}
        </Button>
      </div>
    );
  }

  return (
    <div className={showEproCard ? 'h-full items-center flex' : 'h-full w-full'}>
      {showEproCard ? <EproCard /> :
        eproList && eproList?.length > 0 ? (<>
          <EproScheduleTable
            onScroll={handleScroll} scrollContainerRef={scrollContainerRef}
            onRowClick={(e) => setSelectedEpro(e)} rowSelect={selectedEpro}
            headerItems={headerItems} columns={eproList || []}
            isMoreLoading={isEproLoading}
          />
          <hr className="bg-purple" />
          <QuestionTable headerItems={questionheaderItems} columns={questionList} />
        </>
      ) : (
        <div className='flex justify-center items-center w-full my-auto h-full'>
          <FiLoader className='w-20 h-20 animate-spin place-self-center text-blue-ocean-deep' />
        </div>
      )}
    </div>
  );
};

export default EproScheduleQuestionaire;
