import { createSvgIcon } from '@mui/material';

export const CloseIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <g clipPath='url(#clip0_1092_2326)'>
      <path
        d='M7 11V13H17V11H7ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z'
        fill='#E07A7A'
      />
    </g>
    <defs>
      <clipPath id='clip0_1092_2326'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>,
  'CloseIcon',
);

export const DragIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='26' height='25' viewBox='0 0 26 25' fill='none'>
    <g clipPath='url(#clip0_769_10415)'>
      <path d='M11.9168 18.75C11.9168 19.8958 10.9418 20.8333 9.75016 20.8333C8.5585 20.8333 7.5835 19.8958 7.5835 18.75C7.5835 17.6042 8.5585 16.6667 9.75016 16.6667C10.9418 16.6667 11.9168 17.6042 11.9168 18.75ZM9.75016 10.4167C8.5585 10.4167 7.5835 11.3542 7.5835 12.5C7.5835 13.6458 8.5585 14.5833 9.75016 14.5833C10.9418 14.5833 11.9168 13.6458 11.9168 12.5C11.9168 11.3542 10.9418 10.4167 9.75016 10.4167ZM9.75016 4.16666C8.5585 4.16666 7.5835 5.10416 7.5835 6.25C7.5835 7.39583 8.5585 8.33333 9.75016 8.33333C10.9418 8.33333 11.9168 7.39583 11.9168 6.25C11.9168 5.10416 10.9418 4.16666 9.75016 4.16666ZM16.2502 8.33333C17.4418 8.33333 18.4168 7.39583 18.4168 6.25C18.4168 5.10416 17.4418 4.16666 16.2502 4.16666C15.0585 4.16666 14.0835 5.10416 14.0835 6.25C14.0835 7.39583 15.0585 8.33333 16.2502 8.33333ZM16.2502 10.4167C15.0585 10.4167 14.0835 11.3542 14.0835 12.5C14.0835 13.6458 15.0585 14.5833 16.2502 14.5833C17.4418 14.5833 18.4168 13.6458 18.4168 12.5C18.4168 11.3542 17.4418 10.4167 16.2502 10.4167ZM16.2502 16.6667C15.0585 16.6667 14.0835 17.6042 14.0835 18.75C14.0835 19.8958 15.0585 20.8333 16.2502 20.8333C17.4418 20.8333 18.4168 19.8958 18.4168 18.75C18.4168 17.6042 17.4418 16.6667 16.2502 16.6667Z' />
    </g>
    <defs>
      <clipPath id='clip0_769_10415'>
        <rect width='26' height='25' fill='white' />
      </clipPath>
    </defs>
  </svg>,
  'DragIcon',
);

export const ImageIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='32' height='35' viewBox='0 0 32 35' fill='none'>
    <g clipPath='url(#clip0_1713_14156)'>
      <path
        d='M5.33329 6.15558C3.87588 6.15558 2.66663 7.44544 2.66663 9.00002V26.0667C2.66663 27.6213 3.87588 28.9111 5.33329 28.9111H16V26.0667H5.33329V9.00002H26.6666V17.5334H29.3333V9.00002C29.3333 7.44544 28.124 6.15558 26.6666 6.15558H5.33329ZM19.3333 16.1111L14.6666 21.8L11.3333 18.2445L7.70308 23.2222H21.3333V18.9556L19.3333 16.1111ZM24 20.3778V26.0667H18.6666V28.9111H24V34.6H26.6666V28.9111H32V26.0667H26.6666V20.3778H24Z'
        fill='#012C54'
      />
    </g>
    <defs>
      <clipPath id='clip0_1713_14156'>
        <rect width='32' height='34.1333' fill='white' transform='translate(0 0.466675)' />
      </clipPath>
    </defs>
  </svg>,
  'DragIcon',
);

export const UploadIcon = createSvgIcon(
  <svg width='22' height='23' viewBox='0 0 22 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M19.25 14.375V18.2083C19.25 18.7167 19.0568 19.2042 18.713 19.5636C18.3692 19.9231 17.9029 20.125 17.4167 20.125H4.58333C4.0971 20.125 3.63079 19.9231 3.28697 19.5636C2.94315 19.2042 2.75 18.7167 2.75 18.2083V14.375' stroke='#737373' fill='#fff' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M15.5837 7.66667L11.0003 2.875L6.41699 7.66667' stroke='#737373' fill='#fff' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M11 2.875V14.375' stroke='#737373' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
  </svg>,
  'uploadIcon'
)

export const StarFilledIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='currentColor'>
    <path d='M8.00065 1.33325L10.0607 5.50659L14.6673 6.17992L11.334 9.42659L12.1207 14.0133L8.00065 11.8466L3.88065 14.0133L4.66732 9.42659L1.33398 6.17992L5.94065 5.50659L8.00065 1.33325Z' stroke='currentColor' fill='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
  </svg>,
  'starIcon'
)

export const StarOutlinedIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='currentColor'>
    <path d='M8.00065 1.33325L10.0607 5.50659L14.6673 6.17992L11.334 9.42659L12.1207 14.0133L8.00065 11.8466L3.88065 14.0133L4.66732 9.42659L1.33398 6.17992L5.94065 5.50659L8.00065 1.33325Z' stroke='#737373' fill='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
  </svg>,
  'starIcon'
)

export const ResolveIcon = createSvgIcon(
  <svg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path fill='#fff' d='M9 5V8.11111C9 8.34686 8.90635 8.57295 8.73965 8.73965C8.57295 8.90635 8.34686 9 8.11111 9H1.88889C1.65314 9 1.42705 8.90635 1.26035 8.73965C1.09365 8.57295 1 8.34686 1 8.11111V1.88889C1 1.65314 1.09365 1.42705 1.26035 1.26035C1.42705 1.09365 1.65314 1 1.88889 1H6.77778' stroke='#004381' strokeLinecap='round' strokeLinejoin='round' />
    <path fill='#fff' d='M2.77734 5.88846L4.00811 7.22179L8.11068 2.77734' stroke='#004381' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
  ,
  'resolveIcon'
)

export const ThreeDotIcon = createSvgIcon(
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
  >
    <path
      d='M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
  ,
  'threeDotIcon'
)

export const Logo = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='49' height='49' viewBox='0 0 49 49' fill='none'>
    <rect width='49' height='49' rx='24.5' fill='#004381' />
    <line
      x1='10.9375'
      y1='32.4482'
      x2='10.9375'
      y2='19.0828'
      stroke='white'
      strokeWidth='3'
      strokeLinecap='round'
    />
    <line
      x1='30.2698'
      y1='18.2375'
      x2='39.6419'
      y2='27.6097'
      stroke='white'
      strokeWidth='3'
      strokeLinecap='round'
    />
    <line
      x1='20.4292'
      y1='27.382'
      x2='11.421'
      y2='19.3701'
      stroke='white'
      strokeWidth='3'
      strokeLinecap='round'
    />
    <line
      x1='29.8261'
      y1='18.3947'
      x2='21.1052'
      y2='27.2605'
      stroke='white'
      strokeWidth='3'
      strokeLinecap='round'
    />
  </svg>,
  'Logo',
);

export const Tick = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 15' fill='none'>
    <path fillRule='evenodd' clipRule='evenodd' d='M19.546 1.11131C19.8272 1.3926 19.9852 1.77406 19.9852 2.17181C19.9852 2.56956 19.8272 2.95102 19.546 3.23231L8.30303 14.4753C8.15445 14.6239 7.97805 14.7418 7.7839 14.8222C7.58976 14.9027 7.38167 14.9441 7.17153 14.9441C6.96138 14.9441 6.75329 14.9027 6.55915 14.8222C6.365 14.7418 6.1886 14.6239 6.04003 14.4753L0.454026 8.89031C0.310761 8.75194 0.196488 8.58642 0.117874 8.40342C0.0392605 8.22041 -0.00211889 8.02358 -0.00384962 7.82441C-0.00558034 7.62524 0.0323724 7.42772 0.107794 7.24338C0.183215 7.05903 0.294595 6.89156 0.435434 6.75072C0.576273 6.60988 0.74375 6.4985 0.928095 6.42308C1.11244 6.34766 1.30996 6.3097 1.50913 6.31143C1.7083 6.31317 1.90513 6.35455 2.08813 6.43316C2.27114 6.51177 2.43666 6.62605 2.57503 6.76931L7.17103 11.3653L17.424 1.11131C17.5633 0.971921 17.7287 0.861346 17.9108 0.785903C18.0928 0.710461 18.288 0.671631 18.485 0.671631C18.6821 0.671631 18.8772 0.710461 19.0593 0.785903C19.2413 0.861346 19.4067 0.971921 19.546 1.11131Z' fill='currentColor'/>
  </svg>,
  'Tick',
);

export const Video = createSvgIcon(
  <svg width='36' height='36' viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg'>
  <path d='M23.6428 28.8042H5.18516C4.60925 28.8042 4.05692 28.5755 3.64969 28.1683C3.24245 27.7612 3.01367 27.209 3.01367 26.6333V9.26611C3.01367 8.69035 3.24245 8.13818 3.64969 7.73106C4.05692 7.32393 4.60925 7.09521 5.18516 7.09521H23.6428C24.2188 7.09521 24.7711 7.32393 25.1783 7.73106C25.5855 8.13818 25.8143 8.69035 25.8143 9.26611V13.673L31.6991 9.47235C31.8611 9.357 32.0518 9.28837 32.2502 9.27397C32.4486 9.25956 32.6471 9.29992 32.8241 9.39065C33.0012 9.48137 33.1498 9.61898 33.254 9.78843C33.3581 9.95789 33.4136 10.1527 33.4145 10.3516V25.5478C33.4136 25.7467 33.3581 25.9415 33.254 26.111C33.1498 26.2804 33.0012 26.418 32.8241 26.5088C32.6471 26.5995 32.4486 26.6398 32.2502 26.6254C32.0518 26.611 31.8611 26.5424 31.6991 26.4271L25.8143 22.2264V26.6333C25.8143 27.209 25.5855 27.7612 25.1783 28.1683C24.7711 28.5755 24.2188 28.8042 23.6428 28.8042ZM5.18516 9.26611V26.6333H23.6428V20.1206C23.6438 19.9217 23.6993 19.7269 23.8034 19.5575C23.9075 19.388 24.0562 19.2504 24.2333 19.1597C24.4103 19.069 24.6088 19.0286 24.8072 19.043C25.0056 19.0574 25.1963 19.126 25.3583 19.2414L31.2431 23.4421V12.4573L25.3583 16.658C25.1963 16.7734 25.0056 16.842 24.8072 16.8564C24.6088 16.8708 24.4103 16.8304 24.2333 16.7397C24.0562 16.649 23.9075 16.5114 23.8034 16.3419C23.6993 16.1725 23.6438 15.9777 23.6428 15.7788V9.26611H5.18516Z' fill='currentColor'/>
  </svg>,
  'Video',
);

export const PolygonCheck = createSvgIcon(
  <svg width='148' height='148' viewBox='0 0 148 148' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <mask id='mask0_2628_17138' maskUnits='userSpaceOnUse' x='0' y='0' width='148' height='148'>
    <path opacity='0.7' d='M64.4574 1.89813C70.5674 -0.632711 77.4326 -0.632711 83.5425 1.89813L118.236 16.2687C124.346 18.7995 129.2 23.6539 131.731 29.7639L146.102 64.4574C148.633 70.5674 148.633 77.4326 146.102 83.5426L131.731 118.236C129.2 124.346 124.346 129.2 118.236 131.731L83.5426 146.102C77.4326 148.633 70.5674 148.633 64.4574 146.102L29.7639 131.731C23.6539 129.2 18.7995 124.346 16.2687 118.236L1.89814 83.5426C-0.6327 77.4326 -0.632702 70.5674 1.89814 64.4574L16.2687 29.7639C18.7995 23.6539 23.6539 18.7995 29.7639 16.2687L64.4574 1.89813Z' fill='#78E8EF'/>
    </mask>
    <g mask='url(#mask0_2628_17138)'>
    <path d='M-1 -47.9995H161.859V195.992H-1V-47.9995Z' fill='#0973D4'/>
    </g>
    <g clipPath='url(#clip0_2628_17138)'>
    <path fillRule='evenodd' clipRule='evenodd' d='M101.843 53.9072C102.663 54.7276 103.124 55.8402 103.124 57.0003C103.124 58.1604 102.663 59.273 101.843 60.0934L69.0508 92.8855C68.6175 93.3189 68.103 93.6628 67.5367 93.8974C66.9705 94.132 66.3635 94.2527 65.7506 94.2527C65.1377 94.2527 64.5308 94.132 63.9645 93.8974C63.3983 93.6628 62.8838 93.3189 62.4504 92.8855L46.1579 76.5959C45.74 76.1923 45.4067 75.7096 45.1775 75.1758C44.9482 74.642 44.8275 74.0679 44.8224 73.487C44.8174 72.9061 44.9281 72.33 45.1481 71.7924C45.368 71.2547 45.6929 70.7662 46.1037 70.3554C46.5145 69.9446 47.0029 69.6198 47.5406 69.3998C48.0783 69.1798 48.6544 69.0691 49.2353 69.0742C49.8162 69.0792 50.3903 69.1999 50.924 69.4292C51.4578 69.6585 51.9406 69.9918 52.3442 70.4097L65.7491 83.8147L95.6537 53.9072C96.06 53.5006 96.5424 53.1781 97.0734 52.9581C97.6044 52.738 98.1735 52.6248 98.7483 52.6248C99.3231 52.6248 99.8922 52.738 100.423 52.9581C100.954 53.1781 101.437 53.5006 101.843 53.9072Z' fill='#78E8EF'/>
    </g>
    <defs>
    <clipPath id='clip0_2628_17138'>
    <rect width='70' height='70' fill='white' transform='translate(39 39)'/>
    </clipPath>
    </defs>
  </svg>
,
  'PolygonCheck',
);

