import { useState } from 'react';
import { StudyItemButton } from '../components/StudyItemButton';
import clsx from 'classnames';
import { FiChevronDown, FiFolder, FiUser, FiEdit3, FiUserCheck, FiPenTool } from 'react-icons/fi';

interface IProps {
  selectedQuestionnairy?: any;
  item: {
    id?: number;
    label: string;
    value: string;
    icon: any;
    name: string;
    type: string;
    items?: { label: string; value: string; icon: any }[];
    questionnaire?: any;
    idVerification?: any;
  };
  handleRowClick: any;
}
const FolderItem: React.FC<IProps> = ({ item, selectedQuestionnairy, handleRowClick }: IProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const getIcon = (type: string) => {
    switch (type) {
      case 'folder':
        return <FiFolder className='w-5 h-5 text-blue-ocean-deep' />;
      case 'CONSENT':
        return <FiPenTool className='w-5 h-5 text-blue-ocean-deep' />;
      case 'ELIGIBILITY':
        return <FiUserCheck className='w-5 h-5 text-blue-ocean-deep' />;
      case 'id-verification':
        return <FiUser className='w-5 h-5 text-blue-ocean-deep' />;
      case 'E_PRO':
      case 'E_CRF':
        return <FiEdit3 className='w-5 h-5 text-blue-ocean-deep' />;
      default:
        return <FiFolder className='w-5 h-5 text-blue-ocean-deep' />;
    }
  };

  return item?.items ? (
    <>
      <div key={item?.name} className='flex justify-between items-center'>
        <span className='flex justify-start items-center gap-x-2 text-[18px] font-medium text-blue-ocean-deep text-left'>
          {item.label}
        </span>
        <button
          type='button'
          title='chevron-down'
          className='group/chevron-down cursor-pointer p-2'
          onClick={() => setIsOpen((prev) => !prev)}
        >
          <FiChevronDown
            className={clsx(
              'text-blue-ocean-deep transition-all transform-gpu origin-center w-6 h-6',
              isOpen && '-rotate-180',
            )}
          />
        </button>
      </div>
      {isOpen && (
        <div className='flex flex-col gap-y-3 mt-3'>
          {item?.items?.map((sItem: any) => {
            
            return (
              <StudyItemButton
                onClick={() => handleRowClick(sItem)}
                key={sItem.value}
                selected={sItem?.value === selectedQuestionnairy?.value}
                icon={sItem.icon}
                textContent={sItem.label}
                className='py-2 rounded-[10px]'
              />
            );
          })}
        </div>
      )}
    </>
  ) : (
    <StudyItemButton
      onClick={() => handleRowClick(item)}
      key={item.value}
      selected={item?.id === selectedQuestionnairy?.id}
      icon={getIcon(
        item?.questionnaire?.type
          ? item?.questionnaire?.type
          : item?.idVerification
          ? 'id-verification'
          : '',
      )}
      textContent={item?.label}
      className='py-2 rounded-[10px] w-full'
    />
  );
};

export default FolderItem;
