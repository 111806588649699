import React from 'react';
import { TypeForm } from 'types/questionnaire';
import { UseFormRegister, useController } from 'react-hook-form';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import clsx from 'classnames';
import './ckeditor.css';

interface IProps {
  value?: string;
  activeInputType?: string;
  setActiveInputType?: (state: string) => void;
  register?: UseFormRegister<TypeForm>;
  // handleOnChange: (name: any, value: string) => void;
  defaultValue?: string;
  classes?: { wrapper?: string };
  customControl?: any;
  control?: any;
  name: string;
  placeholder?: string;
  onChange?: any;
}

const CKEditorComponent: React.FC<IProps> = ({
  // value,
  name,
  classes,
  customControl,
  defaultValue,
  control,
  placeholder,
  // handleOnChange,
}: IProps) => {
  const props = customControl
    ? customControl
    : useController({ name, control, defaultValue }).field;

  const handleChange = (value: string) => {
    console.log('editor onchange: ', value);
    props?.onChange(value);
  };
  return (
    <div className={clsx('my-6', classes?.wrapper)}>
      <CKEditor
        config={
          {
            placeholder,
            toolbar: [
              'heading',
              'alignment',
              '|',
              'fontColor',
              'FontSize',
              'fontFamily',
              'fontBackgroundColor',
              'bold',
              'italic',
              'bulletedList',
              'numberedList',
              'indent',
              '|',
              'blockQuote',
              'undo',
              'redo',
            ],
          } as any
        }
        editor={DecoupledEditor}
        onReady={(editor) => {
          editor.ui
            .getEditableElement()
            .parentElement.insertBefore(
              editor.ui.view.toolbar.element,
              editor.ui.getEditableElement(),
            );
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          handleChange(data);
        }}
        data={props?.value ?? ''}
      />
    </div>
  );
};

export default CKEditorComponent;
