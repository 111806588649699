import React from 'react';
import clsx from 'classnames';

interface IProps {
  children: React.ReactNode;
  className?: string;
  shadow?: boolean;
  onClick?: () => void;
}

const Card: React.FC<IProps> = ({ children, shadow=true, className, onClick }: IProps) => {
  return <div onClick={onClick} className={clsx('bg-white rounded-lg p-4',shadow && 'shadow-md', className)}>{children}</div>;
};

export default Card;
