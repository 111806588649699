import React, { useState } from 'react'; // useState, useRef, useCallback
import clsx from 'classnames';
import { IQuestion } from 'types/questionnaire';
import { useQuestionnaireStore } from 'store/QuestionnaireStore';
import { ToggleButton, Radio } from 'components/common';

interface IProps {
  showScore?: boolean;
  onSaveQuestion: any;
  question: IQuestion;
  setOptionalPropsPopover: any;
  questionnairy?: any;
}

const NumberProps: React.FC<IProps> = ({
  onSaveQuestion,
  question,
  questionnairy,
  setOptionalPropsPopover,
}: IProps) => {
  const { addQuestionError } = useQuestionnaireStore();
  const [scoreChecked, setScoreChecked] = useState<boolean>(question?.isScored ?? false);

  return (
    <div
      className={clsx(
        'w-[175px] absolute z-10 bg-white rounded-[12px] top-0 mt-6 mr-0 -right-[10px] border-[1px] border-purple',
        addQuestionError
          ? '[&>*]:!text-error-feedback [&>button]:!border-error-feedback border-error-feedback'
          : 'border-blue-normal',
      )}
    >
      <div className='flex justify-start items-center flex-col'>
        <div className='w-full pt-2 pb-2 px-3 max-h-[211px] overflow-y-auto'>
          <Radio
            // name={`radioBtns${id}`}
            options={[
              { label: 'Decimal', value: 'DECIMAL' },
              { label: 'INTEGER', value: 'INTEGER' },
            ]}
            optionsClassName='w-full flex-col [&>div]:w-full'
            // optionsClassName={` w-full ${
            //   questionOptions?.length > 3 ? '!grid-cols-3 !grid !gap-x-1' : '!justify-start '
            // }`}
            // disabled={true}
            control={{
              value: question?.numberType,
              onChange: (e: any) => {
                console.log('call onchange: ', e);
                onSaveQuestion({
                  optionalProps: {
                    numberType: e,
                    isScored: question?.isScored,
                  },
                });
                setOptionalPropsPopover(false);
              },
            }}
            noOnchangeCall={true}
            defaultValue={question?.numberType}
            selectedValue={question?.numberType}
            labelClassName='text-sm text-base-black w-full max-w-[80px] min-w-[75px] truncate'
            className={clsx('border-none !py-1.5 !pl-0 !pr-4 gap-x-[4px]')}
            wrapperClassName='[&>div]:px-[2px] relative -left-[1px]'
            radioClassName='!w-4 !h-4 gap-x-2'
          />
          {questionnairy?.questionnaire?.type === 'E_PRO' ? (
            <div className='flex justify-between items-center px-1'>
              <span className='text-gray-medium'>score</span>
              <ToggleButton
                onChange={(e) => {
                  setScoreChecked(e);
                  onSaveQuestion({
                    optionalProps: {
                      isScored: e,
                      numberType: question?.numberType,
                    },
                  });
                }}
                defaultChecked={scoreChecked}
                checked={scoreChecked}
                className='h-5 [&>div>.toggle__dot]:!w-3 [&>div>.toggle__dot]:!h-3'
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default NumberProps;
