
const patientRecruiments: any = [
  {
    id: 1,
    patientId: 123456789,
    patientName: 'Alex John',
    prescreenedPatients: 'by Milo Copilot',
    prescreenedPatientsTotal: 3,
    prescreenedOn: '14 Aug 2024, 15:50',
    eligibility: true,
    verifiedOn: '14 Aug 2024, 15:50',
    site: 'Unwind Therapeutics',
    investigatorName: 'Hollis Valentine',
    inviteStatus: 'INVITED',
    invitedOn: '14 Aug 2024, 15:50',
  },
  {
    id: 2,
    patientId: 123456789,
    patientName: 'Irwin Steele',
    prescreenedPatients: 'by Site staff',
    prescreenedPatientsTotal: 1,
    prescreenedOn: '14 Aug 2024, 15:50',
    eligibility: false,
    verifiedOn: '14 Aug 2024, 15:50',
    site: 'Unwind Therapeutics',
    investigatorName: 'Hilario Hendricks',
    inviteStatus: 'ACCEPTED',
    invitedOn: '14 Aug 2024, 15:50',
  },
  {
    id: 3,
    patientId: 123456789,
    patientName: 'Jerry Hamilton',
    prescreenedPatients: 'by Milo Copilot',
    prescreenedPatientsTotal: 10,
    prescreenedOn: '14 Aug 2024, 15:50',
    eligibility: true,
    verifiedOn: '14 Aug 2024, 15:50',
    site: 'Unwind Therapeutics',
    investigatorName: 'Viola Knox',
    inviteStatus: 'ACTIVE',
    invitedOn: '14 Aug 2024, 15:50',
  },
  {
    id: 4,
    patientId: 123456789,
    patientName: 'Stu Mcpherson',
    prescreenedPatients: 'by Milo Copilot',
    prescreenedPatientsTotal: 3,
    prescreenedOn: '14 Aug 2024, 15:50',
    eligibility: true,
    verifiedOn: '14 Aug 2024, 15:50',
    site: 'Unwind Therapeutics',
    investigatorName: 'Zachary O’brien',
    inviteStatus: 'INVITED',
    invitedOn: '14 Aug 2024, 15:50',
  }
];

export {
  patientRecruiments
}