import { useQuery } from '@tanstack/react-query';
import { memo, useEffect, useState } from 'react';
import { FiTrendingUp, FiFileText, FiUser, FiLoader, FiCalendar } from 'react-icons/fi';
import { Link, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { getPatientData } from 'services/api/appointment';
import { useParticipantStore } from 'store/participantStore';
import clsx from 'classnames';
import { useStudyStore } from 'store/StudyStore';
import { FormattedMessage } from 'react-intl';
import { useQuestionnaireStore } from 'store/QuestionnaireStore';
import { getConsetFormPatient } from 'services/api/questionnaire';
import { fetchStepsByParticipantId } from 'services/api/steps';
import usePermissions from '../../../../hooks/usePermissions';
import { useCentersStore } from '../../../../store/centers';

function ParticipantLayout() {
  const navigate = useNavigate();
  const { studyId, participantId, questionnaireId } = useParams();
  const location = useLocation();
  const { loggedInStudyUser } = useStudyStore();
  const [showPage, setShowPage] = useState(false);
  const [selectedOption, setSelectedOption] = useState<'trending' | 'file' | 'appointment' | 'user' | 'profile'>();
  const { setParticipantData, participant, setParticipant } = useParticipantStore();
  const { centers, selectedCenter } = useCentersStore();
  const { hasNotAccess } = usePermissions();
  const { setConsentFormData, setSteps, setConsentFormLoading, setEproListPageNo } = useQuestionnaireStore();

  const isSiteStaff = loggedInStudyUser?.role?.category === 'SITE_STAFF';
  const isViewOthers = !hasNotAccess('VIEW_OTHER_PATIENTS')

  const { data: stepsList } = useQuery({
    queryKey: ['stepsByStudyId', participantId],
    queryFn: () => fetchStepsByParticipantId(participantId as string),
  });

  const { data: consetFormPatient, isLoading: consentLoading } = useQuery({
    queryKey: ['consetFormPatient', participantId],
    queryFn: () => getConsetFormPatient(participantId as string),
  });

  const { data, refetch, isLoading } = useQuery({
    queryKey: ['participantData'],
    queryFn: () =>
      (participantId || (participant && participant !== 'all')) &&
      getPatientData(participantId || participant),
  });

  useEffect(() => {
    if (questionnaireId && participantId) {
      setEproListPageNo(0);
    }
  }, [questionnaireId, participantId]);

  useEffect(() => {
    setConsentFormLoading(consentLoading);
  }, [consentLoading]);

  useEffect(() => {
    if (stepsList) {
      setSteps(stepsList);
    }
  }, [stepsList]);

  useEffect(() => {
    if (data) {
      setParticipantData(data);
    }
  }, [data]);

  useEffect(() => {
    if (participant && participant !== 'all') {
      refetch();
    }
  }, [participant]);

  useEffect(() => {
    switch (location.pathname.split('/').filter(Boolean)[4]) {
      case 'dashboard':
        setSelectedOption('trending');
        break;
      case 'appointment':
        setSelectedOption('appointment');
        break;
      case 'profile':
        setSelectedOption('profile');
        break;
      case 'questionnaire-status':
        setSelectedOption('file');
        break;
    }
  }, [setSelectedOption, location]);

  useEffect(() => {
    setConsentFormData(consetFormPatient || {});
  }, [consetFormPatient]);

  const isShowPatient = () => {
    const center = centers?.find((cnt: any) => `${cnt.id}` === `${selectedCenter}`);
    const countryCheck = (`${center?.address?.country?.id}` === `${loggedInStudyUser?.country?.id}`) || (loggedInStudyUser?.country?.name === 'Global');
    const isAllowedCntrs = (loggedInStudyUser?.allowedCenters?.length === 0 && countryCheck) || (loggedInStudyUser?.allowedCenters?.length > 0 && loggedInStudyUser?.allowedCenters?.find((cntr: any) => selectedCenter === 'all' ? `${cntr}` === `${center?.id}` : `${cntr}` === `${selectedCenter}`));
    return (isSiteStaff && (isViewOthers || `${center.id}` === `${loggedInStudyUser?.centerId}`)) || (!isSiteStaff && isAllowedCntrs)
  }

  useEffect(() => {
    if (selectedCenter !== 'all' && loggedInStudyUser) {
      if (isShowPatient()) {
        setShowPage(true)
      } else {
        setShowPage(false)
        setParticipant('all');
        navigate(`/study/${studyId}/participants/enrollment`);
      }
    }
  }, [loggedInStudyUser, selectedCenter]);

  const isAppointment = isSiteStaff && `${selectedCenter}` === `${loggedInStudyUser?.centerId}`

  useEffect(() => {
    if (loggedInStudyUser?.centerId && selectedCenter && location?.pathname.includes('appointment') && !isAppointment) {
      navigate(`/study/${studyId}/participants/${participantId}/questionnaire-status`);
    }
  }, [loggedInStudyUser, selectedCenter, location?.pathname, isAppointment, studyId, participantId]);

  if (!showPage) return <></>;
  return (
    <article className='flex w-full bg-background-secondary'>
      <aside className='w-[101px] flex flex-col text-2xl max-h-[23rem] text-blue-ocean-deep bg-white py-7 gap-6 rounded-tr-lg rounded-br-lg mt-[58px] border-2 border-blue-ocean-deep'>
        <Link
          className={clsx(
            ' w-[100px] cursor-pointer flex justify-center items-center flex-col py-[2px]',
            selectedOption === 'file' && '!bg-[rgba(202,199,199,0.22)]',
          )}
          to={`/study/${studyId}/participants/${participantId}/questionnaire-status`}
        >
          <FiUser className='w-6 h-6' strokeWidth={selectedOption === 'file' ? 3 : 1} />
          <span className='mt-1  font-normal text-[12px] px-[1px]'>
            <FormattedMessage id="study-dashboard.patientStatus" />
          </span>
        </Link>
        <Link
          className={clsx(
            'w-[100px] cursor-pointer flex justify-center items-center flex-col py-[2px]',
            selectedOption === 'trending' && '!bg-[rgba(202,199,199,0.22)]',
          )}
          to={`/study/${studyId}/participants/${participantId}/dashboard`}
        >
          <FiTrendingUp className='w-6 h-6' strokeWidth={selectedOption === 'trending' ? 3 : 1} />
          <span className='mt-1  font-normal text-[12px] '>
            <FormattedMessage id="patient-status.progress" />
          </span>
        </Link>
        <Link
          className={clsx(
            'w-[100px] flex justify-center items-center flex-col py-[2px] ',
            selectedOption === 'appointment' && '!bg-[rgba(202,199,199,0.22)] ',
            !isAppointment ? 'cursor-not-allowed pointer-events-none !bg-[rgba(255,255,255,0.22)] opacity-40' : 'cursor-pointer'
          )}
          onClick={ (event) => {
            if (!isAppointment) {
              event.preventDefault()
            }
          }}
          to={`/study/${studyId}/participants/${participantId}/appointment`}
        >
          <FiCalendar className='w-6 h-6' strokeWidth={selectedOption === 'appointment' ? 3 : 1} />
          <span className='mt-1  px-[1px] font-normal text-[11px] '>
            <FormattedMessage id="appointment" />
          </span>
        </Link>
        <Link
          className={clsx(
            'w-[100px] flex justify-center items-center flex-col py-[2px] ',
            selectedOption === 'profile' && '!bg-[rgba(202,199,199,0.22)] ',
            !isShowPatient() ? 'cursor-not-allowed pointer-events-none !bg-[rgba(255,255,255,0.22)] opacity-40' : 'cursor-pointer'
          )}
          onClick={ (event) => {
            if (!isShowPatient()) {
              event.preventDefault()
            }
          }}
          to={`/study/${studyId}/participants/${participantId}/profile`}
        >
          <FiFileText className='w-6 h-6' strokeWidth={selectedOption === 'profile' ? 3 : 1} />
          <span className='mt-1  px-[1px] font-normal text-[11px] '>
            <FormattedMessage id="patient.profile" />
          </span>
        </Link>
      </aside>
      {isLoading ? (
        <FiLoader className='w-20 h-20 animate-spin  self-center text-blue-ocean-deep' />
      ) : (
        <Outlet />
      )}
    </article>
  );
}

export default memo(ParticipantLayout);
