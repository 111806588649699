import { FiFolder } from 'react-icons/fi';
import { IStandardForm } from 'types/study';
import clsx from 'classnames';
import { useStudyStore } from 'store/StudyStore';
import { useQuestionnaireStore } from 'store/QuestionnaireStore';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

interface IProps {
  standardForms: IStandardForm[] | [];
  setPage: (state: string) => void;
  handleRowClick: any;
  handleSelectQuestionnaire: any;
  page: string;
}
const StandardFormsMenu: React.FC<IProps> = ({
  handleRowClick,
  standardForms,
  setPage,
  page,
}: IProps) => {
  const { setSelectedStandardForm } = useStudyStore();
  const { setSelectedQuestionnairy } = useQuestionnaireStore();
  const { questionnaireId: questionnaireIdStr } = useParams();
  const questionnaireId = questionnaireIdStr?.split('-')?.[1];
  const titleMap: any = {
    'AE/SAE/DD': <FormattedMessage id='standard-forms.ae-sae-dd' />,
    'Protocol Deviation': <FormattedMessage id='standard-forms.protocole-deviation' />,
    'Termination form': <FormattedMessage id='standard-forms.termination-form' />,
    '': '',
  };
  if (standardForms?.length === 0) return null;
  return (
    <div className='pl-3 mt-4 mb-4'>
      <div className='mb-5 border-2 border-dashed border-[rgba(0,0,0,0.10) w-[270px] ' />

      <div
        className={clsx(
          'py-4 px-[22px] rounded-lg w-[270px] h-[186px] bg-white',
          page === 'standard-form' && 'border-2 border-blue-ocean-deep',
        )}
      >
        <div className='flex justify-start items-center gap-x-3'>
          <FiFolder className='w-6 h-6 text-blue-ocean-deep' />
          <span className='text-blue-oil text-base font-semibold leading-[22px]'>
            <FormattedMessage id='standard-forms.standard-forms' />
          </span>
        </div>
        {standardForms?.map((form: IStandardForm) => (
          <p
            onClick={() => {
              handleRowClick({ ...form, questionnaire: form } as any);
              setSelectedQuestionnairy({ questionnaire: form, id: form.id } as any);
              setPage('standard-form');
              setSelectedStandardForm(form);
            }}
            key={form.id}
            className={clsx(
              'cursor-pointer px-[10px] py-[7px] mt-[7px] text-left w-full text-blue-oil text-base font-semibold leading-[22px] truncate text-ellipsis',
              Number(questionnaireId) === form.id &&
                'border-[1px] border-purple rounded-lg bg-blue-very-light',
            )}
          >
            {titleMap[form.title]}
          </p>
        ))}
      </div>
    </div>
  );
};
export default StandardFormsMenu;
