import { FormattedMessage } from 'react-intl';
import AgendaCalendar from './AgendaCalendar';

const ParticipantAppointment: React.FC = () => {
  return (
    <section className='w-full bg-background-secondary max-w-screen-2xl mx-auto px-4 xxl:px-0'>
      <h2 className='text-[40px] mt-11 ml-3 pb-4 text-blue-ocean-deep font-normal leading-[34px] text-left'>
        <FormattedMessage id="appointment" />
      </h2>
      <div className='max-w-screen-2xl w-full bg-transparent rounded-3xlflex flex-col '>
        <div className='bg-white rounded-lg border-[1px] border-purple h-full'>
          <AgendaCalendar />
        </div>
      </div>
    </section>
  );
};
export default ParticipantAppointment;
