import { createContext, useState, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import enLang from 'services/language/i18n/en-US.json';
import jaLang from 'services/language/i18n/ja.json';
import ptLang from 'services/language/i18n/pt.json';
import frLang from 'services/language/i18n/fr.json';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { ja, pt, fr, enUS } from 'date-fns/locale';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import setDefaultOptions from 'date-fns/setDefaultOptions';

const locales = { 'en-us': enUS, ja, pt, fr };

type LocaleKey = keyof typeof locales;

interface Messages {
  [key: string]: string;
}

interface MessagesObject {
  [locale: string]: Messages;
}

interface LanguageContextType {
  locale: string;
  dlocale: string;
  setLocale: React.Dispatch<React.SetStateAction<string>>;
}

export const LanguageContext = createContext<LanguageContextType>({
  locale: 'en',
  dlocale: 'en-us',
  setLocale: () => {} // Default value for setLocale
});

const LanguageProvider = ({ children }: any) => {
  const [locale, setLocale] = useState(localStorage.getItem('translation') || 'en');
  const [dlocale, setDLocale] = useState<LocaleKey>('en-us');

  const messages: MessagesObject = {
    en: enLang,
    ja: jaLang,
    pt: ptLang,
    fr: frLang,
  };

  useEffect(() => {
    setLocale(localStorage.getItem('translation') || 'en');
    setDLocale('en-us');
    // setLocale('ja');
    // setDLocale('ja');
    setDefaultOptions({ locale: locales[dlocale] })
  }, []);

  useEffect(() => {
    const newLocale: any = locale === 'en' ? 'en-us' : locale
    setDLocale(newLocale);
  }, [locale]);

  useEffect(() => {
    dlocale && setDefaultOptions({ locale: locales[dlocale] })
  }, [dlocale]);

  return (
    <LanguageContext.Provider value={{ locale, setLocale, dlocale }}>
      <LocalizationProvider adapterLocale={locales[dlocale]} dateAdapter={AdapterDateFns}>
        <IntlProvider locale={locale} defaultLocale={locale} messages={messages[locale]}>
          {children}
        </IntlProvider>
      </LocalizationProvider>
    </LanguageContext.Provider>
  );
};

export default LanguageProvider;
