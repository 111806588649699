import React, { useState } from 'react';
import clsx from 'classnames';

const Rating: React.FC<{
  initialRating: number;
  size?: number;
  label?: string;
  className?: string;
}> = ({ initialRating, size = 5, label, className }) => {
  const [rating, setRating] = useState(initialRating);

  const handleRatingChange = (newRating: number) => {
    setRating(newRating);
  };

  return (
    <div
      data-testid='ratinginput-container'
      className={clsx('mb-4 flex justify-start items-start flex-col', className)}
    >
      <label data-testid='ratinginput-label' className='pl-1 block text-font-darker mb-2'>
        {label}
      </label>
      <div className='flex items-center'>
        {Array.from({ length: size }, (_, i) => i + 1).map((value) => (
          <button
            key={value}
            onClick={() => handleRatingChange(value)}
            className={`${
              value <= rating ? 'text-yellow' : 'text-gray'
            } text-2xl hover:text-yellow-500 focus:outline-none`}
          >
            ★
          </button>
        ))}
      </div>
    </div>
  );
};

export default Rating;
