import { TextInput } from 'components/common';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { IQuestionnaire, IQuestionnaireForm } from 'types/questionnaire';

interface IProps {
  closeModal: () => void;
  questionnaire: IQuestionnaire;
  refetchSteps: any;
  onSubmit: any;
  isLoading: boolean;
}

export default function EditQuestionnaireForm({
  closeModal,
  onSubmit,
  questionnaire,
  isLoading,
}: IProps) {
  const intl = useIntl();
  const { register, handleSubmit } = useForm<IQuestionnaireForm>();

  return (
    <div className='w-[40rem]'>
      <h1 className='mt-4 font-serif leading-[2.625rem] text-[2rem] text-blue-ocean-deep'>
        <FormattedMessage id="step.updateQuestionnaire" />
      </h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          defaultValue={questionnaire?.title}
          name='title'
          register={register}
          label='study-builder.questionnaireTitle'
        />

        <div className='flex justify-end mt-20 mb-12 gap-4'>
          <button
            className='w-[150px] h-12 p-2.5 bg-gray-light text-base-black rounded-[90px] justify-center items-center gap-2.5 inline-flex'
            type='button'
            onClick={closeModal}
          >
            <FormattedMessage id="cancel" />
          </button>
          <button
            className='w-[150px] h-12 p-2.5 bg-blue-ocean-deep rounded-[90px] text-base-white justify-center items-center gap-2.5 inline-flex'
            type='submit'
            disabled={isLoading}
          >
            {isLoading ? `${intl.formatMessage({ id: 'loading' })}...` : `${intl.formatMessage({ id: 'update' })}`}
          </button>
        </div>
      </form>
    </div>
  );
}
