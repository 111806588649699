// import { useState } from 'react';
// import { Button, Modal } from 'components/common';
// import { FiEye } from 'react-icons/fi';
import { retrieveStandardForms } from 'services/api/study';
import { useQuery } from '@tanstack/react-query';
// import { toast } from 'react-toastify';
import { IQuestion } from 'types/questionnaire';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
// import { useQuestionnaireStore } from 'store/QuestionnaireStore';
// import usePermissions from 'hooks/usePermissions';

const useStandardForms = () => {
  const { studyId } = useParams();
  const [questions, setQuestions] = useState<IQuestion[] | [] | null>(null);
  const [selectedStandardForm, setSelectedStandardForm] = useState<any>(null);
  const { data: standardForms, refetch: refetchStandardForms } = useQuery({
    queryKey: ['standard-forms'],
    queryFn: () => retrieveStandardForms(studyId),
    refetchOnMount: 'always',
  });

  return {
    questions,
    setQuestions,
    standardForms,
    selectedStandardForm,
    setSelectedStandardForm,
    refetchStandardForms,
  };
};

export default useStandardForms;
