import React, { useState, useRef, useEffect, ReactElement } from 'react';
import { Popper, Paper, ClickAwayListener, Box } from '@mui/material';
import { FiChevronDown } from 'react-icons/fi';
import { format } from 'date-fns';
import { FormattedMessage } from 'react-intl';
import clsx from 'classnames';

interface IProps {
  availableSlots: any, 
  setValue: any, 
  errors?: any, 
  defaultValue?: any, 
  name: string,
  icon?: ReactElement,
  buttonClasses?: string
}

function SelectTime({ availableSlots, setValue, errors, name, defaultValue, icon = <FiChevronDown className='w-6 h-6' />, buttonClasses }: IProps) {
  const [open, setOpen] = useState(false);
  const anchorElRef = useRef<HTMLDivElement>(null);
  const [selectedTime, setSelectedTime] = useState<any>('Start time');
  const [errorMsg, setErrorMsg] = useState('');

  useEffect(() =>{
    errors?.[name]?.message ? setErrorMsg(errors?.[name]?.message) : setErrorMsg('')
  }, [errors]);

  useEffect(() =>{
    if (defaultValue?.begin && defaultValue?.end) {
      availableSlots.push(defaultValue);
      setSelectedTime(defaultValue);
    }
  }, [defaultValue]);

  const handleClick = () => {
    availableSlots?.length > 0 && setOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const handleSelectTime = (time: any) => {
    setValue('time', time);
    setErrorMsg('');
    setSelectedTime(time);
    setOpen(false);
  };

  // const options = ['11:00 a.m.', '14:30 p.m.', '16:00 p.m.', '12:00 p.m.', '15:00 p.m.', '16:30 p.m.', '13:00 p.m.', '15:30 p.m.', '17:00 p.m.'];

  return (
    <div>
      <div
        className={clsx(`w-[12.125rem] h-[1.875rem] px-2 py-1 rounded border flex justify-between items-center gap-2.5 ${ availableSlots?.length > 0 ? 'cursor-pointer' : 'cursor-not-allowed'} ${open ? 'border-blue-oil  text-blue-oil': 'border-gray-dark text-gray-dark'}`, buttonClasses)}
        onClick={handleClick}
        ref={anchorElRef}
      >
        <p className='text-sm font-normal leading-snug'>{(selectedTime && selectedTime?.begin && format(new Date(selectedTime?.begin), 'hh:mm a')) || 'Start time'}</p>
        {icon}
      </div>
      <Popper open={open} anchorEl={anchorElRef.current} placement='bottom-start' className='z-50'>
        <ClickAwayListener onClickAway={handleClickAway}>
          <Paper className='w-[363px] h-[14.188rem] mt-[1px] pt-4 bg-white !rounded-[10px] border border-blue-ocean-deep'>
            <p className='pl-6 text-lg leading-6 text-blue-ocean-deep font-medium'><FormattedMessage id='agenda-calendar.availabletimes' /></p>
            <Box className=' px-6 pb-[1.938rem] grid grid-flow-row grid-cols-3 gap-x-3 gap-y-4 mt-4 max-h-[8.75rem] overflow-y-auto'>
              {availableSlots?.length > 0 ? availableSlots?.map((opt: any) => (
                <div
                  key={opt.begin}
                  className='w-[6.063rem] h-9 text-blue-ocean-deep text-sm font-normal leading-snug justify-center items-center flex cursor-pointer border border-blue-oil rounded-full hover:bg-purple hover:text-white hover:font-bold hover:border-none'
                  onClick={() => handleSelectTime(opt)}
                >
                    {format(new Date(opt.begin), 'hh:mm a')}
                </div>
              )): <p><FormattedMessage id='loading' />...</p>}
            </Box>
          </Paper>
        </ClickAwayListener>
      </Popper>
      {errorMsg ? <div className='text-error text-left'>{errorMsg}</div> : ''}
    </div>
  );
}

export default SelectTime;
