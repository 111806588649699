import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Editor } from '@ckeditor/ckeditor5-core';
import { UploadAdapter, FileLoader } from '@ckeditor/ckeditor5-upload/src/filerepository';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { useRef, useState, useEffect, useCallback, Dispatch, SetStateAction } from 'react';
import { addStaticQuestionToQuestionnaire } from 'services/api/questionnaire';
import { IQuestion } from 'types/questionnaire';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useOutsideClick } from 'hooks/useOutsideClick';
import { useStudyStore } from 'store/StudyStore';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import './editor.css';
import { FormattedMessage, useIntl } from 'react-intl';

interface PluginInterface {
  createUploadAdapter: (loader: any) => any;
}

interface IQuestionForm {
  id: number;
  questionType: string;
  data: any;
}

interface IProps {
  consentFormContent: string;
  setConsentFormContent: Dispatch<SetStateAction<string>>;
  refetchStudy: any;
  setPage?: any;
}

function uploadAdapter(loader: FileLoader): UploadAdapter {
  return {
    upload: () => {
      return new Promise(async (resolve, reject) => {
        const file = await loader.file;
        const reader: any = new FileReader();
        console.log('file is: ', file, reject);
        reader.onloadend = () => {
          const base64String = reader.result as string;
          resolve({ default: base64String });
        };
        reader.readAsDataURL(file);
      });
    },
    abort: () => {},
  };
}

function uploadPlugin(editor: Editor) {
  const fileRepositoryPlugin = editor.plugins.get('FileRepository') as PluginInterface;
  fileRepositoryPlugin.createUploadAdapter = (loader: any) => {
    return uploadAdapter(loader);
  };
}

const ConsentForm = ({
  setPage,
  consentFormContent,
  setConsentFormContent,
  refetchStudy,
}: IProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [debouncedInputValue, setDebouncedInputValue] = useState('');
  const [images, setImages] = useState<File[]>([]);
  console.log('', setImages);
  const location = useLocation();
  const navigate = useNavigate();
  const { studyId } = useParams();
  const intl = useIntl();
  const { currentStudy } = useStudyStore();

  useEffect(() => {
    async function convertFunc() {
      const arr: any = [];
      for (const element of images) {
        const base64String = await convertFileToBase64(element);
        arr.push(base64String);
      }
    }
    convertFunc();
  }, [images]);

  useEffect(() => {
    if (currentStudy?.consentQuestionnaire?.staticContents?.[0]) {
      setConsentFormContent(currentStudy?.consentQuestionnaire?.staticContents?.[0]?.instruction);
    }
  }, [currentStudy?.consentQuestionnaire?.staticContents]);

  useEffect(() => {
    const body: any = {
      staticContentType: 'INSTRUCTION',
      data: {
        ...(currentStudy?.consentQuestionnaire?.staticContents?.[0]?.id && {
          id: currentStudy?.consentQuestionnaire?.staticContents?.[0]?.id,
        }),
        sortOrder: 1,
        instruction: debouncedInputValue,
      },
    };

    if (debouncedInputValue) addStaticQuestionMutate([body]);
  }, [debouncedInputValue]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedInputValue(consentFormContent);
    }, 2000);
    return () => clearTimeout(timeoutId);
  }, [consentFormContent, 500]);

  useEffect(() => {
    if (
      currentStudy?.consentQuestionnaire?.active == false &&
      location?.pathname?.includes('consent')
    ) {
      navigate(`/study/${studyId}/study-builder`);
      setPage('');
    }
  }, [currentStudy?.consentQuestionnaire?.active]);

  const { mutate: addStaticQuestionMutate } = useMutation<
    IQuestion,
    unknown,
    [IQuestionForm],
    unknown
  >(
    (data: [IQuestionForm]) =>
      addStaticQuestionToQuestionnaire(data, currentStudy?.consentQuestionnaire?.id),
    {
      onSuccess: () => {
        refetchStudy();
      },
      onError(error: any) {
        toast.error(
          error?.response?.data?.message ?? intl.formatMessage({ id: 'error.questionAdd' }),
        );
      },
    },
  );

  const convertFileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        const base64String = reader.result as string;
        resolve(base64String);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  };

  const handleOutsideClick = useCallback(
    (event: MouseEvent | React.MouseEvent<HTMLElement, MouseEvent>, clickedOut: boolean) => {
      if (clickedOut) {
        const body: any = {
          staticContentType: 'INSTRUCTION',
          data: {
            ...(currentStudy?.consentQuestionnaire?.staticContents?.[0]?.id && {
              id: currentStudy?.consentQuestionnaire?.staticContents?.[0]?.id,
            }),
            sortOrder: 1,
            instruction: consentFormContent,
          },
        };

        addStaticQuestionMutate([body]);
      }
    },
    [consentFormContent],
  );

  useOutsideClick(containerRef, handleOutsideClick);

  return (
    <div className='w-full min-h-[658px] bg-white border-[1px] border-purple rounded-lg'>
      <h3 className='text-[20px] text-blue-ocean-deep font-medium leading-[28px] text-left py-[28px] pl-[43px] border-b-2 border-b-purple cursor-pointer pb-4'>
        <FormattedMessage id='study-builder.consentForm' />
      </h3>
      <div
        ref={containerRef}
        className='m-2 md:m-10 md:mb-6 mt-9 bg-white rounded-3xl [&>.ck>.ck-editor__top]:!border-blue-ocean-deep [&>.ck-content]:!border-purple [&>.ck-content]:h-[60vh] [&>.ck-toolbar]:!mb-2 [&>.ck-content]:overflow-y-auto [&>.ck-content]:overflow-x-hidden'
      >
        <CKEditor
          config={
            {
              extraPlugins: [uploadPlugin],
              toolbar: [
                'heading',
                'alignment',
                '|',
                'fontColor',
                'FontSize',
                'fontFamily',
                'fontBackgroundColor',
                'bold',
                'italic',
                '|',
                'imageUpload',
                '|',
                'bulletedList',
                'numberedList',
                'indent',
                'todoList',
                'outdent',
                '|',
                'blockQuote',
                'undo',
                'redo',
              ],
              fontSize: {
                options: [
                  'default',
                  9,
                  11,
                  13,
                  14,
                  15,
                  16,
                  17,
                  18,
                  20,
                  19,
                  21,
                  22,
                  23,
                  24,
                  25,
                  26,
                  27,
                  28,
                  29,
                  30,
                  34,
                ],
              },
              heading: {
                options: [
                  { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                  {
                    model: 'heading1',
                    view: 'h1',
                    title: 'Heading 1',
                    class: 'ck-heading_heading1',
                  },
                  {
                    model: 'heading2',
                    view: 'h2',
                    title: 'Heading 2',
                    class: 'ck-heading_heading2',
                  },
                  {
                    model: 'headingFancy',
                    view: {
                      name: 'h2',
                      classes: ['fancy', 'text-red'], 
                    },
                    title: 'Heading 2 (fancy)',
                    class: 'ck-heading_heading2_fancy',
                    converterPriority: 'high',
                  },
                ],
              },
            } as any
          }
          editor={DecoupledEditor}
          onReady={(editor) => {
            editor.ui
              .getEditableElement()
              .parentElement.insertBefore(
                editor.ui.view.toolbar.element,
                editor.ui.getEditableElement(),
              );
          }}
          onChange={(event, editor) => {
            const data = editor.getData();
            setConsentFormContent(data);
          }}
          data={consentFormContent}
        />
      </div>
    </div>
  );
};
export default ConsentForm;
