import React, { useCallback, useRef, useState } from 'react';
import clsx from 'classnames';
import Input from 'components/common/TextInput';
import { Select, ThreeDotsDropdown } from 'components/common';
import { SvgIcon } from '@mui/material';
import { ThreeDotIcon } from 'components/common/Icons';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { deleteCollaborator, postCollaborators, updateCollaborators } from 'services/api/users';
import { SubmitHandler } from 'react-hook-form';
import { useUserStore } from 'store/usersStore';
import { useParams } from 'react-router-dom';
import { forgetPassword } from 'services/api/auth';
import { useOutsideClick } from 'hooks/useOutsideClick';
import { FormattedMessage, useIntl } from 'react-intl';

export interface ISiteStaffDataItem {
  id: number;
  staffName: string;
  role: string;
  email: string;
  phone: string;
  onboardingProgress: string;
  center?: any;
}

interface FormType {
  studyUserId?: number,
  firstName: string,
  lastName?: string,
  roleId: number,
  email: string,
  phoneNumber: string,
  studyId: number,
  countryName: string,
  centerId: number,
  onboardingProgress?: string
}

export interface ISiteStaffHeaderItem {
  name?: string;
  title?: string;
  width?: string;
}

interface IProps {
  headerItems: ISiteStaffHeaderItem[];
  columns: ISiteStaffDataItem[];
  rowSelect?: boolean;
  methods: any;
  refetch?: any;
  selectedCenter: any;
  isAction: boolean;
}

const SiteStaffTable: React.FC<IProps> = (props) => {
  const intl = useIntl();
  const { headerItems, columns, methods, refetch, selectedCenter, isAction } = props;
  const [isEdit, setIsEdit] = useState(-1);
  const [isError, setIsError] = useState(false);
  const { rolesOpt } = useUserStore();
  const { studyId } = useParams();
  const values: any = methods?.watch();
  const containerRef = useRef<HTMLDivElement>(null);

  const { mutate: addCollaborator } = useMutation<any, unknown, FormType, unknown>(
    (data: FormType) => postCollaborators(data),
    {
      onSuccess: () => {
        refetch();
        methods?.reset();
        setIsError(false);
        toast.success(intl.formatMessage({ id: 'site.staffAddMessage' }));
      },
      onError(e: any) {
        toast.error(e?.response?.data?.message);
      },
    },
  );

  const { mutate: editCollaborators } = useMutation<any, unknown, FormType, unknown>(
    (data: FormType) => updateCollaborators(data),
    {
      onSuccess: () => {
        refetch();
        setIsEdit(-1);
        setIsError(false);
      },
      onError(e: any) {
        toast.error(e?.response?.data?.message);
      },
    },
  );

  const { mutate: delCol } = useMutation<any, unknown, number | string, unknown>(
    (id: number | string) => deleteCollaborator(id, selectedCenter.id),
    {
      onSuccess: () => {
        refetch();
        toast.success(intl.formatMessage({ id: 'site.staffDeleteMessage' }));
      },
      onError(e: any) {
        toast.error(e?.response?.data?.message);
      },
    },
  );

  const { mutate: resetPassword } = useMutation<any, unknown, string, unknown>(
    (email: string) => forgetPassword(email),
    {
      onSuccess: () => {
        toast.success(intl.formatMessage({ id: 'site.requestSendMessage' }));
      },
      onError(e: any) {
        toast.error(e?.response?.data?.message);
      },
    },
  );

  const onSubmit: SubmitHandler<any> = (data: any) => {
    if (!data?.staffName || !data?.email || !data?.roleId) {
      setIsError(true);
    }
    if (isAction && isEdit > -1 && studyId && data[`staffName-${isEdit}`] !== '' && data[`email-${isEdit}`] !== '' && data[`roleId-${isEdit}`] > -1) {
      try {
        editCollaborators({
          studyUserId: isEdit,
          firstName: data[`staffName-${isEdit}`],
          roleId: data[`roleId-${isEdit}`],
          email: data[`email-${isEdit}`],
          phoneNumber: data[`phone-${isEdit}`],
          studyId: parseInt(`${studyId}`, 10),
          countryName: selectedCenter?.country || '',
          centerId: selectedCenter?.id,
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    } else if (isAction && data?.staffName && data?.email && data?.roleId && selectedCenter) {
      try {
        addCollaborator({
          firstName: data?.staffName,
          roleId: data?.roleId,
          email: data?.email,
          phoneNumber: data?.phoneNumber,
          studyId: parseInt(`${studyId}`, 10),
          countryName: selectedCenter?.country || '',
          centerId: selectedCenter?.id,
          onboardingProgress: ''
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  };

  const handleIsEdit = (item: any) => {
    setIsEdit(item?.id);
    const foundRole = rolesOpt?.find((us: any) => us?.label === item?.role);
    methods.setValue(`staffName-${item?.id}`, item?.staffName)
    methods.setValue(`email-${item?.id}`, item?.email)
    methods.setValue(`phone-${item?.id}`, item?.phone)
    methods.setValue(`roleId-${item?.id}`, foundRole?.value);
  }

  const handleOutsideClick = useCallback(
    (event: MouseEvent | React.MouseEvent<HTMLElement, MouseEvent>, clickedOut: boolean) => {
      if (clickedOut) {
        onSubmit?.(values);
      }
    },
    [values],
  );

  useOutsideClick(containerRef, handleOutsideClick);

  const handleDelete = (item: any) => {
    const foundUser = item?.center?.users && item?.center?.users?.find((usr: any) => usr?.id === item?.id);
    foundUser && delCol(foundUser?.user?.id);
  }

  return (
    <div ref={containerRef}  className='font-sans'>
      <table className='w-full min-w-full'>
        <thead>
          <tr>
            {headerItems.map((r) => (
              <th
                key={r.name}
                className={clsx(
                  'text-left text-base text-blue-ocean-deep font-semibold leading-6 tracking-[0.15px] px-2.5 py-2',
                  r.width,
                )}
              >
                {r.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {isAction && (<tr className='bg-transparent group hover:bg-blue-light-100 overflow-hidden rounded'>
            <td className='px-2.5 w-[15.7rem] h-[2.5rem] py-1 rounded-l'>
              <Input
                onKeyDown={(e: any) => {
                  if (e.key === 'Enter') {
                    onSubmit?.(values);
                  }
                }}
                register={methods.register}
                name='staffName'
                inputClassName={`h-7 !rounded placeholder:text-sm ${isError && values?.staffName === '' ? '!border-error': '!border-gray-very-light group-hover:!border-blue-normal'}`}
                className='!mt-0'
                placeholder={intl.formatMessage({ id: 'site.addSiteStaff' })}
              />
            </td>
            <td className='px-2.5 py-1 w-[12rem]'>
              <Select
                name='roleId'
                noTranslation={true}
                options={rolesOpt?.length > 0 ? rolesOpt : ([] as any)}
                buttonClassName='h-7 bg-white !text-gray-dark w-[10.7rem]'
                selectOptClass='!text-sm'
                mainClass={`!rounded ${isError && !values?.roleId ? '!border-error': '!border-gray-very-light group-hover:!border-blue-normal'} w-[10.9rem]`}
                optionsClassName='text-gray-dark !text-sm'
                optWrapperClassName='z-100'
                onBlur={() => onSubmit?.({ ...methods.watch() })}
              />
            </td>
            <td className='px-2.5 py-1 w-[16rem]'>
              <Input
                register={methods.register}
                name='email'
                onKeyDown={(e: any) => {
                  if (e.key === 'Enter') {
                    onSubmit?.(values);
                  }
                }}
                inputClassName={`h-7 !rounded placeholder:text-sm ${isError && values?.email === '' ? '!border-error': '!border-gray-very-light group-hover:!border-blue-normal'}`}
                className='!mt-0'
                placeholder=''
              />
            </td>
            <td className=''></td>
            <td className='rounded-r '></td>
          </tr>)}
          {columns.map((item) => (
            <>
              <tr
                key={item.id}
                className='group bg-transparent hover:bg-blue-light-100 border-b border-b-gray-very-light'
              >
                <td onDoubleClick={() => handleIsEdit(item)} className='px-2.5 text-gray-dark text-sm py-2.5 text-left table-cell'>
                  {isAction && isEdit === item?.id ?
                    <Input
                      onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                          onSubmit?.(values);
                        }
                      }}
                      register={methods.register}
                      name={`staffName-${item?.id}`}
                      inputClassName='h-7 !rounded placeholder:text-sm !border-blue-normal' 
                      className='!mt-0' 
                      placeholder={intl.formatMessage({ id: 'site.addSiteStaff' })}
                    /> : <p>{item.staffName}</p>
                  }
                </td>
                <td onDoubleClick={() => handleIsEdit(item)} className='px-2.5 text-gray-dark text-sm py-2.5 text-left table-cell'>
                  {isAction && isEdit === item?.id ?
                    <Select
                      noTranslation={true}
                      name={`roleId-${item?.id}`}
                      options={rolesOpt?.length > 0 ? rolesOpt : ([] as any)}
                      buttonClassName='h-7 bg-white !text-gray-dark w-[183px]'
                      selectOptClass='!text-sm'
                      mainClass='!rounded !border-blue-normal w-[186px]'
                      optionsClassName='text-gray-dark !text-sm'
                      onBlur={() => onSubmit?.({ ...methods.watch() })}
                    /> : <p>{item.role}</p>
                  }
                </td>
                <td className='px-2.5 text-gray-dark text-sm py-2.5 text-left table-cell'>
                  <p>{item.email}</p>
                </td>
                <td onDoubleClick={() => handleIsEdit(item)} className='px-2.5 text-gray-dark text-sm py-2.5 text-left table-cell'>
                  {isAction && isEdit === item?.id ?
                    <Input
                      onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                          onSubmit?.(values);
                        }
                      }}
                      type='number'
                      register={methods.register}
                      name={`phone-${item?.id}`}
                      inputClassName='h-7 [&::-webkit-inner-spin-button]:appearance-none !rounded placeholder:text-sm !border-blue-normal' className='!mt-0'
                      placeholder={intl.formatMessage({ id: 'site.addPhoneNumber' })}
                    /> : <p>{item.phone}</p>
                  }
                </td>
                <td className='px-2.5 text-gray-dark text-sm py-2.5 text-left table-cell'>
                  {item.onboardingProgress}
                </td>
                {isAction && (<ThreeDotsDropdown
                  dotIcon={<SvgIcon component={ThreeDotIcon} className='cursor-pointer text-blue-ocean-deep' />}
                  classes={{
                    container: 'hidden group-hover:block',
                    // container: 'block',
                    button: '!px-0 absolute -right-5 top-1',
                    content: '-top-10 !-right-12 w-fit -mt-[51px] border-none',
                  }}
                  customContent={
                    <div className='bg-blue-oil rounded-[2px] text-xs font-medium w-max text-left text-white'>
                      <span
                        onClick={() => handleDelete(item)}
                        className='cursor-pointer block p-1.5 hover:bg-blue-ocean-deep'
                      >
                        <FormattedMessage id="delete" />
                      </span>
                      <span
                        onClick={() => resetPassword(item?.email)}
                        className='cursor-pointer block p-1.5 hover:bg-blue-ocean-deep'
                      >
                        <FormattedMessage id="reset-password" />
                      </span>
                    </div>
                  }
                />)}
              </tr>
            </>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SiteStaffTable;
