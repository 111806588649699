import { differenceInYears } from 'date-fns';

export function randomIntFromInterval(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export const calculateAge = (dob: string): number => {
  const age = differenceInYears(new Date(), new Date(dob));
  return age;
};
export const getMaxSortId = (data: any) => {
  if (data?.length === 0 || !data) return 0;
  const maxSortId = data?.reduce(
    (max: any, item: any) => (item?.sortOrder > max ? item?.sortOrder : max),
    -Infinity,
  );
  return maxSortId;
};

export const disableWeekends = (date: any) => {
  return date.getDay() === 0 || date.getDay() === 6;
};

export const generateLinearNumbers = (start: number, end: number, count: number): number[] => {

  if (count < 1) {
    throw new Error('Count must be a positive integer.');
  }

  const lowerBound = Math.min(start, end);
  const upperBound = Math.max(start, end);

  const step = (upperBound - lowerBound) / (count - 1); // Calculate the step size

  const numbers1 = [];
  for (let i = 0; i < count; i++) {
    numbers1.push(Math.ceil(lowerBound + i * step));
  }

  if (start > end) {
    return numbers1.slice(1, numbers1.length - 1).reverse();
  } else {
    return numbers1.slice(1, numbers1.length - 1);
  }
  // return arr;
  // console.log("params: ", )
  // if (count < 1) {
  //   throw new Error('Count must be a positive integer.');
  // }

  // const step = (end - start) / (count - 1); // Calculate the step size

  // const numbers = [];
  // for (let i = 0; i < count; i++) {
  //   console.log('gen val: ', start, i, step);
  //   numbers.push(start + i * step);
  // }
  // console.log('numbers: ', numbers);
  // return numbers;
};
