import { axiosClient } from 'config/axios';
import {
  IStep,
  IStepForm,
  IRorderStepsForm,
  IStepPatientResponseItem,
  IAddAnswerForm,
} from 'types/step';

export const fetchStepsByStudyId = async (
  id: number | string,
  centerId?: number | string,
  patientId?: number | string,
): Promise<IStep[]> => {
  
  const queryParams =
    centerId || patientId
      ? `?${centerId && `centerId=${centerId}&`}${patientId && `patientId=${patientId}`}`
      : '';
  const { data } = await axiosClient.get<IStep[]>(`/steps/study/${id}${queryParams}`);
  return data;
};

export const getSummary = async (id: number | string) => {
  const { data } = await axiosClient.get(`/queries/summary?studyId=${id}`);
  return data;
};

export const fetchStepsByParticipantId = async (
  id: number | string,
): Promise<IStepPatientResponseItem[]> => {
  const { data } = await axiosClient.get<IStepPatientResponseItem[]>(`/patients/${id}/steps`);
  return data;
};

export const createStep = async (payload: IStepForm) => {
  const { data } = await axiosClient.post<IStep>(`/steps?studyId=${payload?.studyId ?? 1}`, [
    {
      folderId: payload?.folderId,
      questionnaireId: payload?.questionnaireId,
      idVerificationId: payload?.idVerificationId,
      sortOrder: payload.sortOrder,
    },
  ]);
  return data;
};

export const reOrderSteps = async (payload: IRorderStepsForm) => {
  const { data } = await axiosClient.put<IStep>(
    `/steps/reorder?studyId=${payload?.studyId ?? 1}`,
    payload.newList,
  );
  return data;
};

export const deleteStep = async (stepId: number) => {
  return axiosClient.delete(`/steps/${stepId}`);
};

export const addPatientAnswers = async (stepId: number, payload: IAddAnswerForm[]) => {
  const { data } = await axiosClient.post(`/patients/steps/${stepId}/answers`, payload);
  return data;
};

export const deletePatientAnswer = async (id: number) => {
  const { data } = await axiosClient.delete(`patients/answers/${id}`);
  return data;
};

export const updateStudyStatus = async (studyId: number, status: string) => {
  const { data } = await axiosClient.put(`studies/${studyId}/updateStatus?studyStatus=${status}`);
  return data;
};

export const updateStudyCriteria = async (studyId: number, payload: any) => {
  const { data } = await axiosClient.put(`studies/${studyId}/criteria`, payload);
  return data;
};

const headers = {
  'Content-Type': 'multipart/form-data',
};

// const config = {
//   onUploadProgress: function(e: any) {
//     var percentCompleted = Math.round((e.loaded * 100) / e.total)
//     console.log(percentCompleted)
//   }
// }

export const addAnsUploadFile = async ({ stepId, questionId, payload, onProgress }: any) => {
  const formData = new FormData();
  formData.append('file', payload);
  const { data } = await axiosClient.post(
    `/patients/steps/${stepId}/questions/${questionId}/uploadFile`,
    formData,
    { onUploadProgress: onProgress, headers },
  );
  return data;
};

export const addRepAnsUploadFile = async ({ stepId, questionId, payload, onProgress, repeatedAnswersRecordId }: any) => {
  const formData = new FormData();
  formData.append('file', payload);
  const { data } = await axiosClient.post(
    `patients/steps/${stepId}/questions/${questionId}/repeated-answer-upload-file${repeatedAnswersRecordId ? `?repeatedAnswersRecordId=${repeatedAnswersRecordId}` : ''}`,
    formData,
    { onUploadProgress: onProgress, headers },
  );
  return data;
};
