import React from 'react';
import { FiAlertCircle, FiChevronRight, FiFolder } from 'react-icons/fi';
import clsx from 'classnames';

interface Folder {
  title: string;
  onClick: () => void;
  isOpen: boolean;
  isResolved?: boolean;
  isQueries?: boolean;
}

function Folder({ title, isOpen, onClick, isResolved, isQueries }: Folder) {
  return (
    <button
      type='button'
      onClick={onClick}
      className='flex w-full items-center justify-between transition-all text-blue-ocean-deep  px-2 py-1.5 pl-4 '
    >
      <div className='flex items-center gap-2'>
        <FiFolder className='w-5 h-5 text-blue-ocean-deep' />
        <p className='leading-6 font-semibold text-blue-ocean-deep text-sm'>{title}</p>
        {isQueries && !isOpen && !isResolved && <FiAlertCircle className='w-3 h-3 text-error cursor-pointer block' />}
      </div>
      <FiChevronRight
        className={clsx(
          'transition-all stroke-1 transform-gpu origin-center w-5 h-6 text-blue-ocean-deep',
          isOpen && 'rotate-90',
        )}
      />
    </button>
  );
}

export default Folder;
