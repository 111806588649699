import React, { useState, useRef, useCallback } from 'react';
import { FiFilter, FiChevronRight, FiChevronDown, FiLoader, FiChevronLeft } from 'react-icons/fi';
import { Button } from 'components/common';
import FilterForm from './FilterForm';
import { useParams } from 'react-router-dom';
import { useOutsideClick } from 'hooks/useOutsideClick';
import { retrieveAuditTrials, retrieveAuditTriggers } from 'services/api/audittrial';
import { useQuery } from '@tanstack/react-query';
import clsx from 'classnames';
import { format } from 'date-fns';
import { FormattedMessage, useIntl } from 'react-intl';
import ExpandableTableRow from './ExpandableTableRow';

interface ITrigger {
  label: string;
  value: string;
}
interface TableData {}

interface TableProps {
  data?: TableData[];
  fetchData?: (page: number, pageSize: number) => Promise<{ data: TableData[]; total: number }>; // Function to handle data fetching
  currentPage?: number;

  onPageChange?: (page: number) => void;
}

const AuditTrail: React.FC<TableProps> = () => {
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [eventType, setEventType] = useState<any>(null);
  const [triggeredBy, setTriggeredBy] = useState(null);
  const [pageSize, setPageSize] = useState<any>(25);
  const [showPageSize, setShowPageSize] = useState<any>(null);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const { studyId } = useParams();
  const [currentPage, setCurrentPage] = useState(0);
  const intl = useIntl();

  const tags: string[] = [];
  if (startDate) tags.push(intl.formatMessage({ id: 'audit-trial.start-date' }));
  if (endDate) tags.push(intl.formatMessage({ id: 'audit-trial.end-date' }));
  if (eventType) tags.push(intl.formatMessage({ id: 'audit-trial.event-type' }));
  if (triggeredBy) tags.push(intl.formatMessage({ id: 'audit-trial.triggered-by' }));

  const getFilters = () => {
    const filtersStr: string =
      (startDate ? `startDate=${format(new Date(startDate), 'yyyy-MM-dd')}` + '&' : '') +
      (endDate ? `endDate=${format(new Date(endDate), 'yyyy-MM-dd')}` + '&' : '') +
      (eventType ? `eventType=${eventType}` + '&' : '') +
      (triggeredBy ? `triggeredBy=${triggeredBy}` + '&' : '');
    return filtersStr;
  };

  const {
    data: audits,
    isLoading,
    refetch: refetchAudits,
  } = useQuery<any | undefined>({
    queryKey: ['audit-trails', studyId, pageSize],
    queryFn: () => retrieveAuditTrials(studyId, getFilters(), `${currentPage}`, pageSize),
    refetchOnMount: 'always',
  });
  const { data: auditsTriggers } = useQuery<string[] | undefined>({
    queryKey: ['audit-triggers', studyId],
    queryFn: () => retrieveAuditTriggers(studyId),
    refetchOnMount: 'always',
  });

  const columns = [
    { label: intl.formatMessage({ id: 'audit-trial.date' }), value: 'date' },
    { label: intl.formatMessage({ id: 'audit-trial.username' }), value: 'username' },
    { label: intl.formatMessage({ id: 'audit-trial.user-role' }), value: 'role' },
    { label: intl.formatMessage({ id: 'audit-trial.participant-ID' }), value: 'patientCode' },

    { label: intl.formatMessage({ id: 'audit-trial.event-type' }), value: 'eventType' },
    { label: intl.formatMessage({ id: 'audit-trial.old-value' }), value: 'oldValue' },
    { label: intl.formatMessage({ id: 'audit-trial.new-value' }), value: 'newValue' },
  ];
  const containerRef = useRef<HTMLDivElement>(null);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    setTimeout(() => {
      refetchAudits();
    }, 100);
  };

  const handleOutsideClick = useCallback(
    (event: any | React.MouseEvent<HTMLElement, MouseEvent>, clickedOut: boolean) => {
      if (clickedOut) {
        if (
          !event.target.classList.contains('page-btn') &&
          !event.target.classList.contains('MuiSvgIcon-root') &&
          !event.target.classList.contains('page-size-item') &&
          !event.target.classList.contains('filter-tag') &&
          !event.target.classList.contains('pageSize') &&
          !(
            [
              'wrapper',
              'audit-trial-container',
              'pagination-wrapper',
              'pagination-total-counts',
              'items-per-page',
              'filters-wrapper',
            ].includes(event.target.id) && !isFilterOpen
          ) &&
          !event.target.classList.contains('prev-page') &&
          !event.target.classList.contains('next-page') &&
          event.target.tagName !== 'svg'
        ) {
          if (currentPage + 1 !== audits?.totalPages) setCurrentPage(0);
          if (event.target.classList.contains('page-size')) {
            setShowPageSize(!showPageSize);
          } else if (event.target.classList.contains('filter-btn')) {
            event.stopPropagation();
            setShowPageSize(false);
          } else if (!event.target.classList.contains('MuiButtonBase-root')) {
            event.stopPropagation();
            refetchAudits();
            setIsFilterOpen(false);
            setShowPageSize(false);
          }
        }
      }
    },
    [isFilterOpen, tags],
  );

  const triggers: ITrigger[] | undefined = auditsTriggers?.map((item: string) => ({
    label: item,
    value: item,
  }));

  useOutsideClick(containerRef, handleOutsideClick);

  const handleRemoveTag = (filterTag: string) => {
    if (filterTag === intl.formatMessage({ id: 'audit-trial.start-date' })) setStartDate(null);
    if (filterTag === intl.formatMessage({ id: 'audit-trial.end-date' })) setEndDate(null);
    if (filterTag === intl.formatMessage({ id: 'audit-trial.event-type' })) setEventType(null);
    if (filterTag === intl.formatMessage({ id: 'audit-trial.triggered-by' })) setTriggeredBy(null);
    setCurrentPage(0);
    setTimeout(() => {
      refetchAudits();
    }, 100);
    refetchAudits;
  };
  const handleClickPageSize = (pgSize: number) => {
    setCurrentPage(0);
    setPageSize(pgSize);
    setShowPageSize(false);
    setTimeout(() => {
      refetchAudits();
    }, 100);
  };
  const getTagValue = (tagName: string) => {
    console.log('tagname: ', tagName);
    switch (tagName) {
      case `${intl.formatMessage({ id: 'audit-trial.start-date' })}`:
        return `${intl.formatMessage({ id: 'audit-trial.start-date' })} : ${startDate ? format(new Date(startDate), 'yyyy-MM-dd') : ''}`;
      case `${intl.formatMessage({ id: 'audit-trial.end-date' })}`:
        return `${intl.formatMessage({ id: 'audit-trial.end-date' })}: ${startDate ? format(new Date(startDate), 'yyyy-MM-dd') : ''}`;
      case `${intl.formatMessage({ id: 'audit-trial.event-type' })}`:
        return `${intl.formatMessage({ id: 'audit-trial.event-type' })}: ${EVENT_TYPES.find((it: any) => it.value === eventType)?.label ?? ''}`;
      case `${intl.formatMessage({ id: 'audit-trial.triggered-by' })}`:
        return `${intl.formatMessage({ id: 'audit-trial.triggered-by' })}: ${triggeredBy ?? ''}`;
      default:
        return null;
    }
  };
  const EVENT_TYPES: any = [
    {
      label: intl.formatMessage({ id: 'audit-trial.patient-non-anonymized-data-modified' }),
      value: 'PATIENT_NON_ANONYMIZED_DATA_MODIFIED',
    },
    {
      label: intl.formatMessage({ id: 'audit-trial.document-signed' }),
      value: 'DOCUMENT_SIGNED',
    },
    {
      label: intl.formatMessage({ id: 'audit-trial.document-signature-expired' }),
      value: 'DOCUMENT_SIGNATURE_EXPIRED',
    },
    {
      label: intl.formatMessage({ id: 'audit-trial.patient-anonymized-data-modified' }),
      value: 'PATIENT_ANONYMIZED_DATA_MODIFIED',
    },
    {
      label: intl.formatMessage({ id: 'audit-trial.data-verified' }),
      value: 'DATA_VERIFIED',
    },
    {
      label: intl.formatMessage({ id: 'audit-trial.participant-deleted' }),
      value: 'PARTICIPANT_DELETED',
    },
    {
      label: intl.formatMessage({ id: 'audit-trial.participant-created' }),
      value: 'PARTICIPANT_CREATED',
    },
    {
      label: intl.formatMessage({ id: 'audit-trial.participant-deregistered-client-app' }),
      value: 'PARTICIPANT_DEREGISTERED_CLIENT_APP',
    },
    {
      label: intl.formatMessage({ id: 'audit-trial.appointment-scheduled' }),
      value: 'APPOINTMENT_SCHEDULED',
    },
    {
      label: intl.formatMessage({ id: 'audit-trial.appointment-joined' }),
      value: 'APPOINTMENT_JOINED',
    },
    {
      label: intl.formatMessage({ id: 'audit-trial.signature-expired' }),
      value: 'PARTICIPANT_SIGNED',
    },
    {
      label: intl.formatMessage({ id: 'audit-trial.participant-signed' }),
      value: 'Participant signed',
    },
    {
      label: intl.formatMessage({ id: 'audit-trial.query-created' }),
      value: 'QUERY_CREATED',
    },
    {
      label: intl.formatMessage({ id: 'audit-trial.query-updated' }),
      value: 'QUERY_UPDATED',
    },
    {
      label: intl.formatMessage({ id: 'audit-trial.query-response-created' }),
      value: 'QUERY_RESPONSE_CREATED',
    },
    {
      label: intl.formatMessage({ id: 'audit-trial.query-response-updated' }),
      value: 'QUERY_RESPONSE_UPDATED',
    },
    {
      label: intl.formatMessage({ id: 'audit-trial.query-response-deleted' }),
      value: 'QUERY_RESPONSE_DELETED',
    },
    {
      label: intl.formatMessage({ id: 'audit-trial.query-resolved' }),
      value: 'QUERY_RESOLVED',
    },
    {
      label: intl.formatMessage({ id: 'audit-trial.query-deleted' }),
      value: 'QUERY_DELETED',
    },
  ];

  return (
    <div className='flex flex-col justify-start items-center w-full' id='wrapper'>
      <div className='flex flex-col max-w-[1257px] lg:max-w-[1500px] w-full mx-auto  rounded-lg '>
        <div className='flex justify-between items-end' id='audit-trial-container'>
          <h2 className='text-[24px] mt-9 mb-2 pl-6 text-blue-ocean-deep leading-[34px] text-left'>
            <FormattedMessage id='audit-trial.audit-trial' />
          </h2>
          <div className='flex justify-end items-center pb-3 pr-4' id='filters-wrapper'>
            {tags.length ? (
              <div className='flex justify-center items-center gap-x-3 mr-6'>
                {tags.map((it: string) => (
                  <p
                    onClick={() => setIsFilterOpen(!isFilterOpen)}
                    key={it}
                    className='m-0 flex justify-start items-center relative group bg-purple text-blue-ocean-deep text-xs px-3 py-1.5 rounded-full cursor-pointer'
                  >
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRemoveTag(it);
                      }}
                      className='filter-tag hidden group-hover:block absolute  top-0 right-[6px] cursor-pointer'
                    >
                      x
                    </span>
                    <span className='text-white'> {getTagValue(it)}</span>
                  </p>
                ))}
              </div>
            ) : null}
            <Button
              onClick={() => setIsFilterOpen(!isFilterOpen)}
              className='filter-btn hover:bg-purple hover:text-white flex justify-center items-center w-max gap-x-2.5 bg-purple text-white rounded-lg !py-1'
            >
              <span className='filter-btn'>
                <FormattedMessage id='audit-trial.filter' />
              </span>
              <FiFilter className='filter-btn' />
            </Button>
          </div>
        </div>
        {isLoading ? (
          <div className='p-4 text-center flex justify-center items-center w-full'>
            <FiLoader className='w-20 h-20 animate-spin place-self-center text-blue-ocean-deep' />
          </div>
        ) : (
          <div className='' id='form-container' ref={containerRef}>
            <div className='inline-block min-w-full relative border-1 border-purple rounded-lg'>
              <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg bg-white px-11 py-[22px] '>
                <table className='mb-10 min-w-full divide-y divide-gray-200 bg-white table-auto'>
                  <thead>
                    <tr>
                      {columns.map((column) => (
                        <th
                          key={column.label}
                          scope='col'
                          className='px-4 py-3 text-left font-semibold text-base tracking-wider text-blue-ocean-deep min-w-[160px] '
                        >
                          {column.label}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className='divide-y divide-gray-200 bg-white'>
                    {audits?.content?.map((row: any, index: number) => (
                      <React.Fragment key={row.id || Math.random()}>
                        <ExpandableTableRow columns={columns} row={row} key={index} />
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
              {isFilterOpen && (
                <div className='absolute -top-[15px] right-0 w-[331px] h-full bg-white z-50'>
                  <FilterForm
                    triggers={triggers}
                    {...{
                      startDate,
                      setStartDate,
                      endDate,
                      setEndDate,
                      triggeredBy,
                      setTriggeredBy,
                      eventType,
                      setEventType,
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <div className=' mt-4  fixed bottom-0 w-full bg-white flex justify-center items-center'>
        <div
          className=' flex justify-between items-center px-10 py-2.5 w-[90%]'
          id='pagination-wrapper'
        >
          <div className='flex justify-start items-center '>
            <button
              className={`prev-page page-btn mr-2 rounded-md text-sm font-medium flex justify-center items-center w-[22px] h-[22px] text-[9.6px] ${
                currentPage === 0
                  ? 'bg-white text-gray-700 cursor-not-allowed border-1 border-gray-300 rounded-md pointer-events-none	'
                  : 'bg-white text-gray-700  border-1 border-gray-300 rounded-md'
              }`}
              onClick={() => handlePageChange(Math.max(currentPage - 1, 0))}
              disabled={currentPage === 0}
            >
              <FiChevronLeft className='prev-page w-4 h-4 text-gray-700' />
            </button>

            {Array.from({ length: audits?.totalPages ?? 1 }, (_, i) => (
              <button
                key={i + 1}
                className={`page-btn mr-2 rounded-md text-sm font-medium w-[22px] h-[22px] text-[9.6px] ${
                  currentPage === i
                    ? 'border-1 border-blue-ocean-deep text-blue-ocean-deep'
                    : 'border-1 border-gray-300 text-dark'
                }`}
                onClick={() => handlePageChange(i)}
              >
                {i + 1}
              </button>
            ))}
            <button
              className={`next-page rounded-md text-sm font-medium flex justify-center items-center w-[22px] h-[22px] text-[9.6px] ${
                currentPage + 1 === audits?.totalPages
                  ? 'bg-white text-gray-700 cursor-not-allowed border-1 border-gray-300 pointer-events-none	'
                  : 'bg-white text-gray-700 border-1 border-spacing-1 border-gray-300'
              }`}
              onClick={() =>
                currentPage + 1 !== audits?.totalPages
                  ? handlePageChange(Math.min(currentPage + 1, audits?.totalPages))
                  : {}
              }
              disabled={currentPage === audits?.totalPages}
            >
              <FiChevronRight className='next-page w-3 h-3 text-gray-700' />
            </button>
          </div>
          <div>
            <span id='pagination-total-counts'>
              {`${currentPage * pageSize + 1}-${(currentPage + 1) * pageSize < audits?.totalElements ? (currentPage + 1) * pageSize : audits?.totalElements}`}{' '}
              <FormattedMessage id='audit-trial.of' /> {audits?.totalElements ?? ''}
            </span>
          </div>
          <div className='relative flex justify-end items-center gap-x-3'>
            <span className='text-black text-xs' id='items-per-page'>
              <FormattedMessage id='audit-trial.items-per-page' />
            </span>
            <div>
              <div
                onClick={() => setShowPageSize(!showPageSize)}
                className='pageSize flex justify-between items-center py-1 px-2.5 border-1 border-black rounded-full gap-x-2 cursor-pointer'
              >
                <span className='pageSize text-black text-xs'>{pageSize}</span>
                <FiChevronDown className='pageSize' />
              </div>
              <div
                className={clsx(
                  'absolute -top-[120px] right-0 z-50 bg-white w-[100px] rounded-lg py-2',
                  !showPageSize && 'hidden',
                )}
              >
                <ul className='list-none [&>li]:py-1 [&>li]:cursor-pointer'>
                  <li
                    onClick={(e: any) => {
                      e.stopPropagation();
                      handleClickPageSize(25);

                      // setCurrentPage(0);
                      // setPageSize(25);
                      // setShowPageSize(false);
                      // setTimeout(() => {
                      //   refetchAudits();
                      // }, 100);
                    }}
                    className={clsx('page-size-item', pageSize === 25 ? 'bg-gray-300' : '')}
                  >
                    25
                  </li>
                  <li
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClickPageSize(50);
                      // setCurrentPage(0);
                      // setPageSize(50);
                      // setShowPageSize(false);
                      // refetchAudits();
                    }}
                    className={clsx('page-size-item', pageSize === 50 ? 'bg-gray-300' : '')}
                  >
                    50
                  </li>
                  <li
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClickPageSize(100);
                      // setPageSize(100);
                      // setCurrentPage(0);
                      // setShowPageSize(false);
                      // refetchAudits();
                    }}
                    className={clsx('page-size-item', pageSize === 100 ? 'bg-gray-300' : '')}
                  >
                    100
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuditTrail;
