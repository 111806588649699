import { create } from 'zustand';
import { ICenter } from 'types/centers';

export interface IQueryQuestion {
  id: number,
  patientStep: {
      id: number,
      status: string,
      patientId: number,
      type: string
  },
  baseQuestionId: number,
  center: ICenter,
  centerUser: any,
  folder: any,
  comment: string,
  createdAt: string
  updatedAt: string
  replies: string[]
  resolved: boolean 
}

interface QueryQuestionState {
  queryQuestions: IQueryQuestion[] | any;
  setQueryQuestions: (item: ICenter[] | any) => void;
}

export const useQueryQuestionStore = create<QueryQuestionState>((set) => ({
  queryQuestions: [],
  setQueryQuestions: (item) => set({ queryQuestions: item}),
}));
