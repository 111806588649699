/* eslint-disable react/no-unknown-property */
import clsx from 'classnames';

interface IProps {
  item: any;
  provided: any;
  snapshot: any;
  children: any;
  className?: string;
  disable?: boolean;
}
const ListItem: React.FC<IProps> = ({
  disable,
  className,
  provided,
  snapshot,
  children,
}: IProps) => {
  return (
    <div
      className={clsx('grid', className)}
      ref={provided.innerRef}
      snapshot={snapshot}
      {...(disable ? {} : provided.draggableProps)}
      {...(disable ? {} : provided.dragHandleProps)}
    >
      <>{children}</>
      {provided.placeholder}
    </div>
  );
};

export default ListItem;
