import { useMutation, useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import {
  addBiologicalData, addConcoMedication, addDiseaseHistory, addMedicalHistory,
  addNurse, addSurgicalHistory, addSymptoms,
  deleteBiologicalData, deleteConcoMedication, deleteDiseaseHistory,
  deleteMedicalHistory, deleteSurgicalHistory, deleteSymptoms,
  editBiologicalData, editBiologicalFile, editConcoMedication,
  editDiseaseHistory, editMedicalHistory, editNurse,
  editPatientData, editSurgicalHistory, editSymptoms,
  fetchBiologicalData, fetchConcoMedicationFormHistory, fetchDiseaseHistory,
  fetchMedicalHistory, fetchPatientData, fetchSurgicalHistory, fetchSymptoms,
  IBiologicalDataForm, IConcomitantMedicationForm, IDiseaseHistoryForm,
  IMedicalHistoryForm, INurseForm, IPatientForm, ISurgicalHistoryForm,
  ISymptomsForm,
} from '../services/api/patientProfile';
import { toast } from 'react-toastify';
import { editParticipant } from '../services/api/study';

const useAddPatientDataForm = () => {
  const { participantId } = useParams();

  const { refetch: refetchPatientData } = useQuery({
    queryKey: ['patientData', participantId],
    queryFn: () => fetchPatientData(parseInt(`${participantId}`, 10)),
  });

  const editParticipantsMutation = useMutation(
    (data: any) => editParticipant(data),
    {
      onSuccess: () => {
        refetchPatientData()
      },
      onError(e: any) {
        toast.error(e?.response?.data?.message);
      },
    },
  );

  const editPatientMutation = useMutation(
    (data: IPatientForm) => editPatientData(parseInt(`${participantId}`, 10), data),
    {
      onSuccess: () => {
        refetchPatientData()
      },
      onError(e: any) {
        toast.error(e?.response?.data?.message);
      },
    },
  );

  const nurseMutation = useMutation(
    (data: INurseForm) => addNurse(data, parseInt(`${participantId}`, 10)),
    {
      onSuccess: () => {
        refetchPatientData()
      },
      onError(e: any) {
        toast.error(e?.response?.data?.message);
      },
    },
  );

  const editNurseMutation = useMutation(
    ({ data, id }: {data: INurseForm, id: number}) => editNurse(data, parseInt(`${participantId}`, 10), id),
    {
      onSuccess: () => {
        refetchPatientData()
      },
      onError(e: any) {
        toast.error(e?.response?.data?.message);
      },
    },
  );

  const {refetch: refetchMedicalHistory } = useQuery({
    queryKey: ['medicalHistoryForm', participantId],
    queryFn: () => fetchMedicalHistory(parseInt(`${participantId}`, 10)),
  });

  const medicalHistroyMutation = useMutation(
    (data: IMedicalHistoryForm) => addMedicalHistory(data),
    {
      onSuccess: () => {
        refetchMedicalHistory()
      },
      onError(e: any) {
        toast.error(e?.response?.data?.message);
      },
    },
  );

  const editMedicalHistroyMutation = useMutation(
    ({ data, id }: {data: IMedicalHistoryForm, id: number}) => editMedicalHistory(data, id),
    {
      onSuccess: () => {
        refetchMedicalHistory()
      },
      onError(e: any) {
        toast.error(e?.response?.data?.message);
      },
    },
  );

  const deleteMedicalHistroyMutation = useMutation(
    (id: number) => deleteMedicalHistory(id),
    {
      onSuccess: () => {
        refetchMedicalHistory()
      },
      onError(e: any) {
        toast.error(e?.response?.data?.message);
      },
    },
  );

  const { refetch: refetchSurgicalHistory } = useQuery({
    queryKey: ['surgicalHistoryForm', participantId],
    queryFn: () => fetchSurgicalHistory(parseInt(`${participantId}`, 10)),
  });

  const surgicalHistroyMutation = useMutation(
    (data: ISurgicalHistoryForm) => addSurgicalHistory(data),
    {
      onSuccess: () => {
        refetchSurgicalHistory()
      },
      onError(e: any) {
        toast.error(e?.response?.data?.message);
      },
    },
  );

  const editSurgicalHistroyMutation = useMutation(
    ({ data, id }: {data: ISurgicalHistoryForm, id: number}) => editSurgicalHistory(data, id),
    {
      onSuccess: () => {
        refetchSurgicalHistory()
      },
      onError(e: any) {
        toast.error(e?.response?.data?.message);
      },
    },
  );

  const deleteSurgicalHistroyMutation = useMutation(
    (id: number) => deleteSurgicalHistory(id),
    {
      onSuccess: () => {
        refetchSurgicalHistory()
      },
      onError(e: any) {
        toast.error(e?.response?.data?.message);
      },
    },
  );

  const { refetch: refetchConcoMedication } = useQuery({
    queryKey: ['concoMedication', participantId],
    queryFn: () => fetchConcoMedicationFormHistory(parseInt(`${participantId}`, 10)),
  });

  const concoMedicationMutation = useMutation(
    (data: IConcomitantMedicationForm) => addConcoMedication(data),
    {
      onSuccess: () => {
        refetchConcoMedication()
      },
      onError(e: any) {
        toast.error(e?.response?.data?.message);
      },
    },
  );

  const editConcoMedicationMutation = useMutation(
    ({ data, id }: {data: IConcomitantMedicationForm, id: number}) => editConcoMedication(data, id),
    {
      onSuccess: () => {
        refetchConcoMedication()
      },
      onError(e: any) {
        toast.error(e?.response?.data?.message);
      },
    },
  );

  const deleteConcoMedicationMutation = useMutation(
    (id: number) => deleteConcoMedication(id),
    {
      onSuccess: () => {
        refetchConcoMedication()
      },
      onError(e: any) {
        toast.error(e?.response?.data?.message);
      },
    },
  );

  const { refetch: refetchSymptoms } = useQuery({
    queryKey: ['symptomsData', participantId],
    queryFn: () => fetchSymptoms(parseInt(`${participantId}`, 10)),
  });

  const symptomsMutation = useMutation(
    (data: ISymptomsForm) => addSymptoms(data),
    {
      onSuccess: () => {
        refetchSymptoms()
      },
      onError(e: any) {
        toast.error(e?.response?.data?.message);
      },
    },
  );

  const editSymptomsMutation = useMutation(
    ({ data, id }: {data: ISymptomsForm, id: number}) => editSymptoms(data, id),
    {
      onSuccess: () => {
        refetchSymptoms()
      },
      onError(e: any) {
        toast.error(e?.response?.data?.message);
      },
    },
  );

  const deleteSymptomsMutation = useMutation(
    (id: number) => deleteSymptoms(id),
    {
      onSuccess: () => {
        refetchSymptoms()
      },
      onError(e: any) {
        toast.error(e?.response?.data?.message);
      },
    },
  );

  const { refetch: refetchDiseaseHistory } = useQuery({
    queryKey: ['diseaseHistory', participantId],
    queryFn: () => fetchDiseaseHistory(parseInt(`${participantId}`, 10)),
  });

  const diseaseHistoryMutation = useMutation(
    (data: IDiseaseHistoryForm) => addDiseaseHistory(data),
    {
      onSuccess: () => {
        refetchDiseaseHistory()
      },
      onError(e: any) {
        toast.error(e?.response?.data?.message);
      },
    },
  );

  const editDiseaseHistoryMutation = useMutation(
    ({ data, id }: {data: IDiseaseHistoryForm, id: number}) => editDiseaseHistory(data, id),
    {
      onSuccess: () => {
        refetchDiseaseHistory()
      },
      onError(e: any) {
        toast.error(e?.response?.data?.message);
      },
    },
  );

  const deleteDiseaseHistoryMutation = useMutation(
    (id: number) => deleteDiseaseHistory(id),
    {
      onSuccess: () => {
        refetchDiseaseHistory()
      },
      onError(e: any) {
        toast.error(e?.response?.data?.message);
      },
    },
  );

  const { refetch: refetchBiologicalData } = useQuery({
    queryKey: ['biologicalData', participantId],
    queryFn: () => fetchBiologicalData(parseInt(`${participantId}`, 10)),
  });

  const biologicalDataMutation = useMutation(
    (data: any) => addBiologicalData(data),
    {
      onSuccess: () => {
        refetchBiologicalData()
      },
      onError(e: any) {
        toast.error(e?.response?.data?.message);
      },
    },
  );

  const editBiologicalDataMutation = useMutation(
    ({ data, id }: {data: IBiologicalDataForm, id: number}) => editBiologicalData(data, id),
    {
      onSuccess: () => {
        refetchBiologicalData()
      },
      onError(e: any) {
        toast.error(e?.response?.data?.message);
      },
    },
  );

  const deleteBiologicalDataMutation = useMutation(
    (id: number) => deleteBiologicalData(id),
    {
      onSuccess: () => {
        refetchBiologicalData()
      },
      onError(e: any) {
        toast.error(e?.response?.data?.message);
      },
    },
  );

  const editBiologicalFileMutation = useMutation(
    ({ data, id }: {data: any, id: number}) => editBiologicalFile(data, id),
    {
      onSuccess: () => {
        refetchBiologicalData()
      },
      onError(e: any) {
        toast.error(e?.response?.data?.message);
      },
    },
  );

  return {
    editPatientMutation, editParticipantsMutation,
    nurseMutation, editNurseMutation, deleteBiologicalDataMutation,
    medicalHistroyMutation, editMedicalHistroyMutation, deleteMedicalHistroyMutation,
    surgicalHistroyMutation, editSurgicalHistroyMutation, deleteSurgicalHistroyMutation,
    concoMedicationMutation, editConcoMedicationMutation, deleteConcoMedicationMutation,
    symptomsMutation, editSymptomsMutation, deleteSymptomsMutation,
    diseaseHistoryMutation, editDiseaseHistoryMutation, deleteDiseaseHistoryMutation,
    biologicalDataMutation, editBiologicalDataMutation, editBiologicalFileMutation,
  };
};

export default useAddPatientDataForm;