import React, { useCallback, useEffect, useRef, useState, FunctionComponent } from 'react';
import { useOutsideClick } from 'hooks/useOutsideClick';
import { TextInput, ThreeDotsDropdown } from 'components/common';
import clsx from 'classnames';
import { IPermissionItem } from 'types/roles';
import useRoles from '../../useRoles';
import { ThreeDotIcon } from 'components/common/Icons';
import { SvgIcon } from '@mui/material';
import usePermissions from 'hooks/usePermissions';
import { FormattedMessage, useIntl } from 'react-intl';

type WrapperComponentProps = any;
type WrappedComponentProps = any;

interface IProps {
  WrapperComponent?: FunctionComponent<WrapperComponentProps>;
  handleCallBack?: any;
  props?: WrapperComponentProps & WrappedComponentProps;
  defaultValue?: any;
  defaultIsOpen?: boolean;
  inputPlaceHolder?: string;
  inputContainerClassName?: string;
  name: string;
  id: number;
  selectedRole?: any;
  setSelectedRole?: any;
  category: string;
  permissions: IPermissionItem[];
  selectedPermissions: number[];
  setSelectedPermissions: any;
  list: any;
  rolesRefetch: any;
}

const Role: FunctionComponent<IProps> = ({
  handleCallBack = () => {},
  defaultIsOpen = false,
  inputPlaceHolder = '',
  inputContainerClassName = '',
  name,
  id,
  list,
  selectedRole,
  setSelectedRole,
  permissions,
  selectedPermissions,
  setSelectedPermissions,
  category,
  rolesRefetch,
}) => {
  const intl = useIntl();
  const { handleEditRole, handleDeleteRole } = useRoles({ rolesRefetch });
  // const { selectedRole, setSelectedRole } = useRoles();
  const [isOpen, setIsOpen] = useState<any>(null);
  const [isHover, setIsHover] = useState<any>(false);
  const [value, setValue] = useState(name);
  const containerRef = useRef<HTMLDivElement>(null);
  const labelRef = useRef<HTMLLabelElement>(null);
  const { isFreezeStudy, hasNotAccess } = usePermissions();

  const handleOutsideClick = useCallback(
    (event: MouseEvent | React.MouseEvent<HTMLElement, MouseEvent>, clickedOut: boolean) => {
      if (clickedOut && event.target !== labelRef.current) {
        setIsOpen(false);
        handleCallBack(value);
        if (value && name !== value) handleEditRole(id, value, category, selectedPermissions);
      }
    },
    [setIsOpen, value],
  );

  useEffect(() => {
    if (isOpen == false) {
      console.log('call handleCallBack count');
    }
  }, [isOpen]);

  useEffect(() => {
    if (defaultIsOpen) {
      setIsOpen(true);
    }
  }, [defaultIsOpen]);

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      handleCallBack(value);
      setIsOpen(false);
      if (value && name !== value) handleEditRole(id, value, category, selectedPermissions);
    }
  };

  useOutsideClick(containerRef, handleOutsideClick);

  const handleRowOnClick = () => {
    const tmpList: number[] = permissions.map((p: IPermissionItem) => p.id);
    setSelectedRole(selectedRole === id ? null : id);
    setSelectedPermissions([...tmpList]);
  };
  const hideDeleteRoleAction = () => {
    if (list.length === 1 && (category === 'SITE_STAFF' || category === 'FULL_ADMINISTRATIVE'))
      return true;
  };
  const disableActions = isFreezeStudy() || hasNotAccess('ADD_ROLES') || hasNotAccess('VIEW_ROLES');

  return (
    <div
      className='flex w-full'
      onMouseEnter={!disableActions ? () => setIsHover(true) : () => {}}
      onMouseLeave={() => setIsHover(false)}
    >
      <div className='w-[95%] hover:bg-blue-light py-1 shrink-0 flex justify-start items-center gap-x-[10px] mb-1'>
        <span
          onClick={handleRowOnClick}
          className={clsx(
            'shrink-0 p-0 cursor-pointer w-[18px] h-[18px] rounded-full bg-white border-[3px] border-blue-ocean-deep flex justify-center items-center',
          )}
        >
          <span
            className={clsx(
              'shrink-0 rounded-[20px] w-[10px] h-[10px] block',
              selectedRole === id ? 'bg-blue-ocean-deep' : 'bg-white',
            )}
          ></span>
        </span>
        {isOpen ? (
          <div ref={containerRef} className='w-full'>
            <TextInput
              control={{
                value: value,
                onChange: (e: any) => setValue(e.target.value),
              }}
              name={'title1'}
              placeholder={inputPlaceHolder ?? `${intl.formatMessage({ id: 'patient-profile.type' })} ...`}
              noTranslation={true}
              className={clsx(
                'h-full !mt-0 [&>input]:border-none [&>input]:bg-blue-light [&>input]:h-[28px] [&>input]:mt-0 [&>input]:pl-2 pt-0 pb-0 [&>input]:rounded [&>input]:w-full ',
                inputContainerClassName,
              )}
              inputClassName='border-r-none border-l-none border-t-none !px-1 !py-1 !text-[14px]'
              onKeyDown={handleKeyDown}
            />
          </div>
        ) : (
          <div
            className='flex justify-start items-center text-blue-ocean-deep font-normal text-sm leading-[22px] flex-1'
            onClick={handleRowOnClick}
            onDoubleClick={(e: any) => {
              if (!disableActions) {
                e.stopPropagation();
                handleRowOnClick();
                setIsOpen(!isOpen);
              }
            }}
          >
            <span className='truncate text-ellipsis cursor-pointer w-[160px] inline-block text-left'>{name}</span>
          </div>
        )}
      </div>
      <ThreeDotsDropdown
        dotIcon={<SvgIcon component={ThreeDotIcon} className='cursor-pointer ' />}
        classes={{
          container: isHover ? (hideDeleteRoleAction() ? 'hidden' : 'block') : 'hidden',
          button: '!px-0',
          content: '-top-[27px] w-fit -mt-[51px] border-none',
        }}
        customContent={
          <span
            onClick={() => handleDeleteRole(id)}
            className='cursor-pointer bg-blue-oil w-10 h-5 rounded-[2px] px-[7px] py-1 text-[10px] text-white'
          >
            <FormattedMessage id="delete" />
          </span>
        }
      />
    </div>
  );
};

export default Role;
