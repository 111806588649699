import { axiosClient } from 'config/axios';
import { INewFlow, INewTaskFlow } from 'types/flow';

export const fetchOnboardingFlows = async (id: string | undefined) => {
  const { data } = await axiosClient.get(`onboarding-workflows/studies/${id}`);
  return data;
};

export const createNewFlowFC = async (payload: INewFlow) => {
  const { data } = await axiosClient.post<INewFlow>('/onboarding-workflows', {
    studyId: payload.studyId,
    title: payload.title,
  });
  return data;
};
export const addTaskToFlowFC = async (payload: INewTaskFlow) => {
  const { data } = await axiosClient.post<INewTaskFlow>('/onboarding-tasks', {
    workflowId: payload.workflowId,
    type: payload.type,
    sortOrder: payload.sortOrder,
  });
  return data;
};
export const reOrderTasksFC = async (payload: any) => {
  const { data } = await axiosClient.put<any>(
    `/onboarding-workflows/${payload.flowId}/reorder`,
    payload.newOrders,
  );
  return data;
};
export const editFlowTitleFC = async (payload: { id: number; newTitle: string }) => {
  const { data } = await axiosClient.put<any>(
    `/onboarding-workflows/${payload.id}/title?title=${payload.newTitle}`,
  );
  return data;
};
export const deleteTaskFC = async (taskId: string) => {
  const { data } = await axiosClient.delete<any>(`/onboarding-tasks/${taskId}`);
  return data;
};
export const deleteFlowFC = async (flowId: string) => {
  const { data } = await axiosClient.delete<any>(`/onboarding-workflows/${flowId}`);
  return data;
};
export const editTaskInfoFC = async (params: any) => {
  const { id, ...rest } = params;
  const { data } = await axiosClient.put<any>(`/onboarding-tasks/${id}`, { ...rest });
  return data;
};

export const attachFileToTaskFC = async (id?: any, payload?: any) => {
  console.log('call upload paras: ', id, payload);

  const { data } = await axiosClient.post<any>(`/onboarding-tasks/${id}/attachment`, payload, {
    headers: {
      'Content-Type': 'multipart/form-data', // Ensure the correct content type
    },
  });

  return data;
};
export const deleteTaskAttachmentFC = async (payload?: any) => {
  const { data } = await axiosClient.delete<any>(
    `/onboarding-tasks/${payload?.id?.id}/delete-attachment?attachmentUrl=${payload?.id?.url?.trim()}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  return data;
};
