import DashboardLayout from 'components/layout/DashboardLayout';
import { StudyParticipants } from 'pages/Study/StudyParticipants';
import StudyDashboard from 'pages/Study/StudyDashboard';
import { StudyBuilder } from 'pages/Study/StudyBuilder';
import { Suspense, lazy } from 'react';
import componentLoader from 'utils/componentLoader';
import Onboarding from 'pages/Study/Onboarding';
import Settings from 'pages/Study/Settings';
import ParticipantLayout from 'pages/Study/layout/ParticipantLayout';
import ParticipantDashboard from 'pages/Study/ParticipantPages/ParticipantDashboard';
import Users from 'pages/Study/Users';
import SiteSelect from 'pages/Study/SiteSelect';
import VideoCall from 'pages/VideoCall';
import { RouteObject } from 'react-router-dom';
import { QuestionnaireStatus } from 'pages/Study/ParticipantPages/QuestionnaireStatus';
import FallbackLoading from 'components/common/FallbackLoading';
import Studies from 'pages/HomePage/Studies';
import HomePage from 'pages/HomePage';
import DisplayStep from 'pages/Study/ParticipantPages/QuestionnaireStatus/DisplayStep';
import CreateStudy from 'pages/HomePage/CreateStudy';
import QueryDashboard from 'pages/Study/ParticipantPages/QueryDashboard';
import Sites from 'pages/Sites';
import SiteList from 'pages/Sites/SitesList';
import ParticipantAppointment from 'pages/Study/ParticipantPages/ParticipantAppointment';
import ConsentFormQuestion from 'pages/Study/ParticipantPages/QuestionnaireStatus/ConsentFormQuestion';
import ParticipantProfile from '../pages/Study/ParticipantPages/ParticipantProfile';
import AuditTrail from 'pages/Study/AuditTrail';
import SiteOnboarding from '../pages/Sites/SiteOnboarding';
import Participants from '../pages/Study/ParticipantPages/Participants';
import PatientRecruitment from '../pages/Study/ParticipantPages/PatientRecruiment';

const LoginPage = lazy(() =>
  componentLoader(() => import(/* webpackChunkName: "SignInPage" */ 'pages/Authentication/SignIn')),
);

const ForgetPage = lazy(() =>
  componentLoader(
    () => import(/* webpackChunkName: "ForgotPassword" */ 'pages/Authentication/ForgotPassword'),
  ),
);

const ResetPage = lazy(() =>
  componentLoader(
    () => import(/* webpackChunkName: "ResetPassword" */ 'pages/Authentication/ResetPassword'),
  ),
);

const ParticipantVideo = lazy(() =>
  componentLoader(() => import('pages/Study/ParticipantPages/ParticipantVideo')),
);

const RegulatoryDashboard = lazy(() =>
  componentLoader(
    () => import(/* webpackChunkName: "DashboardHomePage" */ 'pages/Dashboard/Regulatory'),
  ),
);
const UserListPage = lazy(() =>
  componentLoader(() => import(/* webpackChunkName: "UserPage" */ 'pages/HomePage/Users')),
);
const AdminSettingsPage = lazy(() =>
  componentLoader(() => import(/* webpackChunkName: "UserPage" */ 'pages/AdminSettings')),
);
// const UserStudyDashboard = lazy(() =>
//   componentLoader(
//     () => import(/* webpackChunkName: "DashboardHomePage" */ 'pages/Study/UserStudy'),
//   ),
// );
const StudySummary = lazy(() =>
  componentLoader(
    () => import(/* webpackChunkName: "DashboardHomePage" */ 'pages/Study/StudySummary'),
  ),
);
const StudyStandardFormsDashboard = lazy(() =>
  componentLoader(
    () =>
      import(/* webpackChunkName: "DashboardHomePage" */ 'pages/Study/StudyStandardFormsDashboard'),
  ),
);
// const StudyBuilder = lazy(() =>
//   componentLoader(
//     () => import(/* webpackChunkName: "DashboardHomePage" */ 'pages/Study/StudyBuilder'),
//   ),
// );

export const routes: RouteObject[] = [
  {
    path: '/login',
    element: <LoginPage />,
  },
  {
    path: '/forgot-password',
    element: <ForgetPage />,
  },
  {
    path: '/forget_password',
    element: <ResetPage />,
  },
  {
    id: 'root',
    path: '/',
    element: <HomePage />,
    children: [
      {
        path: '/users',
        element: <UserListPage />,
      },
      {
        path: '/admin-settings',
        element: <AdminSettingsPage />,
      },
      {
        path: '/',
        element: <Studies />,
      },
      {
        path: '/study/create',
        element: <CreateStudy />,
      },
      {
        path: '/study',
        element: <DashboardLayout />,
        children: [
          {
            path: '/study/:studyId/onboarding',
            element: <Onboarding />,
          },
          {
            path: '/study/:studyId/regulatory-dashboard',
            element: <RegulatoryDashboard />,
          },
          {
            path: '/study/:studyId/query-dashboard',
            element: <QueryDashboard />,
          },
          {
            path: '/study/:studyId/questionnaire',
            element: <StudyBuilder />,
          },
          {
            path: '/study/:studyId/participants',
            element: <Participants />,
            children: [
              {
                path: '/study/:studyId/participants/recruitment',
                element: <PatientRecruitment />,
              },
              {
                path: '/study/:studyId/participants/enrollment',
                element: <StudyParticipants />,
              },
            ],
          },
          {
            path: '/study/:studyId/participants/:participantId/',
            element: <ParticipantLayout />,
            children: [
              {
                path: '/study/:studyId/participants/:participantId/dashboard',
                element: <ParticipantDashboard />,
              },
              {
                path: '/study/:studyId/participants/:participantId/appointment',
                element: <ParticipantAppointment />,
              },
              {
                path: '/study/:studyId/participants/:participantId/profile',
                element: <ParticipantProfile />,
              },
              {
                path: '/study/:studyId/participants/:participantId/video',
                element: (
                  <Suspense fallback={<FallbackLoading />}>
                    <ParticipantVideo />
                  </Suspense>
                ),
              },
              {
                path: '/study/:studyId/participants/:participantId/questionnaire-status',
                element: <QuestionnaireStatus />,
                children: [
                  {
                    path: '/study/:studyId/participants/:participantId/questionnaire-status/:questionnaireId',
                    element: <DisplayStep />,
                  },
                  {
                    path: '/study/:studyId/participants/:participantId/questionnaire-status/consent',
                    element: <ConsentFormQuestion />,
                  },
                ],
              },
            ],
          },
          {
            path: '/study/:studyId/summary',
            element: (
              <Suspense fallback={<FallbackLoading />}>
                <StudySummary />
              </Suspense>
            ),
          },
          {
            path: '/study/:studyId/details',
            element: (
              <Suspense fallback={<FallbackLoading />}>
                <Settings />
              </Suspense>
            ),
          },
          {
            path: '/study/:studyId/standard-forms',
            element: (
              <Suspense fallback={<FallbackLoading />}>
                <StudyStandardFormsDashboard />
              </Suspense>
            ),
          },
          {
            path: '/study/:studyId/study-dashboard',
            element: <StudyDashboard />,
          },
          {
            path: '/study/:studyId/study-builder/:questionnaireId',
            element: <StudyBuilder />,
          },
          {
            path: '/study/:studyId/study-builder',
            element: <StudyBuilder />,
          },
          {
            path: '/study/:studyId/settings',
            element: <Settings />,
          },
          {
            path: '/study/:studyId/audit-trail',
            element: <AuditTrail />,
          },
          {
            path: '/study/:studyId/users',
            element: <Users />,
          },
          {
            path: '/study/:studyId/site-select',
            element: <SiteSelect />,
          },

          {
            path: '/study/:studyId/sites',
            element: <Sites />,
            children: [
              {
                path: '/study/:studyId/sites/all',
                element: <SiteList />,
              },
              {
                path: '/study/:studyId/sites/onboarding',
                element: <SiteOnboarding />,
              },
              {
                path: '/study/:studyId/sites/requests',
                element: <Onboarding />,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 'video-call',
    path: 'video-call/:appId/:channel/:token/:participantId/:studyId',
    element: <VideoCall />,
  },
];
