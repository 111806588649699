import StatusBar from 'components/shared/StatusBar';
import { Outlet } from 'react-router-dom';
const StudyHome = () => {
  return (
    <>
      <StatusBar />
      <Outlet />
    </>
  );
};

export default StudyHome;
