import { TextInput, TextArea, Checkbox, FileUpload } from 'components/common';
import { ITask } from 'types/flow';
import { useIntl } from 'react-intl';
import { FiFilePlus } from 'react-icons/fi';
import { useState, useEffect } from 'react';
import CompleteFeasibilityContent from './CompleteFeasibilityContent';
import GenericDocument from './GenericDocument';
import {
  editTaskInfoFC,
  attachFileToTaskFC,
  deleteTaskAttachmentFC,
} from 'services/api/onboarding';
import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';

interface IProps {
  selectedTask: ITask | null;
  refetchFlows: () => void;
  actionType?: any;
  setActiontype?: any;
  information?: any;
  setTaskInformation?: any;
  setSelectedTask?: any;
}
const RightContent = ({
  actionType,
  information,
  setTaskInformation,
  setActiontype,
  selectedTask,
  refetchFlows,
  setSelectedTask,
}: IProps) => {
  const [title, setTaskTitle] = useState(selectedTask?.title || '');
  const [isRequired, setIsRequired] = useState(selectedTask?.isRequired || false);

  const [agreementText, setAgreementText] = useState(selectedTask?.agreementText ?? 'I agree');
  const [declinationText, setDeclinationText] = useState(
    selectedTask?.declinationText ?? 'I do not agree',
  );
  const intl = useIntl();
  useEffect(() => {
    setTaskTitle(selectedTask?.title || '');
    setTaskInformation(selectedTask?.information || '');
    setIsRequired(selectedTask?.isRequired || false);
    setAgreementText(selectedTask?.agreementText ?? 'I agree');
    setDeclinationText(selectedTask?.declinationText ?? 'I do not agree');
  }, [selectedTask]);
  const { mutate: editTaskInfoMutate } = useMutation<any, unknown, any, unknown>(
    (data: any) => editTaskInfoFC(data),
    {
      onSuccess: (newTask: ITask) => {
        setSelectedTask(newTask);
        refetchFlows();
      },
      onError(error: any) {
        toast.error(
          error?.response?.data?.message ?? intl.formatMessage({ id: 'error.questionAdd' }),
        );
      },
    },
  );

  const handleBlur = async (field: string, value: any) => {
    if (selectedTask) {
      try {
        editTaskInfoMutate({
          title,
          information,
          isRequired,
          id: selectedTask.id,
          ...(selectedTask.type === 'GENERIC_DOCUMENT'
            ? { ...(selectedTask.actionType ? { actionType: selectedTask?.actionType } : {}) }
            : {}),
          ...(['CHOOSE_RESPONSE', 'ATTESTATION_REQUIRED'].includes(
            selectedTask?.actionType ?? '',
          ) &&
          selectedTask.type === 'GENERIC_DOCUMENT' &&
          selectedTask.actionType
            ? { agreementText, declinationText }
            : {}),
          [field]: value,
        });
      } catch (error) {
        console.error('Failed to update task:', error);
      }
    }
  };
  const { mutate: attachFileToTaskMutation } = useMutation<
    any,
    unknown,
    { id: any; data: any },
    unknown
  >(({ id, data }) => attachFileToTaskFC(id, data), {
    onSuccess: () => {
      refetchFlows();
    },
    onError(error: any) {
      toast.error(
        error?.response?.data?.message ?? intl.formatMessage({ id: 'error.questionAdd' }),
      );
    },
  });

  const handleUploadAttachment = (file: any) => {
    const formData = new FormData();
    formData.append('file', file);

    try {
      if (selectedTask) {
        attachFileToTaskMutation({ id: selectedTask.id, data: formData });
      } else {
        throw new Error('Selected task is undefined');
      }
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message ?? intl.formatMessage({ id: 'error.questionDeleted' }),
      );
    }
  };
  const { mutate: deleteTaskAttachmentMutate } = useMutation<any, unknown, any, unknown>(
    (id: any, url?: any) => deleteTaskAttachmentFC({ id, url }),
    {
      onSuccess: () => {
        toast.success(intl.formatMessage({ id: 'study-builder.flows-task-success-task-delete' }));
        refetchFlows();
      },
      onError(error: any) {
        toast.error(error?.response?.data?.message ?? 'Delete attachment error!');
      },
    },
  );
  const onDeleteAttachment = (url: string) => {
    deleteTaskAttachmentMutate({ id: selectedTask?.id, url });
  };
  const renderOptionalInputs = (type?: string) => {
    switch (type) {
      case 'SIGN_AND_RETURN_CDA':
      case 'AGREE_TO_CONFIDENTIALITY_STATEMENT':
        return !selectedTask?.attachments?.length ? (
          <>
            <FileUpload
              btnClass='bg-white !pl-0 [&>label]:text-blue-oil [&>label]:text-sm [&>label]:font-light'
              noSizeLabel={true}
              noLabel={true}
              accept='.odf,.pdf,.txt,.jpeg,.heic,.png,.doc,.docx,.jpg,.webp'
              fileIcon={<FiFilePlus className='text-blue-oil w-5 h-5' />}
              attachLabel={intl.formatMessage({ id: 'study-builder.flows-task-attach-document' })}
              onFileUpload={(file: any) => handleUploadAttachment(file)}
              noSize={true}
              onEndUpload={true}
            />
          </>
        ) : (
          <>
            {selectedTask?.attachments?.length === 1
              ? selectedTask?.attachments?.map((attachment: string, index) => (
                  <div key={index} className='pl-4 flex items-center mt-2'>
                    <span className='text-sm text-blue-oil font-light'>
                      {attachment?.split('/').pop()}
                    </span>
                    <svg
                      className='ml-2 cursor-pointer'
                      onClick={() => onDeleteAttachment(attachment)}
                      xmlns='http://www.w3.org/2000/svg'
                      width='12'
                      height='12'
                      viewBox='0 0 8 8'
                      fill='none'
                    >
                      <path
                        d='M6.65625 1L0.999395 6.65685'
                        stroke='#012C54'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        d='M1 1L6.65685 6.65686'
                        stroke='#012C54'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </div>
                ))
              : null}
          </>
        );
      case 'VIEW_PROTOCOL':
        return (
          <>
            <FileUpload
              btnClass='bg-white !pl-0 [&>label]:text-blue-oil [&>label]:text-sm [&>label]:font-light'
              noSizeLabel={true}
              noLabel={true}
              accept='.odf,.pdf,.txt,.jpeg,.heic,.png,.doc,.docx,.jpg,.webp'
              fileIcon={<FiFilePlus className='text-blue-oil w-5 h-5' />}
              attachLabel={intl.formatMessage({ id: 'study-builder.flows-task-attach-document' })}
              onFileUpload={(file: any) => handleUploadAttachment(file)}
              onDeleteFile={onDeleteAttachment}
              noSize={true}
              onEndUpload={true}
            />
            {selectedTask?.attachments?.map((attachment: string, index) => (
              <div key={index} className='pl-4 flex items-center mt-2'>
                <span className='text-sm text-blue-oil font-light'>
                  {attachment?.split('/').pop()}
                </span>
                <svg
                  className='ml-2 cursor-pointer'
                  onClick={() => onDeleteAttachment(attachment)}
                  xmlns='http://www.w3.org/2000/svg'
                  width='12'
                  height='12'
                  viewBox='0 0 8 8'
                  fill='none'
                >
                  <path
                    d='M6.65625 1L0.999395 6.65685'
                    stroke='#012C54'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M1 1L6.65685 6.65686'
                    stroke='#012C54'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
              </div>
            ))}
          </>
        );
      case 'COMPLETE_FEASIBILITY_SURVEY':
        return (
          <CompleteFeasibilityContent selectedTask={selectedTask} refetchFlows={refetchFlows} />
        );
      case 'GENERIC_DOCUMENT':
        return (
          <>
            <GenericDocument
              selectedTask={selectedTask}
              onDeleteAttachment={onDeleteAttachment}
              handleUploadAttachment={handleUploadAttachment}
              {...{
                title,
                information,
                isRequired,
                refetchFlows,
                actionType,
                setActiontype,
                agreementText,
                setAgreementText,
                declinationText,
                setDeclinationText,
                setSelectedTask,
                setTaskInformation,
              }}
            />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className=' py-5'>
      <h2 className='px-[29px] pb-[18px] text-[20px] text-blue-ocean-deep font-semibold text-left w-full leading-7 border-b-1 border-b-purple'>
        {intl.formatMessage({
          id: 'study-builder.flows-task-task',
        })}{' '}
        <span>
          {' '}
          {selectedTask?.sortOrder} - {title}
        </span>
      </h2>
      <div className='px-[29px]'>
        <TextInput
          label={intl.formatMessage({
            id: 'study-builder.flows-task-title',
          })}
          className='w-full mt-6'
          control={{ value: title, onChange: (e) => setTaskTitle(e.target.value) }}
          name='title'
          inputClassName='border-blue-oil'
          onBlur={() => handleBlur('title', title)}
        />
        <TextArea
          label={intl.formatMessage({
            id: 'study-builder.flows-task-information',
          })}
          className='w-full mt-10 max-h-[142px] [&>textarea]:border-blue-oil'
          name='information'
          customControl={{
            value: information,
            onChange: (e: any) => setTaskInformation(e),
          }}
          // defaultValue={information}
          onBlur={() => handleBlur('information', information)}
        />
        <Checkbox
          control={{
            checked: isRequired,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
              setIsRequired(e.target.checked);
              handleBlur('isRequired', e.target.checked);
            },
          }}
          // onBlur={() => handleBlur('isRequired', isRequired)}
          label={intl.formatMessage({
            id: 'study-builder.flows-task-next-task-becomes-due',
          })}
          name={'isRequired'}
          className='[&>label]:text-blue-oil [&>label]:text-sm [&>label]:font-light'
          mainClass='mt-10'
          inputClass='!h-5 !w-5 checked:outline outline-1 rounded-lg !accent-base-white !text-gray-dark'
        />
        {renderOptionalInputs(selectedTask?.type)}
      </div>
    </div>
  );
};

export default RightContent;
