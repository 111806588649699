import ProgressBar from '../ProgressBar';
import { UploadIcon } from '../Icons';
import FileUploader from '../FileUploader';
import { useController } from 'react-hook-form';
import { FiFileText, FiTrash, FiX } from 'react-icons/fi';
import { FormattedMessage } from 'react-intl';
import React from 'react';

const FileUploadWithProgressBar = ({
  imageCard,
  defaultValue,
  handleUpload,
  register,
  name,
  disabled,
  accept,
  progress,
  btnClasses,
  customIcon,
  showName,
  mobileView,
  onFocus,
  control
}: any) => {
  const props = defaultValue ? { value: defaultValue } : useController({ name, control })?.field;

  const handleFileUpload = (e: any) => {
    try {
      handleUpload(e);
    } catch (e) {
      console.log(e, 'catch');
    }
  };

  const checkImgFile = () => {
    const name = typeof props?.value === 'string' ? props?.value : props?.value?.name;
    return (
      name?.includes('.png') ||
      name?.includes('.jpg') ||
      name?.includes('.jpeg') ||
      name?.includes('.webp') ||
      name?.includes('.gif') ||
      name?.includes('.heic')
    );
  };

  const nameList: any =
    !checkImgFile() &&
    (props?.value && typeof props?.value === 'string'
      ? props?.value?.split('/')
      : [props?.value?.name]);

  const showFile = () => {
    if (props?.value && typeof props?.value === 'string') {
      return props?.value;
    }
    return URL.createObjectURL(props?.value);
  };

  return (
    <div className='text-sm !text-base-black font-roboto w-full flex justify-start'>
      {!props?.value && progress === 0 && (
        <FileUploader
          register={register}
          name={name}
          accept={accept}
          disabled={disabled}
          onFocus={onFocus}
          btnClasses={btnClasses}
          onFileUpload={handleFileUpload}
          icon={customIcon ? undefined : UploadIcon}
          customIcon={customIcon}
        />
      )}

      {progress > 0 && (
        <div className='flex justify-end'>
          {progress < 100 && (
            <div className='flex gap-4 items-center justify-between'>
              <p>
                <FormattedMessage id='patient-status.uploading' /> {progress}%
              </p>
              <div className='flex-1'>
                <ProgressBar current={progress} max={100} className='min-w-[113px] h-[0.4375rem]' />
              </div>
            </div>
          )}
        </div>
      )}
      {props?.value && props?.value !== '-' && showName ? (
        <>
          <div className='flex justify-between items-center'>
            <p>{showName}</p>
            <FiX onClick={() => handleUpload('')} className='w-4 h-5' />
          </div>
        </>
      ) : progress === -100 ? (
        <div className='flex gap-4 items-center pr-4'>
          <FiX className='text-error text-xl' />
          <span>
            <FormattedMessage id='error.fileUpload' />
          </span>
        </div>
      ) : (defaultValue && defaultValue !== '-') ||
        (props?.value && props?.value !== '-') ||
        progress >= 100 ? (
        <div className='flex gap-4 items-center pr-4'>
          {props?.value && props?.value !== '-' && (imageCard ? (
            <div
              className={`${checkImgFile() ? 'w-[100px] h-[100px]' : ''}  flex justify-center items-center relative`}
            >
              {checkImgFile() ? (
                <img
                  className='w-max max-w-[100px] max-h-[100px] m-auto'
                  src={showFile()}
                  alt='patient image'
                />
              ) : (
                <div className='flex items-center'>
                  <FiFileText className='text-[30px] mr-1' />
                  <p className='break-all max-w-[220px]'>{nameList[nameList?.length - 1]}</p>
                </div>
              )}
              {!disabled && (
                <div
                  onClick={() => handleUpload('')}
                  className={`${checkImgFile() ? 'absolute top-1 right-1' : 'ml-2'} cursor-pointer mt-[4px] mr-[4px] border border-blue-normal bg-white p-[0.19rem] rounded-[6px] w-4 h-4`}
                >
                  <FiTrash className='!w-2 !h-2 text-blue-normal stroke-[3px]' />
                </div>
              )}
            </div>
          ) : (
            <>
              <img
                className='h-[22px] w-[22px]'
                src={process.env.PUBLIC_URL + '/assets/images/check-file.svg'}
                alt='tick icon'
              />
              <span>
                <FormattedMessage id='patient-status.fileUploaded' />
                UploadIcon
              </span>
            </>
          ))}
        </div>
      ) : mobileView ? (
        <div className='flex gap-4 items-center pr-4'>
          <label
            className={'block font-normal font-roboto text-sm text-base-black cursor-pointer'}
          >
            <UploadIcon />
            <span className='ml-1 pt-[2px]'>
              <FormattedMessage id='patient-status.uploadFile' />
            </span>
          </label>
        </div>
      ) : null}
    </div>
  );
};

export default FileUploadWithProgressBar;
