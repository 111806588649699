import { Opt } from 'types/form';
import { create } from 'zustand';

interface UserState {
  rolesOpt: Opt[];
  usersOpt: Opt[];
  countryOpt: Opt[],
  setRolesOpt: (item: Opt[]) => void;
  setUsersOpt: (item: Opt[]) => void;
  setCountryOpt: (item: Opt[]) => void;
}

export const useUserStore = create<UserState>((set) => ({
  rolesOpt: [],
  usersOpt: [],
  countryOpt: [],
  setRolesOpt: (item) =>
    set(() => ({ rolesOpt: item })),
  setUsersOpt: (item) =>
    set(() => ({ usersOpt: item })),
  setCountryOpt: (item) => 
    set(() => ({ countryOpt: item })),
}));
