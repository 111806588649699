import React, { useRef } from 'react';
import clsx from 'classnames';
import { SvgIcon } from '@mui/material';
import { FieldValues, UseFormSetValue, UseFormRegister } from 'react-hook-form';
import { FieldErrors } from '../../../types/form';
import { ImageIcon } from '../Icons';
import { useIntl } from 'react-intl';

interface FileInputProps {
  name?: string;
  label?: string;
  fileLabel?: string;
  fileLabelClasses?: string;
  disabled?: boolean;
  control?: {
    onChange?: (value: any) => void;
    onBlur?: () => void;
    value?: any;
    type?: string;
  };
  accept?: string;
  className?: string;
  setValue?: UseFormSetValue<FieldValues>;
  trigger?: (name?: string | string[]) => Promise<boolean> | void;
  errors?: FieldErrors<FieldValues>;
  register?: UseFormRegister<any>;
  icon?: React.ElementType;
  customIcon?: any;
  btnClasses?: string;
  onFileUpload?: (file: File) => void;
  onFocus?: (file: any) => void;
}

const FileUploader: React.FC<FileInputProps> = ({
  name = '',
  label,
  fileLabel = 'Upload File',
  fileLabelClasses,
  control,
  accept,
  className,
  errors,
  disabled,
  register,
  icon = ImageIcon,
  btnClasses,
  onFileUpload,
  customIcon,
  onFocus,
}) => {
  const intl = useIntl();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const errorMsg = errors?.[name]?.message as string | undefined;


  const handleFileChange = () => {
    const selectedFile = inputRef.current?.files && inputRef.current?.files[0];

    if (selectedFile) {
      onFileUpload && onFileUpload(selectedFile);
    }
  };

  return (
    <div className={clsx('w-fit', disabled ? 'cursor-not-allowed' : 'cursor-pointer', className)}>
      {label && <label htmlFor={name} className='text-left block text-base-black mb-2 font-semibold leading-6'>
        {label}
      </label>}
      <input
        {...(control ? { ...control } : register ? { ...register(name) } : {})}
        name={name}
        type='file'
        accept={accept}
        onFocus={onFocus}
        ref={inputRef}
        disabled={disabled}
        className='hidden'
        onChange={handleFileChange}
      />
      <button
        className={clsx(
          'h-6 flex gap-x-3 w-fit items-center bg-white min-w-[7.5rem]',
          disabled ? 'cursor-not-allowed' : 'cursor-pointer',
          errors?.[name]?.message ? 'border border-error' : '', btnClasses
        )}
        onClick={(e) => {
          e.preventDefault();
          inputRef.current?.click();
        }}
      >
        {customIcon ? customIcon : <SvgIcon className="!text-[22px]" component={icon} />}
        <label className={clsx('block font-normal font-roboto text-sm text-base-black', disabled ? 'cursor-not-allowed' : 'cursor-pointer', fileLabelClasses)}>
          {fileLabel === 'Upload File' ? intl.formatMessage({ id: 'patient-status.uploadFile' }) : fileLabel}
        </label>
      </button>
      {errorMsg ? <div className='text-error text-left'>{errorMsg}</div> : ''}
    </div>
  );
};

export default FileUploader;
