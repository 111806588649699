import React from 'react';
import Autocomplete from 'react-google-autocomplete';
import { useController } from 'react-hook-form';
import Tooltip from '../../../components/common/Tooltip';


interface IProps {
  name: string;
  value?: string;
  onPlaceSelected: (place: any) => void;
  onChange?: (place: any) => void;
  onSubmit: (data: any) => void;
  control?: any;
  methods?: any;
  isError?: boolean;
  tooltipText?: any;
}

const AutoCompleteInput: React.FC<IProps> = ({
  name, onPlaceSelected, onSubmit, control, methods, tooltipText, isError
}) => {
  const [showTip, setShowTip] = React.useState(false);
  const props = useController({ control, name }).field;

  return (
    <>
    <Tooltip text={showTip && tooltipText ? tooltipText : ''} >
      <Autocomplete
        name={name}
        language='en'
        className={`h-7 rounded placeholder:text-sm ${isError ? 'border-error': 'border-blue-normal'} border px-2 w-full outline-none focus:outline-none`}
        apiKey={process.env.REACT_APP_GOOGLE_KEY}
        defaultValue={props?.value?.address}
        onChange={(e: any) => {
          e?.target?.value ? setShowTip?.(true) : setShowTip?.(false);
        }}
        options={{
          types: ['(regions)'],
        }}
        onPlaceSelected={(place: any) => {
          onPlaceSelected(place);
          if (place.address_components) {
            const foundCountry = place.address_components.find((pc: any) => pc.types[0] === 'country');
            props.value?.id ? 
            props.onChange({
              ...props.value,
              id: props.value?.id,
              latitude: place?.geometry?.location?.lat(),
              longitude: place?.geometry?.location?.lng(),
              address: place.formatted_address,
              countryName: foundCountry.long_name || '',
            }) 
            :
            props.onChange({
              latitude: place?.geometry?.location?.lat(),
              longitude: place?.geometry?.location?.lng(),
              address: place.formatted_address,
              countryName: foundCountry.long_name || '',
            });
            const newData: any = methods.watch();
            onSubmit?.(newData);
          }
        }}
      />
      </Tooltip>
    </>
  );
};

export default AutoCompleteInput;
