import { createSvgIcon } from '@mui/material';

export const TextInputIcon = createSvgIcon(
  <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M4 4V6.66667H28V4H4ZM4 9.33333V12H21.3333V9.33333H4ZM4 14.6667V17.3333H28V14.6667H4ZM4 20V22.6667H21.3333V20H4ZM4 25.3333V28H28V25.3333H4Z' />
  </svg>,
  'TextInputIcon',
);
export const DropdownIcon = createSvgIcon(
  <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g id='Dropdown Field'>
      <path
        id='Vector'
        d='M2.66667 8C1.20925 8 0 9.20925 0 10.6667V21.3333C0 22.7908 1.20925 24 2.66667 24H29.3333C30.7908 24 32 22.7908 32 21.3333V10.6667C32 9.20925 30.7908 8 29.3333 8H2.66667ZM2.66667 10.6667H18.6667V21.3333H2.66667V10.6667ZM21.3333 10.6667H29.3333V21.3333H21.3333V10.6667ZM22.6667 14.6667L25.3333 17.3333L28 14.6667H22.6667Z'
      />
    </g>
  </svg>,
  'DropdownIcon',
);

export const RatingIcon = createSvgIcon(
  <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_844_304)'>
      <path d='M29.3337 12.32L19.747 11.4933L16.0003 2.66667L12.2537 11.5067L2.66699 12.32L9.94699 18.6267L7.76033 28L16.0003 23.0267L24.2403 28L22.067 18.6267L29.3337 12.32ZM16.0003 20.5333L10.987 23.56L12.3203 17.8533L7.89366 14.0133L13.7337 13.5067L16.0003 8.13333L18.2803 13.52L24.1203 14.0267L19.6937 17.8667L21.027 23.5733L16.0003 20.5333Z' />
    </g>
    <defs>
      <clipPath id='clip0_844_304'>
        <rect width='32' height='32' fill='white' />
      </clipPath>
    </defs>
  </svg>,
  'RatingIcon',
);

export const CheckboxIcon = createSvgIcon(
  <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_844_274)'>
      <path d='M26.667 5.33333V21.3333H10.667V5.33333H26.667ZM26.667 2.66666H10.667C9.20033 2.66666 8.00033 3.86666 8.00033 5.33333V21.3333C8.00033 22.8 9.20033 24 10.667 24H26.667C28.1337 24 29.3337 22.8 29.3337 21.3333V5.33333C29.3337 3.86666 28.1337 2.66666 26.667 2.66666ZM16.627 18.6667L12.0003 14L13.867 12.12L16.627 14.8933L23.467 8L25.3337 9.88L16.627 18.6667ZM5.33366 8H2.66699V26.6667C2.66699 28.1333 3.86699 29.3333 5.33366 29.3333H24.0003V26.6667H5.33366V8Z' />
    </g>
    <defs>
      <clipPath id='clip0_844_274'>
        <rect width='32' height='32' fill='white' />
      </clipPath>
    </defs>
  </svg>,
  'CheckboxIcon',
);


export const TickIcon = createSvgIcon(
  <svg
    className="absolute !w-3 !h-3 hidden peer-checked:block !ml-[2px]"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="white"
    strokeWidth="4"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <polyline points="20 6 9 17 4 12"></polyline>
  </svg>,
  'TickIcon',
);

export const RadioIcon = createSvgIcon(
  <svg className="absolute ml-[0.2px] !w-4 !h-4 hidden peer-disabled:block"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <circle cx="8" cy="8" r="7" stroke="#004381" strokeWidth="2" />
    <circle cx="8.00065" cy="7.99992" r="3.66667" fill="#004381" stroke="#004381" strokeWidth="2" />
  </svg>,
  'RadioIcon',
);

export const DateTimeIcon = createSvgIcon(
  <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0ZM8 2.66667C10.9333 2.66667 13.3333 5.06667 13.3333 8C13.3333 10.9333 10.9333 13.3333 8 13.3333C5.06667 13.3333 2.66667 10.9333 2.66667 8C2.66667 5.06667 5.06667 2.66667 8 2.66667ZM21.3333 2.66667V5.33333H18.3177C18.5377 6.18667 18.6667 7.07733 18.6667 8H25.3333V10.6667H18.3177C18.0737 11.6133 17.7078 12.508 17.2292 13.3333H25.3333L25.3359 26.6667H6.66667V18.5755C5.73467 18.4595 4.84133 18.2241 4 17.8828V26.6667C4 28.1373 5.196 29.3333 6.66667 29.3333H25.3333C26.804 29.3333 28 28.1373 28 26.6667V8C28 6.52933 26.804 5.33333 25.3333 5.33333H24V2.66667H21.3333ZM9.20052 4.26562L6.93229 8.13281L9.86719 11.0677L11.0677 9.73438L9.06771 7.86719L10.6667 5.06771L9.20052 4.26562Z' />
  </svg>,
  'DateTimeIcon',
);

export const FileUploadIcon = createSvgIcon(
  <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M16.0003 2.11459L6.11491 12H12.0003V20.6667C12.0003 21.7561 12.9109 22.6667 14.0003 22.6667H18.0003C19.0897 22.6667 20.0003 21.7561 20.0003 20.6667V12H25.8857L16.0003 2.11459ZM16.0003 5.88542L19.4482 9.33334H17.3337V20H14.667V9.33334H12.5524L16.0003 5.88542ZM2.66699 26.6667V29.3333H29.3337V26.6667H2.66699Z' />
  </svg>,
  'FileUploadIcon',
);

export const RadioButtonIcon = createSvgIcon(
  <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M16.0003 2.66667C8.65232 2.66667 2.66699 8.652 2.66699 16C2.66699 23.348 8.65232 29.3333 16.0003 29.3333C23.3483 29.3333 29.3337 23.348 29.3337 16C29.3337 8.652 23.3483 2.66667 16.0003 2.66667ZM16.0003 5.33334C21.9072 5.33334 26.667 10.0932 26.667 16C26.667 21.9068 21.9072 26.6667 16.0003 26.6667C10.0935 26.6667 5.33366 21.9068 5.33366 16C5.33366 10.0932 10.0935 5.33334 16.0003 5.33334ZM16.0003 8C11.5978 8 8.00033 11.5975 8.00033 16C8.00033 20.4025 11.5978 24 16.0003 24C20.4028 24 24.0003 20.4025 24.0003 16C24.0003 11.5975 20.4028 8 16.0003 8ZM16.0003 10.6667C18.9616 10.6667 21.3337 13.0387 21.3337 16C21.3337 18.9613 18.9616 21.3333 16.0003 21.3333C13.039 21.3333 10.667 18.9613 10.667 16C10.667 13.0387 13.039 10.6667 16.0003 10.6667Z' />
  </svg>,
  'RadioButtonIcon',
);

export const NumberValueIcon = createSvgIcon(
  <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M2.66667 8C1.20925 8 0 9.20925 0 10.6667V21.3333C0 22.7908 1.20925 24 2.66667 24H29.3333C30.7908 24 32 22.7908 32 21.3333V10.6667C32 9.20925 30.7908 8 29.3333 8H2.66667ZM2.66667 10.6667H29.3333V21.3333H2.66667V10.6667ZM4 12V14.6667H5.33333V20H8V12H4ZM10.6667 17.3333V20H13.3333V17.3333H10.6667ZM16 17.3333V20H18.6667V17.3333H16Z' />
  </svg>,
  'NumberValueIcon',
);

export const InstructionIcon = createSvgIcon(
  <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M5.34668 4C3.89087 4 2.68001 5.20665 2.68001 6.66406L2.66699 30.6667L8.00033 25.3333H26.667C28.1244 25.3333 29.3337 24.1241 29.3337 22.6667V6.66667C29.3337 5.20925 28.1244 4 26.667 4H5.34668ZM5.34668 6.66667H26.667V22.6667H6.89616L5.33887 24.224L5.34668 6.66667ZM14.667 9.33333V12H17.3337V9.33333H14.667ZM14.667 14.6667V20H17.3337V14.6667H14.667Z' />
  </svg>,
  'InstructionIcon',
);
export const HeaderIcon = createSvgIcon(
  <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M6.66667 4C5.20925 4 4 5.20925 4 6.66667V28H6.66667V6.66667H25.3333V28H28V6.66667C28 5.20925 26.7908 4 25.3333 4H6.66667ZM9.33333 9.33333V14.6667H22.6667V9.33333H9.33333ZM9.33333 20V22.6667H22.6667V20H9.33333ZM9.33333 25.3333V28H18.6667V25.3333H9.33333Z' />
  </svg>,
  'HeaderIcon',
);
export const ImageIcon = createSvgIcon(
  <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_901_349)'>
      <path d='M25.3333 6.66667V25.3333H6.66667V6.66667H25.3333ZM25.3333 4H6.66667C5.2 4 4 5.2 4 6.66667V25.3333C4 26.8 5.2 28 6.66667 28H25.3333C26.8 28 28 26.8 28 25.3333V6.66667C28 5.2 26.8 4 25.3333 4ZM18.8533 15.8133L14.8533 20.9733L12 17.52L8 22.6667H24L18.8533 15.8133Z' />
    </g>
    <defs>
      <clipPath id='clip0_901_349'>
        <rect width='32' height='32' fill='white' />
      </clipPath>
    </defs>
  </svg>,
  'ImageIcon',
);
export const TemplateIcon = createSvgIcon(
  <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M6.66667 4C5.20925 4 4 5.20925 4 6.66667V25.3333C4 26.7908 5.20925 28 6.66667 28H25.3333C26.7908 28 28 26.7908 28 25.3333V6.66667C28 5.20925 26.7908 4 25.3333 4H6.66667ZM6.66667 6.66667H25.3333V9.33333H6.66667V6.66667ZM6.66667 12H25.3333V25.3333H6.66667V12ZM9.33333 13.3333V16H22.6667V13.3333H9.33333ZM9.33333 17.3333V24H14.6667V17.3333H9.33333ZM17.3333 17.3333V24H22.6667V17.3333H17.3333Z' />
  </svg>,
  'ImageIcon',
);
export const EyeIcon = createSvgIcon(
  <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g id='fi:eye'>
      <path
        id='Vector'
        d='M1.3335 15.9999C1.3335 15.9999 6.66683 5.33325 16.0002 5.33325C25.3335 5.33325 30.6668 15.9999 30.6668 15.9999C30.6668 15.9999 25.3335 26.6666 16.0002 26.6666C6.66683 26.6666 1.3335 15.9999 1.3335 15.9999Z'
        stroke='#012C54'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        id='Vector_2'
        d='M16 20C18.2091 20 20 18.2091 20 16C20 13.7909 18.2091 12 16 12C13.7909 12 12 13.7909 12 16C12 18.2091 13.7909 20 16 20Z'
        stroke='#012C54'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
  </svg>,
  'EyeIcon',
);

export const EditIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32' fill='none'>
    <path
      d='M14.6665 5.33325H5.33317C4.62593 5.33325 3.94765 5.6142 3.44755 6.1143C2.94746 6.6144 2.6665 7.29267 2.6665 7.99992V26.6666C2.6665 27.3738 2.94746 28.0521 3.44755 28.5522C3.94765 29.0523 4.62593 29.3333 5.33317 29.3333H23.9998C24.7071 29.3333 25.3854 29.0523 25.8855 28.5522C26.3856 28.0521 26.6665 27.3738 26.6665 26.6666V17.3333'
      stroke='#012C54'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M24.6665 3.33331C25.1969 2.80288 25.9164 2.50488 26.6665 2.50488C27.4166 2.50488 28.1361 2.80288 28.6665 3.33331C29.1969 3.86374 29.4949 4.58316 29.4949 5.33331C29.4949 6.08346 29.1969 6.80288 28.6665 7.33331L15.9998 20L10.6665 21.3333L11.9998 16L24.6665 3.33331Z'
      stroke='#012C54'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>,
  'EyeIcon',
);

export const SaveIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32' fill='none'>
    <path
      d='M18.6668 2.66675H8.00016C7.29292 2.66675 6.61464 2.9477 6.11454 3.4478C5.61445 3.94789 5.3335 4.62617 5.3335 5.33341V26.6667C5.3335 27.374 5.61445 28.0523 6.11454 28.5524C6.61464 29.0525 7.29292 29.3334 8.00016 29.3334H24.0002C24.7074 29.3334 25.3857 29.0525 25.8858 28.5524C26.3859 28.0523 26.6668 27.374 26.6668 26.6667V10.6667L18.6668 2.66675Z'
      stroke='#012C54'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M16 24V16'
      stroke='#012C54'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12 20H20'
      stroke='#012C54'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M18.6665 2.66675V10.6667H26.6665'
      stroke='#012C54'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>,
  'EyeIcon',
);
export const SearchIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      d='M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z'
      stroke='#004381'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M20.9999 20.9999L16.6499 16.6499'
      stroke='#004381'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>,
  'EyeIcon',
);
export const EditItemIcon = createSvgIcon(
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M10 16.6667H17.5'
      stroke='#004381'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M13.75 2.91669C14.0815 2.58517 14.5312 2.39893 15 2.39893C15.2321 2.39893 15.462 2.44465 15.6765 2.53349C15.891 2.62233 16.0858 2.75254 16.25 2.91669C16.4142 3.08085 16.5444 3.27572 16.6332 3.4902C16.722 3.70467 16.7678 3.93455 16.7678 4.16669C16.7678 4.39884 16.722 4.62871 16.6332 4.84319C16.5444 5.05766 16.4142 5.25254 16.25 5.41669L5.83333 15.8334L2.5 16.6667L3.33333 13.3334L13.75 2.91669Z'
      stroke='#004381'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>,
  'EditItemIcon',
);

export const HeaderItemIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32' fill='none'>
    <path
      d='M6.66667 4C5.20925 4 4 5.20925 4 6.66667V28H6.66667V6.66667H25.3333V28H28V6.66667C28 5.20925 26.7908 4 25.3333 4H6.66667ZM9.33333 9.33333V14.6667H22.6667V9.33333H9.33333ZM9.33333 20V22.6667H22.6667V20H9.33333ZM9.33333 25.3333V28H18.6667V25.3333H9.33333Z'
      fill='#fff'
    />
  </svg>,
  'HeaderItemIcon',
);
export const InstructionItemIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      d='M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z'
      stroke='#012C54'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12 16V12'
      stroke='#012C54'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12 8H12.0104'
      stroke='#012C54'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>,
  'InstructionItemIcon',
);
export const ImageItemIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='26' height='26' viewBox='0 0 26 26' fill='none'>
    <path
      d='M20.5833 3.25H5.41667C4.22005 3.25 3.25 4.22005 3.25 5.41667V20.5833C3.25 21.78 4.22005 22.75 5.41667 22.75H20.5833C21.78 22.75 22.75 21.78 22.75 20.5833V5.41667C22.75 4.22005 21.78 3.25 20.5833 3.25Z'
      stroke='#012C54'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M22.7501 16.25L17.3334 10.8333L5.41675 22.75'
      stroke='#012C54'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.20825 10.8333C10.1057 10.8333 10.8333 10.1058 10.8333 9.20831C10.8333 8.31085 10.1057 7.58331 9.20825 7.58331C8.31079 7.58331 7.58325 8.31085 7.58325 9.20831C7.58325 10.1058 8.31079 10.8333 9.20825 10.8333Z'
      stroke='#012C54'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>,
  'ImageItemIcon',
);
