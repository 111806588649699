import { useNavigate, useParams } from 'react-router-dom';
import { useCentersStore } from 'store/centers';
import { ICenter } from 'types/centers';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import DotsNavigation from './DotsNavigation';
import EditTextField from './EditTextField';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import { FiCheckCircle, FiMail, FiUserCheck } from 'react-icons/fi';
import { FormattedMessage } from 'react-intl';
import { IoIosCheckmarkCircleOutline } from 'react-icons/io';
import { format } from 'date-fns';
import { useStudyStore } from '../../../store/StudyStore';
import { useQuestionnaireStore } from '../../../store/QuestionnaireStore';

type ParticipantsListItemProps = {
  item: any;
  center: ICenter;
  isSiteStaff: boolean;
  isShowPatient?: (center: any) => void;
};

const ParticipantsListItem = ({
  item,
  center,
  isSiteStaff,
  isShowPatient,
}: ParticipantsListItemProps) => {
  const containerRef: any = useRef()
  const { studyId } = useParams();
  const navigate = useNavigate();
  const { setSelectedCenter } = useCentersStore();
  const { setEproListPageNo, setStepEproList } = useQuestionnaireStore();
  const { currentStudy, loggedInStudyUser } = useStudyStore();
  const isRandomized: any = currentStudy && currentStudy?.studyType === 'RANDOMIZED';
  const [active, setActive] = useState(false);
  const [isDoubleClick, setDoubleClick] = useState(false);
  const [editMode, setEditMode] = useState({
    firstName: false,
    phoneNumber: false,
    icfVersion: false,
  });
  const orderIndex = item.id < 10 ? `00${item.id}` : `0${item.id}`;
  const isDoubleClickRef = useRef(isDoubleClick);
  isDoubleClickRef.current = isDoubleClick;

  const isSite = isShowPatient?.(center);
  const isShowSite = `${center.id}` === `${loggedInStudyUser?.centerId}`;
  const hasEditAccess = isSiteStaff && isShowSite;

  const handleRowClick = (id: number | string, centerId: number | string) => {
    if (isSite) {
      setSelectedCenter(centerId);
      setEproListPageNo(0);
      setStepEproList([]);
      navigate(`/study/${studyId}/participants/${id}/questionnaire-status`);
    }
  };

  useEffect(() => {
    setDoubleClick(false);
  }, []);

  const isEditAces = hasEditAccess && isSite && isSiteStaff;

  const handleSingleCLick = (e: any) => {
    if (e.detail === 1) {
      setTimeout(() => {
        !isDoubleClickRef.current && handleRowClick(item?.id, center?.id);
      }, 400)
    }
    if (e.detail === 2) {
      setDoubleClick(true);
      isEditAces
        ? setEditMode({
          firstName: true,
          phoneNumber: true,
          icfVersion: true,
        }) : null
    }
  }

  const handleOutsideClick = useCallback(
    (event: MouseEvent | React.MouseEvent<HTMLElement, MouseEvent>, clickedOut: boolean) => {
      if (clickedOut) {
        setDoubleClick(false);
      }
    },
    [],
  );

  useOutsideClick(containerRef, handleOutsideClick);

  const renderStatus = (status: string) => {
    switch (status) {
      case 'STARTED':
        return <div className='flex items-center w-max'>
          <img alt='' src={process.env.PUBLIC_URL + '/assets/images/mail_tick.svg'} className='text-blue-ocean-deep w-4 h-4 mr-2'/>
          <p className='capitalize w-max'><FormattedMessage id={`patient-recruitment.${status}`} /></p>
        </div>
      case 'INVITED':
        return <div className='flex items-center'>
          <FiMail className='text-blue-ocean-deep w-3.5	h-3.5 mr-2'/>
          <p className='capitalize'><FormattedMessage id={`patient-recruitment.${status}`} /></p>
        </div>
      case 'COMPLETED':
        return <div className='flex items-center'>
          <FiCheckCircle className='text-green-success w-3.5 h-3.5 mr-2'/>
          <p className='capitalize'><FormattedMessage id={`patient-recruitment.${status}`} /></p>
        </div>
      case 'ACCEPTED':
        return <div className='flex items-center'>
          <FiUserCheck className='text-blue-normal w-3.5 h-3.5 mr-2'/>
          <p className='capitalize'><FormattedMessage id={`patient-recruitment.${status}`} /></p>
        </div>
      case 'ACTIVE':
        return <div className='flex items-center'>
          <IoIosCheckmarkCircleOutline className='text-green-success w-3.5 h-3.5 mr-2'/>
          <p className='capitalize'><FormattedMessage id={`patient-recruitment.${status}`} /></p>
        </div>
    }
  }

  const renderConsentStatus = (status: string) => {
    switch (status) {
      case 'Awaiting consent':
        return <div className='bg-yellow p-1 w-max'>
          <p className='text-gray-dark text-sm'>{status}</p>
        </div>
      case 'Consented':
        return <div className="bg-green-confirmation p-1 w-max">
          <p className="text-gray-dark text-sm">{status}</p>
        </div>
      case 'Incomplete':
        return <div className="bg-orange p-1 w-max">
          <p className="text-gray-dark text-sm">{status}</p>
        </div>
      case 'Rejected':
        return <div className="bg-orange p-1 w-max">
          <p className="text-gray-dark text-sm">{status}</p>
        </div>
      default:
        return <></>
    }
  }

  const isStudyConsent = currentStudy?.consentQuestionnaire ? currentStudy?.consentQuestionnaire?.active : false;

  return (
    <tr
      ref={containerRef}
      key={item?.id}
      className={
        'relative text-left border-b border-gray-light hover:bg-slate-200 hover:bg-blue-light-100 hover:font-bold hover:text-black'
      }
      onClick={handleSingleCLick}
      onDoubleClick={() => isEditAces
        ? setEditMode({
          firstName: true,
          phoneNumber: true,
          icfVersion: true,
        }) : null
      }
      onMouseOver={() => setActive(true)}
      onMouseOut={() => setActive(false)}
    >
      <td className="p-4 text-[#6C757D] text-left table-cell text-sm cursor-default">
        <span
          className="text-[14px] text-[#403F3F] ">{`${orderIndex}${center?.address?.country?.countryIsoCode}${item?.centerId}`}</span>
      </td>

      <td className={'p-4 text-left text-[#403F3F] table-cell text-sm cursor-default'}>
        {`${center?.address?.country?.countryIsoCode}${item?.centerId}`}
      </td>
      <td
        className={`p-4 text-left text-[#403F3F] break-all table-cell text-sm ${isEditAces ? 'cursor-pointer' : 'cursor-default'} `}
        onClick={() =>
          isEditAces
            ? setEditMode({
              ...editMode,
              firstName: true,
            })
            : null
        }
      >
        {editMode.firstName && isEditAces ? (
          <EditTextField
            item={item}
            name="firstName"
            setEditMode={setEditMode}
            editMode={editMode}
            currentValue={item?.userProfile?.firstName}
          />
        ) : isSiteStaff && isShowSite ? (
          item?.userProfile?.firstName
        ) : (
          '*************'
        )}
      </td>

      <td className="p-4 text-left break-all text-[#403F3F] table-cell text-sm cursor-default">
        {isSiteStaff && isShowSite ? item?.userProfile?.userAccount?.email : '*************'}
      </td>
      {isRandomized &&
        <td className="p-4 text-left text-[#403F3F] table-cell text-sm cursor-default">
          {item?.randGroup}
        </td>
      }
      <td className="p-4 text-sm text-left lowercase first-letter:uppercase text-[#403F3F] cursor-default">
        {renderStatus(item?.patientStatus)}
      </td>
      {isStudyConsent && (
        <td className="p-4 text-sm text-left lowercase first-letter:uppercase text-[#403F3F] cursor-default">
          {renderConsentStatus(item?.consentStatus)}
        </td>
      )}
      {isStudyConsent && (
        <td className="p-4 text-sm text-left lowercase first-letter:uppercase text-[#403F3F] cursor-default">
          {item?.consentedOn ? format(new Date(item?.consentedOn), 'dd MMM yyyy, HH:mm') : ''}
        </td>
      )}
      {isStudyConsent && (
        <td
          className={`p-4 text-left text-[#403F3F] table-cell text-sm ${isEditAces ? 'cursor-pointer' : 'cursor-default'} `}
          onClick={() =>
            isEditAces
              ? setEditMode({
                ...editMode,
                icfVersion: true,
              })
              : null
          }
        >
          {editMode.icfVersion && isEditAces ? (
            <EditTextField
              item={item}
              name="icfVersion"
              setEditMode={setEditMode}
              editMode={editMode}
              currentValue={item?.icfVersion}
            />
          ) : isSiteStaff && isShowSite ? (
            item?.icfVersion ? `${item?.icfVersion}` : ''
          ) : (
            '*************'
          )}
        </td>
      )
    }
    <td
      className={`p-4 text-left break-all text-sm text-[#403F3F] ${isEditAces ? 'cursor-pointer' : 'cursor-default'} w-[184px]`}
      onClick={() =>
        isEditAces
          ? setEditMode({
            ...editMode,
            phoneNumber: true,
          })
          : null
      }
    >
      {editMode.phoneNumber && isEditAces ? (
        <EditTextField
          item={item}
          name="phoneNumber"
          setEditMode={setEditMode}
          editMode={editMode}
          currentValue={item?.userProfile?.userAccount?.phoneNumber}
        />
      ) : isSiteStaff && isShowSite ? (
        item?.userProfile?.userAccount?.phoneNumber || '---'
      ) : (
        '*************'
        )}
      </td>

      {isEditAces ? <DotsNavigation onClick={(e: any) => e?.stopPropagation} active={active} centerId={center?.id} item={item} /> : null}
    </tr>
  );
};

export default ParticipantsListItem;
