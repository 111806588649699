import { axiosClient } from 'config/axios';
import { StudyForm } from 'pages/HomePage/CreateStudy/CreateStudyForm';
import { ICountry } from 'types/centers';
import {
  IDisease,
  IStudy,
  StudyUpdate,
  StudyUpdateStatus,
  IActivateConsentForm,
} from 'types/study';

interface IAddParticipant {
  centerId: string;
  firstName: string;
  email: string;
}

interface IRequest {
  name: string;
  speciality?: string;
  location?: string;
  email: string;
  noOfPatients?: string;
  doctors?: any;
}

export const fetchDisease = async () => {
  const { data } = await axiosClient.get<IDisease[]>('/diseases');
  return data;
};

export const fetchStudies = async () => {
  const { data } = await axiosClient.get<IStudy[]>('/studies');
  return data;
};

export const fetchStudyById = async (id: number | string) => {
  const { data } = await axiosClient.get<IStudy>(`/studies/${id}`);
  return data;
};

const headers = {
  'Content-Type': 'multipart/form-data',
};

export const createStudy = async (newStudy: StudyForm) => {
  const { data } = await axiosClient.post<IStudy>('/studies', newStudy, { headers });
  return data;
};

export const updateStudy = async (study: StudyUpdate) => {
  const { data } = await axiosClient.put<StudyUpdate>(`/studies/${study.id}`, study);
  return data;
};

export const updateStudyLogo = async (study: any) => {
  const { data } = await axiosClient.put<StudyUpdate>(
    `/studies/${study.id}/updateLogo`,
    study.logo,
    { headers },
  );
  return data;
};

export const addParticipant = async ({ centerId, firstName, email }: IAddParticipant) => {
  return axiosClient.post(`/centers/${centerId}/patients`, { firstName, email });
};

export const editParticipant = async (data: any) => {
  return axiosClient.put('/centers/patients', data);
};

export const deleteParticipant = async (centerId: number | string, id: number | string) => {
  return axiosClient.delete(`/centers/${centerId}/patients/${id}`);
};

export const retrieveCenters = async (studyId = '') => {
  const { data } = await axiosClient.get(`/centers${studyId ? `?studyId=${studyId}` : ''}`);
  return data;
};

export const postCenters = async (data: any) => {
  return axiosClient.post('/centers', data);
};

export const updateCenter = async (id: number, data: any) => {
  return axiosClient.put(`/centers/${id}`, data);
};

export const deleteCenter = async (id: any) => {
  return axiosClient.delete(`/centers/${id}`);
};

export const getCountries = async () => {
  const { data } = await axiosClient.get<ICountry[]>('/countries');
  return data;
};

export const getRequests = async (id: string | number) => {
  const { data } = await axiosClient.get<IRequest>(`/studies/${id}`);
  return data;
};

export const updateStudyStatus = async (study: StudyUpdateStatus) => {
  const { data } = await axiosClient.put<StudyUpdateStatus>(
    `studies/${study?.studyId}/updateStatus?studyStatus=${study.status}`,
  );
  return data;
};

export const updateEproSchedule = async (id: any, data: any) => {
  return axiosClient.put(`/epro-schedule/${id}`, data);
};

export const activateConsentForm = async ({ studyId, title, type }: IActivateConsentForm) => {
  const { data } = await axiosClient.post<IActivateConsentForm>(
    `questionnaires/studies/${studyId}`,
    { title, type },
  );
  return data;
};

export const hideConsentQuestionnaire = async (questionnaireId: number) => {
  const { data } = await axiosClient.put<number>(`questionnaires/${questionnaireId}/hide`);
  return data;
};

export const retrieveStandardForms = async (studyId = '') => {
  const { data } = await axiosClient.get(`standard-form/studies/${studyId}`);
  return data;
};

export const exportStudyData = async (studyId = '', format = '') => {
  const { data } = await axiosClient.get(`/data-extraction/studies/${studyId}?extension=${format}`);
  return data;
};
