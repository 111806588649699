import { useMutation, useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { getConsetFormPatient, postConsetForm } from 'services/api/questionnaire';

const useAddConsentForm = () => {
  const { participantId } = useParams();
  const { refetch: refetchConsent } = useQuery({
    queryKey: ['consetFormPatient', participantId],
    queryFn: () => getConsetFormPatient(participantId as string),
  });

  const mutation = useMutation(
    (params: { id: any; stepNo: number; data: any }) => {
      return postConsetForm(params.id, params.stepNo, params.data);
    },
    {
      onSuccess: () => {
        refetchConsent();
      },
    },
  );

  return mutation;
};

export default useAddConsentForm;