import useAuthStore from 'store/authStore';
import { useStudyStore } from 'store/StudyStore';
import { IPermission } from 'types/roles';

const usePermissions = () => {
  const { userData } = useAuthStore();
  const { currentStudy, loggedInStudyUser } = useStudyStore();

  const hasNotAccess = (permsName: string) => {
    if (isGeneralAdmin()) return false;
    else {
      const findAccess = loggedInStudyUser?.role?.permissions?.find(
        (prmsn: IPermission) => prmsn.name === permsName,
      );
      if (findAccess?.id) return false;
      return true;
    }
  };

  const isGeneralAdmin = () =>
    userData?.userAccount?.role?.name === 'ADMIN' &&
    userData?.userAccount?.role?.type === 'CLINICAL_TRIAL';
  
  const isFreezeStudy = () => currentStudy?.status === 'FREEZE';

  return { hasNotAccess, isGeneralAdmin, isFreezeStudy };
};

export default usePermissions;
