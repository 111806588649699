// ThreeDotsDropdown.tsx
import React, { useState, useRef, useEffect } from 'react';
import clsx from 'classnames';
import { SvgIcon } from '@mui/material';
import { ThreeDotIcon } from '../Icons';
import { useIntl } from 'react-intl';

export interface Option {
  label: string;
  icon?: React.ReactNode;
  action: () => void;
}

interface ThreeDotsDropdownProps {
  options?: Option[];
  dotIcon?: React.ReactNode;
  classes?: {
    container?: string;
    button?: string;
    content?: string;
    options?: string;
  };
  customContent?: React.ReactNode;
  noTransition?: boolean;
  defaultOpen?: boolean;
}

const ThreeDotsDropdown: React.FC<ThreeDotsDropdownProps> = ({
  defaultOpen = false,
  options = [],
  dotIcon,
  classes,
  customContent,
  noTransition,
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const intl = useIntl();

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    }

    window.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const toggleDropdown = (e: any) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (action: () => void) => {
    action();
    setIsOpen(false);
  };

  return (
    <div className={clsx('relative', classes?.container)} ref={dropdownRef}>
      <button
        onClick={(e) => toggleDropdown(e)}
        className={clsx('flex px-2 z-10 py-1 focus:outline-none', classes?.button)}
      >
        {dotIcon ? (
          dotIcon
        ) : (
          <SvgIcon className='cursor-pointer rotate-90' component={ThreeDotIcon} />
        )}
      </button>

      {isOpen && (
        <div
          className={clsx(
            'z-50 absolute right-0 mt-2 w-40 bg-white border border-blue-ocean-deep rounded shadow-lg',
            classes?.content,
          )}
        >
          {customContent ? (
            customContent
          ) : (
            <ul>
              {options?.map((option, index) => (
                <li
                  key={index}
                  className={clsx(
                    'text-left hover:text-white flex items-center cursor-pointer hover:bg-gray-100 px-4 py-2',
                    classes?.options,
                  )}
                  onClick={() => handleOptionClick(option.action)}
                >
                  {option?.icon && <div className='mr-2'>{option.icon}</div>}
                  {noTransition ? option.label : intl.formatMessage({ id: option.label })}
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    </div>
  );
};

export default ThreeDotsDropdown;
