import React, { memo } from 'react';
import clsx from 'classnames';
import { DatePicker, TimePicker, DateTimePicker, PickersActionBar } from '@mui/x-date-pickers';
import {
  FieldErrors,
  FieldValues,
  ControllerRenderProps,
  UseControllerProps,
  useController,
} from 'react-hook-form';
import { CustomControl } from './CustomControl';
import { PickerType } from './Calendar.types';

interface CalendarProps extends UseControllerProps<any> {
  label: string;
  type: PickerType;
  minDate?: Date | undefined;
  customControl?: CustomControl;
  className?: string;
  placeholder?: string;
  readOnly?: boolean;
  inputDisable?: boolean;
  errors?: FieldErrors<FieldValues>;
  defaultValue?: Date | undefined;
  onBlur?: (e: any) => void;
  onFocus?: (e: any) => void;
  slots?: any;
  shouldDisableDate?: any;
  format?: any;
  noErrorMessage?: boolean;
  isOkButtonDisabled?: boolean;
  iconPosition?: any;
  setIsDatePickerOpen?: (isDatePickerOpen: boolean) => void;
}

const PickerStyle = {
  '& input': {
    padding: '0.75rem',
    paddingRight: '0px',
    color: 'var(--blue-ocean-deep)',
    lineHeight: '1.5rem',
    height: 'auto',
  },
  '& fieldset': {
    border: '1px solid var(--blue-ocean-deep)',
    flex: '1',
    borderRadius: '.75rem',
  },
  '& .MuiInputBase-root': {
    flex: 1,
  },
  '& .MuiInputBase-root:hover': {
    '& fieldset': {
      borderColor: 'var(--blue-ocean-deep)',
    },
    '& .MuiSvgIcon-root': {
      color: 'var(--blue-ocean-deep)',
    },
  },
  '& .MuiSvgIcon-root': {
    color: 'var(--blue-ocean-deep)',
  },
  '& .Mui-selected': {
    backgroundColor: 'var(--purple)',
    background: 'var(--purple)',
  },
};

const renderPicker = (
  type: PickerType,
  props: CustomControl | ControllerRenderProps<any, string>,
  className: string | undefined,
  minDate: Date | undefined,
  readOnly: boolean,
  onBlur: (value?: Date | undefined) => void,
  placeholder: string,
  inputDisable: boolean,
  slots?: any,
  shouldDisableDate?: any,
  format?: string,
  position?: 'end' | 'start' | undefined,
  setIsDatePickerOpen?: any,
  isOkButtonDisabled?: any,
  onFocus?: any,
) => {
  switch (type) {
    case 'date':
      return (
        <DatePicker
          {...props}
          sx={PickerStyle}
          onChange={(e) => {
            props.onChange(e);
            onBlur?.(e);
          }}
          slots={slots}
          format={format || 'dd/MM/yyyy'}
          minDate={minDate}
          onOpen={() => setIsDatePickerOpen(true)}
          onClose={() => setIsDatePickerOpen(false)}
          shouldDisableDate={shouldDisableDate}
          slotProps={{
            inputAdornment: {
              position: position || 'end',
            },
            textField: {
              onKeyDown: (e: any) => e.target.blur(e.target.value),
              inputProps: inputDisable ? { readOnly: inputDisable, onFocus: onFocus } : {},
              placeholder: placeholder || 'MM/DD/YYYY',
            },
            day: {
              sx: {
                '&.MuiPickersDay-root.Mui-selected': {
                  backgroundColor: '#C491D7',
                },
              },
            },
          }}
          readOnly={readOnly}
          value={(props.value && new Date(props.value)) || null}
          className={clsx('hello flex w-full  max-h-[48px]', className)}
        />
      );
    case 'year':
      return (
        <DatePicker
          sx={PickerStyle}
          className={clsx('flex w-full  max-h-[48px]', className)}
          views={['year']}
          onOpen={() => setIsDatePickerOpen(true)}
          onClose={() => setIsDatePickerOpen(false)}
          {...props}
          shouldDisableDate={shouldDisableDate}
          onChange={(e) => {
            props.onChange(e);
            onBlur?.(e);
          }}
          minDate={minDate}
          format={format || 'yyyy'}
          slots={slots}
          slotProps={{
            inputAdornment: {
              position: position || 'end',
            },
            textField: {
              onKeyDown: (e: any) => e.target.blur(e.target.value),
              inputProps: inputDisable ? { readOnly: inputDisable, onFocus: onFocus } : {},
              placeholder: placeholder || 'YYYY',
            },
          }}
          readOnly={readOnly}
          value={(props.value && new Date(props.value)) || null}
        />
      );
    case 'yearmonth':
      return (
        <DatePicker
          sx={PickerStyle}
          className={clsx('flex w-full  max-h-[48px]', className)}
          views={['month', 'year']}
          onOpen={() => setIsDatePickerOpen(true)}
          onClose={() => setIsDatePickerOpen(false)}
          {...props}
          minDate={minDate}
          shouldDisableDate={shouldDisableDate}
          slots={slots}
          format={format || 'MM/yyyy'}
          slotProps={{
            inputAdornment: {
              position: position || 'end',
            },
            textField: {
              onKeyDown: (e: any) => e.target.blur(e.target.value),
              inputProps: inputDisable ? { readOnly: inputDisable, onFocus: onFocus } : {},
              placeholder: placeholder || 'MM/YYYY',
            },
          }}
          onChange={(e) => {
            props.onChange(e);
            onBlur?.(e);
          }}
          readOnly={readOnly}
          value={(props.value && new Date(props.value)) || null}
        />
      );
    case 'time':
      return (
        <TimePicker
          sx={PickerStyle}
          className={clsx('flex w-full  max-h-[48px]', className)}
          {...props}
          onChange={(e) => {
            props.onChange(e);
            onBlur?.(e);
          }}
          onOpen={() => setIsDatePickerOpen?.(true)}
          onClose={() => setIsDatePickerOpen?.(false)}
          slots={slots}
          slotProps={{
            inputAdornment: {
              position: position || 'end',
            },
            textField: {
              onKeyDown: (e: any) => e.target.blur(e.target.value),
              inputProps: inputDisable ? { readOnly: inputDisable, onFocus: onFocus } : {},
              placeholder: placeholder || 'hh:mm aa',
            },
          }}
          format={format || 'hh:mm aa'}
          readOnly={readOnly}
          value={(props.value && new Date(props.value)) || null}
        />
      );
    case 'datetime':
      return (
        <DateTimePicker
          sx={PickerStyle}
          className={clsx('flex w-full max-h-[48px]', className)}
          {...props}
          onChange={(e) => {
            props.onChange(e);
            onBlur?.(e);
          }}
          onOpen={() => setIsDatePickerOpen(true)}
          onClose={() => setIsDatePickerOpen(false)}
          minDate={minDate}
          format={format || 'dd/MM/yyyy hh:mm aa'}
          shouldDisableDate={shouldDisableDate}
          readOnly={readOnly}
          slots={slots}
          slotProps={{
            inputAdornment: {
              position: position || 'end',
            },
            textField: {
              onKeyDown: (e: any) => e.target.blur(e.target.value),
              inputProps: inputDisable ? { readOnly: inputDisable, onFocus: onFocus } : {},
              placeholder: placeholder || 'MM/DD/YYYY-hh:mm aa',
            },
            actionBar: (props) => (
              <PickersActionBar
                {...props}
                actions={['clear', 'cancel']}
                okButtonProps={{ disabled: isOkButtonDisabled }}
              />
            ),
          }}
          value={(props.value && new Date(props.value)) || null}
        />
      );
  }
};

const Calendar: React.FC<CalendarProps> = ({
  label,
  type = 'date',
  customControl,
  className,
  placeholder,
  minDate,
  readOnly,
  errors,
  onBlur,
  inputDisable,
  slots,
  shouldDisableDate,
  format,
  noErrorMessage,
  iconPosition,
  setIsDatePickerOpen = () => {},
  isOkButtonDisabled,
  onFocus,
  ...rest
}) => {
  const props = customControl ? customControl : useController(rest).field;
  const errorMsg = errors?.[rest.name]?.message as string | undefined;

  return (
    <div
      className={clsx(
        'flex justify-start items-start leading-6 rounded-xl flex-col my-4 [&>.react-datepicker-wrapper]:w-full',
        className,
        // errorMsg && 'border-danger-100 border-2',
      )}
    >
      {label && (
        <label className='block font-semibold text-blue-ocean-deep truncate pb-2'>{label}</label>
      )}
      {renderPicker(
        type,
        props,
        className,
        minDate,
        readOnly || false,
        onBlur as any,
        placeholder || '',
        inputDisable || false,
        slots,
        shouldDisableDate,
        format,
        iconPosition,
        setIsDatePickerOpen,
        isOkButtonDisabled,
        onFocus
      )}
      {!noErrorMessage && errorMsg ? <div className='text-error text-left'>{errorMsg}</div> : ''}
    </div>
  );
};

export default memo(Calendar);
