// src/components/Accordion.tsx
import React, { useState } from 'react';
import clsx from 'classnames';

interface IProps {
  title?: string;
  content?: React.ReactNode;
  className?: string;
  header?: React.ReactNode;
  disabled?: boolean;
}

const Accordion: React.FC<IProps> = ({ title, disabled, header, content, className }: IProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };
  // if (disabled) return null;
  return (
    <div className={clsx('w-full mx-auto', className, disabled)}>
      <div className='border rounded-[10px] overflow-hidden'>
        <div className=''>
          <div className='flex justify-between items-center px-4 py-3'>
            {header ? header : <h3 className='text-md font-medium  cursor-pointer'>{title}</h3>}
            <svg
              onClick={!disabled ? toggleAccordion : () => {}}
              className={`w-6 h-6 ${isOpen ? 'transform rotate-180' : ''}`}
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              stroke='currentColor'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
                d={isOpen ? 'M5 15l7-7 7 7' : 'M19 9l-7 7-7-7'}
              />
            </svg>
          </div>
        </div>
        {isOpen && (
          <div className={clsx('px-2', disabled && 'cursor-not-allowed')}>
            {disabled ? null : content}
          </div>
        )}
      </div>
    </div>
  );
};

export default Accordion;
