import { createSvgIcon } from '@mui/material';

export const DragIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='26' height='25' viewBox='0 0 26 25' fill='none'>
    <g clipPath='url(#clip0_769_10415)'>
      <path d='M11.9168 18.75C11.9168 19.8958 10.9418 20.8333 9.75016 20.8333C8.5585 20.8333 7.5835 19.8958 7.5835 18.75C7.5835 17.6042 8.5585 16.6667 9.75016 16.6667C10.9418 16.6667 11.9168 17.6042 11.9168 18.75ZM9.75016 10.4167C8.5585 10.4167 7.5835 11.3542 7.5835 12.5C7.5835 13.6458 8.5585 14.5833 9.75016 14.5833C10.9418 14.5833 11.9168 13.6458 11.9168 12.5C11.9168 11.3542 10.9418 10.4167 9.75016 10.4167ZM9.75016 4.16666C8.5585 4.16666 7.5835 5.10416 7.5835 6.25C7.5835 7.39583 8.5585 8.33333 9.75016 8.33333C10.9418 8.33333 11.9168 7.39583 11.9168 6.25C11.9168 5.10416 10.9418 4.16666 9.75016 4.16666ZM16.2502 8.33333C17.4418 8.33333 18.4168 7.39583 18.4168 6.25C18.4168 5.10416 17.4418 4.16666 16.2502 4.16666C15.0585 4.16666 14.0835 5.10416 14.0835 6.25C14.0835 7.39583 15.0585 8.33333 16.2502 8.33333ZM16.2502 10.4167C15.0585 10.4167 14.0835 11.3542 14.0835 12.5C14.0835 13.6458 15.0585 14.5833 16.2502 14.5833C17.4418 14.5833 18.4168 13.6458 18.4168 12.5C18.4168 11.3542 17.4418 10.4167 16.2502 10.4167ZM16.2502 16.6667C15.0585 16.6667 14.0835 17.6042 14.0835 18.75C14.0835 19.8958 15.0585 20.8333 16.2502 20.8333C17.4418 20.8333 18.4168 19.8958 18.4168 18.75C18.4168 17.6042 17.4418 16.6667 16.2502 16.6667Z' />
    </g>
    <defs>
      <clipPath id='clip0_769_10415'>
        <rect width='26' height='25' fill='white' />
      </clipPath>
    </defs>
  </svg>,
  'DragIcon',
);

export const AlignLeft = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
    <path d='M0 0V2.22222H20V0H0ZM0 4.44444V6.66667H14.4444V4.44444H0ZM0 8.88889V11.1111H20V8.88889H0ZM0 13.3333V15.5556H14.4444V13.3333H0ZM0 17.7778V20H20V17.7778H0Z' />
  </svg>,
  'AlignLeft',
);

export const AddFile = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='16' height='21' viewBox='0 0 16 21' fill='none'>
    <path d='M2 0.959999C0.905745 0.959999 0 1.86574 0 2.96V18.96C0 20.0543 0.905745 20.96 2 20.96H14C15.0943 20.96 16 20.0543 16 18.96V6.96L10 0.959999H2ZM2 2.96H9V7.96H14V18.96H2V2.96ZM7 10.96V12.96H5V14.96H7V16.96H9V14.96H11V12.96H9V10.96H7Z' />
  </svg>,
  'AddFile',
);

export const Copy = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'>
    <g clipPath='url(#clip0_769_10451)'>
      <path d='M16 1.96H4C2.9 1.96 2 2.86 2 3.96V17.96H4V3.96H16V1.96ZM19 5.96H8C6.9 5.96 6 6.86 6 7.96V21.96C6 23.06 6.9 23.96 8 23.96H19C20.1 23.96 21 23.06 21 21.96V7.96C21 6.86 20.1 5.96 19 5.96ZM19 21.96H8V7.96H19V21.96Z' />
    </g>
    <defs>
      <clipPath id='clip0_769_10451'>
        <rect width='24' height='24' fill='white' transform='translate(0 0.959999)' />
      </clipPath>
    </defs>
  </svg>,
  'Copy',
);

export const Pen = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'>
    <path d='M18.4141 2.08C18.1581 2.08 17.902 2.18189 17.707 2.38469L15.707 4.46469L14.293 5.93531L3 17.68V21.84H7L21.707 6.54469C22.098 6.13805 22.098 5.47966 21.707 5.07406L19.1211 2.38469C18.9261 2.18189 18.6701 2.08 18.4141 2.08ZM18.4141 4.59063L19.5859 5.80938L18.293 7.15406L17.1211 5.93531L18.4141 4.59063ZM15.707 7.40594L16.8789 8.62469L6.17188 19.76H5V18.5413L15.707 7.40594Z' />
  </svg>,
  'Pen',
);

export const Thrash = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'>
    <path d='M10 2.08L9 3.12H3V5.2H4.10938L5.90234 21.0438C6.01959 22.0833 6.882 22.88 7.88867 22.88H16.1113C17.118 22.88 17.9804 22.0833 18.0977 21.0438L19.8906 5.2H21V3.12H19H15L14 2.08H10ZM6.12305 5.2H7H17.877L16.1113 20.8H7.88867L6.12305 5.2ZM9 7.28V17.68C9 18.2541 9.448 18.72 10 18.72H11V7.28H9ZM13 7.28V18.72H14C14.552 18.72 15 18.2541 15 17.68V7.28H13Z' />
  </svg>,
  'Thrash',
);

export const ChevronDown = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32' fill='none'>
    <path d='M9.90625 12.6667L7.90625 14.6667L16 22.7604L24.0938 14.6667L22.0938 12.6667L16 18.7604L9.90625 12.6667Z' />
  </svg>,
  'ChevronDown',
);

export const ChevronUp = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32' fill='none'>
    <path d='M9.90625 12.6667L7.90625 14.6667L16 22.7604L24.0938 14.6667L22.0938 12.6667L16 18.7604L9.90625 12.6667Z' />
  </svg>,
  'ChevronUp',
);

export const RemoveIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'>
    <path
      d='M10 2.07996L9 3.11996H3V5.19996H4.10938L5.90234 21.0437C6.01959 22.0833 6.882 22.88 7.88867 22.88H16.1113C17.118 22.88 17.9804 22.0833 18.0977 21.0437L19.8906 5.19996H21V3.11996H19H15L14 2.07996H10ZM6.12305 5.19996H7H17.877L16.1113 20.8H7.88867L6.12305 5.19996ZM9 7.27996V17.68C9 18.254 9.448 18.72 10 18.72H11V7.27996H9ZM13 7.27996V18.72H14C14.552 18.72 15 18.254 15 17.68V7.27996H13Z'
      fill='#004381'
    />
  </svg>,
  'ChevronDown',
);
export const AddIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='22' height='16' viewBox='0 0 22 16' fill='none'>
    <path
      d='M2.13333 0C0.9568 0 0 0.9568 0 2.13333V8.53333C0 9.70987 0.9568 10.6667 2.13333 10.6667H3.2C3.2 9.92533 3.31298 9.2096 3.51458 8.53333H2.13333V2.13333H19.2V8.53333H17.8188C18.0204 9.2096 18.1333 9.92533 18.1333 10.6667H19.2C20.3765 10.6667 21.3333 9.70987 21.3333 8.53333V2.13333C21.3333 0.9568 20.3765 0 19.2 0H2.13333ZM10.6667 5.33333C7.7216 5.33333 5.33333 7.7216 5.33333 10.6667C5.33333 13.6117 7.7216 16 10.6667 16C13.6117 16 16 13.6117 16 10.6667C16 7.7216 13.6117 5.33333 10.6667 5.33333ZM9.6 7.46667H11.7333V9.6H13.8667V11.7333H11.7333V13.8667H9.6V11.7333H7.46667V9.6H9.6V7.46667Z'
      fill='#004381'
    />
  </svg>,
  'ChevronDown',
);
