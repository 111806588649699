import { axiosClient } from 'config/axios';
import { IUser, IStudyUser, ICreateStudyUser, IEditStudyUser, IDeleteStudyUser } from 'types/user';

import { IPermissionItem, ICreateRole, IEditRole, IRoleCard } from 'types/roles';
import { throwError } from 'utils/throwError';
import axios from 'axios';

export const postCollaborators = async (data: any) => {
  return axiosClient.post('/centers/users', data);
};

export const updateCollaborators = async (data: any) => {
  return axiosClient.put('/centers/users', data);
};

export const deleteCollaborator = async (userId: number | string, centerId: number | string) => {
  return axiosClient.delete(`/centers/${centerId}/users/${userId}`);
};

// Role

export const getRoles = async (roleType = '', studyId = '') => {
  const { data } = await axiosClient.get<IRoleCard[]>(
    `/roles?roleType=${roleType}` + (studyId ? `&studyId=${studyId}` : ''),
  );
  return data;
};

export const getPermissions = async () => {
  try {
    const { data } = await axiosClient.get<IPermissionItem[]>(
      '/permissions?platformType=CLINICAL_TRIAL',
    );

    return data;
  } catch (err) {
    throwError(err);
  }
};

export const createRole = async (newRole: ICreateRole) => {
  const { data } = await axiosClient.post<ICreateRole>('/roles', newRole);
  return data;
};

export const editRole = async (data: IEditRole) => {
  return axiosClient.put(`/roles/${data.id}`, data);
};

export const deleteRole = async (id: any) => {
  return axiosClient.delete(`/roles/${id}`);
};

// Users

export const postUsers = async (data: any) => {
  return axiosClient.post('/auth/register', data);
};

export const editUser = async (id: any, data: any) => {
  return axiosClient.put(`/users/${id}`, data);
};

export const getUsers = async (platformType = '') => {
  const { data } = await axiosClient.get<IUser[]>(`/users?platformType=${platformType}`);
  return data;
};

export const deleteUser = async (id: any) => {
  return axiosClient.delete(`/users/${id}`);
};

// study
export const getStudyUsers = async (studyId: string) => {
  const { data } = await axiosClient.get<IStudyUser[]>(`studies/${studyId}/users`);
  return data;
};

export const createStudyUser = async (newStudyUser: ICreateStudyUser) => {
  const { data } = await axiosClient.post<ICreateStudyUser>('studies/users', newStudyUser);
  return data;
};

export const editStudyUser = async (newStudyUser: IEditStudyUser) => {
  const { data } = await axiosClient.put<IEditStudyUser>('studies/users', newStudyUser);
  return data;
};

export const deleteStudyUser = async (data: IDeleteStudyUser) => {
  return axiosClient.delete<IDeleteStudyUser>(`studies/${data.studyId}/users/${data.userId}`);
};

export const blockStudyUser = async (userId: number) => {
  const { data } = await axiosClient.put<number>(`auth/users/${userId}/block`);
  return data;
};

export const resetStudyUserPassword = async (email: string) => {
  return axiosClient.put<string>(`auth/passwords/resets/emails/${email}`);
};

export const getUserLocationData = async () => {
  axios
      .get('https://ipapi.co/json/')
      .then((response) => {
        const data = response.data;
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
}

export const getAllUsers = async (page: number, size: number, search = '', roleType = '', roleName = '',  sandbox= false) => {
  const { data } = await axiosClient.get(`users?${search ? `search=${search}&` : ''}${roleType ? `roleType=${roleType}&` : ''}${roleName && `roleName=${roleName}&`}&sandbox=${sandbox}&page=${page}&size=${size}`);
  return data;
};

export const deleteAdminUser = async (id: number) => {
  const { data } = await axiosClient.delete(`users/${id}`);
  return data;
};

export const updateUserSandbox = async (id: number, sandbox: boolean) => {
  const { data } = await axiosClient.put(`users/${id}/sandbox?sandbox=${sandbox}`);
  return data;
};

export const updateUserRole = async (id: number, roleType: string, roleName: string | null) => {
  const { data } = await axiosClient.put(`users/${id}/role?roleType=${roleType}${roleName ? `&roleName=${roleName}` : ''}`);
  return data;
};

export const updateUser = async (id: number, payload: any) => {
  const { data } = await axiosClient.put(`users/${id}`, payload);
  return data;
};

export const updateUserAccess = async (id: number, block: boolean) => {
  const { data } = await axiosClient.put(`users/${id}/block?block=${block}`);
  return data;
};
