import Input from 'components/common/TextInput';
import { useState } from 'react';
import { UseControllerProps, useController } from 'react-hook-form';
import { FiArrowUpCircle } from 'react-icons/fi';
import { useIntl } from 'react-intl';

interface IQProps extends UseControllerProps<any> {
  name: string;
  placeholder?: string;
  onSubmitQuery?: (value: string) =>  void;
  methods?: any;
}

const QueryQuestionnaire = ({ name, methods, placeholder, onSubmitQuery }: IQProps) => {
  const intl = useIntl();
  const props = useController({ name }).field;
  const [errors, setErrors] = useState(false);

  return (
    <div className={`h-[1.125rem] bg-blue-very-light flex items-center justify-between px-1.5 py-1 rounded border ${errors ? 'border-error' : 'border-blue-very-light'}`}>
      <Input
        name={name}  
        register={methods?.register} 
        placeholder={placeholder || intl.formatMessage({ id: 'query' })} 
        className='!mt-0 !w-full' 
        inputClassName='!p-0 border-none z-30 bg-transparent !text-black !text-[0.69rem] h-full w-full' 
        onKeyDown={(e: any) => {
          e.target.value === '' ? setErrors(true) : setErrors(false);
          if (e.key === 'Enter' && e.target.value) {
            onSubmitQuery?.(e.target.value);
          }
          if (e.key === 'Enter') {
            e.target.blur(e.target.value);
            e.preventDefault();
          }
        }}
      />
      <div>
        <FiArrowUpCircle onClick={() => onSubmitQuery?.(props?.value || '')} className='w-3 h-3 text-purple bg-blue-dark rounded-full' />
      </div>
    </div>
  );
}

export default QueryQuestionnaire;