import React, { useRef, useState } from 'react';
import clsx from 'classnames';
import { SvgIcon } from '@mui/material';
import { FieldValues, UseFormSetValue, UseFormRegister } from 'react-hook-form';
import { FieldErrors } from '../../../types/form';
import { ImageIcon } from '../Icons';
import { FormattedMessage, useIntl } from 'react-intl';

interface FileInputProps {
  name?: string;
  label?: string;
  control?: {
    onChange?: (value: any) => void;
    onBlur?: () => void;
    value?: any;
    type?: string;
  };
  noSize?: boolean;
  noFileName?: boolean;
  accept?: string;
  disabled?: boolean;
  className?: string;
  btnClass?: string;
  fileIcon?: any;
  setValue?: UseFormSetValue<FieldValues>;
  trigger?: (name?: string | string[]) => Promise<boolean> | void;
  errors?: FieldErrors<FieldValues>;
  register?: UseFormRegister<any>;
  onFileUpload?: (file: File) => void;
  noSizeLabel?: boolean;
  attachLabel?: string;
  noLabel?: boolean;
  onDeleteFile?: any;
  onEndUpload?: any;
  requiredIcon?: boolean;
}

const FileUpload: React.FC<FileInputProps> = ({
  name = '',
  label = 'Upload',
  control,
  accept,
  className,
  errors,
  register,
  disabled,
  onFileUpload,
  btnClass,
  noSize,
  noFileName,
  fileIcon,
  noSizeLabel,
  attachLabel,
  noLabel = false,
  onDeleteFile,
  onEndUpload,
  requiredIcon,
}) => {
  const intl = useIntl();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [file, setFile] = useState<File | undefined>();
  const errorMsg = errors?.[name]?.message as string | undefined;

  const handleFileChange = () => {
    const selectedFile = inputRef.current?.files && inputRef.current?.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      onFileUpload && onFileUpload(selectedFile);
      if (onEndUpload) {
        setTimeout(() => {
          setFile(undefined);
        }, 300);
      }
    }
  };

  return (
    <div className={clsx('w-full max-w-[19.6875rem]', className)}>
      {label && !noLabel && (
        <label
          htmlFor={name}
          className='relative text-left block text-blue-ocean-deep mb-2 font-semibold leading-6'
        >
          {label}
        </label>
      )}
      <input
        {...(control ? { ...control } : register ? { ...register(name) } : {})}
        name={name}
        type='file'
        accept={accept}
        ref={inputRef}
        disabled={disabled}
        onChange={handleFileChange}
        className='hidden border border-gray-300 p-2 w-fit rounded-lg'
      />
      <button
        className={clsx(
          'my-1 h-12 flex gap-x-3 cursor-pointer w-full min-w-[200px] bg-gray justify-start items-center py-2 px-2 rounded-md',
          errors?.[name]?.message ? 'border border-error' : '',
          btnClass,
        )}
        onClick={(e) => {
          e.preventDefault();
          inputRef.current?.click();
        }}
      >
        {fileIcon ? fileIcon : <SvgIcon className='100' component={ImageIcon} />}
        {!noFileName && (
          <label
            className={clsx(
              'relative block mt-[2px] two-line-truncate max-w-[200px] text-blue-oil font-normal text-base',
              requiredIcon && !file?.size && 'pr-2',
            )}
          >
            {file?.name
              ? file?.name
              : attachLabel
                ? attachLabel
                : intl.formatMessage({ id: 'patient-status.upload' })}
            {requiredIcon && !file?.size && <span className='absolute -right-[2px] top-0'>*</span>}
          </label>
        )}
        {!noSize &&
          (file?.size ? (
            <p
              className='text-dark-300 text-sm flex-1 text-right flex justify-end items-center gap-x-1'
              dir='ltr'
            >
              <svg
                onClick={(e: any) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setFile(undefined);
                  onDeleteFile && onDeleteFile();
                }}
                className='cursor-pointer'
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
              >
                <path
                  d='M12 2C17.53 2 22 6.47 22 12C22 17.53 17.53 22 12 22C6.47 22 2 17.53 2 12C2 6.47 6.47 2 12 2ZM15.59 7L12 10.59L8.41 7L7 8.41L10.59 12L7 15.59L8.41 17L12 13.41L15.59 17L17 15.59L13.41 12L17 8.41L15.59 7Z'
                  fill='#E78383'
                />
              </svg>
              <span>
                ({Math.floor(file?.size / 1000)}) <FormattedMessage id='patient-status.kb' />
              </span>
            </p>
          ) : !noSizeLabel ? (
            <p className='text-blue-ocean-deep text-[7px] flex-1 text-right' dir='ltr'>
              (<FormattedMessage id='patient-status.less1MB' />)
            </p>
          ) : null)}
      </button>
      {errorMsg ? <div className='text-error text-left'>{errorMsg}</div> : ''}
    </div>
  );
};

export default FileUpload;
