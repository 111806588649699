import { useEffect } from 'react';
import ScheduleEproEnd from './ScheduleEproEnd';
import ScheduleEproOn from './ScheduleEproOn';
import { format } from 'date-fns';

type ScheduleEproRepeatProps = {
  type: string;
  hasEpro: any;
  handleMutation: any;
  formMethods: any;
};

const ScheduleEproRepeat = ({ type, hasEpro, handleMutation, formMethods }: ScheduleEproRepeatProps) => {
  const {
    setValue,
    watch
  } = formMethods;

  const values = watch();

  const formatDateText = (date: any) => {
    if (!date) return null;

    const newDate = new Date(values?.endDate);
    newDate.setDate(newDate.getDate());
    const dateFormatedText = format(newDate, 'do/LLLL/Y').replace('/', ' of ').replace('/', ' ');

    return dateFormatedText;
  };

  const handleDaysChange = (e: any, type: string) => {
    const textToNumberRegex = /^([1-9]|[1-9][0-9]{1,2}|999)$/;
    if (e.target.value === '' || textToNumberRegex.test(e.target.value)) {
      type === 'days' ? setValue('frequency', e.target.value) : setValue('endNumber', e.target.value);
    }
  };

  useEffect(() => {
    if (hasEpro?.repeat === 'DO_NOT_REPEAT') {
      setValue('frequency', '1');
      setValue('endNumber', '1');
      setValue('endCriteria', 'OCCURRENCES');
      setValue('monthlyOnDay', '1');
      setValue('ends', 'AFTER');
      setValue('endDate', '');
      setValue('weeklyOnDays', []);
    }

    if (
      hasEpro?.repeat === 'DAILY' ||
      hasEpro?.repeat === 'WEEKLY' ||
      hasEpro?.repeat === 'MONTHLY'
    ) {
      setValue('frequency', hasEpro.frequency || 1);
      setValue('endNumber', hasEpro.endNumber || 1);
      setValue('endCriteria', hasEpro.endCriteria || 'OCCURRENCES');
      setValue('ends', hasEpro.ends || 'AFTER');
      setValue('endDate', hasEpro?.endDate?.replace(/T.*$/, '') || '');
    }

    if (hasEpro?.repeat === 'WEEKLY' && hasEpro?.weeklyOnDays) {
      setValue('weeklyOnDays', hasEpro?.weeklyOnDays?.split(',') || [])
    }

    if (hasEpro?.repeat === 'MONTHLY' && hasEpro.monthlyOnDay) {
      setValue('monthlyOnDay', hasEpro.monthlyOnDay || '1')
    }
  }, [hasEpro]);

  const onTheDaysText = (value: any) =>  {
    let label;
    if (value === 1) {
      label = `${value}st`;
    } else if (value === 2) {
      label = `${value}nd`;
    } else if (value === 3) {
      label = `${value}rd`;
    } else {
      label = `${value}th`;
    }
    return label;
  };

  const infoMessage = (type: string) => {

    console.log(values);
    const formattedEndDate = formatDateText(values?.endDate);
    const formattedCriteria = values?.endCriteria?.toLowerCase();
    const formattedWeeklyOnDays = values?.weeklyOnDays && values?.weeklyOnDays?.length > 0 && values?.weeklyOnDays?.map(
      (weekday: any) => weekday.toLowerCase().charAt(0).toUpperCase() + weekday.toLowerCase().slice(1),
    );

    if (type === 'DAILY') {
      return values?.ends === 'ON_THIS_DAY'
        ? `Occurs every ${values?.frequency} days until the ${formattedEndDate}`
        : `Occurs every ${values?.frequency} days for ${values?.endNumber} ${formattedCriteria}`;
    }
    if (type === 'WEEKLY') {
      return values?.ends === 'ON_THIS_DAY'
        ? `Occurs every ${JSON.stringify(formattedWeeklyOnDays).replace(/[[\]"]/g, '')} until the ${formattedEndDate}`
        : `Occurs every ${JSON.stringify(formattedWeeklyOnDays).replace(/[[\]"]/g, '')} after ${values?.endNumber} ${formattedCriteria}`;
    }
    if (type === 'MONTHLY') {
      return values?.ends === 'ON_THIS_DAY'
        ? `Occurs ${onTheDaysText(values?.monthlyOnDay) || '1st'} every ${values?.frequency} months until the ${formattedEndDate}`
        : `Occurs ${onTheDaysText(values?.monthlyOnDay) || '1st'} every ${values?.frequency} month until the after ${values?.endNumber} ${formattedCriteria}`;
    }
  };

  return type !== 'DO_NOT_REPEAT' ? (
    <>
      <div className='flex items-center gap-2 mt-2'>
        <p className='text-[#737373] font-normal font-sans text-sm'>Every</p>
        <input
          className='w-[34px] h-[20px] focus:outline-none border-b-[0.5px] border-[#737373]'
          type='text'
          value={values?.frequency}
          onChange={(e) => handleDaysChange(e, 'days')}
          onBlur={handleMutation}
          min={1}
          max={999}
        />
        <p className='text-[#737373] font-normal font-sans text-sm'>
          {type === 'DAILY'
            ? 'day(s)'
            : type === 'WEEKLY'
              ? 'week(s)'
              : type === 'MONTHLY'
                ? 'month(s)'
                : ''}
        </p>
      </div>
      {type !== 'DAILY' ? (
        <ScheduleEproOn
          type={type}
          handleMutation={handleMutation}
          formMethods={formMethods}
          hasEpro={hasEpro}
        />
      ) : null}
      <ScheduleEproEnd
        handleDaysChange={handleDaysChange}
        handleMutation={handleMutation}
        formMethods={formMethods}
        hasEpro={hasEpro}
      />
      <span className='font-sans text-xs block text-left mt-2 text-[#737373]'>
        {infoMessage(type)}
      </span>
    </>
  ) : null;
};

export default ScheduleEproRepeat;
