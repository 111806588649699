import { IQueryQuestion, useQueryQuestionStore } from 'store/queryQuestionStore';
import QueryTable from './QueryTable';
import { IQueryDataItem, IQueryHeaderItem } from './types';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getQuestionQueries } from 'services/api/questionQuery';
import { useStudyStore } from 'store/StudyStore';
import { FormattedMessage, useIntl } from 'react-intl';

const QueryDashboard: React.FC = () => {
  const intl = useIntl();
  const { studyId } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState<IQueryDataItem[]>();
  const { setQueryQuestions } = useQueryQuestionStore();
  const { currentStudy } = useStudyStore();

  const { data: queryList, refetch } = useQuery({
    queryKey: ['queries'],
    queryFn: () => studyId && getQuestionQueries(parseInt(`${studyId}`, 10), -1, -1),
  });

  useEffect(() => {
    studyId && refetch();
  }, [studyId]);

  useEffect(() => {
    queryList && setQueryQuestions(queryList);
  }, [queryList]);

  const getZeroNo = (num: number) => {
    const text = `${num}`;
    return text.length === 1 ? '00' : text.length === 2 ? '0' : '';
  };

  useEffect(() => {
    queryList?.length > 0 &&
      setData([
        ...queryList.map((query: IQueryQuestion) => ({
          ...query,
          patient: `${getZeroNo(query?.patientStep?.patientId)}${query?.patientStep?.patientId}${query?.center?.address?.country?.countryIsoCode || ''}${query?.center?.id}`,
          status: query?.resolved ? 'RESOLVED' : 'UNRESOLVED',
          visit: query?.folder?.name || '',
          center: query?.center,
          date: query?.createdAt,
        })),
      ]);
  }, [queryList]);

  const onRowClick = (e: any) => {
    if (currentStudy?.steps && currentStudy?.steps?.length > 0) {
      currentStudy?.steps?.map((item: any) => {
        const foundQest =
          item?.questionnaire?.questions &&
          item?.questionnaire?.questions?.length > 0 &&
          item?.questionnaire?.questions?.find((qest: any) => qest.id === e.baseQuestionId);
        if (foundQest) {
          navigate(
            `/study/${studyId}/participants/${e.patientStep.patientId}/questionnaire-status/q-${item?.questionnaire?.id}`,
            {
              state: { queryId: item?.id, baseQuestionId: e?.baseQuestionId },
            },
          );
        }
      });
    } else {
      navigate(`/study/${studyId}/participants/${e.patientStep.patientId}/questionnaire-status`);
    }
  };

  const queryHeaderItems: IQueryHeaderItem[] = [
    {
      title: intl.formatMessage({ id: 'query-dashboard.patient' }),
      name: 'id',
      width: 'w-24',
    },
    {
      title: intl.formatMessage({ id: 'patient.status' }),
      name: 'status',
    },
    {
      title: intl.formatMessage({ id: 'query-dashboard.comment' }),
      name: 'comment',
      width: 'w-2/6',
    },
    {
      title: intl.formatMessage({ id: 'query-dashboard.visit' }),
      name: 'visit',
    },
    {
      title: intl.formatMessage({ id: 'patient-profile.center' }),
      name: 'center',
    },
    {
      title: intl.formatMessage({ id: 'patient-status.date' }),
      name: 'date',
    },
  ];

  return (
    <section className='w-full  container mx-auto'>
      <div className='max-w-[1342px] h-max w-full ml-11 mt-10  rounded-3xl flex flex-col '>
        <h2 className='pl-4 text-[40px] font-sans text-blue-ocean-deep font-normal leading-6 text-left '>
          <FormattedMessage id='query-dashboard' />
        </h2>
        <div className='bg-white mt-4 border-1 border-purple rounded-lg px-6 min-h-[800px]'>
          <div className='w-full mx-auto'></div>
          <div className='w-full mx-auto '>
            <QueryTable
              headerItems={queryHeaderItems}
              onRowClick={(e: any) => onRowClick(e)}
              columns={data || []}
            />
          </div>
        </div>
      </div>
    </section>
  );
};
export default QueryDashboard;
