import { Button, Select, Calendar } from 'components/common';
import { FC, Dispatch, SetStateAction } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import { useIntl, FormattedMessage } from 'react-intl';

interface ITrigger {
  label: string;
  value: string;
}
interface IProps {
  triggers: ITrigger[] | undefined;
  startDate: any;
  setStartDate: Dispatch<SetStateAction<any>>;
  endDate: any;
  setEndDate: Dispatch<SetStateAction<any>>;
  triggeredBy: any;
  setTriggeredBy: Dispatch<SetStateAction<any>>;
  eventType: any;
  setEventType: Dispatch<SetStateAction<any>>;
}
const FilterForm: FC<IProps> = ({
  triggers = [],
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  triggeredBy,
  setTriggeredBy,
  eventType,
  setEventType,
}: IProps) => {
  const intl = useIntl();

  const handleReset = () => {
    setStartDate(null);
    setEndDate(null);
    setEventType(null);
    setTriggeredBy(null);
  };
  const EVENT_TYPES: any = [
    {
      label: intl.formatMessage({ id: 'audit-trial.patient-non-anonymized-data-modified' }),
      value: 'PATIENT_NON_ANONYMIZED_DATA_MODIFIED',
    },
    {
      label: intl.formatMessage({ id: 'audit-trial.document-signed' }),
      value: 'DOCUMENT_SIGNED',
    },
    {
      label: intl.formatMessage({ id: 'audit-trial.document-signature-expired' }),
      value: 'DOCUMENT_SIGNATURE_EXPIRED',
    },
    {
      label: intl.formatMessage({ id: 'audit-trial.patient-anonymized-data-modified' }),
      value: 'PATIENT_ANONYMIZED_DATA_MODIFIED',
    },
    {
      label: intl.formatMessage({ id: 'audit-trial.data-verified' }),
      value: 'DATA_VERIFIED',
    },
    {
      label: intl.formatMessage({ id: 'audit-trial.participant-deleted' }),
      value: 'PARTICIPANT_DELETED',
    },
    {
      label: intl.formatMessage({ id: 'audit-trial.participant-created' }),
      value: 'PARTICIPANT_CREATED',
    },
    {
      label: intl.formatMessage({ id: 'audit-trial.participant-deregistered-client-app' }),
      value: 'PARTICIPANT_DEREGISTERED_CLIENT_APP',
    },
    {
      label: intl.formatMessage({ id: 'audit-trial.appointment-scheduled' }),
      value: 'APPOINTMENT_SCHEDULED',
    },
    {
      label: intl.formatMessage({ id: 'audit-trial.appointment-joined' }),
      value: 'APPOINTMENT_JOINED',
    },
    {
      label: intl.formatMessage({ id: 'audit-trial.signature-expired' }),
      value: 'PARTICIPANT_SIGNED',
    },
    {
      label: intl.formatMessage({ id: 'audit-trial.participant-signed' }),
      value: 'Participant signed',
    },
    {
      label: intl.formatMessage({ id: 'audit-trial.query-created' }),
      value: 'QUERY_CREATED',
    },
    {
      label: intl.formatMessage({ id: 'audit-trial.query-updated' }),
      value: 'QUERY_UPDATED',
    },
    {
      label: intl.formatMessage({ id: 'audit-trial.query-response-created' }),
      value: 'QUERY_RESPONSE_CREATED',
    },
    {
      label: intl.formatMessage({ id: 'audit-trial.query-response-updated' }),
      value: 'QUERY_RESPONSE_UPDATED',
    },
    {
      label: intl.formatMessage({ id: 'audit-trial.query-response-deleted' }),
      value: 'QUERY_RESPONSE_DELETED',
    },
    {
      label: intl.formatMessage({ id: 'audit-trial.query-resolved' }),
      value: 'QUERY_RESOLVED',
    },
    {
      label: intl.formatMessage({ id: 'audit-trial.query-deleted' }),
      value: 'QUERY_DELETED',
    },
  ];
  return (
    <div className='px-8 py-6 border-purple border-1 rounded-lg bg-white'>
      <div className='flex justify-between items-center'>
        <h3 className='text-grey-dark text-[18px] font-semibold'>
          <FormattedMessage id='audit-trial.filter-by' />
        </h3>
        <Button
          onClick={() => handleReset()}
          className='bg-white hover:bg-white font-light text-[14px] text-grey-dark'
        >
          <FormattedMessage id='audit-trial.reset' />
        </Button>
      </div>
      <p className='text-left mt-5 mb-3 text-grey-dark text-sm font-semibold'>
        <FormattedMessage id='audit-trial.created-between' />
      </p>
      <div className='flex flex-col gap-y-3'>
        <Calendar
          customControl={{
            value: startDate,
            onChange: (e: any) => setStartDate(e),
          }}
          name='startDate'
          label=''
          className='no-border-mui border !my-0 border-blue-ocean-deep !rounded [&>div]!flex-reverse [&>div>div>input]:!text-left [&>div>div>input]:!py-1 [&>div>div>input]:!text-sm'
          placeholder={intl.formatMessage({ id: 'audit-trial.start-date' })}
          format='dd/MM/yyyy'
          inputDisable={true}
          type='date'
          slots={{
            openPickerIcon: FiChevronDown,
          }}
        />
        <Calendar
          customControl={{
            value: endDate,
            onChange: (e: any) => setEndDate(e),
          }}
          name='endDate'
          label=''
          className='no-border-mui border !my-0 border-blue-ocean-deep !rounded [&>div]!flex-reverse [&>div>div>input]:!text-left [&>div>div>input]:!py-1 [&>div>div>input]:!text-sm'
          placeholder={intl.formatMessage({ id: 'audit-trial.end-date' })}
          format='dd/MM/yyyy'
          inputDisable={true}
          type='date'
          slots={{
            openPickerIcon: FiChevronDown,
          }}
        />
      </div>
      <p className='text-left mt-10 mb-3 text-grey-dark text-sm font-semibold'>
        <FormattedMessage id='audit-trial.event-type' />
      </p>
      <Select
        className='min-w-[251px]'
        buttonClassName='bg-white h-[30px]'
        name='startDate'
        defaultValue={''}
        customControl={{
          value: eventType,
          onChange: (e: any) => setEventType(e),
        }}
        mainClass='rounded-md mt-3 border-blue-ocean-deep'
        placeholder={intl.formatMessage({ id: 'audit-trial.event-type' })}
        options={EVENT_TYPES}
        noTranslation={true}
      />
      <p className='text-left mt-10 mb-3 text-grey-dark text-sm font-semibold'>
        <FormattedMessage id='audit-trial.triggered-by' />
      </p>
      <Select
        className='min-w-[251px]'
        buttonClassName='bg-white h-[30px]'
        name='triggeredBy'
        defaultValue={''}
        customControl={{
          value: triggeredBy,
          onChange: (e: any) => setTriggeredBy(e),
        }}
        mainClass='rounded-md mt-3'
        placeholder={intl.formatMessage({ id: 'audit-trial.triggered-by' })}
        options={triggers}
        noTranslation={true}
      />
    </div>
  );
};

export default FilterForm;
