import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Select from 'react-select';

type ScheduleEproOnProps = {
  type: string;
  formMethods: any;
  handleMutation: any;
  hasEpro: any;
};

const ScheduleEproOn = ({
  type,
  hasEpro,
  formMethods,
  handleMutation
}: ScheduleEproOnProps) => {
  const {
    setValue,
    watch
  } = formMethods;

  const values = watch();
  
  const [weekdays, setWeekDays] = useState([
    {
      day: 'M',
      value: 'MONDAY',
      select: true,
    },
    {
      day: 'T',
      value: 'TUESDAY',
      select: false,
    },
    {
      day: 'W',
      value: 'WEDNESDAY',
      select: false,
    },
    {
      day: 'T',
      value: 'THURSDAY',
      select: false,
    },
    {
      day: 'F',
      value: 'FRIDAY',
      select: false,
    },
    {
      day: 'S',
      value: 'SATURDAY',
      select: false,
    },
    {
      day: 'S',
      value: 'SUNDAY',
      select: false,
    },
  ]);

  useEffect(() => {
    if (hasEpro?.weeklyOnDays) {
      const hasWeek = hasEpro?.weeklyOnDays?.split(',');
  
      if (hasWeek) {
        const found = weekdays.map((r: any) => {
          if (hasWeek.includes(r.value)) {
            return { ...r, select: true };
          } else {
            return { ...r, select: false };
          }
        });
        setWeekDays(found);
      }
    }
  }, [hasEpro]);

  const onTheDaysOptions = Array.from({ length: 31 }, (_, index) => {
    const value = index + 1;
    let label;
    if (value === 1) {
      label = `${value}st`;
    } else if (value === 2) {
      label = `${value}nd`;
    } else if (value === 3) {
      label = `${value}rd`;
    } else {
      label = `${value}th`;
    }
    return { label, value };
  });

  const getOptLabel: any = () => {
    return onTheDaysOptions.filter((item) => item.value === hasEpro?.monthlyOnDay)[0];
  };

  const handleSelect = (_ob: any, index: number) => {
    setWeekDays((prevWeekdays) => {
      const newArr = prevWeekdays.map((obj, i) => {
        if (i === index) {
          return { ...obj, select: !obj.select };
        }
        return obj;
      });
      const newList = newArr.filter((e: any) => e.select)
      setValue('weeklyOnDays', newList.map((e) => e.value))
      handleMutation();
      return newArr;
    });
  };

  return (
    <div className='flex gap-2 mt-3'>
      {type === 'WEEKLY' ? (
        <div className='flex items-center mt-2 gap-2'>
          <p className='text-[#737373] font-normal font-sans text-sm'>
            <FormattedMessage id="on" />
          </p>
          <ul className='flex gap-[2px]'>
            {weekdays.map((day, i) => (
              <li
                className={`flex text-sm font-sans font-normal rounded items-center justify-center w-[20px] h-[17px] border-[0.5px] border-[#004381] ${day.select ? 'bg-purple text-white border-none' : values?.weeklyOnDays?.length === 0 ? 'border-error-feedback text-error-feedback' : ''}`}
                onClick={() => handleSelect(day, i)}
                key={i}
              >
                {day.day}
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <div className='flex items-center gap-2'>
          <p className='text-[#737373] font-normal font-sans text-sm'>
            <FormattedMessage id="study-builder.onThe" />
          </p>
          <Select
            options={onTheDaysOptions}
            className='selectEpro text-sm w-[70px] selectEproRepeat text-left'
            defaultValue={{
              value: hasEpro?.monthlyOnDay,
              label: getOptLabel()?.label || '1st',
            }}
            components={{
              IndicatorSeparator: () => null,
            }}
            isSearchable={false}
            onChange={(e: any) => {
              setValue('monthlyOnDay', e.value)
              handleMutation();
            }}
          />
          <p className='text-[#737373] font-normal font-sans text-sm'>
            <FormattedMessage id="allStudies.day" />
          </p>
        </div>
      )}
    </div>
  );
};

export default ScheduleEproOn;
