import { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ListItem from './ListItem';
import Question from '../Question';
import { IQuestion } from 'types/questionnaire';
import { useQuestionnaire } from '../useQuestionnaire';
import clsx from 'classnames';
import RepeatedQuestion from '../Question/RepeatedQuestion';
import TitleQuestion from '../Question/TitleQuestion';

interface IProps {
  questions: IQuestion[] | any;
  handleOnSaveQuestion: any;
  repeatedQuestion?: boolean;
  allQuestions?: IQuestion[] | undefined;
  filterRepeatedInput?: boolean;
  className?: string;
  titleQuestionId?: any;
  isTitleQuestion?: boolean;
  filterTitleInput?: boolean;
  filterFileInput?: boolean;
  filterRequired?: any;
  onRemoveQuestionApi?: any;
  onDuplicateQuestion?: any;
  handleReorderQuestionsFC?: any;
  workflowQuestionnaire?: any;
  handleWorkflowOnSaveQuestion?: any;
  shouldFetchSteps?: any;
  shouldFetchSF?: any;
  isStandardForm?: any;
}

const DragDropList: React.FC<IProps> = ({
  handleOnSaveQuestion,
  questions,
  repeatedQuestion,
  allQuestions = [],
  filterRepeatedInput,
  className,
  titleQuestionId,
  isTitleQuestion,
  filterTitleInput,
  filterFileInput,
  filterRequired,
  onRemoveQuestionApi,
  onDuplicateQuestion,
  handleReorderQuestionsFC,
  workflowQuestionnaire,
  handleWorkflowOnSaveQuestion,
  shouldFetchSteps,
  shouldFetchSF,
  isStandardForm,
}: IProps) => {
  const { handleReorderQuestions } = useQuestionnaire({ shouldFetchSteps, shouldFetchSF });
  const [items, setItems] = useState<IQuestion[]>(questions);
  const [closePopups, setClosePopups] = useState<boolean>(false);
  const [collapsedSubQuestions, setCollapsedSubQuestions] = useState<number[]>([]);

  useEffect(() => {
    if (Array.isArray(questions)) setItems([...questions]);
  }, [questions]);

  const onDragEnd = (result: any) => {
    if (result?.destination?.droppableId !== result?.source?.droppableId) {
      return;
    }
    const newItems = Array.from(items);

    if (newItems[result?.destination?.index]?.editable === false) return;
    const [removed] = newItems.splice(result.source.index, 1);

    // skip if the removed item is empty input
    if (!removed?.questionTitle) return;

    newItems.splice(result.destination.index, 0, removed);

    const newQuestions: IQuestion[] = newItems
      .map((qs: IQuestion, index: number) => {
        return { ...qs, sortOrder: index + 1 };
      })
      .filter((qs: IQuestion) => qs.questionTitle);

    // reorder empty input to the end of the list
    if (newQuestions.findIndex((qs: IQuestion) => !qs.questionTitle) == -1)
      newQuestions.push(questions[questions.length - 1]);
    if (repeatedQuestion || isTitleQuestion) {
      const concatedAllQuestions: any = [...allQuestions, ...newQuestions];

      handleReorderQuestionsFC
        ? handleReorderQuestionsFC(newQuestions)
        : handleReorderQuestions(concatedAllQuestions);
      setItems(newItems);
      setClosePopups(false);
      return;
    } else {
      handleReorderQuestionsFC
        ? handleReorderQuestionsFC(newQuestions)
        : handleReorderQuestions(newQuestions);

      setItems(newItems);
      setClosePopups(false);
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd} onDragStart={() => setClosePopups(true)}>
      <Droppable droppableId={repeatedQuestion ? 'droppable-sub-questions' : 'droppable'}>
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className={clsx('border-2 border-gray first:border-t-0', className)}
          >
            {items?.map((item: any, index: number) => (
              <Draggable
                key={`${item.id}`}
                draggableId={`${item?.id ?? item?.questionTitle}`}
                index={index}
              >
                {(provided, snapshot) => (
                  <ListItem
                    className={item?.editable === false ? '!cursor-not-allowed' : ''}
                    provided={provided}
                    snapshot={snapshot}
                    item={item}
                    disable={item?.editable === false}
                  >
                    <Question
                      key={`${item?.id} ${repeatedQuestion}`}
                      onSave={(qs: IQuestion) => {
                        
                        handleOnSaveQuestion({
                          id: `frontId-${index + 1}`,
                          ...item,
                          ...qs,
                          ...(qs?.questionType === 'REPEATED'
                            ? { parentQuestionId: qs?.parentQuestionId }
                            : {}),
                        });
                      }}
                      onRemoveQuestionApi={onRemoveQuestionApi}
                      onDuplicateQuestion={onDuplicateQuestion}
                      question={item}
                      closePopups={closePopups}
                      setClosePopups={setClosePopups}
                      editable={item?.editable}
                      filterRepeatedInput={filterRepeatedInput}
                      filterTitleInput={filterTitleInput}
                      isTitleQuestion={item.questionType === 'TITLE'}
                      titleQuestionId={titleQuestionId}
                      filterFileInput={filterFileInput}
                      filterRequired={filterRequired}
                      isStandardForm={isStandardForm}
                      {...{
                        collapsedSubQuestions,
                        setCollapsedSubQuestions,
                        shouldFetchSteps,
                        shouldFetchSF,
                      }}
                    />
                    {(item.questionType === 'REPEATED' && !item?.children?.length) ||
                    (item.questionType === 'REPEATED' &&
                      !collapsedSubQuestions?.includes(item?.id)) ? (
                      <RepeatedQuestion
                        questions={item?.children ?? []}
                        parentQuestionId={item.id}
                        allQuestions={questions}
                      />
                    ) : null}
                    {item.questionType === 'TITLE' && (
                      <TitleQuestion
                        questions={item?.titleQuestions ?? []}
                        titleQuestionId={item.id}
                        allQuestions={questions}
                        question={item}
                        workflowQuestionnaire={workflowQuestionnaire}
                        handleWorkflowOnSaveQuestion={handleWorkflowOnSaveQuestion}
                        onDuplicateQuestion={onDuplicateQuestion}
                        onRemoveQuestionApi={onRemoveQuestionApi}
                        handleReorderQuestionsFC={handleReorderQuestionsFC}
                        isStandardForm={isStandardForm}
                      />
                    )}
                  </ListItem>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DragDropList;
