import { Button } from 'components/common';
import React from 'react';
import { FormattedMessage } from 'react-intl';

interface IPopup {
  isOpen: boolean;
  onClose: () => void;
}

const Popup: React.FC<IPopup> = (props) => {
  const { isOpen, onClose } = props;
  if (!isOpen) return null;

  return (
    <div className='w-full h-full absolute top-0 left-0 z-50 backdrop-blur-[1.5px]'>
      <div className='px-[2.125rem] rounded-lg shadow bg-white pt-[2.875rem] pb-[1.625rem] w-full max-w-[416px] mx-auto mt-20'>
        <p className='text-blue-ocean-deep text-xl font-medium'>
          <FormattedMessage id="site.sitePatientConnectionMessage" />
        </p>
        <Button
          type='button'
          onClick={onClose}
          className='bg-blue-ocean-deep text-white w-[184px] rounded-full h-12 text-sm hover:bg-blue-ocean-deep mt-3'
        >
          <FormattedMessage id="ok" />
        </Button>
      </div>
    </div>
  );
};

export default Popup;
