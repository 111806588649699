import { useState, useCallback, useRef } from 'react';
import { IRoleCard, IRole } from 'types/roles';
import Role from './Role';
import clsx from 'classnames';
import { Button, TextInput } from 'components/common';
import { useOutsideClick } from 'hooks/useOutsideClick';
// import { RoleCategoryEnum } from 'enums/roles';
import useRoles from '../../useRoles';
import usePermissions from 'hooks/usePermissions';
import { FormattedMessage, useIntl } from 'react-intl';

interface IProps extends IRoleCard {
  selectedRole?: any;
  setSelectedRole?: any;
  barColor: string;
  setSelectedPermissions: any;
  selectedPermissions: number[];
  rolesRefetch: any;
}
const RoleCard: React.FC<IProps> = ({
  category,
  list,
  selectedRole,
  setSelectedRole,
  barColor,
  selectedPermissions,
  setSelectedPermissions,
  rolesRefetch,
}: IProps & { selectedRole?: any; setSelectedRole?: any }) => {
  const intl = useIntl();
  const [newRole, setNewRole] = useState(false);
  const [value, setValue] = useState<any>(null);
  const { handleCreateNewRole } = useRoles({ rolesRefetch });
  const containerRef = useRef<HTMLDivElement>(null);
  const labelRef = useRef<HTMLLabelElement>(null);
  const { isFreezeStudy, hasNotAccess } = usePermissions();

  const handleOutsideClick = useCallback(
    (event: MouseEvent | React.MouseEvent<HTMLElement, MouseEvent>, clickedOut: boolean) => {
      if (clickedOut && event.target !== labelRef.current) {
        setNewRole(false);
        if (value) handleCreateNewRole(value, category, selectedPermissions);
        setValue('');
      }
    },
    [setNewRole, value],
  );
  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      setNewRole(false);
      if (value) handleCreateNewRole(value, category, selectedPermissions);
      setValue('');
    }
  };
  useOutsideClick(containerRef, handleOutsideClick);
  const disableActions = isFreezeStudy() || hasNotAccess('ADD_ROLES') || hasNotAccess('VIEW_ROLES');

  return (
    <div className='mb-[12px] flex flex-col justify-start items-start w-[226px]'>
      <h3 className='text-left text-base text-blue-ocean-deep leading-6 font-semibold'>
        {intl.formatMessage({ id: `roles.${category}` })}
      </h3>
      {list.length ? (
        <div className='flex gap-x-[10px] mt-4 w-full'>
          <div className={clsx('w-1 rounded-full h-auto', barColor)}></div>
          <div className='flex-1'>
            {list.map((role: IRole) => (
              <Role
                key={role.id}
                {...role}
                list={list}
                category={category}
                selectedPermissions={selectedPermissions}
                setSelectedPermissions={setSelectedPermissions}
                selectedRole={selectedRole}
                setSelectedRole={setSelectedRole}
                rolesRefetch={rolesRefetch}
              />
            ))}
          </div>
        </div>
      ) : (
        ''
      )}
      {newRole && !disableActions ? (
        <div ref={containerRef} className='w-full mt-[6px] ml-4'>
          <TextInput
            control={{
              value: value,
              onChange: (e: any) => setValue(e.target.value),
            }}
            name={'title1'}
            placeholder={'T..'}
            noTranslation={true}
            className={clsx(
              'h-full !mt-0 [&>input]:border-none [&>input]:bg-blue-light  [&>input]:h-[32px] [&>input]:mt-0 [&>input]:pl-2 pt-0 pb-0 [&>input]:rounded [&>input]:w-[182px]',
            )}
            // inputClassName='[&>input]:!text-sm'
            inputClassName='border-r-none border-l-none border-t-none !text-sm'
            onKeyDown={handleKeyDown}
          />
        </div>
      ) : !disableActions ? (
        <Button
          onClick={() => {
            setNewRole(true);
            setSelectedPermissions([]);
          }}
          className='bg-white text-purple text-left font-normal text-sm rounded-full !px-1 ml-3 !py-1'
        >
          <FormattedMessage id="users.createNew" />
        </Button>
      ) : (
        <div className='h-4' />
      )}
    </div>
  );
};

export default RoleCard;
