import { QuestionButtonProps } from './QuestionButton.types';
import clsx from 'classnames';

export function QuestionButton({
  Logo,
  disabled,
  textContent,
  onClick,
  onHover,
  className,
  ...rest
}: QuestionButtonProps) {
  return (
    <button
      {...rest}
      disabled={disabled}
      onClick={onClick}
      onMouseEnter={onHover}
      className={clsx(
        'group pl-[8px] py-2 w-[185px] overflow-hidden relative transition group ease-in-out delay-[5ms] bg-white hover:bg-blue-dark rounded-lg justify-start items-center gap-[1px] inline-flex font-normal disabled:bg-disabled disabled:text-white disabled:cursor-not-allowed',
        className,
      )}
    >
      {Logo}
      <span className='flex justify-left group-hover:text-blue-normal text-sm z-10 w-[172px] pl-[2px]'>
        {textContent}
      </span>
    </button>
  );
}
