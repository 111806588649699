// TODO
// refactor https://react-svgr.com/playground/
import { createSvgIcon } from '@mui/material';

export const TextInputIcon = createSvgIcon(
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='22'
    height='21'
    viewBox='0 0 22 21'
    fill='#ffffff'
    className='group-hover:stroke-[#fff] '
  >
    <path
      d='M12.8332 1.75H5.49984C5.01361 1.75 4.54729 1.93437 4.20347 2.26256C3.85966 2.59075 3.6665 3.03587 3.6665 3.5V17.5C3.6665 17.9641 3.85966 18.4092 4.20347 18.7374C4.54729 19.0656 5.01361 19.25 5.49984 19.25H16.4998C16.9861 19.25 17.4524 19.0656 17.7962 18.7374C18.14 18.4092 18.3332 17.9641 18.3332 17.5V7L12.8332 1.75Z'
      stroke='#737373'
      className=' group-hover:stroke-[#0973D4]'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M14.6668 14.875H7.3335'
      stroke='#737373'
      className=' group-hover:stroke-[#0973D4]'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M14.6668 11.375H7.3335'
      stroke='#737373'
      className=' group-hover:stroke-[#0973D4]'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.16683 7.875H8.25016H7.3335'
      stroke='#737373'
      className=' group-hover:stroke-[#0973D4]'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12.8335 1.75V7H18.3335'
      stroke='#737373'
      className=' group-hover:stroke-[#0973D4]'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>,
  'TextInputIcon',
);
export const DropdownIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='26' height='13' viewBox='0 0 26 13' fill='none'>
    <path
      d='M2.16667 0C0.982515 0 0 0.982515 0 2.16667V10.8333C0 12.0175 0.982515 13 2.16667 13H23.8333C25.0175 13 26 12.0175 26 10.8333V2.16667C26 0.982515 25.0175 0 23.8333 0H2.16667ZM2.16667 2.16667H15.1667V10.8333H2.16667V2.16667ZM17.3333 2.16667H23.8333V10.8333H17.3333V2.16667ZM18.4167 5.41667L20.5833 7.58333L22.75 5.41667H18.4167Z'
      fill='#737373'
      className='group-hover:stroke-[#0973D4] group-hover:fill-[#0973D4]'
    />
  </svg>,
  'DropdownIcon',
);
export const FileTickIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      d='M14 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V8L14 2Z'
      stroke='white'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M14 2V8H20' stroke='white' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M16.1737 12L10.7933 17.2174L8.34766 14.8459'
      stroke='white'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>,
  'FileTickIcon',
);

export const RatingIcon = createSvgIcon(
  <svg
    className='group-hover:stroke-[#0973D4] group-hover:fill-[#0973D4]'
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='#737373'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_844_304)' className='group-hover:stroke-[#0973D4] '>
      <path
        className='group-hover:stroke-[#0973D4] '
        d='M29.3337 12.32L19.747 11.4933L16.0003 2.66667L12.2537 11.5067L2.66699 12.32L9.94699 18.6267L7.76033 28L16.0003 23.0267L24.2403 28L22.067 18.6267L29.3337 12.32ZM16.0003 20.5333L10.987 23.56L12.3203 17.8533L7.89366 14.0133L13.7337 13.5067L16.0003 8.13333L18.2803 13.52L24.1203 14.0267L19.6937 17.8667L21.027 23.5733L16.0003 20.5333Z'
      />
    </g>
    <defs>
      <clipPath id='clip0_844_304' className='group-hover:stroke-[#0973D4] '>
        <rect className='group-hover:stroke-[#0973D4] ' width='32' height='32' fill='white' />
      </clipPath>
    </defs>
  </svg>,
  'RatingIcon',
);

export const CheckboxIcon = createSvgIcon(
  <svg
    fill='#737373'
    className='group-hover:fill-[#0973D4] '
    width='32'
    height='32'
    viewBox='0 0 32 32'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_844_274)'>
      <path d='M26.667 5.33333V21.3333H10.667V5.33333H26.667ZM26.667 2.66666H10.667C9.20033 2.66666 8.00033 3.86666 8.00033 5.33333V21.3333C8.00033 22.8 9.20033 24 10.667 24H26.667C28.1337 24 29.3337 22.8 29.3337 21.3333V5.33333C29.3337 3.86666 28.1337 2.66666 26.667 2.66666ZM16.627 18.6667L12.0003 14L13.867 12.12L16.627 14.8933L23.467 8L25.3337 9.88L16.627 18.6667ZM5.33366 8H2.66699V26.6667C2.66699 28.1333 3.86699 29.3333 5.33366 29.3333H24.0003V26.6667H5.33366V8Z' />
    </g>
    <defs>
      <clipPath id='clip0_844_274' className='group-hover:stroke-[#0973D4] '>
        <rect width='32' height='32' fill='white' />
      </clipPath>
    </defs>
  </svg>,
  'CheckboxIcon',
);

export const DateTimeIcon = createSvgIcon(
  <svg
    fill='#737373'
    className='group-hover:fill-[#0973D4] '
    width='32'
    height='32'
    viewBox='0 0 32 32'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0ZM8 2.66667C10.9333 2.66667 13.3333 5.06667 13.3333 8C13.3333 10.9333 10.9333 13.3333 8 13.3333C5.06667 13.3333 2.66667 10.9333 2.66667 8C2.66667 5.06667 5.06667 2.66667 8 2.66667ZM21.3333 2.66667V5.33333H18.3177C18.5377 6.18667 18.6667 7.07733 18.6667 8H25.3333V10.6667H18.3177C18.0737 11.6133 17.7078 12.508 17.2292 13.3333H25.3333L25.3359 26.6667H6.66667V18.5755C5.73467 18.4595 4.84133 18.2241 4 17.8828V26.6667C4 28.1373 5.196 29.3333 6.66667 29.3333H25.3333C26.804 29.3333 28 28.1373 28 26.6667V8C28 6.52933 26.804 5.33333 25.3333 5.33333H24V2.66667H21.3333ZM9.20052 4.26562L6.93229 8.13281L9.86719 11.0677L11.0677 9.73438L9.06771 7.86719L10.6667 5.06771L9.20052 4.26562Z' />
  </svg>,
  'DateTimeIcon',
);

export const RadioButtonIcon = createSvgIcon(
  <svg
    className='group-hover:fill-[#0973D4] '
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='#737373'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M16.0003 2.66667C8.65232 2.66667 2.66699 8.652 2.66699 16C2.66699 23.348 8.65232 29.3333 16.0003 29.3333C23.3483 29.3333 29.3337 23.348 29.3337 16C29.3337 8.652 23.3483 2.66667 16.0003 2.66667ZM16.0003 5.33334C21.9072 5.33334 26.667 10.0932 26.667 16C26.667 21.9068 21.9072 26.6667 16.0003 26.6667C10.0935 26.6667 5.33366 21.9068 5.33366 16C5.33366 10.0932 10.0935 5.33334 16.0003 5.33334ZM16.0003 8C11.5978 8 8.00033 11.5975 8.00033 16C8.00033 20.4025 11.5978 24 16.0003 24C20.4028 24 24.0003 20.4025 24.0003 16C24.0003 11.5975 20.4028 8 16.0003 8ZM16.0003 10.6667C18.9616 10.6667 21.3337 13.0387 21.3337 16C21.3337 18.9613 18.9616 21.3333 16.0003 21.3333C13.039 21.3333 10.667 18.9613 10.667 16C10.667 13.0387 13.039 10.6667 16.0003 10.6667Z' />
  </svg>,
  'RadioButtonIcon',
);

export const NumberValueIcon = createSvgIcon(
  <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      className='group-hover:stroke-[#0973D4] group-hover:fill-[#0973D4]'
      d='M2.66667 8C1.20925 8 0 9.20925 0 10.6667V21.3333C0 22.7908 1.20925 24 2.66667 24H29.3333C30.7908 24 32 22.7908 32 21.3333V10.6667C32 9.20925 30.7908 8 29.3333 8H2.66667ZM2.66667 10.6667H29.3333V21.3333H2.66667V10.6667ZM4 12V14.6667H5.33333V20H8V12H4ZM10.6667 17.3333V20H13.3333V17.3333H10.6667ZM16 17.3333V20H18.6667V17.3333H16Z'
    />
  </svg>,
  'NumberValueIcon',
);

export const InstructionIcon = createSvgIcon(
  <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M5.34668 4C3.89087 4 2.68001 5.20665 2.68001 6.66406L2.66699 30.6667L8.00033 25.3333H26.667C28.1244 25.3333 29.3337 24.1241 29.3337 22.6667V6.66667C29.3337 5.20925 28.1244 4 26.667 4H5.34668ZM5.34668 6.66667H26.667V22.6667H6.89616L5.33887 24.224L5.34668 6.66667ZM14.667 9.33333V12H17.3337V9.33333H14.667ZM14.667 14.6667V20H17.3337V14.6667H14.667Z' />
  </svg>,
  'InstructionIcon',
);
export const HeaderIcon = createSvgIcon(
  <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M6.66667 4C5.20925 4 4 5.20925 4 6.66667V28H6.66667V6.66667H25.3333V28H28V6.66667C28 5.20925 26.7908 4 25.3333 4H6.66667ZM9.33333 9.33333V14.6667H22.6667V9.33333H9.33333ZM9.33333 20V22.6667H22.6667V20H9.33333ZM9.33333 25.3333V28H18.6667V25.3333H9.33333Z' />
  </svg>,
  'HeaderIcon',
);
export const ImageIcon = createSvgIcon(
  <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_901_349)'>
      <path d='M25.3333 6.66667V25.3333H6.66667V6.66667H25.3333ZM25.3333 4H6.66667C5.2 4 4 5.2 4 6.66667V25.3333C4 26.8 5.2 28 6.66667 28H25.3333C26.8 28 28 26.8 28 25.3333V6.66667C28 5.2 26.8 4 25.3333 4ZM18.8533 15.8133L14.8533 20.9733L12 17.52L8 22.6667H24L18.8533 15.8133Z' />
    </g>
    <defs>
      <clipPath id='clip0_901_349'>
        <rect width='32' height='32' fill='white' />
      </clipPath>
    </defs>
  </svg>,
  'ImageIcon',
);
export const TemplateIcon = createSvgIcon(
  <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M6.66667 4C5.20925 4 4 5.20925 4 6.66667V25.3333C4 26.7908 5.20925 28 6.66667 28H25.3333C26.7908 28 28 26.7908 28 25.3333V6.66667C28 5.20925 26.7908 4 25.3333 4H6.66667ZM6.66667 6.66667H25.3333V9.33333H6.66667V6.66667ZM6.66667 12H25.3333V25.3333H6.66667V12ZM9.33333 13.3333V16H22.6667V13.3333H9.33333ZM9.33333 17.3333V24H14.6667V17.3333H9.33333ZM17.3333 17.3333V24H22.6667V17.3333H17.3333Z' />
  </svg>,
  'ImageIcon',
);
export const EyeIcon = createSvgIcon(
  <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g id='fi:eye'>
      <path
        id='Vector'
        d='M1.3335 15.9999C1.3335 15.9999 6.66683 5.33325 16.0002 5.33325C25.3335 5.33325 30.6668 15.9999 30.6668 15.9999C30.6668 15.9999 25.3335 26.6666 16.0002 26.6666C6.66683 26.6666 1.3335 15.9999 1.3335 15.9999Z'
        stroke='#012C54'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        id='Vector_2'
        d='M16 20C18.2091 20 20 18.2091 20 16C20 13.7909 18.2091 12 16 12C13.7909 12 12 13.7909 12 16C12 18.2091 13.7909 20 16 20Z'
        stroke='#012C54'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
  </svg>,
  'EyeIcon',
);

export const EditIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32' fill='none'>
    <path
      d='M14.6665 5.33325H5.33317C4.62593 5.33325 3.94765 5.6142 3.44755 6.1143C2.94746 6.6144 2.6665 7.29267 2.6665 7.99992V26.6666C2.6665 27.3738 2.94746 28.0521 3.44755 28.5522C3.94765 29.0523 4.62593 29.3333 5.33317 29.3333H23.9998C24.7071 29.3333 25.3854 29.0523 25.8855 28.5522C26.3856 28.0521 26.6665 27.3738 26.6665 26.6666V17.3333'
      stroke='#012C54'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M24.6665 3.33331C25.1969 2.80288 25.9164 2.50488 26.6665 2.50488C27.4166 2.50488 28.1361 2.80288 28.6665 3.33331C29.1969 3.86374 29.4949 4.58316 29.4949 5.33331C29.4949 6.08346 29.1969 6.80288 28.6665 7.33331L15.9998 20L10.6665 21.3333L11.9998 16L24.6665 3.33331Z'
      stroke='#012C54'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>,
  'EyeIcon',
);

export const SaveIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32' fill='none'>
    <path
      d='M18.6668 2.66675H8.00016C7.29292 2.66675 6.61464 2.9477 6.11454 3.4478C5.61445 3.94789 5.3335 4.62617 5.3335 5.33341V26.6667C5.3335 27.374 5.61445 28.0523 6.11454 28.5524C6.61464 29.0525 7.29292 29.3334 8.00016 29.3334H24.0002C24.7074 29.3334 25.3857 29.0525 25.8858 28.5524C26.3859 28.0523 26.6668 27.374 26.6668 26.6667V10.6667L18.6668 2.66675Z'
      stroke='#012C54'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M16 24V16'
      stroke='#012C54'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12 20H20'
      stroke='#012C54'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M18.6665 2.66675V10.6667H26.6665'
      stroke='#012C54'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>,
  'EyeIcon',
);
export const SearchIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      d='M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z'
      stroke='#004381'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M20.9999 20.9999L16.6499 16.6499'
      stroke='#004381'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>,
  'EyeIcon',
);
export const EditItemIcon = createSvgIcon(
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M10 16.6667H17.5'
      stroke='#004381'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M13.75 2.91669C14.0815 2.58517 14.5312 2.39893 15 2.39893C15.2321 2.39893 15.462 2.44465 15.6765 2.53349C15.891 2.62233 16.0858 2.75254 16.25 2.91669C16.4142 3.08085 16.5444 3.27572 16.6332 3.4902C16.722 3.70467 16.7678 3.93455 16.7678 4.16669C16.7678 4.39884 16.722 4.62871 16.6332 4.84319C16.5444 5.05766 16.4142 5.25254 16.25 5.41669L5.83333 15.8334L2.5 16.6667L3.33333 13.3334L13.75 2.91669Z'
      stroke='#004381'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>,
  'EditItemIcon',
);

export const SiteStaffIcon = createSvgIcon(
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='currentColor'
  >
    <path
      d='M17 21.75H7C2.59 21.75 1.25 20.41 1.25 16V8C1.25 3.59 2.59 2.25 7 2.25H17C21.41 2.25 22.75 3.59 22.75 8V16C22.75 20.41 21.41 21.75 17 21.75ZM7 3.75C3.42 3.75 2.75 4.43 2.75 8V16C2.75 19.57 3.42 20.25 7 20.25H17C20.58 20.25 21.25 19.57 21.25 16V8C21.25 4.43 20.58 3.75 17 3.75H7Z'
      fill='currentColor'
    />
    <path
      d='M19 8.75H14C13.59 8.75 13.25 8.41 13.25 8C13.25 7.59 13.59 7.25 14 7.25H19C19.41 7.25 19.75 7.59 19.75 8C19.75 8.41 19.41 8.75 19 8.75Z'
      fill='currentColor'
    />
    <path
      d='M19 12.75H15C14.59 12.75 14.25 12.41 14.25 12C14.25 11.59 14.59 11.25 15 11.25H19C19.41 11.25 19.75 11.59 19.75 12C19.75 12.41 19.41 12.75 19 12.75Z'
      fill='currentColor'
    />
    <path
      d='M19 16.75H17C16.59 16.75 16.25 16.41 16.25 16C16.25 15.59 16.59 15.25 17 15.25H19C19.41 15.25 19.75 15.59 19.75 16C19.75 16.41 19.41 16.75 19 16.75Z'
      fill='currentColor'
    />
    <path
      d='M8.50043 12.0399C7.09043 12.0399 5.94043 10.8899 5.94043 9.47992C5.94043 8.06992 7.09043 6.91992 8.50043 6.91992C9.91043 6.91992 11.0604 8.06992 11.0604 9.47992C11.0604 10.8899 9.91043 12.0399 8.50043 12.0399ZM8.50043 8.41992C7.92043 8.41992 7.44043 8.89992 7.44043 9.47992C7.44043 10.0599 7.92043 10.5399 8.50043 10.5399C9.08043 10.5399 9.56043 10.0599 9.56043 9.47992C9.56043 8.89992 9.08043 8.41992 8.50043 8.41992Z'
      fill='currentColor'
    />
    <path
      d='M11.9999 17.08C11.6199 17.08 11.2899 16.79 11.2499 16.4C11.1399 15.32 10.2699 14.45 9.17991 14.35C8.71991 14.31 8.25991 14.31 7.79991 14.35C6.70991 14.45 5.83991 15.31 5.72991 16.4C5.68991 16.81 5.31991 17.12 4.90991 17.07C4.49991 17.03 4.19991 16.66 4.23991 16.25C4.41991 14.45 5.84991 13.02 7.65991 12.86C8.20991 12.81 8.76991 12.81 9.31991 12.86C11.1199 13.03 12.5599 14.46 12.7399 16.25C12.7799 16.66 12.4799 17.03 12.0699 17.07C12.0499 17.08 12.0199 17.08 11.9999 17.08Z'
      fill='currentColor'
    />
  </svg>,
  'SiteStaffIcon',
);
export const PatientsIcon = createSvgIcon(
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='currentColor'
  >
    <path
      d='M18.0003 7.91002C17.9703 7.91002 17.9503 7.91002 17.9203 7.91002H17.8703C15.9803 7.85002 14.5703 6.39001 14.5703 4.59001C14.5703 2.75001 16.0703 1.26001 17.9003 1.26001C19.7303 1.26001 21.2303 2.76001 21.2303 4.59001C21.2203 6.40001 19.8103 7.86001 18.0103 7.92001C18.0103 7.91001 18.0103 7.91002 18.0003 7.91002ZM17.9003 2.75002C16.8903 2.75002 16.0703 3.57002 16.0703 4.58002C16.0703 5.57002 16.8403 6.37002 17.8303 6.41002C17.8403 6.40002 17.9203 6.40002 18.0103 6.41002C18.9803 6.36002 19.7303 5.56002 19.7403 4.58002C19.7403 3.57002 18.9203 2.75002 17.9003 2.75002Z'
      fill='currentColor'
    />
    <path
      d='M18.0105 15.2801C17.6205 15.2801 17.2305 15.2501 16.8405 15.1801C16.4305 15.1101 16.1605 14.7201 16.2305 14.3101C16.3005 13.9001 16.6905 13.6301 17.1005 13.7001C18.3305 13.9101 19.6305 13.6802 20.5005 13.1002C20.9705 12.7902 21.2205 12.4001 21.2205 12.0101C21.2205 11.6201 20.9605 11.2401 20.5005 10.9301C19.6305 10.3501 18.3105 10.1201 17.0705 10.3401C16.6605 10.4201 16.2705 10.1401 16.2005 9.73015C16.1305 9.32015 16.4005 8.93015 16.8105 8.86015C18.4405 8.57015 20.1305 8.88014 21.3305 9.68014C22.2105 10.2701 22.7205 11.1101 22.7205 12.0101C22.7205 12.9001 22.2205 13.7502 21.3305 14.3502C20.4205 14.9502 19.2405 15.2801 18.0105 15.2801Z'
      fill='currentColor'
    />
    <path
      d='M5.97047 7.91C5.96047 7.91 5.95047 7.91 5.95047 7.91C4.15047 7.85 2.74047 6.39 2.73047 4.59C2.73047 2.75 4.23047 1.25 6.06047 1.25C7.89047 1.25 9.39047 2.75 9.39047 4.58C9.39047 6.39 7.98047 7.85 6.18047 7.91L5.97047 7.16L6.04047 7.91C6.02047 7.91 5.99047 7.91 5.97047 7.91ZM6.07047 6.41C6.13047 6.41 6.18047 6.41 6.24047 6.42C7.13047 6.38 7.91047 5.58 7.91047 4.59C7.91047 3.58 7.09047 2.75999 6.08047 2.75999C5.07047 2.75999 4.25047 3.58 4.25047 4.59C4.25047 5.57 5.01047 6.36 5.98047 6.42C5.99047 6.41 6.03047 6.41 6.07047 6.41Z'
      fill='currentColor'
    />
    <path
      d='M5.96 15.2801C4.73 15.2801 3.55 14.9502 2.64 14.3502C1.76 13.7602 1.25 12.9101 1.25 12.0101C1.25 11.1201 1.76 10.2701 2.64 9.68014C3.84 8.88014 5.53 8.57015 7.16 8.86015C7.57 8.93015 7.84 9.32015 7.77 9.73015C7.7 10.1401 7.31 10.4201 6.9 10.3401C5.66 10.1201 4.35 10.3501 3.47 10.9301C3 11.2401 2.75 11.6201 2.75 12.0101C2.75 12.4001 3.01 12.7902 3.47 13.1002C4.34 13.6802 5.64 13.9101 6.87 13.7001C7.28 13.6301 7.67 13.9101 7.74 14.3101C7.81 14.7201 7.54 15.1101 7.13 15.1801C6.74 15.2501 6.35 15.2801 5.96 15.2801Z'
      fill='currentColor'
    />
    <path
      d='M12.0003 15.38C11.9703 15.38 11.9503 15.38 11.9203 15.38H11.8703C9.98031 15.32 8.57031 13.86 8.57031 12.06C8.57031 10.22 10.0703 8.72998 11.9003 8.72998C13.7303 8.72998 15.2303 10.23 15.2303 12.06C15.2203 13.87 13.8103 15.33 12.0103 15.39C12.0103 15.38 12.0103 15.38 12.0003 15.38ZM11.9003 10.22C10.8903 10.22 10.0703 11.04 10.0703 12.05C10.0703 13.04 10.8403 13.84 11.8303 13.88C11.8403 13.87 11.9203 13.87 12.0103 13.88C12.9803 13.83 13.7303 13.03 13.7403 12.05C13.7403 11.05 12.9203 10.22 11.9003 10.22Z'
      fill='currentColor'
    />
    <path
      d='M11.9993 22.76C10.7993 22.76 9.5993 22.45 8.6693 21.82C7.7893 21.23 7.2793 20.39 7.2793 19.49C7.2793 18.6 7.7793 17.74 8.6693 17.15C10.5393 15.91 13.4693 15.91 15.3293 17.15C16.2093 17.74 16.7193 18.58 16.7193 19.48C16.7193 20.37 16.2193 21.23 15.3293 21.82C14.3993 22.44 13.1993 22.76 11.9993 22.76ZM9.4993 18.41C9.0293 18.72 8.7793 19.11 8.7793 19.5C8.7793 19.89 9.0393 20.27 9.4993 20.58C10.8493 21.49 13.1393 21.49 14.4893 20.58C14.9593 20.27 15.2093 19.88 15.2093 19.49C15.2093 19.1 14.9493 18.72 14.4893 18.41C13.1493 17.5 10.8593 17.51 9.4993 18.41Z'
      fill='currentColor'
    />
  </svg>,
  'PatientsIcon',
);

export const DeleteIcon = createSvgIcon(
  <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect width='14' height='14' fill='#E3E3E3' />
    <g id='Group 34262'>
      <g id='Group 34216'>
        <g id='Group 33948'>
          <rect
            id='Rectangle 1317'
            x='-141.5'
            y='-53.5'
            width='174'
            height='178'
            rx='11.5'
            fill='white'
            stroke='#0973D4'
          />
        </g>
        <g id='Group 33999'>
          <g id='Group 33879'>
            <rect
              id='Rectangle 1319'
              x='-129.5'
              y='-6.5'
              width='151'
              height='26'
              rx='3.5'
              stroke='#CACACA'
            />
          </g>
          <g id='Group 33992'>
            <path
              id='Vector'
              d='M4 7L10 7'
              stroke='#CACACA'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <circle id='Ellipse 52' cx='7' cy='7' r='6.5' stroke='#CACACA' />
          </g>
        </g>
      </g>
    </g>
  </svg>,
  'DeleteIcon',
);
export const NumberIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='26' height='13' viewBox='0 0 26 13' fill='none'>
    <path
      d='M2.16667 0C0.982515 0 0 0.982515 0 2.16667V10.8333C0 12.0175 0.982515 13 2.16667 13H23.8333C25.0175 13 26 12.0175 26 10.8333V2.16667C26 0.982515 25.0175 0 23.8333 0H2.16667ZM2.16667 2.16667H23.8333V10.8333H2.16667V2.16667ZM3.25 3.25V5.41667H4.33333V9.75H6.5V3.25H3.25ZM8.66667 7.58333V9.75H10.8333V7.58333H8.66667ZM13 7.58333V9.75H15.1667V7.58333H13Z'
      fill='#737373'
      className='group-hover:stroke-[#0973D4] group-hover:fill-[#0973D4]'
    />
  </svg>,
  'NumberIcon',
);
export const FileIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='22' height='23' viewBox='0 0 22 23' fill='none'>
    <path
      d='M19.25 14.375V18.2083C19.25 18.7167 19.0568 19.2042 18.713 19.5636C18.3692 19.9231 17.9029 20.125 17.4167 20.125H4.58333C4.0971 20.125 3.63079 19.9231 3.28697 19.5636C2.94315 19.2042 2.75 18.7167 2.75 18.2083V14.375'
      stroke='#737373'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
      className='group-hover:stroke-[#0973D4] group-hover:fill-[#0973D4]'
    />
    <path
      d='M15.5837 7.66667L11.0003 2.875L6.41699 7.66667'
      stroke='#737373'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
      className='group-hover:stroke-[#0973D4] group-hover:fill-[#0973D4]'
    />
    <path
      d='M11 2.875V14.375'
      stroke='#737373'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
      className='group-hover:stroke-[#0973D4] group-hover:fill-[#0973D4]'
    />
  </svg>,
  'FileIcon',
);
export const ScaleIcon = createSvgIcon(
  <svg width='20' height='10' viewBox='0 0 20 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M15 10C13.8 10 12.7417 9.621 11.825 8.863C10.9083 8.105 10.3333 7.15067 10.1 6H4.8C4.6 6.45 4.29167 6.81267 3.875 7.088C3.45833 7.36333 3 7.50067 2.5 7.5C1.8 7.5 1.20833 7.25833 0.725 6.775C0.241667 6.29167 0 5.7 0 5C0 4.3 0.241667 3.70833 0.725 3.225C1.20833 2.74167 1.8 2.5 2.5 2.5C3 2.5 3.45833 2.63767 3.875 2.913C4.29167 3.18833 4.6 3.55067 4.8 4H10.1C10.3333 2.85 10.9083 1.89567 11.825 1.137C12.7417 0.378334 13.8 -0.000665789 15 8.77963e-07C16.3833 8.77963e-07 17.5627 0.487668 18.538 1.463C19.5133 2.43833 20.0007 3.61733 20 5C19.9993 6.38267 19.5117 7.562 18.537 8.538C17.5623 9.514 16.3833 10.0013 15 10ZM15 8C15.8333 8 16.5417 7.70833 17.125 7.125C17.7083 6.54167 18 5.83333 18 5C18 4.16667 17.7083 3.45833 17.125 2.875C16.5417 2.29167 15.8333 2 15 2C14.1667 2 13.4583 2.29167 12.875 2.875C12.2917 3.45833 12 4.16667 12 5C12 5.83333 12.2917 6.54167 12.875 7.125C13.4583 7.70833 14.1667 8 15 8Z'
      fill='#737373'
      className='group-hover:stroke-[#0973D4] group-hover:fill-[#0973D4]'
    />
  </svg>,
  'ScaleIcon',
);
export const RepeatedQuestionIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      d='M4 12V9C4 8.20435 4.31607 7.44129 4.87868 6.87868C5.44129 6.31607 6.20435 6 7 6H20M20 6L17 3M20 6L17 9M20 12V15C20 15.7956 19.6839 16.5587 19.1213 17.1213C18.5587 17.6839 17.7956 18 17 18H4M4 18L7 21M4 18L7 15'
      stroke='#737373'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>,
  'ScaleIcon',
);
