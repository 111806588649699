import { useCallback, useRef } from 'react';
import QueryInput from './QueryInput';
import { IQueryAnsForm, addQueryAnswer } from 'services/api/questionQuery';
import { useMutation } from '@tanstack/react-query';
import { useOutsideClick } from 'hooks/useOutsideClick';
import { IQueryQuestion } from 'store/queryQuestionStore';
import { toast } from 'react-toastify';
import QueryItem from './QueryItem';
import { useStudyStore } from '../../../../store/StudyStore';

interface IQProps {
  id: number;
  methods?: any;
  refetch?: any;
  showQuery: boolean,
  isAction: boolean,
  setShowQuery: (query: boolean) => void;
  query: IQueryQuestion;
}

const QueryQuestionnaire = ({ id, methods, setShowQuery, isAction, refetch, query }: IQProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { loggedInStudyUser } = useStudyStore();

  const { mutate: addQueryComment } = useMutation<
    any,
    unknown,
    IQueryAnsForm,
    unknown
  >(
    (data: IQueryAnsForm) => addQueryAnswer(data),
    {
      onSuccess: () => {
        methods.setValue(`queryComment${id}-${query?.id}`, '');
        refetch();
      },
      onError(e: any) {
        toast.error(e?.response?.data?.message);
      },
    },
  );

  const handleOutsideClick = useCallback((e: any, clickedOut: boolean) => {
    if (clickedOut) {
      methods.setValue(`query${id}`, '');
      setShowQuery?.(false);
    }
  },
    [setShowQuery],
  );

  const handleAddComment = (data: any) => {
    const centerUserId = loggedInStudyUser?.id;

    if (data &&  centerUserId !== -1) {
      addQueryComment({
        queryId: query?.id,
        centerUserId: parseInt(`${centerUserId}`, 10) || 1,
        comment: data
      })
    }
  }

  useOutsideClick(containerRef, handleOutsideClick);


  return (
    <div className='bg-white p-2 rounded shadow mb-2'>
      <QueryItem isAction={isAction} id={id} methods={methods} isResolved={query.resolved} setShowQuery={setShowQuery} refetch={refetch} query={query} />
      {query?.replies && query?.replies.map((reply: any) => (
        <QueryItem isAction={isAction} key={reply?.id} id={id} isResolved={query.resolved} methods={methods} isReply={true} setShowQuery={setShowQuery} refetch={refetch} query={reply} />
      ))}
      {isAction && !query.resolved && <QueryInput name={`queryComment${id}-${query?.id}`} placeholder='add Comment' methods={methods} onSubmitQuery={(e: any) => handleAddComment(e)} />}
    </div>
  )
}

export default QueryQuestionnaire;