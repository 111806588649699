import countries from 'country-data';
import { useEffect, useState } from 'react';
import { ICountryInfo } from '../types/countries';

export const useCountryInfo = () => {
  const [countryData, setCountryData] = useState<ICountryInfo>({
    city: '',
    ip: '',
    country: '',
    countryData: {},
  });

  useEffect(() => {
    fetch('https://ipinfo.io/json')
      .then(response => response.json())
      .then(data => {
        const { city, country, ip } = data;
        const countryData: ICountryInfo = countries.countries[country.toUpperCase()];
        setCountryData({
          countryData, city, country, ip
        })
      })
      .catch(error => {
        console.error('Error fetching location data:', error);
      });
  }, []);

  return countryData;
};
