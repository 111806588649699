import { Button } from 'components/common';
import { SetStateAction, Dispatch } from 'react';

interface IProps {
  setShowStudyDashboard?: Dispatch<SetStateAction<boolean>>;
}
const StudyDashboard = ({ setShowStudyDashboard = () => {} }: IProps) => {
  return (
    <div className='w-full h-screen bg-[#F9F9F9] flex p-5 flex-col'>
      <h1 className='text-left text-[32px] my-5 text-blue-ocean-deep font-serif'>
        Study Dashboard
      </h1>
      <div className='flex flex-col w-full h-full bg-white justify-center items-center font-serif'>
        <img
          src={process.env.PUBLIC_URL + '/assets/images/study/empty-study.png'}
          alt='nature image'
          className='rounded-full'
        />
        <h2 className='text-blue-oil text-[32px] leading-[42px] my-4'>Welcome to</h2>
        <h3 className='text-[44px] text-blue-oil leading-[52px]'>Study of stomach</h3>
        <Button
          onClick={() => {
            setShowStudyDashboard(false);
          }}
          className='bg-blue-ocean-deep rounded-full mt-5 text-white text-base font-bold px-6 py-[13px]'
        >
          Start to build
        </Button>
      </div>
    </div>
  );
};
export default StudyDashboard;
