import { FormattedMessage, useIntl } from 'react-intl';
import ScrollContainer from 'react-indiana-drag-scroll';
import { useCallback, useEffect, useRef, useState } from 'react';
import InfoCard from './components/InfoCard';
import DataTable from './components/DataTable';
import useGetPatientData from '../../../../hooks/useGetPatientData';
import { useCentersStore } from '../../../../store/centers';

const ParticipantProfile: React.FC = () => {
  const intl = useIntl();
  const cardContainerRef = useRef<HTMLDivElement>(null);
  const [cardContainerHeight, setCardContainerHeight] = useState<number | 'auto'>('auto');
  const [medicalData, setMedicalData] = useState<any>([]);
  const [surgicalData, setSurgicalData] = useState<any>([]);
  const [adverseData, setAdverseData] = useState<any>([]);
  const [diseaseData, setDiseaseData] = useState<any>([]);
  const [concoMedications, setConcoMedications] = useState<any>([]);
  const [biologicals, setBiologicals] = useState<any>([]);
  const [symptoms, setSymptoms] = useState<any>([]);
  const { adverseEventData, medicalHistoryData, surgicalHistoryData,
    diseaseHistoryData, concoMedicationData, biologicalData, symptomsData
  } = useGetPatientData();
  const { centers } = useCentersStore();

  const getCardContainerHeight = useCallback(() => {
    if (cardContainerRef.current?.parentElement) {
      const current = cardContainerRef.current;
      const parentElement = cardContainerRef.current.parentElement;
      let currentHeight = parentElement.clientHeight;
      const currentPadding = parseInt(getComputedStyle(current).padding.split('p')[0]);
      const currentMargin = parseInt(getComputedStyle(current).marginTop.split('p')[0]);
      const currentGap = parseInt(getComputedStyle(current).gap.split('p')[0]);

      currentHeight -= currentPadding * 2 + currentMargin + currentGap / 2;

      if (cardContainerRef.current.previousElementSibling) {
        const siblingElement = cardContainerRef.current.previousElementSibling;
        currentHeight -= siblingElement.clientHeight;
        currentHeight -= parseInt(getComputedStyle(siblingElement).marginBottom.split('p')[0]);
      }

      setCardContainerHeight(currentHeight);
    }
  }, [cardContainerRef.current]);

  useEffect(() => {
    getCardContainerHeight();
  }, [cardContainerRef]);


  useEffect(() => {
    setMedicalData(medicalHistoryData && medicalHistoryData?.content?.length > 0 && medicalHistoryData?.content?.map((med: any) => ({
      id: med?.id,
      name: med?.name,
      startDate: med?.startDate,
      type: med?.type,
      confirmationDate: med?.confirmationDate,
    })));
  }, [medicalHistoryData]);

  useEffect(() => {
    setAdverseData(adverseEventData && adverseEventData?.length > 0 && adverseEventData?.map((med: any) => {
      const foundCenter = centers?.find((cn: any) => cn.id === med?.patient?.centerId);

      return ({
        id: med?.id,
        name: med?.name,
        center: foundCenter?.name,
        visit: med?.visit,
        date: med?.createdAt,
      })
    }));
  }, [adverseEventData]);

  useEffect(() => {
    setSurgicalData(surgicalHistoryData && surgicalHistoryData?.content?.length > 0 && surgicalHistoryData?.content?.map((med: any) => ({
      id: med?.id,
      name: med?.name,
      date: med?.date,
      indication: med?.indication,
    })));
  }, [surgicalHistoryData]);

  useEffect(() => {
    setDiseaseData(diseaseHistoryData && diseaseHistoryData?.content?.length > 0 && diseaseHistoryData?.content?.map((med: any) => ({
      id: med?.id,
      name: med?.name,
      startDate: med?.startDate,
      endDate: med?.endDate,
      status: med?.status,
    })));
  }, [diseaseHistoryData]);

  useEffect(() => {
    setConcoMedications(concoMedicationData && concoMedicationData?.content?.length > 0 && concoMedicationData?.content?.map((med: any) => ({
      id: med?.id,
      name: med?.name,
      tradeName: med?.tradeName,
      startDate: med?.startDate,
      route: med?.route,
      dosage: med?.dosage,
      discontinuationReason: med?.discontinuationReason,
      important: med?.important,
    })));
  }, [concoMedicationData]);

  useEffect(() => {
    setBiologicals(biologicalData && biologicalData?.content?.length > 0 && biologicalData?.content?.map((med: any) => ({
      id: med?.id,
      name: med?.name,
      date: med?.date,
      type: med?.type,
      results: med?.results,
      file: med?.file,
    })));
  }, [biologicalData]);

  useEffect(() => {
    setSymptoms(symptomsData && symptomsData?.content?.length > 0 && symptomsData?.content?.map((med: any) => ({
      id: med?.id,
      name: med?.name,
      startDate: med?.startDate,
    })));
  }, [symptomsData]);

  return (
    <section className="w-full bg-background-secondary max-w-screen-2xl mx-auto px-4 xxl:px-0">
      <h2 className="text-2xl mt-11 ml-3 pb-4 text-blue-ocean-deep font-normal font-serif leading-[34px] text-left">
        <FormattedMessage id="patient-profile" />
      </h2>
      <ScrollContainer
        innerRef={cardContainerRef}
        nativeMobileScroll
        hideScrollbars={false}
        style={{
          minHeight: cardContainerHeight,
        }}
        className="bg-white w-full min-w-[1280px] rounded-lg p-6 relative no-scrollbar scroll-sm overflow-y-auto gap-6 justify-between"
      >
        <InfoCard />
        <DataTable title={intl.formatMessage({ id: 'study-dashboard.adverseEvent' })} headerType='adverseEvent' columns={adverseData} />
        <div className='grid grid-cols-[55%_43.3%] gap-6'>
          <DataTable title={intl.formatMessage({ id: 'patient-profile.medicalHistory' })} columns={medicalData} headerType='medicalHistory' />
          <DataTable title={intl.formatMessage({ id: 'patient-profile.surgicalHistory' })} columns={surgicalData} headerType='surgicalHistory' />
        </div>
        <div className='grid grid-cols-[50%_48.3%] gap-6'>
          <DataTable title={intl.formatMessage({ id: 'patient-profile.diseaseHistory' })}  columns={diseaseData} headerType='diseaseHistory' />
          <DataTable title={intl.formatMessage({ id: 'patient-profile.symptoms' })}  columns={symptoms} headerType='symptoms' />
        </div>
        <DataTable title={intl.formatMessage({ id: 'patient-profile.concomitantMedication' })} headerType='concoMedication' columns={concoMedications} />
        <DataTable title={intl.formatMessage({ id: 'patient-profile.biologicalData' })} headerType='biologicalData' columns={biologicals} />
      </ScrollContainer>
    </section>
  );
};
export default ParticipantProfile;
