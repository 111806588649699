import { FormProvider, useForm } from 'react-hook-form';
import TextArea from '../../components/common/TextArea';
import { useStudyStore } from '../../store/StudyStore';
import { useCallback, useEffect, useRef } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { IStudy, StudyUpdate } from '../../types/study';
import { fetchStudyById } from '../../services/api/study';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { updateStudyCriteria } from '../../services/api/steps';
import { useParams } from 'react-router-dom';

const SiteOnboarding: React.FC = () => {
  const { studyId  } = useParams();
  const methods = useForm();
  const { currentStudy } = useStudyStore();
  const containerRef = useRef<HTMLDivElement>(null);

  const values = methods.watch();

  const { refetch } = useQuery<IStudy>({
    queryKey: ['study'],
    queryFn: () => fetchStudyById(studyId as string),
  });

  const { mutate: updateStudyMutation } = useMutation<StudyUpdate, unknown, any, unknown>(
    (data: StudyUpdate) => updateStudyCriteria(parseInt(`${studyId}`, 10), data),
    {
      onSuccess() {
        refetch();
      },
    },
  );

  useEffect(() => {
    if (currentStudy) {
      methods.reset({
        inclusionCriteria: currentStudy.inclusionCriteria,
        exclusionCriteria: currentStudy.exclusionCriteria,
      });
    }
  }, [currentStudy]);

  const onSubmit: any = (data: any) => {
    if (currentStudy?.id && (data.inclusionCriteria !== currentStudy.inclusionCriteria || data.exclusionCriteria !== currentStudy.exclusionCriteria)) {
      updateStudyMutation({
        inclusionCriteria: data.inclusionCriteria || currentStudy.inclusionCriteria,
        exclusionCriteria: data.exclusionCriteria || currentStudy.exclusionCriteria,
      });
    }
  }

  const handleOutsideClick = useCallback(
    (event: any, clickedOut: boolean) => {
      if (clickedOut) {
        onSubmit?.(values);
      }
    },
    [values],
  );

  useOutsideClick(containerRef, handleOutsideClick);

  return (
    <section className='pb-6'>
      <div className='flex w-full'>
        <div className='w-full mx-auto'>
          <div className='flex justify-between'>
            <h1 className="text-left text-2xl mb-5 pl-4 text-blue-ocean-deep font-sans text-[40px]">
              Inclusion and exclusion criteria
            </h1>
          </div>
          <div>
            <FormProvider {...methods}>
              <form>
                <div className='flex justify-between items-start'>
                  <div className="w-full bg-white border-purple p-6 border rounded-lg">
                    <p className="text-blue-oil text-left">Please add the inclusion and exclusion criteria</p>
                    <div ref={containerRef} className="flex pt-8">
                      <div className="border-r border-purple w-[50%]">
                        <p className="text-lg text-center text-blue-oil pb-8">Inclusion criteria</p>
                        <TextArea
                          textClass="!border-0 focus:outline-none !pr-6 focus:ring-0 focus:border-transparent max-h-[24rem]"
                          control={methods?.control}
                          rows={16}
                          onKeyPress={(e: any) => {
                            if (e.key === 'Enter') {
                              onSubmit?.({ ...values, inclusionCriteria: e.target.value });
                            }
                          }}
                          name="inclusionCriteria"
                          placeholder="Type or paste inclusion criteria here."
                        />
                      </div>
                      <div className="w-[50%]">
                        <p className="text-lg text-center text-blue-oil pb-8">Exclusion criteria</p>
                        <TextArea
                          textClass="!border-0 !pl-6 focus:outline-none focus:ring-0 focus:border-transparent max-h-[24rem]"
                          control={methods?.control}
                          rows={16}
                          onKeyPress={(e: any) => {
                            if (e.key === 'Enter') {
                              onSubmit?.({ ...values, exclusionCriteria: e.target.value });
                            }
                          }}
                          name="exclusionCriteria"
                          placeholder="Type or paste inclusion criteria here."
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </FormProvider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SiteOnboarding;