import { Select } from 'components/common';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import useAuthStore from 'store/authStore';
import { fetchStudies } from 'services/api/study';
import { Navigate, useNavigate } from 'react-router-dom';
import StudyCard from 'components/common/StudyCard';
import { FiLoader } from 'react-icons/fi';
import { useQuery } from '@tanstack/react-query';
import { IStudy } from 'types/study';
import usePermissions from 'hooks/usePermissions';
import { FormattedMessage } from 'react-intl';

export default function Studies() {
  const navigate = useNavigate();
  const sectionRef = useRef<HTMLElement>(null);
  const [studyStatus, setstudyStatus] = useState('all');
  const [filteredData, setFilteredData] = useState<IStudy[]>([]);
  const [studyList, setStudyList] = useState<IStudy[]>([]);
  const { accessToken, userData } = useAuthStore();
  const { isGeneralAdmin } = usePermissions();
  const { data, isLoading, refetch } = useQuery({
    queryKey: ['studies'],
    queryFn: () => userData && userData?.firstName && fetchStudies(),
    refetchOnMount: 'always',
  });

  useEffect(() => {
    if (data) {
      setStudyList(data);
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, [userData]);

  useEffect(() => {
    studyStatus !== 'all'
      ? studyList &&
        studyList?.length > 0 &&
        setFilteredData(studyList?.filter((item: any) => item.status === studyStatus))
      : studyList && studyList?.length > 0 && setFilteredData(studyList);
  }, [studyList, studyStatus]);

  useLayoutEffect(() => {
    const headerHeight = document.querySelector('header')?.clientHeight;
    if (headerHeight && sectionRef.current) {
      sectionRef.current.style.setProperty('height', `calc(100vh - ${headerHeight}px)`);
    }
  }, []);

  if (!accessToken) {
    return <Navigate to='/login' />;
  }

  const handleStudyFilter = (e: any) => {
    setstudyStatus(e || 'all');
    localStorage.setItem('studyFilter', e || 'all');
  };

  useEffect(() => {
    if (localStorage.getItem('studyFilter')) {
      setstudyStatus(localStorage.getItem('studyFilter') || 'all');
    }
    // empty saved data for site-select workflows
    localStorage.removeItem('selectedFlow');
    localStorage.removeItem('selectedTask');
  }, []);

  return (
    <>
      <section
        ref={sectionRef}
        className='pl-[3.87rem] overflow-y-auto pr-[5.31rem] bg-background pb-6'
      >
        <div className='flex pt-8 items-center justify-between mb-7 w-full'>
          <h1 className='text-[2rem] font-serif leading-[2.625rem] text-blue-ocean-deep'>
            <FormattedMessage id='allStudies.welcome' /> {userData?.firstName}
          </h1>
          <div className='flex items-center gap-[1.937rem]'>
            <Select
              className='min-w-[12.25rem]'
              buttonClassName='bg-white h-8'
              name='studyStatus'
              defaultValue={''}
              optWrapperClassName='border border-blue-ocean-deep border-t-gray-light !-left-[1px]'
              customControl={{
                onChange: (e) => handleStudyFilter(e),
                value: studyStatus,
              }}
              options={[
                {
                  label: 'allStudies.all',
                  value: 'all',
                },
                {
                  label: 'allStudies.deployment',
                  value: 'DEPLOYMENT',
                },
                {
                  label: 'allStudies.execution',
                  value: 'EXECUTION',
                },
                {
                  label: 'allStudies.freeze',
                  value: 'FREEZE',
                },
              ]}
            />
            {isGeneralAdmin() && (
              <button
                type='button'
                className='h-8 p-2.5 bg-blue-ocean-deep rounded-[90px] justify-center items-center px-9 inline-flex'
                onClick={() => navigate('/study/create')}
              >
                <span className='text-center text-white text-base font-medium leading-snug'>
                  <FormattedMessage id='study.createStudy' />
                </span>
              </button>
            )}
          </div>
        </div>
        <div className='flex flex-col gap-4 w-full min-h-fit'>
          {isLoading ? (
            <FiLoader className='w-20 h-20 animate-spin place-self-center text-blue-ocean-deep' />
          ) : (
            filteredData &&
            filteredData?.map((study) => {
              return (
                <StudyCard
                  id={study?.id}
                  title={study.title}
                  shortTitle={study.shortTitle}
                  startDate={study.startDate}
                  enrollmentDate={study.enrollmentDate}
                  completionDate={study?.completionDate}
                  totalPatient={study.currentPatients}
                  numberOfPatient={study.numberOfPatient}
                  center={study.center}
                  role={userData?.userAccount?.role?.name}
                  status={study.status}
                  key={study.id}
                  loggedInStudyUserRole={study?.loggedInStudyUserRole}
                />
              );
            })
          )}
        </div>
      </section>
    </>
  );
}
