import { useState } from 'react';
import clsx from 'classnames';
import { Link } from 'react-router-dom';
import { ToggleButton, Accordion } from 'components/common';
import { FormattedMessage } from 'react-intl';

const Onboarding: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [isRegulatoryChecked, setIsRegulatoryChecked] = useState(false);

  return (
    <div className='flex'>
      <div
        className={` ${open ? 'w-20' : 'w-80 '
          } flex flex-col h-screen p-3 border-r-[1px] border-r-[#EAEAEA] duration-300`}
      >
        <div className='space-y-3'>
          <div className={clsx('flex items-center justify-between', open && '!block')}>
            <h2 className='text-xl font-bold text-white'><FormattedMessage id='dashboard' /></h2>
            <button onClick={() => setOpen(!open)}>
              <img
                className={clsx('h-[24px] w-[24px] mr-2', open && 'rotate-180	')}
                src={process.env.PUBLIC_URL + '/assets/images/layout/arrow_left.svg'}
                alt='nature image'
              />
            </button>
          </div>
          <div className='flex-1 flex flex-col justify-start items-start gap-y-2'>
            <Link
              to='/study/cte-config'
              className='w-full flex items-center justify-between py-2 px-4 space-x-3 rounded-md bg-[#EAEAEA]'
            >
              <p className='flex justify-end items-end gap-x-1 w-full'>
                <span className={clsx(open ? 'hidden ' : '', 'flex justify-center items-center')}>
                  <img
                    className={clsx('h-[24px] w-[24px] mr-2', open && 'rotate-180	')}
                    src={process.env.PUBLIC_URL + '/assets/images/layout/arrow_left.svg'}
                    alt='nature image'
                  />
                  <FormattedMessage id='back' />
                </span>
              </p>
            </Link>
            <Accordion
              header={
                <div className='flex-1 flex justify-between items-center pr-1'>
                  <span className={clsx(open ? 'hidden ' : '', 'text-xs')}>
                    <FormattedMessage id="step.IDVerification" />
                  </span>
                  {!open && (
                    <ToggleButton
                      onChange={(e) => setIsRegulatoryChecked(e)}
                      checked={isRegulatoryChecked}
                    />
                  )}
                </div>
              }
              className='[&>div]:border-none'
              title={'title'}
              content={
                <ul className='pt-2 pb-4 space-y-1 text-sm'>
                  <li
                    className='rounded-sm'
                  // key={title}
                  // onClick={() => setActiveTab()}
                  >
                    <a
                      href='#'
                      className='flex items-center justify-between py-4 px-4 space-x-3 rounded-md hover:bg-[#EAEAEA]'
                    >
                      <p className='flex justify-start items-center gap-x-1'>
                        <img
                          className='h-[24px] w-[24px] mr-2'
                          src={
                            process.env.PUBLIC_URL +
                            '/assets/images/layout/airline_seat_flat_icon.svg'
                          }
                          alt='nature image'
                        />
                        <span className={open ? 'hidden ' : ''}><FormattedMessage id="users.option" />{' '}1</span>
                      </p>
                    </a>
                  </li>
                  <li
                    className='rounded-sm'
                  // key={title}
                  // onClick={() => setActiveTab()}
                  >
                    <a
                      href='#'
                      className='flex items-center justify-between py-4 px-4 space-x-3 rounded-md hover:bg-[#EAEAEA]'
                    >
                      <p className='flex justify-start items-center gap-x-1'>
                        <img
                          className='h-[24px] w-[24px] mr-2'
                          src={process.env.PUBLIC_URL + '/assets/images/layout/usb_icon.svg'}
                          alt='nature image'
                        />
                        <span className={open ? 'hidden ' : ''}><FormattedMessage id="users.option" />{' '}2</span>
                      </p>
                    </a>
                  </li>
                  <li
                    className='rounded-sm'
                  // key={title}
                  // onClick={() => setActiveTab()}
                  >
                    <a
                      href='#'
                      className='flex justify-between items-center py-4 px-4 space-x-3 rounded-md hover:bg-[#EAEAEA]'
                    >
                      <p className='flex justify-start items-center gap-x-1'>
                        <img
                          className='h-[24px] w-[24px] mr-2'
                          src={
                            process.env.PUBLIC_URL +
                            '/assets/images/layout/airline_seat_flat_icon.svg'
                          }
                          alt='nature image'
                        />
                        <span className={open ? 'hidden ' : ''}><FormattedMessage id="users.option" />{' '}3</span>
                      </p>
                    </a>
                  </li>
                  <li
                    className='rounded-sm'
                  // key={title}
                  // onClick={() => setActiveTab()}
                  >
                    <a
                      href='#'
                      className='flex justify-between items-center py-4 px-4 space-x-3 rounded-md hover:bg-[#EAEAEA]'
                    >
                      {/* <button
                    type='submit'
                    className='w-[156px] bg-blue-ocean-deep text-white py-2 px-4 rounded-[10px] '
                  > */}
                      <p className='flex justify-start items-center gap-x-1'>
                        <img
                          className='h-[24px] w-[24px] mr-2'
                          src={
                            process.env.PUBLIC_URL + '/assets/images/layout/vertical_split_icon.svg'
                          }
                          alt='nature image'
                        />
                        <span className={open ? 'hidden ' : ''}><FormattedMessage id="users.option" />{' '}3</span>
                      </p>

                      {/* </button> */}
                    </a>
                  </li>
                </ul>
              }
            />
            <Accordion
              header={
                <div className='flex-1 flex justify-between items-center pr-1'>
                  <span className={clsx(open ? 'hidden ' : '', 'text-xs')}>
                    <FormattedMessage id="patient-status.questionaryEligibility" />
                  </span>
                  {!open && (
                    <ToggleButton
                      onChange={(e) => setIsRegulatoryChecked(e)}
                      checked={isRegulatoryChecked}
                    />
                  )}
                </div>
              }
              className='[&>div]:border-none'
              title={'title'}
              content={
                <ul className='pt-2 pb-4 space-y-1 text-sm'>
                  <li
                    className='rounded-sm'
                  // key={title}
                  // onClick={() => setActiveTab()}
                  >
                    <a
                      href='#'
                      className='flex items-center justify-between py-4 px-4 space-x-3 rounded-md hover:bg-[#EAEAEA]'
                    >
                      <p className='flex justify-start items-center gap-x-1'>
                        <img
                          className='h-[24px] w-[24px] mr-2'
                          src={
                            process.env.PUBLIC_URL +
                            '/assets/images/layout/airline_seat_flat_icon.svg'
                          }
                          alt='nature image'
                        />
                        <span className={open ? 'hidden ' : ''}><FormattedMessage id='dashboard' /></span>
                      </p>
                    </a>
                  </li>
                  <li
                    className='rounded-sm'
                  // key={title}
                  // onClick={() => setActiveTab()}
                  >
                    <a
                      href='#'
                      className='flex items-center justify-between py-4 px-4 space-x-3 rounded-md hover:bg-[#EAEAEA]'
                    >
                      <p className='flex justify-start items-center gap-x-1'>
                        <img
                          className='h-[24px] w-[24px] mr-2'
                          src={
                            process.env.PUBLIC_URL +
                            '/assets/images/layout/airline_seat_flat_icon.svg'
                          }
                          alt='nature image'
                        />
                        <span className={open ? 'hidden ' : ''}><FormattedMessage id='patient-status.eCRFBuilder' /></span>
                      </p>
                    </a>
                  </li>
                  <li
                    className='rounded-sm'
                  // key={title}
                  // onClick={() => setActiveTab()}
                  >
                    <a
                      href='#'
                      className='flex items-center justify-between py-4 px-4 space-x-3 rounded-md hover:bg-[#EAEAEA]'
                    >
                      <p className='flex justify-start items-center gap-x-1'>
                        <img
                          className='h-[24px] w-[24px] mr-2'
                          src={
                            process.env.PUBLIC_URL +
                            '/assets/images/layout/airline_seat_flat_icon.svg'
                          }
                          alt='nature image'
                        />
                        <span className={open ? 'hidden ' : ''}><FormattedMessage id='patient-status.inclusionsList' /></span>
                      </p>
                    </a>
                  </li>
                </ul>
              }
            />
            <Accordion
              header={
                <div className='flex-1 flex justify-between items-center pr-1'>
                  <span className={clsx(open ? 'hidden ' : '', 'text-xs')}><FormattedMessage id='patient-status.eConstent/Web' /></span>

                  {!open && (
                    <ToggleButton
                      onChange={(e) => setIsRegulatoryChecked(e)}
                      checked={isRegulatoryChecked}
                    />
                  )}
                </div>
              }
              className='[&>div]:border-none'
              title={'title'}
              content={
                <ul className='pt-2 pb-4 space-y-1 text-sm'>
                  <li
                    className='rounded-sm'
                  // key={title}
                  // onClick={() => setActiveTab()}
                  >
                    <a
                      href='#'
                      className='flex items-center justify-between py-4 px-4 space-x-3 rounded-md hover:bg-[#EAEAEA]'
                    >
                      <p className='flex justify-start items-center gap-x-1'>
                        <img
                          className='h-[24px] w-[24px] mr-2'
                          src={
                            process.env.PUBLIC_URL +
                            '/assets/images/layout/airline_seat_flat_icon.svg'
                          }
                          alt='nature image'
                        />
                        <span className={open ? 'hidden ' : ''}><FormattedMessage id="users.option" />{' '}1</span>                      </p>
                    </a>
                  </li>
                  <li
                    className='rounded-sm'
                  // key={title}
                  // onClick={() => setActiveTab()}
                  >
                    <a
                      href='#'
                      className='flex items-center justify-between py-4 px-4 space-x-3 rounded-md hover:bg-[#EAEAEA]'
                    >
                      <p className='flex justify-start items-center gap-x-1'>
                        <img
                          className='h-[24px] w-[24px] mr-2'
                          src={
                            process.env.PUBLIC_URL +
                            '/assets/images/layout/airline_seat_flat_icon.svg'
                          }
                          alt='nature image'
                        />
                        <span className={open ? 'hidden ' : ''}><FormattedMessage id="users.option" />{' '}2</span>
                      </p>
                    </a>
                  </li>
                </ul>
              }
            />
          </div>
        </div>
      </div>
      <div className='w-full mx-auto '>
        <h2 className='text-[20px] text-[#343A40] font-medium leading-6 my-[23px] pl-6 text-left border-b-2 border-b-[#EAEAEA] pb-[23px]'>
          <FormattedMessage id="study.onBoardingDashboard" />
        </h2>
        <div>{'children'}</div>
      </div>
    </div>
  );
};
export default Onboarding;
