import { SvgIcon } from '@mui/material';
// import { QuestionButtonProps } from './QuestionButton.types';
import clsx from 'classnames';
import type { MouseEvent, ButtonHTMLAttributes } from 'react';
import type { SvgIconComponent } from '@mui/icons-material';

interface StudyItemButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  Logo?: SvgIconComponent | any;
  textContent: string;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  selected?: boolean;
  icon?: any;
}

export function StudyItemButton({
  Logo,
  disabled,
  textContent,
  onClick,
  className,
  selected,
  icon,
  ...rest
}: StudyItemButtonProps) {
  return (
    <button
      {...rest}
      disabled={disabled}
      onClick={onClick}
      className={clsx(
        'px-[6px] py-[3px] h-[42px] overflow-hidden relative transition group ease-in-out delay-[5ms] bg-white hover:bg-blue-ocean-deep rounded-lg justify-center items-center gap-1 inline-flex text-blue font-normal hover:text-white  hover:border-white hover:border-2 disabled:bg-disabled disabled:text-white disabled:cursor-not-allowed',
        className,
        selected && '!bg-blue-ocean-deep text-white',
      )}
    >
      <div className='pl-2 py-[4px] flex justify-start gap-1 items-center w-full hover:border-[1px] hover:border-white'>
        {icon ? (
          icon
        ) : Logo ? (
          <SvgIcon component={Logo} className='fill-blue group-hover:fill-white z-1' />
        ) : (
          ''
        )}
        <p className='block justify-left text-base z-1 text-left truncate w-[200px] cursor-text'>
          {textContent}
        </p>
      </div>
    </button>
  );
}
