import { Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import { NavBar } from 'components/shared/NavBar';
import useAuthStore from 'store/authStore';
import usePermissions from '../../../hooks/usePermissions';
import { useEffect, useState } from 'react';
import { useStudyStore } from '../../../store/StudyStore';
import clsx from 'classnames';
import { useQuestionnaireStore } from '../../../store/QuestionnaireStore';

const DashboardLayout: React.FC = () => {
  const navigate = useNavigate();
  const { accessToken } = useAuthStore();
  const { hasNotAccess } = usePermissions();
  const { studyId } = useParams();
  const { loggedInStudyUser } = useStudyStore();
  const { userData } = useAuthStore();
  const { currentStudy } = useStudyStore();
  const [isRedirectCheck, setIsRedirectCheck] = useState(false);
  const { setCustomFolders } = useQuestionnaireStore();

  if (!accessToken) {
    return <Navigate to='/login' />;
  }

  useEffect(() => {
    if (!location?.pathname?.includes('/standard-forms')) {
      setCustomFolders([]);
    }

    const isDeployment = currentStudy?.status === 'DEPLOYMENT';
    const isAdminClinicalTrial =
      userData?.userAccount?.role?.name === 'ADMIN' &&
      userData?.userAccount?.role?.type === 'CLINICAL_TRIAL';
    const paths = [
      'query-dashboard',
      'summary',
      'users',
      'sites',
      'site-select',
      'participants',
      'standard-forms',
      'study-builder',
      'audit-trail',
    ];

    const deploymentPaths = [
      'site-select',
      'study-builder',
      'users',
    ];

    const hasPath = paths.some((path) => location.pathname?.includes(path));

    const redirect = (path: string) => {
      navigate(path);
      setIsRedirectCheck(false);
    };

    if (isDeployment || hasPath) setIsRedirectCheck(true);

    if (isAdminClinicalTrial) return redirect(`/study/${studyId}/users`);

    if (location?.pathname?.includes('details')) return redirect(location.pathname);
    if (location?.pathname?.includes('audit-trail') && !isDeployment)
      return redirect(location.pathname);

    if (studyId && isDeployment) {
      const hasDeploymentPath = deploymentPaths.some((path) => location.pathname?.includes(path));

      const savedFlow: any = localStorage.getItem('selectedFlow');
      const savedTask: any = localStorage.getItem('selectedTask');
      if (location.pathname?.includes('site-select')) {
        setIsRedirectCheck(false);

        if (savedFlow || savedTask) {
          const search = `?selectedFlowId=${savedFlow ? JSON.parse(savedFlow ?? '')?.id : ''}&selectedTaskId=${savedTask ? JSON.parse(savedTask ?? '')?.id : ''}`;
          redirect(`/study/${studyId}/site-select` + search);
        } else redirect(`/study/${studyId}/site-select`);
        return;
      }

      if (!hasDeploymentPath) {
        return !hasNotAccess('STUDY_BUILDER')
          ? redirect(`/study/${studyId}/study-builder`)
          : redirect(`/study/${studyId}/users`)
      }

      return location.pathname?.includes('users')
        ? redirect(location.pathname)
        : !hasNotAccess('STUDY_BUILDER')
          ? redirect(location.pathname)
          : redirect(`/study/${studyId}/users`)

    }

    const isPermissions =
      (location.pathname?.includes('users')) ||
      (location.pathname?.includes('sites')) ||
      (location.pathname?.includes('study-builder') && !hasNotAccess('STUDY_BUILDER')) ||
      (location.pathname?.includes('query-dashboard')) ||
      ((location.pathname?.includes('participants') ||
        location.pathname?.includes('standard-forms')));

    if (
      loggedInStudyUser &&
      studyId &&
      isPermissions &&
      !location.pathname?.includes('summary') &&
      !isDeployment
    ) {
      location.pathname?.includes('study-builder')
        ? redirect(`/study/${studyId}/summary`)
        : redirect(location.pathname);
    } else if (
      loggedInStudyUser &&
      studyId &&
      !isPermissions &&
      !isDeployment
    ) {
      redirect(`/study/${studyId}/summary`);
    } else if (
      loggedInStudyUser &&
      !isPermissions &&
      isDeployment
      // (hasNotAccess('VIEW_DASHBOARD') || isDeployment)
    ) {
      navigate('/');
    }
  }, [location.pathname, loggedInStudyUser, currentStudy]);

  return (
    <>
      <div className='h-[calc(100%-4.8125rem)] bg-base-background border-none'>
        <NavBar />
        {!isRedirectCheck && (
          <div
            className={clsx(
              'flex w-full h-full overflow-y-auto pl-[5.4625rem] ',
              location.pathname?.includes('site-select') ? 'bg-white' : 'bg-background-secondary',
            )}
          >
            <Outlet />
          </div>
        )}
      </div>
    </>
  );
};

export default DashboardLayout;
