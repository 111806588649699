import { setupResponseInterceptor } from 'config/axios';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuthStore from 'store/authStore';

const NavigateFunctionComponent = () => {
  const navigate = useNavigate();
  const { setAccessToken } = useAuthStore();


  useEffect(() => {
    setupResponseInterceptor(navigate,setAccessToken);
  }, []);

  return <></>;
};
export default NavigateFunctionComponent;
