import { InputProps } from '../../types/form';

export const areaOfMedicalOptions = [
  { label: 'study.allergyImmunology', value: 'ALLERGY_AND_IMMUNOLOGY' },
  { label: 'study.adolescentMedicine', value: 'ADOLESCENT_MEDICINE' },
  { label: 'study.anesthesiology', value: 'ANESTHESIOLOGY' },
  { label: 'study.aerospaceMedicine', value: 'AEROSPACE_MEDICINE' },
  { label: 'study.bariatrics', value: 'BARIATRICS' },
  { label: 'study.cardiology', value: 'CARDIOLOGY' },
  { label: 'study.cardiothoracicSurgery', value: 'CARDIOTHORACIC_SURGERY' },
  { label: 'study.childAdolescentPsychiatry', value: 'CHILD_AND_ADOLESCENT_PSYCHIATRY' },
  { label: 'study.clinicalNeurophysiology', value: 'CLINICAL_NEUROPHYSIOLOGY' },
  { label: 'study.colorectalSurgery', value: 'COLORECTAL_SURGERY' },
  { label: 'study.dermatology', value: 'DERMATOLOGY' },
  { label: 'study.developmentalPediatrics', value: 'DEVELOPMENTAL_PEDIATRICS' },
  { label: 'study.emergencyMedicine', value: 'EMERGENCY_MEDICINE' },
  { label: 'study.endocrinology', value: 'ENDOCRINOLOGY' },
  { label: 'study.familyMedicine', value: 'FAMILY_MEDICINE' },
  { label: 'study.forensicPathology', value: 'FORENSIC_PATHOLOGY' },
  { label: 'study.forensicPsychiatry', value: 'FORENSIC_PSYCHIATRY' },
  { label: 'study.gastroenterology', value: 'GASTROENTEROLOGY' },
  { label: 'study.generalSurgery', value: 'GENERAL_SURGERY' },
  { label: 'study.generalSurgicalOncology', value: 'GENERAL_SURGICAL_ONCOLOGY' },
  { label: 'study.geriatrics', value: 'GERIATRICS' },
  { label: 'study.geriatricPsychiatry', value: 'GERIATRIC_PSYCHIATRY' },
  { label: 'study.gynecologicOncology', value: 'GYNECOLOGIC_ONCOLOGY' },
  { label: 'study.hematology', value: 'HEMATOLOGY' },
  { label: 'study.hematologicPathology', value: 'HEMATOLOGIC_PATHOLOGY' },
  { label: 'study.infectiousDisease', value: 'INFECTIOUS_DISEASE' },
  { label: 'study.internalMedicine', value: 'INTERNAL_MEDICINE' },
  { label: 'study.interventionalRadiology', value: 'INTERVENTIONAL_RADIOLOGY' },
  { label: 'study.intensiveCareMedicine', value: 'INTENSIVE_CARE_MEDICINE' },
  { label: 'study.maternalFetalMedicine', value: 'MATERNAL_FETAL_MEDICINE' },
  { label: 'study.medicalBiochemistry', value: 'MEDICAL_BIOCHEMISTRY' },
  { label: 'study.medicalGenetics', value: 'MEDICAL_GENETICS' },
  { label: 'study.medicalOncology', value: 'MEDICAL_ONCOLOGY' },
  { label: 'study.neonatology', value: 'NEONATOLOGY' },
  { label: 'study.nephrology', value: 'NEPHROLOGY' },
  { label: 'study.neurology', value: 'NEUROLOGY' },
  { label: 'study.neuropathology', value: 'NEUROPATHOLOGY' },
  { label: 'study.neuroradiology', value: 'NEURORADIOLOGY' },
  { label: 'study.neurosurgery', value: 'NEUROSURGERY' },
  { label: 'study.nuclearMedicine', value: 'NUCLEAR_MEDICINE' },
  { label: 'study.obstetricsGynecology', value: 'OBSTETRICS_AND_GYNECOLOGY' },
  { label: 'study.occupationalMedicine', value: 'OCCUPATIONAL_MEDICINE' },
  { label: 'study.ophthalmology', value: 'OPHTHALMOLOGY' },
  { label: 'study.orthopedicSurgery', value: 'ORTHOPEDIC_SURGERY' },
  { label: 'study.oralMaxillofacialSurgery', value: 'ORAL_AND_MAXILLOFACIAL_SURGERY' },
  { label: 'study.otorhinolaryngology', value: 'OTORHINOLARYNGOLOGY' },
  { label: 'study.palliativeCare', value: 'PALLIATIVE_CARE' },
  { label: 'study.pathology', value: 'PATHOLOGY' },
  { label: 'study.pediatrics', value: 'PEDIATRICS' },
  { label: 'study.pediatricAllergyImmunology', value: 'PEDIATRIC_ALLERGY_AND_IMMUNOLOGY' },
  { label: 'study.pediatricCardiology', value: 'PEDIATRIC_CARDIOLOGY' },
  { label: 'study.pediatricEmergencyMedicine', value: 'PEDIATRIC_EMERGENCY_MEDICINE' },
  { label: 'study.pediatricEndocrinology', value: 'PEDIATRIC_ENDOCRINOLOGY' },
  { label: 'study.pediatricGastroenterology', value: 'PEDIATRIC_GASTROENTEROLOGY' },
  { label: 'study.pediatricHematologyOncology', value: 'PEDIATRIC_HEMATOLOGY_AND_ONCOLOGY' },
  { label: 'study.pediatricInfectiousDisease', value: 'PEDIATRIC_INFECTIOUS_DISEASE' },
  { label: 'study.pediatricNephrology', value: 'PEDIATRIC_NEPHROLOGY' },
  { label: 'study.pediatricRespiratoryMedicine', value: 'PEDIATRIC_RESPIRATORY_MEDICINE' },
  { label: 'study.pediatricRheumatology', value: 'PEDIATRIC_RHEUMATOLOGY' },
  { label: 'study.pediatricSurgery', value: 'PEDIATRIC_SURGERY' },
  { label: 'study.physicalMedicineRehabilitation', value: 'PHYSICAL_MEDICINE_AND_REHABILITATION' },
  { label: 'study.plasticReconstructiveAestheticSurgery', value: 'PLASTIC_RECONSTRUCTIVE_AND_AESTHETIC_SURGERY' },
  { label: 'study.Psychiatry', value: 'PSYCHIATRY' },
  { label: 'study.publicHealth', value: 'PUBLIC_HEALTH' },
  { label: 'study.radiationOncology', value: 'RADIATION_ONCOLOGY' },
  { label: 'study.radiology', value: 'RADIOLOGY' },
  { label: 'study.reproductiveEndocrinologyInfertility', value: 'REPRODUCTIVE_ENDOCRINOLOGY_AND_INFERTILITY' },
  { label: 'study.respiratoryMedicine', value: 'RESPIRATORY_MEDICINE' },
  { label: 'study.rheumatology', value: 'RHEUMATOLOGY' },
  { label: 'study.sportsMedicine', value: 'SPORTS_MEDICINE' },
  { label: 'study.thoracicSurgery', value: 'THORACIC_SURGERY' },
  { label: 'study.toxicology', value: 'TOXICOLOGY' },
  { label: 'study.transfusionMedicine', value: 'TRANSFUSION_MEDICINE' },
  { label: 'study.urology', value: 'UROLOGY' },
  { label: 'study.vascularSurgery', value: 'VASCULAR_SURGERY' },
];

export const interventionOptions = [
  { label: 'study.behavior', value: 'BEHAVIOR' },
  { label: 'study.device', value: 'DEVICE' },
  { label: 'study.dietarySupplement', value: 'DIETARY_SUPPLEMENT' },
  { label: 'study.drug', value: 'DRUG' },
];

export const selectTrialPhaseOptions = [
  { label: 'study.notApplicable', value: 'NOT_APPLICABLE' },
  { label: 'study.earlyPhase1', value: 'EARLY_PHASE_1' },
  { label: 'study.phase1', value: 'PHASE_1' },
  { label: 'study.phase2', value: 'PHASE_2' },
  { label: 'study.phase3', value: 'PHASE_3' },
  { label: 'study.phase4', value: 'PHASE_4' },
  { label: 'study.preceMarked', value: 'PRECE_MARKED' },
  { label: 'study.pmcf', value: 'PMCF' },
];

// export const studyStatusOptions = [
//   { label: 'study.frozen', value: 'FROZEN' },
//   { label: 'study.live', value: 'LIVE' },
//   { label: 'study.onHold', value: 'ON HOLD' },
//   { label: 'study.test', value: 'TEST' },
// ];

export const studyTypeOptions = [
  { label: 'study.observational', value: 'OBSERVATIONAL' },
  { label: 'study.investigational', value: 'INVESTIGATIONAL'},
  { label: 'study.registry', value: 'REGISTRY' },
  { label: 'study.survey', value: 'SURVEY' },
  { label: 'study.randomized', value: 'RANDOMIZED' },
];

export const NEW_USER_FORM: InputProps[] = [
  {
    label: 'Full name',
    type: 'text',
    name: 'fullName',
    placeholder: 'FullName placeholder',
    value: '',
    validations: [
      {
        type: 'minLength',
        value: 3,
        message: 'Min. 3 characters',
      },
      {
        type: 'required',
        message: 'FullName is required',
      },
    ],
  },
  {
    label: 'Role',
    type: 'text',
    name: 'role',
    placeholder: 'Role text',
    value: '',
    validations: [
      {
        type: 'minLength',
        value: 3,
        message: 'Min. 3 characters',
      },
      {
        type: 'required',
        message: 'Role is required',
      },
    ],
  },
  {
    label: 'Email',
    type: 'text',
    name: 'email',
    placeholder: 'Email text',
    value: '',
    validations: [
      {
        type: 'minLength',
        value: 3,
        message: 'Min. 3 characters',
      },
      {
        type: 'required',
        message: 'Email is required',
      },
    ],
  },
];

export const ADD_USER_TO_STUDY: InputProps[] = [
  {
    label: 'User',
    type: 'text',
    name: 'user',
    placeholder: 'User placeholder',
    value: '',
    validations: [
      {
        type: 'minLength',
        value: 3,
        message: 'Min. 3 characters',
      },
      {
        type: 'required',
        message: 'User is required',
      },
    ],
  },
  {
    label: 'Role',
    type: 'text',
    name: 'role',
    placeholder: 'Role text',
    value: '',
    validations: [
      {
        type: 'minLength',
        value: 3,
        message: 'Min. 3 characters',
      },
      {
        type: 'required',
        message: 'Role is required',
      },
    ],
  },
  {
    label: 'Centers',
    type: 'text',
    name: 'centers',
    placeholder: 'Email text',
    value: '',
    validations: [
      {
        type: 'minLength',
        value: 3,
        message: 'Min. 3 characters',
      },
      {
        type: 'required',
        message: 'Centers is required',
      },
    ],
  },
];
