import React, { useState, useRef, useCallback, useEffect } from 'react';
import { FiGrid, FiTrash, FiCopy } from 'react-icons/fi';
import clsx from 'classnames';
import QuestionTypes from '../QuestionTypes';
import { useOutsideClick } from 'hooks/useOutsideClick';
import { ThreeDotsDropdown, Tooltip } from 'components/common';
import { SvgIcon } from '@mui/material';
import { MoreIcon } from '../../../Icons';
import { useQuestionnaire } from '../useQuestionnaire';
import { v4 as uuidv4 } from 'uuid';
import TextEditorToolBox from './TextEditorToolBox';

interface QuestionProps {
  onSave?: any;
  question: any;
  closePopups: boolean;
  setClosePopups: any;
  editable?: boolean;
  collapsedSubQuestions: any;
  setCollapsedSubQuestions: any;
  filterRepeatedInput?: boolean;
  isTitleQuestion?: boolean;
  titleQuestionId?: any;
  filterTitleInput?: boolean;
  filterFileInput?: boolean;
  filterRequired?: boolean;
  onRemoveQuestionApi?: any;
  onDuplicateQuestion?: any;
  shouldFetchSteps?: any;
  shouldFetchSF?: any;
  isStandardForm?: boolean;
}
let tmpRequired: boolean = false;

const Question: React.FC<QuestionProps> = ({
  question,
  setClosePopups,
  closePopups,
  onSave = () => {},
  editable,
  collapsedSubQuestions,
  setCollapsedSubQuestions,
  filterRepeatedInput,
  isTitleQuestion,
  titleQuestionId,
  filterTitleInput,
  filterFileInput,
  filterRequired = false,
  onRemoveQuestionApi,
  onDuplicateQuestion,
  shouldFetchSteps,
  shouldFetchSF,
  isStandardForm,
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [optionalPropsPopover, setOptionalPropsPopover] = useState(false);
  const [required, setRequired] = useState(question?.required ?? false);
  const [isHover, setIsHover] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  tmpRequired = required ?? question?.required;
  const { handleRemoveQuestionApi, handleDuplicateQuestion } = useQuestionnaire({
    shouldFetchSteps,
    shouldFetchSF,
  });
  const [isEditing, setIsEditing] = useState(false);
  const [localQsType, setLocalQsType] = useState<string | null>(null);
  const [value, setValue] = useState<any>(question?.questionTitle);
  const inputRef: any = useRef<HTMLInputElement>(null);

  const togglePopover = () => {
    setIsPopoverOpen(!isPopoverOpen);
    setClosePopups(false);
  };
  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);
  useEffect(() => {
    if (!question?.questionTitle) {
      setValue('');
      setIsEditing(false);
    }
  }, [question]);

  useEffect(() => {
    if (closePopups) {
      setIsPopoverOpen(false);
      setClosePopups(false);
      setOptionalPropsPopover(false);
    }
  }, [closePopups]);

  const handleClick = () => {
    setIsEditing(true);
    setIsPopoverOpen(false);
    setClosePopups(false);
    setTimeout(() => {
      document
        .getElementById(
          `text-toolbox-editor-${question?.sortOrder ?? 0}${question?.id ? question?.id : ''}`,
        )
        ?.focus();
    }, 200);
  };

  const handleOnSaveQuestion = (opts?: any, check?: any, qssId?: any) => {
    onSave(
      localQsType
        ? {
            questionTitle: value,
            required: check !== undefined ? check : required, // tmpRequired,
            questionType: localQsType,
            optionalProps: {
              questionOptions: opts ?? [],
              ...(question?.isScored ? { isScored: question?.isScored } : {}),
              ...(question?.stars ? { stars: question?.stars } : {}),
              ...(question?.numberType ? { numberType: question?.numberType } : {}),
              ...(question?.dateTimeType ? { dateTimeType: question?.dateTimeType } : {}),
              ...(question?.increment
                ? {
                    increment: question?.increment,
                    startValue: question?.startValue,
                    endValue: question?.endValue,
                    startLegend: question?.startLegend ? question?.startLegend : '',
                    endLegend: question?.endLegend ? question?.endLegend : '',
                  }
                : {}),
              // ...(question?.inputType ? { inputType: question?.inputType } : {}),
            },
            qssId,
          }
        : {
            questionTitle: value,
            required: check !== undefined ? check : required, // tmpRequired,
            optionalProps: {
              questionOptions: opts ?? [],
              ...(question?.isScored ? { isScored: question?.isScored } : {}),
              ...(question?.stars ? { stars: question?.stars } : {}),
              ...(question?.increment
                ? {
                    increment: question?.increment,
                    startValue: question?.startValue,
                    endValue: question?.endValue,
                    startLegend: question?.startLegend ? question?.startLegend : '',
                    endLegend: question?.endLegend ? question?.endLegend : '',
                  }
                : {}),
            },
            qssId,
          },
    );
  };
  const handleBlur = () => {
    setTimeout(() => {
      if (!value) setValue(question?.questionTitle);
      setIsEditing(false);
      if (value && value !== question?.questionTitle) {
        const options = [...(question?.questionOptions ?? [])];
        handleOnSaveQuestion(options, null, question?.qssId);
      }
      setIsPopoverOpen(false);
      setClosePopups(false);
    }, 500);
  };
  const handleSetRequiredCheck = (check?: boolean) => {
    const newVal = value ?? question?.questionTitle;

    setTimeout(() => {
      if (!newVal) setValue(question?.questionTitle);
      setIsEditing(false);

      if (newVal && question?.required !== check && question?.questionTitle !== '') {
        const options = [...(question?.questionOptions ?? [])];
        handleOnSaveQuestion(options, check);
      }
      setIsPopoverOpen(false);
      setClosePopups(false);
    }, 500);
  };

  const handleChangeQuestionType = (newQsType?: string) => {
    if (question?.questionTitle)
      onSave({
        id: question?.id,
        questionTitle: value,
        required: required,
        questionType: newQsType,
        ...(question?.titleQuestionId ? { titleQuestionId: question?.titleQuestionId } : {}),
        ...(question?.parentQuestionId ? { parentQuestionId: question?.parentQuestionId } : {}),
        optionalProps: {
          questionOptions: question?.questionOptions ?? [],
          ...(question?.isScored ? { isScored: question?.isScored } : {}),
          ...(question?.stars ? { stars: question?.stars } : {}),
          ...(question?.numberType ? { numberType: question?.numberType } : {}),
          ...(question?.dateTimeType ? { dateTimeType: question?.dateTimeType } : {}),
          ...(question?.increment
            ? {
                increment: question?.increment,
                startValue: question?.startValue,
                endValue: question?.endValue,
                startLegend: question?.startLegend ? question?.startLegend : '',
                endLegend: question?.endLegend ? question?.endLegend : '',
              }
            : {}),
          // ...(question?.inputType ? { inputType: question?.inputType } : {}),
        },
      });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e?.target?.value);
  };
  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      if (!value) setValue(question?.questionTitle);
      setIsEditing(false);
      const options = [...(question?.questionOptions ?? [])];
      if (value && question?.questionTitle !== value)
        onSave(
          localQsType
            ? {
                questionTitle: value,
                questionType: localQsType,
                required: required, // tmpRequired,
                optionalProps: {
                  questionOptions: options ?? [],
                  ...(titleQuestionId ? { titleQuestionId: titleQuestionId } : {}),
                  ...(question?.isScored ? { isScored: question?.isScored } : {}),
                  ...(question?.stars ? { stars: question?.stars } : {}),
                  ...(question?.numberType ? { numberType: question?.numberType } : {}),
                  ...(question?.dateTimeType ? { dateTimeType: question?.dateTimeType } : {}),
                  ...(question?.increment
                    ? {
                        increment: question?.increment,
                        startValue: question?.startValue,
                        endValue: question?.endValue,
                        startLegend: question?.startLegend ? question?.startLegend : '',
                        endLegend: question?.endLegend ? question?.endLegend : '',
                      }
                    : {}),
                  // ...(question?.inputType ? { inputType: question?.inputType } : {}),
                },
              }
            : {
                questionTitle: value,
                required: required, // tmpRequired,
                optionalProps: {
                  ...(titleQuestionId ? { titleQuestionId: titleQuestionId } : {}),
                  questionOptions: options ?? [],
                  ...(question?.isScored ? { isScored: question?.isScored } : {}),
                  ...(question?.stars ? { stars: question?.stars } : {}),
                  ...(question?.numberType ? { numberType: question?.numberType } : {}),
                  ...(question?.dateTimeType ? { dateTimeType: question?.dateTimeType } : {}),
                  ...(question?.increment
                    ? {
                        increment: question?.increment,
                        startValue: question?.startValue,
                        endValue: question?.endValue,
                        startLegend: question?.startLegend ? question?.startLegend : '',
                        endLegend: question?.endLegend ? question?.endLegend : '',
                      }
                    : {}),
                  // ...(question?.inputType ? { inputType: question?.inputType } : {}),
                },
              },
        );
    }
  };
  const handleOutsideClick = useCallback(
    (e: any, clickedOut: boolean) => {
      if (clickedOut) {
        setIsPopoverOpen(false);
        setOptionalPropsPopover(false);
        setClosePopups(false);
      }
    },
    [setIsPopoverOpen],
  );

  useOutsideClick(containerRef, handleOutsideClick);

  const isStyledQuestion =
    !['TITLE'].includes(question?.questionType) && !question?.parentQuestionId;

  return (
    <>
      <div
        ref={containerRef}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        className={clsx(
          'group relative flex border-t-2 border-gray min-h-[32px] py-1 px-[10px] justify-start items-start w-full',
          isEditing ? 'bg-blue-light-100 h-[45px]' : '',
          value && !isEditing ? 'hover:bg-blue-light-100' : 'hover:bg-blue-light-100',
          editable == false
            ? 'opacity-40 pointer-events-none !cursor-not-allowed'
            : 'cursor-pointer',
          isTitleQuestion && '!bg-blue-very-light pt-2',
        )}
      >
        {isEditing || (value && isStyledQuestion) ? (
          <>
            {!isStyledQuestion ? (
              <input
                ref={inputRef}
                onKeyDown={handleKeyDown}
                id={`${uuidv4()}-${question?.parentQuestionId && 'sub-input'}`}
                type='text'
                value={value}
                onChange={handleChange}
                onBlur={handleBlur}
                className='p-4 outline-none focus:outline-none border-[1px] border-purple !rounded-[4px] h-full w-[313px]'
              />
            ) : isEditing || value ? (
              <>
                <TextEditorToolBox
                  onChangeHandler={handleChange}
                  onBlur={handleBlur}
                  defaultValue={value}
                  handleKeyDown={handleKeyDown}
                  titleQuestionId={!!titleQuestionId}
                  filterRequired={filterRequired}
                  question={question}
                  tmpRequired={required}
                  handleRequired={(e: any) => {
                    handleSetRequiredCheck(e);
                    setRequired(e);
                  }}
                  toggleQuestionTypePopover={togglePopover}
                  setValue={setValue}
                  setRequired={setRequired}
                />
              </>
            ) : null}
          </>
        ) : value ? (
          <>
            <div
              id='value'
              onClick={handleClick}
              className={clsx(
                'pl-2 py-1 max-w-[645px] text-left break-words	min-w-[313px]',
                isHover ? 'border-[1px] border-purple rounded-[4px]' : '',
                question?.titleQuestionId && 'border-gray-very-light border-2 rounded-[4px]',
                isTitleQuestion && 'border-2 border-gray-300 rounded-[4px]',
                titleQuestionId || isTitleQuestion ? 'bg-blue-very-light' : 'bg-white ',
              )}
            >
              {value}
            </div>
            {titleQuestionId && isStyledQuestion ? (
              <>
                <TextEditorToolBox
                  onChangeHandler={handleChange}
                  onBlur={handleBlur}
                  handleKeyDown={handleKeyDown}
                  defaultValue={value}
                  titleQuestionId={!!titleQuestionId}
                  filterRequired={filterRequired}
                  question={question}
                  tmpRequired={tmpRequired}
                  handleRequired={(e: boolean) => {
                    setRequired(e);
                    handleSetRequiredCheck(e);
                  }}
                  toggleQuestionTypePopover={togglePopover}
                  setValue={setValue}
                  setRequired={setRequired}
                />
              </>
            ) : null}
          </>
        ) : (
          <>
            <div
              onClick={handleClick}
              className={clsx(
                'border-2  w-[313px] py-[6px] pl-[10px] rounded-[4px] text-[#CACACA] text-sm flex justify-start items-center',
                isHover ? 'border-purple rounded-[4px]' : 'border-gray-very-light',
              )}
            >
              Type your question
            </div>
          </>
        )}

        {!!value && (
          <>
            <QuestionTypes
              question={question}
              togglePopover={() => {
                togglePopover();
              }}
              onSaveQuestion={onSave}
              isPopoverOpen={isPopoverOpen}
              optionalPropsPopover={optionalPropsPopover}
              setOptionalPropsPopover={setOptionalPropsPopover}
              updateQuestion={(qsType: string) => {
                handleChangeQuestionType(qsType);
              }}
              closePopups={closePopups}
              collapsedSubQuestions={collapsedSubQuestions}
              setCollapsedSubQuestions={setCollapsedSubQuestions}
              filterRepeatedInput={filterRepeatedInput}
              filterTitleInput={filterTitleInput}
              filterFileInput={filterFileInput}
              handleOpenPopup={togglePopover}
              setLocalQsType={setLocalQsType}
              isStandardForm={isStandardForm}
            />
          </>
        )}
        {isHover && value && (
          <div
            className={clsx(
              'border-none absolute top-[5px] -right-7',
              isHover ? 'absolute' : 'hidden',
            )}
          >
            <ThreeDotsDropdown
              classes={{
                container: '',
                content: 'w-fit absolute border-none !shadow-none !bg-white -top-[9px] -right-6 ',
              }}
              dotIcon={<SvgIcon component={MoreIcon} className='cursor-pointer ' />}
              customContent={
                <div className='flex pr-2 -mr-[107px]  gap-x-2 rounded-lg [&>svg]:bg-white'>
                  <Tooltip
                    position='top'
                    arrowClass='!top-[24px] '
                    className='w-max'
                    text={'Delete'}
                  >
                    <FiTrash
                      onClick={() => {
                        onRemoveQuestionApi
                          ? onRemoveQuestionApi({ ...question, questionType: '' })
                          : handleRemoveQuestionApi({ ...question, questionType: '' });
                      }}
                      className='text-blue-normal border-2 border-blue-normal w-8 h-8 p-1 rounded-[12px]'
                    />
                  </Tooltip>
                  <Tooltip
                    position='top'
                    arrowClass='!top-[24px]'
                    className='w-max'
                    text={'Items list'}
                  >
                    <FiGrid
                      onClick={() => togglePopover()}
                      className='text-blue-normal border-2 border-blue-normal w-8 h-8 p-1 rounded-[12px]'
                    />
                  </Tooltip>
                  <Tooltip
                    position='top'
                    arrowClass='!top-[24px]'
                    className='w-max'
                    text={'Duplicate'}
                  >
                    <FiCopy
                      onClick={() => {
                        onDuplicateQuestion
                          ? onDuplicateQuestion(Number(question?.id))
                          : handleDuplicateQuestion(Number(question?.id));
                      }}
                      className='text-blue-normal border-2 border-blue-normal w-8 h-8 p-1 rounded-[12px]'
                    />
                  </Tooltip>
                </div>
              }
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Question;
