import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { addParticipant, retrieveCenters } from 'services/api/study';
import { useParams } from 'react-router-dom';

export const useAddParticipant = () => {
  const { studyId } = useParams();
  const intl = useIntl();
  const queryClient = useQueryClient();

  const { refetch } = useQuery({
    queryKey: ['centers'],
    queryFn: () => studyId && retrieveCenters(studyId),
  });

  const mutation = useMutation({
    mutationFn: addParticipant,
    onSuccess: () => {
      toast.success(intl.formatMessage({ id: 'patient.participantAddMsg' }));
      queryClient.invalidateQueries(['addParticipant']);
      refetch();
    },
    onError(e: any) {
      toast.error(e?.response?.data?.message);
    },
  });

  return mutation;
};
