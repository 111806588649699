import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@tanstack/react-query';
import { getPermissions, createRole, editRole, deleteRole } from 'services/api/users';
import { ICreateRole, IEditRole } from 'types/roles';
import { toast } from 'react-toastify';
interface IProps {
  rolesRefetch?: any;
}
const useRoles = ({ rolesRefetch = () => {} }: IProps) => {
  const [selectedRole, setSelectedRole] = useState<any>(null);

  const { studyId } = useParams();

  const { data: permissions } = useQuery({
    queryKey: ['roles'],
    queryFn: () => getPermissions(),
    refetchOnMount: 'always',
  });
  // const {
  //   data: roles,
  //   isLoading: isRolesLoading,
  //   refetch: rolesRefetch,
  // } = useQuery({
  //   queryKey: ['user-roles'],
  //   queryFn: () => getRoles('CLINICAL_TRIAL', studyId),
  //   refetchOnWindowFocus: 'always',
  // });

  const { mutate: createRoleMutation } = useMutation<ICreateRole, unknown, any, unknown>(
    (data: ICreateRole) => createRole(data),
    {
      onSuccess: () => {
        rolesRefetch();
      },
      onError(error: any) {
        toast.error(error?.response?.data?.message);
      },
    },
  );

  const { mutate: editRoleMutation } = useMutation<any, unknown, IEditRole, unknown>(
    (data: IEditRole) => editRole(data),
    {
      onSuccess() {
        rolesRefetch();
      },
      onError(error: any) {
        toast.error(error?.response?.data?.message);
      },
    },
  );

  const { mutate: deleteMutationRole } = useMutation<any, unknown, number | string, unknown>(
    (id: number | string) => deleteRole(id),
    {
      onSuccess: () => {
        rolesRefetch();
      },
      onError(error: any) {
        toast.error(error?.response?.data?.message);
      },
    },
  );

  const handleCreateNewRole = (
    newRoleName: string,
    category: string,
    selectedPermissions: number[],
  ) => {
    const payload: any = {
      name: newRoleName,
      category: category,
      type: 'STUDY',
      studyId: studyId,
      permissionIds: selectedPermissions,
    };

    createRoleMutation(payload);
  };

  const handleEditRole = (
    id: number,
    newRoleName: string,
    category: string,
    selectedPermissions: number[],
  ) => {
    const payload: any = {
      id,
      name: newRoleName,
      category: category,
      type: 'STUDY',
      studyId: studyId,
      permissionIds: selectedPermissions,
    };

    editRoleMutation(payload);
  };

  const handleDeleteRole = (id: number) => {
    deleteMutationRole(id);
  };

  return {
    permissions,
    handleCreateNewRole,
    handleEditRole,
    selectedRole,
    setSelectedRole,
    handleDeleteRole,
  };
};

export default useRoles;
