import { FC } from 'react';
import { FileUpload, TextInput } from 'components/common';
import { FiFilePlus } from 'react-icons/fi';
import { useIntl } from 'react-intl';

interface IProps {
  actionType: string;
  handleUploadAttachment?: any;
  onDeleteAttachment?: any;
  handleBlur?: any;
  setAgreementText?: any;
  setDeclinationText?: any;
  agreementText?: any;
  declinationText?: any;
  selectedTask?: any;
}
const ActionTypeBody: FC<IProps> = ({
  actionType,
  handleUploadAttachment,
  onDeleteAttachment,
  handleBlur,
  setAgreementText,
  setDeclinationText,
  agreementText,
  declinationText,
  selectedTask,
}: IProps) => {
  const intl = useIntl();
  const renderInputs = () => {
    switch (actionType) {
      case 'DOWNLOAD_REQUIRED':
        return (
          <div>
            <FileUpload
              btnClass='bg-white !pl-0 [&>label]:text-blue-oil [&>label]:text-sm [&>label]:font-light'
              noSizeLabel={true}
              noLabel={true}
              accept='.odf,.pdf,.txt,.jpeg,.heic,.png,.doc,.docx,.jpg,.webp'
              fileIcon={<FiFilePlus className='text-blue-oil w-5 h-5' />}
              attachLabel={intl.formatMessage({ id: 'study-builder.flows-task-attach-document' })}
              onFileUpload={(file: any) => handleUploadAttachment(file, true)}
              onDeleteFile={onDeleteAttachment}
              noSize={true}
              onEndUpload={true}
            />
            {selectedTask?.attachments?.map((attachment: string, index: number) => (
              <div key={index} className='flex items-center mt-4'>
                <span className='text-sm text-blue-oil font-light'>
                  {attachment?.split('/').pop()}
                </span>
                <svg
                  className='ml-2 cursor-pointer'
                  onClick={() => onDeleteAttachment(attachment)}
                  xmlns='http://www.w3.org/2000/svg'
                  width='12'
                  height='12'
                  viewBox='0 0 8 8'
                  fill='none'
                >
                  <path
                    d='M6.65625 1L0.999395 6.65685'
                    stroke='#012C54'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M1 1L6.65685 6.65686'
                    stroke='#012C54'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
              </div>
            ))}
          </div>
        );
      case 'APPROVAL_REQUIRED':
        return (
          <div>
            <FileUpload
              btnClass='bg-white !pl-0 [&>label]:text-blue-oil [&>label]:text-sm [&>label]:font-light'
              noSizeLabel={true}
              noLabel={true}
              accept='.odf,.pdf,.txt,.jpeg,.heic,.png,.doc,.docx,.jpg,.webp'
              fileIcon={<FiFilePlus className='text-blue-oil w-5 h-5' />}
              attachLabel={intl.formatMessage({ id: 'study-builder.flows-task-attach-document' })}
              onFileUpload={(file: any) => handleUploadAttachment(file, true)}
              onDeleteFile={onDeleteAttachment}
              noSize={true}
              onEndUpload={true}
            />
            {selectedTask?.attachments?.map((attachment: string, index: number) => (
              <div key={index} className='flex items-center mt-4'>
                <span className='text-sm text-blue-oil font-light'>
                  {attachment?.split('/').pop()}
                </span>
                <svg
                  className='ml-2 cursor-pointer'
                  onClick={() => onDeleteAttachment(attachment)}
                  xmlns='http://www.w3.org/2000/svg'
                  width='12'
                  height='12'
                  viewBox='0 0 8 8'
                  fill='none'
                >
                  <path
                    d='M6.65625 1L0.999395 6.65685'
                    stroke='#012C54'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M1 1L6.65685 6.65686'
                    stroke='#012C54'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
              </div>
            ))}
          </div>
        );
      case 'CHOOSE_RESPONSE':
        return (
          <>
            <FileUpload
              btnClass='bg-white !pl-0 [&>label]:text-blue-oil [&>label]:text-sm [&>label]:font-light'
              noSizeLabel={true}
              noLabel={true}
              accept='.odf,.pdf,.txt,.jpeg,.heic,.png,.doc,.docx,.jpg,.webp'
              fileIcon={<FiFilePlus className='text-blue-oil w-5 h-5' />}
              attachLabel={intl.formatMessage({ id: 'study-builder.flows-task-attach-document' })}
              onFileUpload={(file: any) => handleUploadAttachment(file, true)}
              onDeleteFile={onDeleteAttachment}
              noSize={true}
              onEndUpload={true}
            />
            {selectedTask?.attachments?.map((attachment: string, index: number) => (
              <div key={index} className='flex items-center mt-4'>
                <span className='text-sm text-blue-oil font-light'>
                  {attachment?.split('/').pop()}
                </span>
                <svg
                  className='ml-2 cursor-pointer'
                  onClick={() => onDeleteAttachment(attachment)}
                  xmlns='http://www.w3.org/2000/svg'
                  width='12'
                  height='12'
                  viewBox='0 0 8 8'
                  fill='none'
                >
                  <path
                    d='M6.65625 1L0.999395 6.65685'
                    stroke='#012C54'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M1 1L6.65685 6.65686'
                    stroke='#012C54'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
              </div>
            ))}
            <div className='flex justify-between items-center mt-2'>
              <TextInput
                label={intl.formatMessage({
                  id: 'study-builder.flows-task-agreement-text',
                })}
                className='w-[309px] [&>label]:font-light '
                control={{
                  value: agreementText,
                  onChange: (e) => setAgreementText(e.target.value),
                }}
                name='task-agreementText'
                placeholder='I agree'
                inputClassName='border-blue-oil !p-[6px] !rounded-[2px]'
                noTranslation={true}
                requiredStar={true}
                onBlur={() => handleBlur('agreementText', agreementText)}
              />
              <TextInput
                label={intl.formatMessage({
                  id: 'study-builder.flows-task-declination-text',
                })}
                className='w-[309px] [&>label]:font-light'
                control={{
                  value: declinationText,
                  onChange: (e: any) => setDeclinationText(e.target.value),
                }}
                name='task-declinationText'
                placeholder='I do not agree'
                inputClassName='border-blue-oil !p-[6px] !rounded-[2px]'
                noTranslation={true}
                requiredStar={true}
                onBlur={() => handleBlur('declinationText', declinationText)}
              />
            </div>
          </>
        );
      case 'ATTESTATION_REQUIRED':
        return (
          <>
            <FileUpload
              btnClass='bg-white !pl-0 [&>label]:text-blue-oil [&>label]:text-sm [&>label]:font-light'
              noSizeLabel={true}
              noLabel={true}
              accept='.odf,.pdf,.txt,.jpeg,.heic,.png,.doc,.docx,.jpg,.webp'
              fileIcon={<FiFilePlus className='text-blue-oil w-5 h-5' />}
              attachLabel={intl.formatMessage({ id: 'study-builder.flows-task-attach-document' })}
              onFileUpload={(file: any) => handleUploadAttachment(file, true)}
              onDeleteFile={onDeleteAttachment}
              noSize={true}
              onEndUpload={true}
            />
            {selectedTask?.attachments?.map((attachment: string, index: number) => (
              <div key={index} className='flex items-center mt-4'>
                <span className='text-sm text-blue-oil font-light'>
                  {attachment?.split('/').pop()}
                </span>
                <svg
                  className='ml-2 cursor-pointer'
                  onClick={() => onDeleteAttachment(attachment)}
                  xmlns='http://www.w3.org/2000/svg'
                  width='12'
                  height='12'
                  viewBox='0 0 8 8'
                  fill='none'
                >
                  <path
                    d='M6.65625 1L0.999395 6.65685'
                    stroke='#012C54'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M1 1L6.65685 6.65686'
                    stroke='#012C54'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
              </div>
            ))}
            <div className='flex justify-between items-center mt-2'>
              <TextInput
                label={intl.formatMessage({
                  id: 'study-builder.flows-task-agreement-text',
                })}
                className='w-[309px] [&>label]:font-light '
                control={{
                  value: agreementText,
                  onChange: (e) => setAgreementText(e.target.value),
                }}
                name='task-agreementText'
                placeholder='I agree'
                inputClassName='border-blue-oil !p-[6px] !rounded-[2px]'
                noTranslation={true}
                requiredStar={true}
                onBlur={() => handleBlur('agreementText', agreementText)}
              />
              <TextInput
                label={intl.formatMessage({
                  id: 'study-builder.flows-task-declination-text',
                })}
                className='w-[309px] [&>label]:font-light'
                control={{
                  value: declinationText,
                  onChange: (e: any) => setDeclinationText(e.target.value),
                }}
                name='task-declinationText'
                placeholder='I do not agree'
                inputClassName='border-blue-oil !p-[6px] !rounded-[2px]'
                noTranslation={true}
                requiredStar={true}
                onBlur={() => handleBlur('declinationText', declinationText)}
              />
            </div>
          </>
        );
      default:
        return null;
    }
  };
  return <div>{renderInputs()}</div>;
};

export default ActionTypeBody;
