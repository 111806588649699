import { IUser } from 'types/user';
import { create } from 'zustand';

interface IParticipantData {
  id: number;
  userProfile: IUser;
  dateOfBirth: string;
  weight: number;
  height: number;
  bloodType: string;
  gender: string;
  doctor: null;
  createdAt: string;
  updatedAt: string;
  emailStatus: string;
  numberOfStep: [];
  heartRate: [];
  selectedQuestionnaire?: any;
  setSelectedQuestionnaire?: any;
}

interface ParticipantStore {
  selectedQuestionnaire?: any;
  setSelectedQuestionnaire?: any;
  participantData: IParticipantData | any;
  participant: number | string;
  scoreResult: number;
  setParticipantData: (participantData: any) => void;
  setParticipant: (participant: number | string) => void;
  setScoreResult: (scoreResult: number) => void;
}

export const useParticipantStore = create<ParticipantStore>((set) => ({
  participantData: {},
  participant: '',
  scoreResult: 0,
  setParticipantData(itemData) {
    set(() => ({ participantData: itemData }));
  },
  setParticipant(itemData) {
    set(() => ({ participant: itemData }));
  },
  setSelectedQuestionnaire(selectedQuestionnaire: any) {
    set(() => ({ selectedQuestionnaire }));
  },
  setScoreResult(scoreResult: number) {
    set(() => ({ scoreResult }));
  },
}));
