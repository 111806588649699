import { useEffect, useState } from 'react';
import { IFolder } from 'types/folder';
import QuestionnaireButton from './QuestionnaireButton';
import Folder from './Folder';
import { IStepWithStatus, IStepStatus } from 'types/step';
import { useQueryQuestionStore } from 'store/queryQuestionStore';
import { useIntl } from 'react-intl';

interface SubFolder extends Partial<IFolder> {
  id?: number;
  name: string;
  status: IStepStatus;
  selectedQuestionnaire: number | string;
  setSelectedQuestionnaire: React.Dispatch<React.SetStateAction<number | string>>;
  setSelectedFolder?: () => void;
  setIsParentOpen?: (isParentOpen: boolean) => void;
  option: IStepWithStatus;
  isQueries?: boolean;
}

export default function SubFolder({
  name,
  status,
  questionnaires,
  selectedQuestionnaire,
  setSelectedQuestionnaire,
  setSelectedFolder,
  option,
  isQueries,
}: SubFolder) {
  const intl = useIntl();
  const { queryQuestions } = useQueryQuestionStore();
  const [isOpen, setIsOpen] = useState(
    !!questionnaires?.find((questionnaire) => questionnaire.id === selectedQuestionnaire),
  );

  useEffect(() => {
    selectedQuestionnaire && setIsOpen(
      !!questionnaires?.find((questionnaire) => `${questionnaire.id}` === `${selectedQuestionnaire}`),
    );
  }, [questionnaires, selectedQuestionnaire]);

  function handleToggleFolder() {
    setSelectedFolder?.();
    setIsOpen((prevValue) => !prevValue);
  }

  const checkResolvedFolder = (option: any) => {
    let allResolved = true;
    if (option?.folder?.folders && queryQuestions?.length > 0) {
      queryQuestions?.map((item: any) => {
        const foundFolder = option?.folder?.folders.find((fld: any) => fld?.folder?.parentFolderId === item?.folder?.id)
        foundFolder?.folder?.questionnaires &&  foundFolder?.folder?.questionnaires?.map((quest: any) => {
          const foundQuestionaire = quest?.questions.find((qd: any) => qd?.id === item?.baseQuestionId);
          if (foundFolder && foundQuestionaire && !item.resolved) {
            allResolved = false;
          }
        });

      });
    }
    return allResolved;
  }

  return (
    <div data-type='folder'>
      <Folder 
        isOpen={isOpen} 
        title={name} 
        isQueries={isQueries}
        isResolved={checkResolvedFolder(option)}
        onClick={handleToggleFolder}
      />
      {isOpen && !!questionnaires?.length && (
        <div className='pl-7 mt-[0.7rem] flex flex-col gap-[0.7rem] mb-4 pr-6'>
          {questionnaires.map((questionnaire) =>
            'type' in questionnaire ? (
              <QuestionnaireButton
                id={questionnaire.id}
                status={status}
                option={option}
                isQueries={isQueries}
                selectedQuestionnaire={selectedQuestionnaire}
                setSelectedQuestionnaire={setSelectedQuestionnaire}
                type={questionnaire.type}
                title={questionnaire.title}
                key={questionnaire.id}
              />
            ) : (
              <QuestionnaireButton
                id={questionnaire.id}
                status={status}
                option={option}
                isQueries={isQueries}
                key={questionnaire.id}
                selectedQuestionnaire={selectedQuestionnaire}
                setSelectedQuestionnaire={setSelectedQuestionnaire}
                type='ID_VERIFICATION'
                title={intl.formatMessage({ id: 'step.IDVerification' })}
              />
            ),
          )}
        </div>
      )}
    </div>
  );
}
