import { Calendar, Select } from 'components/common';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import { FormattedMessage, useIntl } from 'react-intl';

const NestedOption = ({ startDate, type, methods, setIsDatePickerOpen }: any) => {
  const intl = useIntl();
  const [weeklyOnDays, setWeeklyOnDays] = useState<string[]>(['']);
  const [customRepeat, setCustomRepeat] = useState('DAY');
  const [weekdays, setWeekDays] = useState([
    {
      day: 'S',
      value: 'SUNDAY',
      disable: true,
      select: false,
    },
    {
      day: 'M',
      value: 'MONDAY',
      select: true,
    },
    {
      day: 'T',
      value: 'TUESDAY',
      select: false,
    },
    {
      day: 'W',
      value: 'WEDNESDAY',
      select: false,
    },
    {
      day: 'T',
      value: 'THURSDAY',
      select: false,
    },
    {
      day: 'F',
      value: 'FRIDAY',
      select: false,
    },
    {
      day: 'S',
      value: 'SATURDAY',
      disable: true,
      select: false,
    },
  ]);

  const values = methods?.watch();

  useEffect(() => {
    if (values?.repeat && values?.repeat !== '') {
      setCustomRepeat(values?.repeat);
    }
    if (values?.weeklyOnDays && values?.weeklyOnDays.length > 0) {
      setWeeklyOnDays(values?.weeklyOnDays);
    }
  }, [values]);

  useEffect(() => {
    if (weeklyOnDays && weeklyOnDays?.length > 0) {
      setWeekDays((prevWeekdays) => {
        const newArr = prevWeekdays.map((obj) => {
          if (weeklyOnDays?.find((e: any) => e === obj.value)) {
            return { ...obj, select: true };
          }
          return obj;
        });
        return newArr;
      });
    }
  }, [weeklyOnDays]);

  const handleSelect = (_ob: any, index: number) => {
    setWeekDays((prevWeekdays) => {
      const newArr = prevWeekdays.map((obj, i) => {
        if (i === index) {
          return { ...obj, select: !obj.select };
        }
        return obj;
      });
      const newList = newArr.filter((e: any) => e.select);
      setWeeklyOnDays(newList.map((e) => e.value));
      methods?.setValue('weeklyOnDays', newList.map((e) => e.value));
      return newArr;
    });
  };

  return (
    <div className='p-2 border border-blue-ocean-deep rounded-[10px] bg-white w-[23.813rem]'>
      {type === 'Custom' && (
        <div className='flex items-end gap-3 mb-3'>
          <p className='text-sm text-gray-dark'><FormattedMessage id="appointment.start" /></p>
          <input type='text' value={format(new Date(startDate), 'dd/MM/yyyy')} name="startDate" readOnly placeholder={format(new Date(startDate), 'dd/MM/yyyy')} className='h-[30px] w-[6.938rem] text-gray-dark rounded text-center text-sm border border-gray-dark p-1 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none' />
        </div>
      )}
      <div className='flex gap-4 items-end'>
        <div className='flex gap-2 items-end'>
          <label className='text-sm leading-[22px] text-gray-dark'>
            <FormattedMessage id='appointment.repeatEvery' />
          </label>
          <input
            value={values?.frequency}
            onChange={(e) => methods.setValue('frequency', e?.target?.value)}
            name='frequency'
            type='number'
            onBlur={(e: any) => e?.target?.value ? methods.setValue('frequency', e?.target?.value) : methods.setValue('frequency', 1)}
            placeholder='1'
            className='w-[37px] h-[30px] rounded text-black text-center text-sm border-[1px] border-purple px-2 py-1 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none'
          />
        </div>
        {type === 'Custom' ? ( 
          <Select
            customControl={{ value: customRepeat, onChange: (e: any) => {
              methods.setValue('repeat', e)
              if (e === 'WEEK') {
                methods.setValue('weeklyOnDays', ['MONDAY']);
              }
              setCustomRepeat(e);
            }}}
            name='customRepeat'
            options={[
              { label: intl.formatMessage({ id: 'appointment.day' }) , value: 'DAY' },
              { label: intl.formatMessage({ id: 'appointment.week' }) , value: 'WEEK' },
              { label: intl.formatMessage({ id: 'appointment.month' }) , value: 'MONTH' },
              { label: intl.formatMessage({ id: 'appointment.year' }) , value: 'YEAR' },
            ]}
            noTranslation={true}
            className='bg-white '
            mainClass='!rounded h-[1.875rem] w-[4.875rem] !border-b-blue-ocean-deep'
            buttonClassName='!gap-x-1 !px-3 py-1 text-blue-ocean-deep leading-[15px]'
            selectOptClass='!text-sm font-semibold !font-normal !w-max !overflow-visible'
            optionsClassName='!text-sm font-semibold !px-2 !py-1 !font-normal hover:!bg-blue-ocean-deep hover:text-white'
            optWrapperClassName='border border-blue-ocean-deep py-[0.688rem] !rounded-[10px]'
          />
        ) : (
          <p className='text-gray-dark font-normal font-sans text-sm '>
            {type === 'Daily'
              ? 'day(s)'
              : type === 'Weekly'
                ? 'week(s)'
                : type === 'Monthly'
                  ? 'month(s)'
                  : type === 'Yearly'
                    ? 'year(s)'
                    : ''}
          </p>
        )}
      </div>
      {(type === 'Weekly' || customRepeat === 'WEEK') && (
        <div>
          <ul className='flex gap-[2px] mt-4 ml-4'>
            {weekdays.map((day, i) => (
              <li
                className={`flex text-sm font-sans font-normal rounded-full ml-1.5 items-center justify-center w-[24px] h-[24px] border-[0.5px] ${day?.disable ? 'bg-gray-light text-white' : 'border-blue-ocean-deep text-blue-ocean-deep'} ${day.select ? 'bg-purple text-white border-none' : weeklyOnDays?.length === 0 ? 'border-error-feedback text-error-feedback' : ''}`}
                onClick={() => handleSelect(day, i)}
                key={i}
              >
                {day.day}
              </li>
            ))}
          </ul>
        </div>
      )}
      <div className='flex items-end gap-3 mt-4'>
        <p className='text-sm text-gray-dark'>
          <FormattedMessage id='end' />
        </p>
        <Calendar
          customControl={{
            value: values?.endDate,
            onChange: (e) => {
              methods?.setValue('endDate', e);
            },
          }}
          name='endDate'
          label=''
          setIsDatePickerOpen={setIsDatePickerOpen}
          className='hello no-border-mui placeholder:text-gray-dark border !my-0 border-gray-dark !rounded [&>div]!flex-reverse [&>div>div>input]:!text-left [&>div>div>input]:!py-1 [&>div>div>input]:!text-sm'
          placeholder='xx / xx / xxxx'
          minDate={startDate}
          type='date'
          slots={{
            openPickerIcon: FiChevronDown,
          }}
        />
      </div>
    </div>
  );
};

export default NestedOption;
