import React from 'react';
import clsx from 'classnames';
import { IQuestion } from 'types/questionnaire';
import { useQuestionnaireStore } from 'store/QuestionnaireStore';
import { Radio } from 'components/common';

interface IProps {
  showScore?: boolean;
  onSaveQuestion: any;
  question: IQuestion;
  setOptionalPropsPopover: any;
  questionnairy?: any;
}
// let localInputType = '';
const TextInputProps: React.FC<IProps> = ({
  onSaveQuestion,
  question,
  setOptionalPropsPopover,
}: IProps) => {
  const { addQuestionError } = useQuestionnaireStore();
  // useEffect(() => {
  //   onSaveQuestion({
  //     optionalProps: { inputType: localInputType },
  //   });
  // }, []);
  return (
    <div
      className={clsx(
        'w-[175px] absolute z-10 bg-white rounded-[12px] top-0 mt-6 mr-0 -right-[10px] border-[1px] border-purple',
        addQuestionError
          ? '[&>*]:!text-error-feedback [&>button]:!border-error-feedback border-error-feedback'
          : 'border-blue-normal',
      )}
    >
      <div className='flex justify-start items-center flex-col'>
        <div className='w-full pt-2 pb-2 px-3 max-h-[211px] overflow-y-auto'>
          <Radio
            options={[
              { label: 'Short', value: 'SHORT' },
              { label: 'Long', value: 'LONG' },
            ]}
            optionsClassName='w-full flex-col [&>div]:w-full text-left'
            control={{
              value: question?.inputType || 'SHORT',
              onChange: (e: any) => {
                console.log('call onchange: ', e);
                // localInputType = e;
                onSaveQuestion({
                  optionalProps: {
                    inputType: e,
                  },
                });
                setOptionalPropsPopover(false);
              },
            }}
            noOnchangeCall={true}
            defaultValue={question?.inputType}
            selectedValue={question?.inputType}
            labelClassName='text-sm text-base-black w-full max-w-[80px] min-w-[75px] truncate'
            className={clsx('border-none !py-1.5 !pl-0 !pr-4 gap-x-[4px]')}
            wrapperClassName='[&>div]:px-[2px] relative -left-[1px]'
            radioClassName='!w-4 !h-4 gap-x-2'
          />
        </div>
      </div>
    </div>
  );
};

export default TextInputProps;
