import React, { useState, useEffect } from 'react';
import CreatableSelect from 'react-select/creatable';
import { FieldValues } from 'react-hook-form';
import { FieldErrors } from '../../../types/form';
import clsx from 'classnames';

interface OptionType {
  value: string;
  label: string;
}
interface IProps {
  label?: string;
  name?: string;
  control: {
    onChange?: (value: any) => void;
    onBlur?: () => void;
    value?: any;
  };
  options: OptionType[];
  errors?: FieldErrors<FieldValues>;
  placeholder?: string;
  className?: {
    wrapper?: string;
    controlMinHeight?: string;
  };
  defaultValue?: any;
  isRequired?: boolean;
}

const MultiSelect: React.FC<IProps> = (props: IProps) => {
  const { label, isRequired, name, options, placeholder, className, defaultValue } = props;
  const [selectedOptions, setSelectedOptions] = useState<OptionType[] | null>(null);
  const [newOptions, setNewOptions] = useState<OptionType[]>(options);

  useEffect(() => {
    if (props?.control?.onChange) {
      props?.control?.onChange(selectedOptions);
    }
  }, [selectedOptions]);

  useEffect(() => {
    if (defaultValue?.length && props?.control?.onChange) {
      if (defaultValue.length === 1 && defaultValue[0] === null) {
        console.log('call useeet', defaultValue);
        props?.control?.onChange([]);
      } else {
        setNewOptions([{ label: defaultValue?.[0], value: defaultValue?.[0] }]);
        setSelectedOptions(defaultValue);
        props?.control?.onChange(defaultValue);
      }
    }
  }, []);
  console.log('multiselect props: ', selectedOptions);
  return (
    <div className={clsx('  flex justify-start items-start flex-col', className?.wrapper)}>
      {label ? (
        <label
          htmlFor={name}
          className='pl-1 text-blue-ocean-deep font-semibold inline-block leading-6 mb-2'
        >
          {label}
        </label>
      ) : null}
      <CreatableSelect
        isClearable
        isMulti
        placeholder={placeholder}
        name={name}
        options={newOptions}
        value={newOptions.filter((el: any) => selectedOptions?.includes(el.value))}
        className='w-full'
        onCreateOption={(str: any) => {
          const newOption = { label: str, value: str };
          setNewOptions([newOption, ...newOptions]);
          setSelectedOptions([...(selectedOptions || []), newOption.value]); // Select the new option
        }}
        styles={{
          placeholder: (base) => ({
            ...base,
            textAlign: 'left',
          }),
          control: (base) => ({
            ...base,
            border:
              !selectedOptions?.length && isRequired ? '1px solid #E07A7A' : '1px solid #CACACA',
            borderRadius: '6px',
            minHeight: className?.controlMinHeight,
          }),
          valueContainer: (base) => ({
            ...base,
            height: '100%',
            border: 'none',
          }),
          multiValue: (base) => ({
            ...base,
            background: '#fff',
            border: '1px solid #CACACA',
            borderRadius: '0.5rem',
            color: '#737373',
          }),
          multiValueLabel: (styles) => ({
            ...styles,
            color: '#737373',
          }),
          multiValueRemove: (styles) => ({
            ...styles,
            ':hover': {
              backgroundColor: 'transparent',
            },
          }),
        }}
        onChange={(option: any) => {
          if (option === null) {
            // onChange(null);
            setSelectedOptions(null);
            return;
          }
          console.log('call onchange first: ');
          setSelectedOptions(option.map((el: any) => el.value));
          //   onChange(option.map((el) => el.value));
        }}
      />
    </div>
  );
};

export default MultiSelect;
