export const INITIAL_EMPTY_QUESTIONNAIRE = {
  conditionForm: [
    {
      comparisonValue: '',
      conditional: '',
      operator: '',
      question: '',
      section: '',
      subsection: '',
    },
  ],
  description: '',
  inputPlaceHolder: '',
  isRequired: false,
  maxCharacters: '',
  questionTitle: '',
  variableName: '',
};

export const isConsentQuestionnaire = (name: string | undefined) => {
  return ['HEADER', 'IMAGE', 'INSTRUCTION'].includes(name ?? '');
};
