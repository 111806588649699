import React, { useState, useEffect } from 'react';
import clsx from 'classnames';
import { IQueryDataItem, ITableProps } from './types';
import { format } from 'date-fns';
import { useParticipantStore } from 'store/participantStore';
import { useQuestionnaireStore } from 'store/QuestionnaireStore';
import { useCentersStore } from 'store/centers';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { alphaSort } from 'utils/array';

const QueryTable: React.FC<ITableProps> = (props: ITableProps) => {
  const { headerItems, columns, onRowClick } = props;
  const [data, setData] = useState<IQueryDataItem[]>(columns);
  const [sortedData, setSortedData] = useState<IQueryDataItem[]>();
  const { participant } = useParticipantStore();
  const { selectedFolder } = useQuestionnaireStore();
  const { selectedCenter } = useCentersStore();

  useEffect(() => {
    setData(columns);
  }, [columns]);

  useEffect(() => {
    setSortedData(data);
  }, [data]);

  useEffect(() => {
    let filteredList = data;
    filteredList = selectedCenter !== 'all' ? filteredList?.filter((item) => `${item?.center?.id}` === `${selectedCenter}`) : filteredList;
    filteredList = participant !== 'all' ? filteredList?.filter((item) => `${item?.patientStep?.patientId}` === `${participant}`) : filteredList;
    filteredList = selectedFolder !== 'all' ? filteredList?.filter((item) => `${item?.folder?.id}` === `${selectedFolder}`) : filteredList;
    setSortedData(filteredList)
  }, [selectedCenter, participant, selectedFolder]);

  const handleSort = (type: string, sortKey: string) => {
    if (sortKey && type){
      switch(sortKey) {
        case 'id':
          setSortedData(sortedData?.sort((a: any, b: any) => type === 'up' ?  a.patientStep?.patientId - b.patientStep?.patientId : b.patientStep?.patientId - a.patientStep?.patientId).map((a)=> a))
          break;
        case 'visit':
          setSortedData(alphaSort(sortedData, type, sortKey));
          break;
        case 'center':
          setSortedData(alphaSort(sortedData, type, 'center', 'name'));
          break;
        case 'date':
          setSortedData(sortedData?.sort((a: any, b: any) => type === 'up' ?  
            new Date(a.date).getTime() - new Date(b.date).getTime() : 
            new Date(b.date).getTime() - new Date(a.date).getTime()
          ).map((a)=> a))
          break;
        default:
          null;
      }
    }
  };

  return (
    <div className='h-max'>
      <table className='min-w-full pl-4'>
        <thead className=''>
          <tr>
            {headerItems.map((r) => (
              <th
                key={r.name}
                className={clsx(
                  'text-left px-4 group cursor-pointer h-14 text-blue-ocean-deep text-sm font-medium',
                  r.width,
                )}
              >
                <div className='flex items-center'>
                  {r.title}
                  {(r.title !== 'Status' && r.title !== 'Comment') && <div className='group-hover:block ml-1 hidden'>
                    <FiChevronUp onClick={() => handleSort('up', r?.name || '')} className='hover:stroke-1'/>
                    <FiChevronDown onClick={() => handleSort('down', r.name || '')}  className='hover:stroke-1' />
                  </div>}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {sortedData?.map((item, i) => (
            <tr
              onClick={() => onRowClick?.(item)}
              key={item.id}
              className={`${i%2 === 0 ? 'bg-white' : 'bg-gray-100' } align-text-top group hover:shadow hover:drop-shadow-xl hover:bg-white cursor-pointer border-b-1 border-b-gray-very-light`}
            >
              <td className='px-4 text-gray-dark text-sm py-2.5 text-left table-cell'>
                <p>{item.patient}</p>
              </td>
              <td className='px-4 text-left text-gray-dark text-sm py-2.5 table-cell'>
                <div className={`${item.status !== 'RESOLVED' ? 'bg-error-feedback ' : 'bg-gray-light '} h-2 w-2 rounded-full`} />
              </td>
              <td className='px-4 text-left text-gray-dark text-sm py-2.5 table-cell'>
                {item.comment}
                <div className='hidden pl-2 group-hover:block'>
                  {item?.replies?.length > 0 && item?.replies?.map((reply: any) => (
                    <p className='text-gray-medium' key={reply?.id}>{reply?.comment}</p>
                  ))}
                </div>
              </td>
              <td className='px-4 text-left text-gray-dark text-sm py-2.5 table-cell'>
                {item.visit}
              </td>
              <td className='px-4 text-left text-gray-dark text-sm py-2.5 table-cell'>
                {item?.center?.name}
              </td>
              <td className='px-4 text-left text-gray-dark text-sm py-2.5 table-cell'>
                {item?.date && format(new Date(item?.date), 'dd/MM/yyyy') || ''}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default QueryTable;
