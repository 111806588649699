import { axiosClient } from 'config/axios';
import { ICountry } from 'types/countries';

// import { IPermissionItem, ICreateRole, IEditRole, IRoleCard } from 'types/roles';
// import { throwError } from 'utils/throwError';

export const getCountries = async () => {
  const { data } = await axiosClient.get<ICountry[]>('countries');
  return data;
};
