import React, { useState, useEffect, useCallback, useRef } from 'react';
import ParticipantsList from './ParticipantsList';
import { fetchStudyById, retrieveCenters } from 'services/api/study';
import { useCentersStore } from 'store/centers';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { FiFilter, FiLoader } from 'react-icons/fi';
import { useParticipantStore } from 'store/participantStore';
import { IStudy } from 'types/study';
import { FormattedMessage } from 'react-intl';
import { Button } from '../../../components/common';
import FilterForm from './FilterForm';
import { useOutsideClick } from '../../../hooks/useOutsideClick';

export function StudyParticipants() {
  const { studyId } = useParams();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [status, setStatus] = useState('');
  const [centersData, setCentersData] = useState([]);
  const containerRef = useRef<HTMLDivElement>(null);
  const { centers, selectedCenter } = useCentersStore();
  const { setParticipant } = useParticipantStore();

  const { data: loadedStudy } = useQuery<IStudy>({
    queryKey: ['study'],
    queryFn: () => fetchStudyById(studyId as string),
  });

  const isFreezed = loadedStudy?.status === 'FREEZE';

  const { isLoading, refetch } = useQuery({
    queryKey: ['centers'],
    queryFn: () => studyId && retrieveCenters(studyId),
  });

  useEffect(() => {
    setParticipant('all');
  }, []);

  useEffect(() => {
    !isLoading && studyId && refetch();
  }, [studyId]);

  useEffect(() => {
    setCentersData(
      selectedCenter == 'all'
        ? centers.sort().reverse()
        : centers
            ?.filter((item: any) => item.id == selectedCenter)
            .sort()
            .reverse() ?? [],
    );
  }, [centers, selectedCenter, status]);

  const handleOutsideClick = useCallback((e: any, clickedOut: boolean) => {
      if (clickedOut) {
        setIsFilterOpen(false);
      }
    },
    [setIsFilterOpen],
  );

  useOutsideClick(containerRef, handleOutsideClick);

  return (
    <div className='flex flex-col relative' ref={containerRef}>
      <div className='flex justify-between items-center'>
        <h1 className='text-left text-2xl mt-9 mb-4 pl-4 text-blue-ocean-deep font-sans text-[40px]'>
          <FormattedMessage id='patient-enrollment' />
        </h1>
        <div className='flex'>
          <div className="flex justify-end items-center mt-9 mb-4 pr-4 ml-9" id="filters-wrapper">
            <Button
              onClick={() => setIsFilterOpen(!isFilterOpen)}
              className="filter-btn hover:bg-purple hover:text-white flex justify-center items-center w-max gap-x-2.5 bg-purple text-white rounded-lg !py-1"
            >
              <span className="filter-btn">
                <FormattedMessage id="audit-trial.filter" />
              </span>
              <FiFilter className="filter-btn" />
            </Button>
          </div>
        </div>
      </div>
      <div
        className='bg-white mt-4 pt-0 rounded-lg w-full mx-auto max-w-[calc(100vw_-_274px)] h-[calc(100vh_-_185px)] overflow-auto no-scrollbar relative border-[1px] border-purple '>
        <img src='/assets/images/bg-logo.png'
             className='w-[413px] h-[378px] absolute bottom-[4.875rem] left-1/2 -translate-x-1/2' alt='logo' />
        <div className='relative pt-4'>
            {isLoading ? (
              <FiLoader className='w-16 h-16 animate-spin my-20 self-center text-blue-ocean-deep' />
            ) : (
              <ParticipantsList status={status} centersData={centersData} isFreezed={isFreezed} />
            )}
          </div>
      </div>
      {isFilterOpen && (
        <div className="absolute rounded-lg top-[70px] right-[14px] w-[226px] bg-white z-50">
          <FilterForm
            {...{
              status,
              setStatus,
            }}
          />
        </div>
      )}
    </div>
  );
}
