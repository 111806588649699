import { useEffect } from 'react';
import { useStudyStore } from 'store/StudyStore';
import DragDropList from '../DragableQuestionsContainer/DragDropList';
import { IQuestion } from 'types/questionnaire';
import { insertAtIndex } from 'utils/array';
import { getMaxSortId } from 'utils/number';
import { useQuestionnaire } from '../DragableQuestionsContainer/useQuestionnaire';
import { useQuestionnaireStore } from 'store/QuestionnaireStore';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

interface IProps {
  page?: string;
  selectedQuestionnairy?: any;
}
const StandardFormsContent: React.FC<IProps> = () => {
  const { questionnaireId: questionnaireIdStr } = useParams();
  const questionnaireId = questionnaireIdStr?.split('-')?.[1];
  const { selectedStandardForm, setSelectedStandardForm } = useStudyStore();
  const { handleAddQSToQuestionnaire, standardForms } = useQuestionnaire({});
  const { questions, setQuestions, selectedQuestionnairy } = useQuestionnaireStore();
  const qssId = questionnaireIdStr?.split('-')?.[1];

  useEffect(() => {
    localStorage.setItem('is-standard-form-page', 'true');
    if ((questions?.length === 1 && questions?.[0]?.questionTitle === '') || !questions) {
      const fndForm = standardForms?.find((form: any) => form?.id === Number(questionnaireId));
      setQuestions(fndForm?.questions);
      setSelectedStandardForm(fndForm);
    }
    return () => {
      localStorage.setItem('is-standard-form-page', 'false');
    };
  }, [standardForms, questions]);

  useEffect(() => {
    const fndEmptyItem: any = questions?.findIndex((it: IQuestion) => !it?.questionTitle);

    if (fndEmptyItem === -1) {
      setQuestions(
        insertAtIndex(
          [...questions],
          {
            id: 0,
            questionTitle: '',
            sortOrder: getMaxSortId(questions) + 1,
            qssId: qssId,
          },
          questions.length - 1,
        ),
      );
    }
    if (questions?.length) {
      const tmpQuestions: any = [...questions];
      tmpQuestions.map((qs: IQuestion) => {
        if (qs?.parentQuestionId) {
          tmpQuestions.forEach((el: any, folderIndx: number) => {
            if (qs?.parentQuestionId === el?.id) {
              tmpQuestions[folderIndx]['children'] = el['children']
                ? tmpQuestions[folderIndx]['children']?.findIndex((it: any) => it.id === qs.id) < 0
                  ? [...el['children'], qs]
                  : [...el['children']]
                : [qs];
            }
          });
        }
      });

      // set sub title questions

      tmpQuestions.map((qs: IQuestion) => {
        if (qs?.titleQuestionId) {
          tmpQuestions.forEach((el: any, folderIndx: number) => {
            if (qs?.titleQuestionId === el?.id) {
              tmpQuestions[folderIndx]['titleQuestions'] = el['titleQuestions']
                ? tmpQuestions[folderIndx]['titleQuestions']?.findIndex(
                    (it: any) => it.id === qs.id,
                  ) < 0
                  ? [...el['titleQuestions'], qs]
                  : [...el['titleQuestions']]
                : [qs];
            }
          });
        }
      });
    }
  }, [questions]);

  const handleOnSaveQuestion = (tmpQuestion: IQuestion & { optionalProps?: any }) => {
    const { id, questionTitle, optionalProps = {} } = tmpQuestion;
    const fndItem: any = questions.find((it: IQuestion) => it?.id === id && it?.id !== 0);

    if (fndItem) {
      handleAddQSToQuestionnaire({
        id: `${id}`,
        questionTitle: questionTitle,
        selectionType: tmpQuestion?.questionType ? tmpQuestion?.questionType : 'TEXT_INPUT',
        required: tmpQuestion?.required,
        maxCharacters: 100,
        ...(optionalProps?.isScored != null ? { isScored: optionalProps?.isScored } : {}),
        inputPlaceHolder: 'placeholder ...',
        sortOrder: fndItem?.sortOrder,
        qssId: tmpQuestion?.qssId,
        optionalProps: {
          ...optionalProps,
          ...(tmpQuestion?.subtitle ? { subtitle: tmpQuestion?.subtitle } : {}),
          ...(selectedQuestionnairy?.questionnaire?.type === 'E_CRF' && optionalProps?.isNew
            ? {
                questionOptions: optionalProps?.questionOptions?.map((opt: any) => ({
                  ...opt,
                  score: null,
                })),
              }
            : {}),
          ...(tmpQuestion?.numberType ? { numberType: tmpQuestion?.numberType } : {}),
          ...(tmpQuestion?.inputType ? { inputType: tmpQuestion?.inputType } : {}),

          ...(tmpQuestion?.dateTimeType ? { dateTimeType: tmpQuestion?.dateTimeType } : {}),
          ...(tmpQuestion?.optionalProps?.numberType
            ? { numberType: tmpQuestion?.optionalProps?.numberType }
            : {}),
          ...(tmpQuestion?.optionalProps?.inputType
            ? { inputType: tmpQuestion?.optionalProps?.inputType }
            : {}),
          ...(tmpQuestion?.optionalProps?.dateTimeType
            ? { dateTimeType: tmpQuestion?.optionalProps?.dateTimeType }
            : {}),
        },
        ...(tmpQuestion?.parentQuestionId
          ? { parentQuestionId: tmpQuestion?.parentQuestionId }
          : {}),
        ...(tmpQuestion?.titleQuestionId ? { titleQuestionId: tmpQuestion?.titleQuestionId } : {}),
        ...(selectedQuestionnairy?.questionnaire?.type === 'E_CRF' ? { isScored: false } : {}),
      });
    } else {
      handleAddQSToQuestionnaire({
        questionTitle: questionTitle,
        selectionType: tmpQuestion?.questionType ? tmpQuestion?.questionType : 'TEXT_INPUT',
        required: tmpQuestion?.required,
        maxCharacters: 100,
        ...(optionalProps?.isScored != null ? { isScored: optionalProps?.isScored } : {}),
        inputPlaceHolder: 'placeholder ...',
        qssId: tmpQuestion?.qssId,
        sortOrder:
          questions?.length && questions[questions.length - 1]?.sortOrder !== undefined
            ? (questions[questions.length - 1].sortOrder ?? 0) + 1
            : null,
        optionalProps: {
          ...optionalProps,
          ...(tmpQuestion?.subtitle ? { subtitle: tmpQuestion?.subtitle } : {}),

          ...(selectedQuestionnairy?.questionnaire?.type === 'E_CRF'
            ? {
                questionOptions: optionalProps?.questionOptions?.map((opt: any) => ({
                  ...opt,
                  label: opt?.label?.replace(/(\r\n|\n|\r)/gm, ''),
                  value: opt?.value?.replace(/(\r\n|\n|\r)/gm, ''),
                  score: null,
                })),
              }
            : {}),
          ...(tmpQuestion?.numberType ? { numberType: tmpQuestion?.numberType } : {}),
          ...(tmpQuestion?.inputType ? { inputType: tmpQuestion?.inputType } : {}),

          ...(tmpQuestion?.dateTimeType ? { dateTimeType: tmpQuestion?.dateTimeType } : {}),
        },
        ...(tmpQuestion?.parentQuestionId
          ? { parentQuestionId: tmpQuestion?.parentQuestionId }
          : {}),
        ...(tmpQuestion?.titleQuestionId ? { titleQuestionId: tmpQuestion?.titleQuestionId } : {}),
        ...(selectedQuestionnairy?.questionnaire?.type === 'E_CRF' ? { isScored: false } : {}),
      });
    }
  };
  const titleMap: any = {
    'AE/SAE/DD': <FormattedMessage id='standard-forms.ae-sae-dd' />,
    'Protocol Deviation': <FormattedMessage id='standard-forms.protocole-deviation' />,
    'Termination form': <FormattedMessage id='standard-forms.termination-form' />,
    '': '',
  };

  return (
    <div className='w-full min-h-[658px] bg-white rounded-lg border-[1px] border-purple'>
      <h3 className='text-[20px] text-blue-ocean-deep font-medium leading-[28px] text-left py-[25px] pl-[25px] border-b-2 border-b-purple cursor-pointer pb-4'>
        {titleMap[selectedStandardForm?.title]}
      </h3>
      <div className='max-w-[1024px] px-11 pt-8 pb-8'>
        <DragDropList
          questions={
            questions
              ?.sort((a: any, b: any) => a?.sortOrder - b?.sortOrder)
              ?.filter((qs: IQuestion) => !qs.parentQuestionId && !qs?.titleQuestionId)
              .map((qs: IQuestion) => ({ ...qs, qssId: qssId })) ?? []
          }
          handleOnSaveQuestion={handleOnSaveQuestion}
          filterRepeatedInput={true}
          isStandardForm={true}
        />
      </div>
    </div>
  );
};
export default StandardFormsContent;
