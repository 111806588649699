import { useCallback, useEffect, useRef } from 'react';
import QuestionnaireButton from './QuestionnaireButton';
import { IStepPatientResponseItem, IStepWithStatus } from 'types/step';
import ScrollContainer from 'react-indiana-drag-scroll';
import { useNavigate, useParams } from 'react-router-dom';
import FolderListItem from './FolderListItem';
import { useQuestionnaireStore } from 'store/QuestionnaireStore';
import { FormattedMessage, useIntl } from 'react-intl';
import clsx from 'classnames';
import { getConsetFormPatient } from 'services/api/questionnaire';
import { useQuery } from '@tanstack/react-query';
import { useParticipantStore } from '../../../../store/participantStore';

interface Props {
  options: IStepPatientResponseItem[];
}

function StepList({ options }: Props) {
  const intl = useIntl();
  const { setSelectedQuestionnaire, selectedQuestionnaire } = useParticipantStore();

  const containerRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { questionnaireId, studyId, participantId } = useParams();
  const { setStepsData, consentFormData, setOpenConsentForm, consentFormLoading,  isOpenConsentForm } = useQuestionnaireStore();

  useEffect(() => {
    setSelectedQuestionnaire(questionnaireId ? parseInt(questionnaireId.split('-')[1]) : -1);
  }, [questionnaireId]);

  const { refetch: refetchConsent } = useQuery({
    queryKey: ['consetFormPatient', participantId],
    queryFn: () => getConsetFormPatient(participantId as string),
  });

  useEffect(() => {
    participantId && refetchConsent();
  }, [participantId, studyId]);

  function findSelected(nodes: HTMLCollection): Node | undefined {
    for (const node of Array.from(nodes)) {
      const elementNode = node as HTMLElement;
      if (elementNode.dataset.type !== 'questionnaire-button' && elementNode.children.length > 1) {
        const selectedFound = findSelected(node.children);
        if (selectedFound) return selectedFound;
      } else {
        if (parseInt(elementNode.dataset.id as string) === selectedQuestionnaire) {
          return node;
        }
      }
    }
    return undefined;
  }

  function handleRedirectButton() {
    navigate(`/study/${studyId}/study-builder`);
  }

  useEffect(() => {
    if (containerRef.current && selectedQuestionnaire) {
      const selectedElement = findSelected(containerRef.current.children) as HTMLElement;
      if (selectedElement) {
        const elementTopOffset = selectedElement.offsetTop;
        if (selectedElement.parentElement)
          selectedElement.parentElement.scrollTo({ top: elementTopOffset, behavior: 'smooth' });
      }
    }
  }, []);

  const getQuestionaires = (arr: IStepWithStatus[], option: any) => {
    const actualStepFolder = arr.find(
      (arrOption) => option.step.folder?.id === arrOption.folder?.id,
    );

    if (
      actualStepFolder?.folder?.questionnaires &&
      !actualStepFolder.folder.questionnaires.some(
        (questionnaire) => questionnaire.id === option.step.questionnaire?.id,
      )
    ) {
      actualStepFolder.folder.questionnaires =
        actualStepFolder?.folder?.questionnaires?.length > 0
          ? [...actualStepFolder.folder.questionnaires, option.step.questionnaire]
          : [option.step.questionnaire];
    } else if (actualStepFolder?.folder && option.step.questionnaire) {
      const foundQuestionaire = arr.find((arrOption) =>
        arrOption?.folder?.questionnaires?.find(
          (arr: any) => arr.id === option.step.questionnaire?.id,
        ),
      );
      !foundQuestionaire &&
        (actualStepFolder.folder.questionnaires =
          actualStepFolder?.folder?.questionnaires?.length > 0
            ? [...actualStepFolder.folder.questionnaires, option.step.questionnaire]
            : [option.step.questionnaire]);
    }
  };

  const reorderSteps = useCallback(
    () =>
      options?.sort((a: any, b: any) => a?.step?.sortOrder - b?.step?.sortOrder)?.reduce<IStepWithStatus[]>((arr, option) => {
        if (!arr.length)
          return [
            { ...option.step, stepId: option.id, status: option.status, answers: option.answers, repeatedAnswersRecords: option.repeatedAnswersRecords, },
          ];

        if (arr?.length > 0) {
          const foundQuestionaire = arr.find((arrOption: any) => arrOption?.questionnaire && (arrOption?.questionnaire?.id === option?.step?.questionnaire?.id));

          if (foundQuestionaire) {
            return arr;
          }
        }

        if (!option.step.folder) {
          return [
            ...arr,
            { ...option.step, stepId: option.id, status: option.status, answers: option.answers, repeatedAnswersRecords: option.repeatedAnswersRecords, },
          ];
        } else {
          if (option.step.questionnaire) {
            if (option.step.folder.parentFolderId) {
              const arrFolders = arr?.find((arrOption) => !!arrOption.folder?.folders?.length);
              arrFolders?.folder?.folders && getQuestionaires(arrFolders?.folder?.folders, option);
            }
            getQuestionaires(arr, option);
          }

          if (option.step.idVerification) {
            arr
              .find((arrOption) => option.step.folder?.id === arrOption.folder?.id)
              ?.folder?.questionnaires.push(option.step.idVerification);
          }

          if (!option.step.questionnaire) {
            if (option.step.folder?.parentFolderId) {
              const folderIndex = arr.findIndex(
                (arrOption) => option.step.folder?.parentFolderId === arrOption.folder?.id,
              );
              const newArr: any = arr;
              if (folderIndex > -1 && newArr[folderIndex]?.folder) {
                newArr[folderIndex].folder.folders = [
                  {
                    ...option.step,
                    stepId: option.id,
                    status: option.status,
                    answers: option.answers,
                    repeatedAnswersRecords: option.repeatedAnswersRecords,
                  },
                ];
                return [...newArr];
              }
            } else {
              return [
                ...arr,
                {
                  ...option.step,
                  stepId: option.id,
                  status: option.status,
                  answers: option.answers,
                  repeatedAnswersRecords: option.repeatedAnswersRecords,
                },
              ];
            }
          }
          return arr;
        }
      }, [] as IStepWithStatus[]),
    [options],
  );

  useEffect(() => {
    reorderSteps();
  }, [options]);

  const optionsInCorrectOrder = reorderSteps();

  useEffect(() => {
    if (options?.length > 0) {
      const foundOpt = optionsInCorrectOrder.find(
        (opt) => opt?.questionnaire?.id === selectedQuestionnaire,
      );
      if (foundOpt) {
        setStepsData(foundOpt);
      }
    }
  }, []);

  const listOptions = optionsInCorrectOrder.map((option) => {
    return option.folder ? (
      <div key={'folder-' + option.folder.id}>
        <FolderListItem
          name={option.folder.name}
          status={option.status}
          option={option}
          isQueries={true}
          folders={option.folder?.folders || []}
          selectedQuestionnaire={selectedQuestionnaire}
          setSelectedQuestionnaire={setSelectedQuestionnaire}
          questionnaires={option.folder.questionnaires}
        />
      </div>
    ) : option.questionnaire ? (
      <QuestionnaireButton
        key={'questionnaire-' + option.questionnaire.id}
        id={option.questionnaire.id}
        status={option.status}
        option={option}
        isQueries={true}
        setSelectedQuestionnaire={setSelectedQuestionnaire}
        selectedQuestionnaire={selectedQuestionnaire}
        title={option.questionnaire.title}
        type={option.questionnaire.type}
      />
    ) : (
      !!option.idVerification && (
        <QuestionnaireButton
          id={option.idVerification.id}
          status={option.status}
          option={option}
          isQueries={true}
          selectedQuestionnaire={selectedQuestionnaire}
          setSelectedQuestionnaire={setSelectedQuestionnaire}
          title={intl.formatMessage({ id: 'step.IDVerification' })}
          type='ID_VERIFICATION'
          key={'id-verification-' + option.idVerification.id}
        />
      )
    );
  });

  const handleClick = () => {
    setOpenConsentForm(true);
    setSelectedQuestionnaire(-1);
    navigate(`/study/${studyId}/participants/${participantId}/questionnaire-status/consent`);
  }

  const getQuestionnaire = (opt: any, qId: any) => {
    let foundQuest: any = null;

    if (opt?.folder) {
      opt?.folder?.questionnaires?.length > 0 && opt?.folder?.questionnaires?.map((quest: any) => {
        if (`${quest?.id}` === `${qId}`) {
          foundQuest = quest;
        }
        return foundQuest;
      });
    } else if (`${opt?.questionnaire?.id}` === `${qId}`) {
      foundQuest = opt?.questionnaire
    }
    return foundQuest;
  }

  useEffect(() => {
    if (questionnaireId === undefined && selectedQuestionnaire === -1 && !location.pathname?.includes('consent') && !consentFormLoading) {
      const foundQuestionaires = optionsInCorrectOrder?.filter((arrOption) => (arrOption?.questionnaire || (arrOption?.folder?.questionnaires && arrOption?.folder?.questionnaires?.length > 0)));
      const answers = options?.filter( (stp: any) => stp?.answers?.length > 0) ?? [];
      const lastAnswer: any = answers && answers?.length > 0 && answers?.[answers?.length - 1];

      let foundLastQuestionnaire: any = null;
      optionsInCorrectOrder && optionsInCorrectOrder?.length > 0 && optionsInCorrectOrder?.map((opt) => {
        if (opt?.folder?.folders) {
          opt?.folder?.folders?.length > 0 && opt?.folder?.folders?.map((flds) => {
            if (getQuestionnaire(flds, lastAnswer?.step?.questionnaire?.id)) {
              foundLastQuestionnaire = getQuestionnaire(flds, lastAnswer?.step?.questionnaire?.id);
            }
          })
        }
        if (getQuestionnaire(opt, lastAnswer?.step?.questionnaire?.id)) {
          foundLastQuestionnaire = getQuestionnaire(opt, lastAnswer?.step?.questionnaire?.id);
        }
      });

      if (foundLastQuestionnaire && foundLastQuestionnaire?.id && options?.length != 0) {
        navigate(`/study/${studyId}/participants/${participantId}/questionnaire-status/q-${foundLastQuestionnaire?.id}`);
        setSelectedQuestionnaire(foundLastQuestionnaire?.id);
      } else if (foundLastQuestionnaire === null && consentFormData && consentFormData?.id && options?.length != 0) {
        handleClick();
      } else if (foundLastQuestionnaire === null && foundQuestionaires && foundQuestionaires?.length > 0) {
        const firstQuestionaire = foundQuestionaires?.[0];
        const qId = firstQuestionaire?.folder ? firstQuestionaire?.folder?.questionnaires[0]?.id : firstQuestionaire?.questionnaire?.id;
        if (qId) {
          navigate(`/study/${studyId}/participants/${participantId}/questionnaire-status/q-${qId}`);
          setSelectedQuestionnaire(qId);
        }
      }
    }
  }, [optionsInCorrectOrder, options, selectedQuestionnaire, questionnaireId, consentFormLoading, consentFormData])

  useEffect(() => {
    if (selectedQuestionnaire || questionnaireId) {
      setOpenConsentForm(false);
    }
  }, [selectedQuestionnaire, questionnaireId])

  useEffect(() => {
    if (location.pathname?.includes('consent')) {
      setOpenConsentForm(true);
      setSelectedQuestionnaire(-1);
    }
  }, [])

  return (
    <ScrollContainer
      innerRef={containerRef}
      hideScrollbars={false}
      className='overflow-y-auto relative pr-3 pb-3 flex flex-col gap-3'
    >
      {consentFormData && consentFormData?.id && (
        <>
          <div className='flex justify-center relative items-center'>
            <button
              type='button'
              className={clsx(
                'p-2 pl-4 rounded-[6px] text-blue-ocean-deep bg-base-white transition-all items-center justify-between w-full border-blue-ocean-deep z-10 gap-3 group flex shadow shadow-[rgba(62, 49, 108, 0.05)] font-semibold',
                isOpenConsentForm ? ' border-[1px] border-purple bg-blue-very-light' : 'hover:border',
              )}
              onClick={handleClick}
            >
              <p className='text-sm font-semibold text-start truncate w-[170px]'>
                <FormattedMessage id="study-builder.consentForm" />
              </p>
            </button>
          </div>
          <hr className='border-dashed border bg-transparent' />
        </>
      )}
      {listOptions.length ? (
        listOptions.map((Option) => Option)
      ) : (
        <div className='flex h-full flex-col'>
          <div className='flex flex-col items-center flex-1 mb-20 mt-20'>
            <img
              src={process.env.PUBLIC_URL + '/assets/images/study/backward-item.svg'}
              className='w-20 h-20'
              alt='nature image'
            />
            <p className='p-2 text-center'>
              <FormattedMessage id="patient-status.noStepInStudy" />
            </p>
          </div>
          <button
            type='button'
            onClick={handleRedirectButton}
            className='mt-4 block bg-base-white text-blue-ocean-deep rounded-xl font-semibold p-2 hover:bg-blue-ocean-deep hover:text-base-white transition-all'
          >
            <FormattedMessage id="patient-status.startBuilding" />
          </button>
        </div>
      )}
    </ScrollContainer>
  );
}

export default StepList;
