import { axiosClient } from '../../config/axios';

export interface IMedicalHistoryForm {
  patientId?: number,
  name: string,
  startDate: string,
  type: string,
  confirmationDate: string
}

export interface ISurgicalHistoryForm {
  patientId?: number,
  name: string,
  date: string,
  indication: string
}

export interface IConcomitantMedicationForm {
  patientId?: number,
  name: string,
  tradeName: string,
  startDate: string,
  route: string,
  discontinuationReason: string,
  dosage: string,
  important: boolean
}

export interface IDiseaseHistoryForm {
  patientId?: number,
  name: string,
  startDate: string,
  endDate: string,
  status: string
}

export interface IBiologicalDataForm {
  patientId?: number,
  name: string,
  date: string,
  type: string,
  results: string
}

export interface ISymptomsForm {
  patientId?: number,
  name: string,
  startDate: string,
}

export interface INurseForm {
  email?: string,
  firstName?: string,
}

export interface IPatientForm {
  dateOfBirth?: string,
  weight?: number,
  height?: number,
  bloodType?: string,
  gender?: string
}

export interface IPagable {
  page: number,
  size: number,
  sort: string[]
}

export const editPatientData = async (patientId: number, payload: IPatientForm) => {
  const { data } = await axiosClient.put(`patients/${patientId}`, payload);
  return data;
};

export const fetchPatientData = async (patientId: number) => {
  const { data } = await axiosClient.get(`patients/${patientId}/summary`);
  return data;
};

export const fetchAdverseEvents = async (patientId: number, pageable?: IPagable) => {
  const { data } = await axiosClient.get(`/standard-form/submissions/patients/${patientId}?page=${pageable?.page || 0}&size=${pageable?.size || 100}&sort=${pageable?.sort || ['']}`);
  return data;
};

export const addNurse = async (payload: INurseForm, patientId: number) => {
  const { data } = await axiosClient.post(`patients/${patientId}/add-nurse`, payload);
  return data;
};

export const editNurse = async (payload: INurseForm, patientId: number, nurseId: number) => {
  const { data } = await axiosClient.put(`patients/${patientId}/nurses/${nurseId}/update-nurse`, payload);
  return data;
};

export const deleteNurse = async (patientId: number, nurseId: number) => {
  const { data } = await axiosClient.delete(`patients/${patientId}/nurses/${nurseId}/remove-nurse`);
  return data;
};

export const fetchMedicalHistory = async (patientId: number, pageable?: IPagable) => {
  const { data } = await axiosClient.get(`medical-history/patients/${patientId}?page=${pageable?.page || 0}&size=${pageable?.size || 100}&sort=${pageable?.sort || ['']}`);
  return data;
};

export const addMedicalHistory = async (payload: IMedicalHistoryForm) => {
  const { data } = await axiosClient.post('medical-history', payload);
  return data;
};

export const editMedicalHistory = async (payload: IMedicalHistoryForm, id: number) => {
  const { data } = await axiosClient.put(`medical-history/${id}`, payload);
  return data;
};

export const deleteMedicalHistory = async (id: number) => {
  const { data } = await axiosClient.delete(`medical-history/${id}`);
  return data;
};

export const fetchSurgicalHistory = async (patientId: number, pageable?: IPagable) => {
  const { data } = await axiosClient.get(`surgical-history/patients/${patientId}?page=${pageable?.page || 0}&size=${pageable?.size || 100}&sort=${pageable?.sort || ['']}`);
  return data;
};

export const addSurgicalHistory = async (payload: ISurgicalHistoryForm) => {
  const { data } = await axiosClient.post('surgical-history', payload);
  return data;
};

export const editSurgicalHistory = async (payload: ISurgicalHistoryForm, id: number) => {
  const { data } = await axiosClient.put(`surgical-history/${id}`, payload);
  return data;
};

export const deleteSurgicalHistory = async (id: number) => {
  const { data } = await axiosClient.delete(`surgical-history/${id}`);
  return data;
};

export const fetchConcoMedicationFormHistory = async (patientId: number, pageable?: IPagable) => {
  const { data } = await axiosClient.get(`concomitant-medication/patients/${patientId}?page=${pageable?.page || 0}&size=${pageable?.size || 100}&sort=${pageable?.sort || ['']}`);
  return data;
};

export const addConcoMedication = async (payload: IConcomitantMedicationForm) => {
  const { data } = await axiosClient.post('concomitant-medication', payload);
  return data;
};

export const editConcoMedication = async (payload: IConcomitantMedicationForm, id: number) => {
  const { data } = await axiosClient.put(`concomitant-medication/${id}`, payload);
  return data;
};

export const deleteConcoMedication = async (id: number) => {
  const { data } = await axiosClient.delete(`concomitant-medication/${id}`);
  return data;
};

export const fetchSymptoms = async (patientId: number, pageable?: IPagable) => {
  const { data } = await axiosClient.get(`symptoms/patients/${patientId}?page=${pageable?.page || 0}&size=${pageable?.size || 100}&sort=${pageable?.sort || ['']}`);
  return data;
};

export const addSymptoms = async (payload: ISymptomsForm) => {
  const { data } = await axiosClient.post('symptoms', payload);
  return data;
};

export const editSymptoms = async (payload: ISymptomsForm, id: number) => {
  const { data } = await axiosClient.put(`symptoms/${id}`, payload);
  return data;
};

export const deleteSymptoms = async (id: number) => {
  const { data } = await axiosClient.delete(`symptoms/${id}`);
  return data;
};

export const fetchDiseaseHistory = async (patientId: number, pageable?: IPagable) => {
  const { data } = await axiosClient.get(`disease-history/patients/${patientId}?page=${pageable?.page || 0}&size=${pageable?.size || 100}&sort=${pageable?.sort || ['']}`);
  return data;
};

export const addDiseaseHistory = async (payload: IDiseaseHistoryForm) => {
  const { data } = await axiosClient.post('disease-history', payload);
  return data;
};

export const editDiseaseHistory = async (payload: IDiseaseHistoryForm, id: number) => {
  const { data } = await axiosClient.put(`disease-history/${id}`, payload);
  return data;
};

export const deleteDiseaseHistory = async (id: number) => {
  const { data } = await axiosClient.delete(`disease-history/${id}`);
  return data;
};

const headers = {
  'Content-Type': 'multipart/form-data',
};

export const fetchBiologicalData = async (patientId: number, pageable?: IPagable) => {
  const { data } = await axiosClient.get(`biological-data/patients/${patientId}?page=${pageable?.page || 0}&size=${pageable?.size || 100}&sort=${pageable?.sort || ['']}`);
  return data;
};

export const addBiologicalData = async (payload: any) => {
  const { data } = await axiosClient.post('biological-data', payload, { headers });
  return data;
};

export const editBiologicalData = async (payload: IBiologicalDataForm, id: number) => {
  const { data } = await axiosClient.put(`biological-data/${id}`, payload);
  return data;
};

export const editBiologicalFile = async (payload: any, id: number) => {
  const { data } = await axiosClient.put(`biological-data/${id}/update-file`, payload, { headers });
  return data;
};

export const deleteBiologicalData = async (id: number) => {
  const { data } = await axiosClient.delete(`biological-data/${id}`);
  return data;
};
