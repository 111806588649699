import React from 'react';
import clsx from 'classnames';
interface ButtonProps {
  children: React.ReactNode;
  className?: string;
  icon?: React.ReactNode;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>; // MouseEvent | undefined;
  type?: 'button' | 'submit' | 'reset' | undefined;
  isLoading?: boolean;
  id?: any;
}

const Button: React.FC<ButtonProps> = ({
  children,
  className = '',
  icon,
  disabled = false,
  onClick = () => {},
  isLoading,
  type,
  id = '',
}) => {
  return (
    <button
      id={id}
      className={clsx(
        `bg-blue-500 hover:bg-background font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${className} disabled:bg-disabled disabled:text-white disabled:cursor-not-allowed`,
        isLoading && 'bg-disabled min-w-[84px]',
      )}
      onClick={onClick}
      disabled={disabled || isLoading}
      type={type}
    >
      {isLoading ? (
        <img
          className={clsx('h-[24px] w-[24px]')}
          src={process.env.PUBLIC_URL + '/assets/images/btn-loading.svg'}
          alt='nature image'
        />
      ) : (
        <>
          {icon && <span className='mr-2'>{icon}</span>}
          {children}
        </>
      )}
    </button>
  );
};

export default Button;
