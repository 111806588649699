import { createSvgIcon } from '@mui/material';

export const CloseIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
    <path
      d='M1.70703 0.292969L0.292969 1.70703L6.58594 8L0.292969 14.293L1.70703 15.707L8 9.41406L14.293 15.707L15.707 14.293L9.41406 8L15.707 1.70703L14.293 0.292969L8 6.58594L1.70703 0.292969Z'
      fill='#7E7E7E'
    />
  </svg>,
  'CloseIcon',
);
