import clsx from 'classnames';
interface IProps {
  current: number;
  max: number;
  className?: string;
  showLabel?: boolean;
  fullColor?: string;
}

export default function ProgressBar({ current, max, className, showLabel, fullColor }: IProps) {
  const actualPercentage = current && max ? (current / max) * 100 : 0;

  return (
    <div className='flex justify-start items-center gap-x-2'>
      <div className={clsx('w-full bg-gray-light h-[0.5625rem] relative rounded-xl', className)}>
        <span
          style={{
            width: `${actualPercentage}%`,
          }}
          className={`left-0 top-0 transition-all max-w-full absolute inline-block h-full bg-${fullColor || 'blue-ocean-deep'} rounded-xl`}
        />
      </div>
      {showLabel && (
        <span className='text-gray-dark'>
          {current}/{max}
        </span>
      )}
    </div>
  );
}
