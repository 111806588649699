import { FiFolder } from 'react-icons/fi';
import { IStandardForm } from 'types/study';
import clsx from 'classnames';
import { useParams, useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useQuestionnaireStore } from 'store/QuestionnaireStore';
import { useParticipantStore } from 'store/participantStore';

interface IProps {
  standardForms: IStandardForm[] | [];
}
const StandardFormsMenu: React.FC<IProps> = ({ standardForms }: IProps) => {
  const { questionnaireId: questionnaireIdStr } = useParams();
  const questionnaireId = questionnaireIdStr?.split('-')?.[1];
  const navigate = useNavigate();
  const { setOpenConsentForm } = useQuestionnaireStore();
  const { setSelectedQuestionnaire } = useParticipantStore();

  const handleOnClick = (id: number) => {
    setOpenConsentForm(false);
    if (Number(questionnaireId?.split('-')?.[1]) === id) return;
    setSelectedQuestionnaire(id);
    const routeId = `sf-${id}`;
    navigate(routeId);
  };
  const titleMap: any = {
    'AE/SAE/DD': <FormattedMessage id='standard-forms.ae-sae-dd' />,
    'Protocol Deviation': <FormattedMessage id='standard-forms.protocole-deviation' />,
    'Termination form': <FormattedMessage id='standard-forms.termination-form' />,
    '': '',
  };
  if (standardForms?.length === 0) return null;
  return (
    <>
      <hr className='border-dashed border bg-transparent' />
      <div className='mt-4 mb-4 rounded-lg'>
        <div className={clsx('p-3 pl-4 rounded-lg w-[250px] h-[186px] bg-white')}>
          <div className='flex justify-start items-center gap-x-3'>
            <FiFolder className='w-5 h-5 text-blue-ocean-deep' />
            <span className='text-blue-ocean-deep text-base font-semibold leading-[22px]'>
              <FormattedMessage id='study-dashboard.standardFrom' />
            </span>
          </div>
          {standardForms?.map((form: IStandardForm) => (
            <p
              onClick={() => {
                handleOnClick(form.id);
              }}
              key={form.id}
              className={clsx(
                'cursor-pointer px-[10px] py-[5px] mt-[7px] hover:border border-blue-ocean-deep rounded-lg text-left w-full text-blue-ocean-deep text-base font-normal leading-[22px] truncate text-ellipsis',
                Number(questionnaireId) === form.id &&
                  'border-[1px] border-purple bg-blue-very-light',
              )}
            >
              {titleMap[form.title]}
            </p>
          ))}
        </div>
      </div>
    </>
  );
};
export default StandardFormsMenu;
