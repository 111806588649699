import { createSvgIcon } from '@mui/material';

export const EmptyItems = createSvgIcon(
  <svg width='73' height='72' viewBox='0 0 73 72' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M57.05 35.25H48.95C40.97 35.25 37.25 31.53 37.25 23.55V15.45C37.25 7.46998 40.97 3.75 48.95 3.75H57.05C65.03 3.75 68.75 7.46998 68.75 15.45V23.55C68.75 31.53 65.03 35.25 57.05 35.25ZM48.95 8.25C43.43 8.25 41.75 9.92998 41.75 15.45V23.55C41.75 29.07 43.43 30.75 48.95 30.75H57.05C62.57 30.75 64.25 29.07 64.25 23.55V15.45C64.25 9.92998 62.57 8.25 57.05 8.25H48.95Z'
      fill='#004381'
    />
    <path
      d='M24.05 68.25H15.95C7.97 68.25 4.25 64.53 4.25 56.55V48.45C4.25 40.47 7.97 36.75 15.95 36.75H24.05C32.03 36.75 35.75 40.47 35.75 48.45V56.55C35.75 64.53 32.03 68.25 24.05 68.25ZM15.95 41.25C10.43 41.25 8.75 42.93 8.75 48.45V56.55C8.75 62.07 10.43 63.75 15.95 63.75H24.05C29.57 63.75 31.25 62.07 31.25 56.55V48.45C31.25 42.93 29.57 41.25 24.05 41.25H15.95Z'
      fill='#004381'
    />
    <path
      d='M41.3604 54.3899H33.5003C32.2703 54.3899 31.2503 53.3699 31.2503 52.1399V48.4499C31.2503 42.9299 29.5703 41.2499 24.0503 41.2499H20.3604C19.1304 41.2499 18.1104 40.2299 18.1104 38.9999V31.1399C18.1104 21.7799 22.2803 17.6099 31.6403 17.6099H39.5003C40.7303 17.6099 41.7503 18.6299 41.7503 19.8599V23.5499C41.7503 29.0699 43.4303 30.7499 48.9503 30.7499H52.6403C53.8703 30.7499 54.8903 31.7699 54.8903 32.9999V40.8599C54.8903 50.2199 50.7204 54.3899 41.3604 54.3899ZM35.7503 49.8899H41.3604C48.2004 49.8899 50.3903 47.6999 50.3903 40.8599V35.2499H48.9503C40.9703 35.2499 37.2503 31.5299 37.2503 23.5499V22.1099H31.6403C24.8003 22.1099 22.6104 24.2999 22.6104 31.1399V36.7499H24.0503C32.0303 36.7499 35.7503 40.4699 35.7503 48.4499V49.8899Z'
      fill='#004381'
    />
  </svg>,
  'EmptyItems',
);
export const DragDropIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='4' height='18' viewBox='0 0 4 18' fill='none'>
    <circle cx='2' cy='2' r='2' fill='#D9D9D9' />
    <circle cx='2' cy='9' r='2' fill='#D9D9D9' />
    <circle cx='2' cy='16' r='2' fill='#D9D9D9' />
  </svg>,
  'DragDropIcon',
);
export const MoreIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='5' height='23' viewBox='0 0 5 23' fill='none'>
    <circle cx='2.5' cy='2.5' r='2.5' fill='#0973D4' />
    <circle cx='2.5' cy='11.5' r='2.5' fill='#0973D4' />
    <circle cx='2.5' cy='20.5' r='2.5' fill='#0973D4' />
  </svg>,
  'MoreIcon',
);
export const CloseEyeIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='25' height='25' viewBox='0 0 25 25' fill='none'>
    <path
      d='M18.8775 18.0982C17.1681 19.4012 15.0866 20.1231 12.9375 20.1582C5.9375 20.1582 1.9375 12.1582 1.9375 12.1582C3.18139 9.84012 4.90664 7.81484 6.9975 6.21823M10.8375 4.39823C11.5258 4.23711 12.2306 4.15657 12.9375 4.15823C19.9375 4.15823 23.9375 12.1582 23.9375 12.1582C23.3305 13.2938 22.6066 14.363 21.7775 15.3482M15.0575 14.2782C14.7829 14.573 14.4516 14.8094 14.0837 14.9734C13.7157 15.1373 13.3184 15.2255 12.9156 15.2326C12.5128 15.2397 12.1127 15.1656 11.7391 15.0147C11.3656 14.8638 11.0262 14.6393 10.7413 14.3544C10.4565 14.0695 10.2319 13.7302 10.081 13.3566C9.93012 12.9831 9.85603 12.5829 9.86313 12.1801C9.87024 11.7773 9.95841 11.3801 10.1224 11.0121C10.2863 10.6441 10.5228 10.3129 10.8175 10.0382'
      stroke='#004381'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M1.9375 1.1582L23.9375 23.1582'
      stroke='#004381'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>,
  'MoreIcon',
);
export const UserTickIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='21' height='18' viewBox='0 0 21 18' fill='#fff'>
    <path
      d='M13.5 16.5V14.8333C13.5 13.9493 13.1488 13.1014 12.5237 12.4763C11.8986 11.8512 11.0507 11.5 10.1667 11.5H4.33333C3.44928 11.5 2.60143 11.8512 1.97631 12.4763C1.35119 13.1014 1 13.9493 1 14.8333V16.5'
      stroke='#012C54'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M14.332 8.16667L15.9987 9.83333L19.332 6.5'
      stroke='#012C54'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M7.24935 8.16667C9.0903 8.16667 10.5827 6.67428 10.5827 4.83333C10.5827 2.99238 9.0903 1.5 7.24935 1.5C5.4084 1.5 3.91602 2.99238 3.91602 4.83333C3.91602 6.67428 5.4084 8.16667 7.24935 8.16667Z'
      stroke='#012C54'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>,
  'MoreIcon',
);
