import { useRef, useState } from 'react';
import NestedOption from './NestedOption';
import { FiChevronDown, FiChevronRight } from 'react-icons/fi';
import { useOutsideClick } from 'hooks/useOutsideClick';

interface IProps {
  methods: any;
  name: string;
}

const Dropdown = ({ name, methods }: IProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('Does not repeat');
  const [isNestedOpen, setIsNestedOpen] = useState(-1);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const values = methods.watch();
  const options = [
    'Does not repeat',
    'Daily',
    'Weekly',
    'Monthly',
    'Yearly',
    'Custom',
  ];

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    setIsNestedOpen(-1);
  }

  const handleSelectOption = (e: any, option: string, i: number) => {
    e.preventDefault();
    setSelectedOption(option);
    switch (option) {
      case 'Does not repeat':
        methods.setValue(name, null);
        setIsOpen(false);
        break;
      case 'Daily':
        methods.setValue(name, 'DAY');
        break;
      case 'Weekly':
        methods.setValue(name, 'WEEK');
        break;
      case 'Monthly':
        methods.setValue(name, 'MONTH');
        break;
      case 'Yearly':
        methods.setValue(name, 'YEAR');
        break;
      case 'Custom':
        methods.setValue('customRepeat', 'DAY');
        break;
    }
    setIsNestedOpen(i);
  };

  const handleOutsideClick = (event: any, clickedOut: boolean) => {
    if (clickedOut && !isDatePickerOpen) {
      setIsOpen(false);
      setIsNestedOpen(-1);
    }
  }

  useOutsideClick(containerRef, handleOutsideClick);
  
  return (
    <div ref={containerRef} className='relative'>
      <div
        className={`w-[12.125rem] h-[1.875rem] px-2 py-1 rounded border flex justify-between items-center gap-2.5 cursor-pointer ${isOpen ? 'border-blue-ocean-deep  text-blue-ocean-deep' : 'border-gray-dark text-gray-dark'}`}
        onClick={toggleDropdown}
      >
        <p className='text-sm font-normal leading-snug'>{selectedOption}</p>
        <FiChevronDown className='w-6 h-6' />
      </div>
      {isOpen && (
        <ul className='absolute left-0 mt-[1px] w-[12.125rem] border border-blue-ocean-deep rounded-[10px] bg-white shadow-md z-10 py-[5px]'>
          {options.map((option, index) => (
            <li key={index} onClick={(e) => handleSelectOption(e, option, index)} className={`px-2 py-1 relative text-sm leading-[22px] text-blue-ocean-deep hover:bg-blue-very-light ${selectedOption === option ? 'bg-blue-very-light text-blue-oil' : '' }  flex items-center justify-between cursor-pointer`}>
              {option}{index !==0 && <FiChevronRight className='w-6 h-6' />}
              {isNestedOpen !== 0 && isNestedOpen === index && <div className='absolute group-hover:block -top-2 left-full z-20'>
                <NestedOption setIsDatePickerOpen={setIsDatePickerOpen} methods={methods} startDate={new Date(values.startDate)} type={option} />
              </div>}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
