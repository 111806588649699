import { IconType } from 'react-icons/lib';
import clsx from 'classnames';

interface SelectableOptionProps {
  selected: boolean;
  onClick: () => void;
  Icon: IconType;
  customIcon?: any;
  title: string;
  disabled: boolean;
  // makeDisable: () => boolean;
}

export function SelectableOption({
  Icon,
  title,
  selected,
  onClick,
  customIcon,
  disabled,
}: SelectableOptionProps) {
  console.log('selected: ', selected);
  return (
    <div
      className={clsx(
        'w-full px-1 py-2 flex flex-col justify-center items-center group transition-all ease-in-out delay-300 cursor-pointer relative',
        selected
          ? 'bg-[rgba(0,67,129,0.10)] [&>div>svg>path]:!stroke-[#fff] [&>div>svg>path]:stroke-2'
          : '[&>div>svg>path]:stroke-1',
        disabled && 'opacity-40 pointer-events-none',
      )}
      onClick={disabled ? () => {} : onClick}
    >
      {customIcon ? (
        <div
          className={clsx(
            '[&>path]:fill-white w-full flex justify-center items-center',
            selected && '[&>svg>g>path]:!stroke-2',
          )}
        >
          {customIcon}
        </div>
      ) : (
        <Icon
          strokeWidth={selected ? 2 : 1}
          className='shrink-0 transition-all text-white ease-in-out delay-75 cursor-pointer z-10 w-6 h-6'
        />
      )}
      <p
        className={clsx(
          'm-0 mt-[7px] text-white text-xs w-full',
          selected ? 'font-bold' : 'font-light',
        )}
      >
        {title}
      </p>
    </div>
  );
}
