import React, {useCallback, useRef, useState} from 'react';
import clsx from 'classnames';
import {FiChevronDown} from 'react-icons/fi';
import {useIntl} from 'react-intl';
import {useController} from 'react-hook-form';
import { useOutsideClick } from '../../../../../hooks/useOutsideClick';

const TableSelect = ({
 btnClass, listClass, options, noTranslation, placeholder,
 customControl, onBlur,
 ...rest
}: any) => {
  const intl = useIntl();
  const props = customControl ? customControl : useController(rest)?.field;
  const containerRef = useRef<HTMLDivElement>(null);

  const [openDropdown, setOpenDropdown] = React.useState(false);
  const [selectedOption, setSelectedOption] = useState(props.value);

  function toggleOpen() {
    setOpenDropdown((prevValue: any) => !prevValue);
  }

  const handleSelectOption = useCallback(
    (optionValue: string | number) => {
      setSelectedOption(optionValue);
      props.onChange(optionValue as string);
      onBlur?.(optionValue);
      toggleOpen();
    },
    [setSelectedOption, props.value]
  );

  const handleOutsideClick = useCallback(
    (e: any, clickedOut: boolean) => {
      if (clickedOut) {
        setOpenDropdown(false);
      }
    },
    [setOpenDropdown]
  );

  useOutsideClick(containerRef, handleOutsideClick);
  const getLabel = () => {
    return options.find((option: any) => `${selectedOption}` === `${option.value}`)
      ?.label;
  };

  return (
    <div ref={containerRef}>
      <button className={`${btnClass} w-[6rem] border-blue-normal-35 h-[28px] items-center !px-2 !py-0.5 flex justify-between border bg-white rounded`}
        onClick={(e) => {
          e?.preventDefault();
          toggleOpen();
        }}
      >
        <p
          className={clsx(
            'text-sm truncate flex-1 text-start w-[50%] text-ellipsis',
            !selectedOption && '!text-gray-light',
          )}
        >
          {selectedOption === ' '
            ? placeholder
            : selectedOption
              ? noTranslation
                ? getLabel()
                : getLabel() ? intl.formatMessage({ id: `${getLabel()}` }) : ''
              : placeholder}</p>
        <FiChevronDown
          className={clsx(
            'transition-all !stroke-[3px] !text-blue-ocean-deep !min-w-[20px] !min-h-[20px] w-[20px] h-[20px]'
          )}
        />
      </button>
      {openDropdown && (
        <ul
          className={`${listClass} max-h-[11.4rem] w-max overflow-auto !p-0 ${options && options?.length > 4 ? 'h-[11.4rem]' : 'h-auto'} `}
          style={{
            position: 'absolute',
            backgroundColor: 'white',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
            listStyle: 'none',
            zIndex: 1000,
          }}
        >
          {options && options?.length > 0 && options?.map((opt: any, index: any) => (
            <li
              key={`${opt?.value}-${index}`}
              data-value={opt?.value}
              onClick={() => handleSelectOption(opt.value)}
              className='list-none px-3 py-2 text-base cursor-pointer text-left !m-0 hover:bg-blue-very-light'
              value={opt?.value}
            >
              {noTranslation ? opt?.label : intl.formatMessage({ id: `${opt?.label}` })}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export default TableSelect;