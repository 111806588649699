import { format } from 'date-fns';

export function getDateLabels(stepsData: any) {
  const labels = stepsData.map((item: any) => (
    format(new Date(item?.date), 'd MMM')
  ))

  return labels;
}

export function getStepsData(stepsData: any) {
  const data = stepsData.map((item: any) => (
    item?.numberOfSteps
  ))

  return data;
}

export function getHeartData(heartData: any ) {
  // let prev = 100;
  const data = heartData.map((item: any) => (
    item?.heartRate
  ))

  return data;
}
