import { ChartData, ChartOptions } from 'chart.js';
import { Card } from 'components/common';
import { TypedChartComponent } from 'react-chartjs-2/dist/types';
import clsx from 'classnames';

interface IProps {
  Chart: TypedChartComponent<any>;
  chartData: ChartData<any>;
  chartOptions: ChartOptions;
  title: string;
  footerTitle: string;
  footerDesc: string;
  footerDate: string;
  customChartDesc?: string;
  className?: { card?: string, title?: string };
}

export function ChartCard({
  Chart,
  chartData,
  chartOptions,
  footerTitle,
  footerDesc,
  footerDate,
  title,
  customChartDesc,
  className,
}: IProps) {
  const customChartOptions = customChartDesc ? structuredClone(chartOptions) : null;
  if (customChartOptions && customChartOptions.scales && customChartOptions.scales.x?.ticks) {
    customChartOptions.scales.x.ticks = {
      display: false,
      padding: 0,
    };
  }

  return (
    <Card className={clsx('w-[25.625rem] h-max !px-4 !pt-5 !pb-0', className?.card)}>
      <h3 className={clsx('text-left text-blue-oil font-bold text-lg pl-4', className?.title)}>{title}</h3>
      <Chart data={chartData} options={customChartOptions || chartOptions} />
      {customChartDesc ? (
        <p className='text-sm text-right text-gray-medium pr-8 mt-2'>{customChartDesc}</p>
      ) : null}
      {(footerTitle || footerDate || footerDesc) && <footer className="flex mt-5 justify-start items-end text-gray-dark">
        <h1 className="text-[2rem] leading-[2.75rem] text-blue-oil">{footerTitle}</h1>
        <p className="mb-2 ml-[0.37rem] leading-5 text-sm flex-1 text-left font-medium">
          {footerDesc}
        </p>
        <p className="mb-2 leading-5 text-sm font-medium">{footerDate}</p>
      </footer>}
    </Card>
  );
}
