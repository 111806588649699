import axios, { AxiosError, AxiosResponse } from 'axios';
import useAuthStore from 'store/authStore';
// import { toast } from 'react-toastify';

export const axiosClient = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_URL}/api/v1/`,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosClient.interceptors.request.use((config) => {
  const token = useAuthStore.getState().accessToken;
  config.headers.Authorization = 'Bearer ' + token;
  return config;
});

export const setupResponseInterceptor = (navigate: any, setAccessToken: any) => {
  axiosClient.interceptors.response.use(
    (response: AxiosResponse) => {
      return response;
    },
    (error: AxiosError) => {
      switch (error?.response?.status) {
        case 401:
          setAccessToken('');
          navigate('/login');

          return;
        case 403:
          // toast.error(error?.response?.statusText ?? 'Permission error!');
          return Promise.reject(error);
        default:
          return Promise.reject(error);
      }
    },
  );
};
