import React, { RefObject, useEffect } from 'react';

export function useOutsideClick(
  ref: RefObject<HTMLElement>,
  onClick: (
    eventOfTarget: MouseEvent | React.MouseEvent<HTMLElement, MouseEvent>,
    clickedOut: boolean,
  ) => void,
  exceptionRefs?: any,
) {
  useEffect(() => {
    function handleClickOutside(event: React.MouseEvent<HTMLElement, MouseEvent> | MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as HTMLElement)) {
        onClick(event, true);
      }

      // Do nothing if clicking inside any of the exception elements
      if (exceptionRefs) {
        if (exceptionRefs.current && exceptionRefs.current.contains(event.target)) {
          onClick(event, false);
        }
      }

    }
    document.addEventListener('mousedown', handleClickOutside, true);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside, true);
    };
  }, [ref, onClick]);
}
