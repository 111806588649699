import { TooltipItem } from 'chart.js';

export const hideScales = {
  x: {
    grid: {
      drawOnChartArea: false,
      display: false,
      drawTicks: false,
    },
    ticks: {
      display: true,
      padding: 10,
    },
    border: {
      display: false,
    },
  },
  y: {
    grid: {
      drawOnChartArea: false,
      display: false,
      drawTicks: false,
    },
    ticks: {
      display: false,
    },
    border: {
      display: false,
    },
  },
};

export const tooltipStyle = {
  displayColors: false,
  includeInvisible: false,
  cornerRadius: 12,
  caretSize: 0,
  padding: 14,
  backgroundColor: '#fff',
  borderWidth: 1,
  borderColor: '#dfdfdf',
  titleColor: '#403F3F',
  titleMarginBottom: 2,
  titleFont: {
    size: 14,
    weight: 'normal',
    family: 'Inter',
  },
  bodyColor: '#403F3F',
  bodySpacing: 0,
  bodyFont: {
    size: 32,
    family: 'Inter',
    lineHeight: 1.375,
  },
  footerColor: '#403F3F',
  footerMarginTop: 0,
  footerFont: {
    size: 14,
    family: 'Inter',
    weight: 'normal',
  },
  callbacks: {
    label(tooltipItem: TooltipItem<'line' | 'bar'>) {
      return tooltipItem.formattedValue;
    },
  },
};

export const customPlugins = {
  tooltip: tooltipStyle,
  legend: {
    display: false,
  },
  title: {
    display: false,
  },
};
