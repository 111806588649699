import React, { useState, useEffect } from 'react';
import clsx from 'classnames';
import { Checkbox } from '../index';
import { DataItem, IProps } from './Table.types';

const Table: React.FC<IProps> = (props: IProps) => {
  const { headerItems, columns, onRowClick, rowSelect = false } = props;
  const [data, setData] = useState<DataItem[]>(columns);
  const [sortBy, setSortBy] = useState<keyof DataItem>('id');
  const [sortDesc, setSortDesc] = useState<boolean>(false);

  useEffect(() => {
    setData(columns);
  }, []);

  const handleSort = (column: keyof DataItem) => {
    if (column === sortBy) {
      setSortDesc(!sortDesc);
    } else {
      setSortBy(column);
      setSortDesc(false);
    }
  };

  const sortedData = [...data].sort((a, b) => {
    const aValue = a[sortBy] as string | number;
    const bValue = b[sortBy] as string | number;
    if (aValue < bValue) return sortDesc ? 1 : -1;
    if (aValue > bValue) return sortDesc ? -1 : 1;
    return 0;
  });

  return (
    <table className='min-w-full pl-4 z-1'>
      <thead className='border-[3px] border-[#DFDFDF] border-r-0 border-l-0'>
        <tr>
          {rowSelect && (
            <th>
              <Checkbox label='' control={{ checked: true, onChange: () => {} }} className='ml-4' />
            </th>
          )}
          {headerItems.map((r) => (
            <th
              key={r.title}
              onClick={() => handleSort('id')}
              className={clsx('cursor-pointer py-4 text-[#343A40] text-sm font-medium', r.width)}
            >
              {r.title}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {sortedData.map((item) => (
          <tr
            onClick={onRowClick}
            key={item.id}
            className='border-b-[1px] border-b-[#DFDFDF] cursor-pointer hover:bg-slate-200'
          >
            {rowSelect && (
              <td className='pl-4 text-[#6C757D] text-sm py-[14px]'>
                <Checkbox control={{ checked: true, onChange: () => {} }} />
              </td>
            )}
            <td className='text-[#6C757D] text-sm py-[14px] flex justify-center items-center'>
              <img
                className='h-[36px] w-[36px] ml-2 rounded-full'
                src={process.env.PUBLIC_URL + '/assets/images/user.png'}
                alt='nature image'
              />
            </td>

            <td className='text-[#6C757D] text-sm py-[14px]'>{item.title}</td>
            <td className='text-[#6C757D] text-sm py-[14px]'>{item.patients}</td>
            <td className='text-[#6C757D] text-sm py-[14px]'>{item.Att1}</td>
            <td className='text-[#6C757D] text-sm py-[14px]'>{item.Att2}</td>
            <td className='text-[#6C757D] text-sm py-[14px]'>{item.Att3}</td>
            <td className='text-[#6C757D] text-sm py-[14px]'>{item.Att4}</td>
            <td className=' py-[4px] justify-center items-center flex'>
              <img
                src={process.env.PUBLIC_URL + '/assets/images/table/action_more.svg'}
                alt='nature image'
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;
