import { useDeleteParticipant } from 'hooks/useDeleteParticipant';
import { useForgetPassword } from 'hooks/useForgetPassword';
import { useEffect, useRef, useState } from 'react';
import { BsThreeDotsVertical } from 'react-icons/bs';

type DotsNavigationProps = {
  active: boolean;
  centerId: string | number;
  item: any;
  onClick?: any;
};

const DotsNavigation = ({ active, centerId, item, onClick }: DotsNavigationProps) => {
  const ref = useRef<HTMLUListElement>(null);
  const [openDots, setOpenDots] = useState(false);
  const { mutate: deleteParticipant } = useDeleteParticipant();
  const { mutate: forgetPasswordParticipant } = useForgetPassword();

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) setOpenDots(false);
  };

  useEffect(() => {
    if (openDots) {
      document.addEventListener('click', handleClickOutside, true);
      return () => {
        document.removeEventListener('click', handleClickOutside, true);
      };
    }
  });

  return (
    <td onClick={onClick} className='flex items-center justify-center'>
      <button className='h-[36px] w-[20px] mt-[50%]' onClick={(e) => {
        setOpenDots((prevState) => !prevState);
        e?.stopPropagation();
      }}>
        <BsThreeDotsVertical className={` ${active ? 'block' : 'hidden'}`} />
      </button>
      {openDots ? (
        <ul
          ref={ref}
          className='rounded-sm !p-0 bg-blue-oil w-[120px] text-left absolute right-[10px] rounded -top-[40px] shadow-md'
        >
          <li className='h-[30px] flex items-center'>
            <button
              className='px-2 text-xs font-sans font-medium text-white transition-all hover:bg-blue-ocean-deep w-full h-full'
              onClick={(e: any) => {
                deleteParticipant({ centerId, ...item });
                e?.stopPropagation();
              }}
            >
              Delete
            </button>
          </li>
          <li className='h-[30px] flex items-center'>
            <button
              className='px-2 text-xs font-sans font-medium text-white transition-all hover:bg-blue-ocean-deep w-full h-full'
              onClick={(e: any) => {
                forgetPasswordParticipant(item?.userProfile?.userAccount?.email);
                e?.stopPropagation();
              }}
            >
              Reset password
            </button>
          </li>
        </ul>
      ) : null}
    </td>
  );
};

export default DotsNavigation;
