import { axiosClient } from 'config/axios';
import { IFolder, IFolderForm, IUpdateFolder } from 'types/folder';

export const createFolder = async (payload: IFolderForm) => {
  const { data } = await axiosClient.post<IFolder>(`/folders/studies/${payload?.studyId ?? 1}`, {
    name: payload.name,
    sortOrder: payload.sortOrder,
  });
  return data;
};

export const updateFolder = async (payload: IUpdateFolder) => {
  const { data } = await axiosClient.put<IFolder>('/folders', payload);
  return data;
};
