import { useState, useRef, useCallback, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Select, TextInput } from 'components/common';
import { useQuery, useMutation } from '@tanstack/react-query';
import {
  fetchOnboardingFlows,
  createNewFlowFC,
  addTaskToFlowFC,
  reOrderTasksFC,
  editFlowTitleFC,
  deleteTaskFC,
  deleteFlowFC,
} from 'services/api/onboarding';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { INewFlow, INewTaskFlow, IFlow, ITask, ITaskType } from 'types/flow';
import { toast } from 'react-toastify';
import { useOutsideClick } from 'hooks/useOutsideClick';
import { FiChevronDown, FiChevronUp, FiPaperclip, FiMoreVertical } from 'react-icons/fi';
import clsx from 'classnames';
import RightContent from './RightContent';
import { InfoIcon } from './Icons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
let localFlows: any = [];
const SiteSelect = () => {
  const { studyId } = useParams();

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const selectedTaskIdFromUrl = queryParams.get('selectedTaskId');
  const selectedFlowIdFromUrl = queryParams.get('selectedFlowId');
  const tasksEndRef = useRef<HTMLDivElement>(null);
  const [createNewFlow, setCreateNewFlow] = useState(false);
  const [selectedFlow, setSelectedFlow] = useState<IFlow | null | any>(null);
  const [selectedTask, setSelectedTask] = useState<ITask | null>(null);
  const [editFlowTitle, setEditFlowTitle] = useState<string | null>(null);
  const [hoveredTaskId, setHoveredTaskId] = useState<string | null>(null);
  const [hoveredFlowId, setHoveredFlowId] = useState<string | null>(null);
  const [showDeletePopup, setShowDeletePopup] = useState<string | null>(null);
  const [newFlowCollapse, setNewFlowCollapse] = useState(true);
  const [newFlow, setNewFlow] = useState(null);

  const [information, setTaskInformation] = useState(selectedTask?.information || '');
  const intl = useIntl();
  const newFlowContainerRef = useRef<HTMLDivElement>(null);
  const editFlowTitleRef = useRef<HTMLDivElement>(null);
  const [showTaskOptions, setShowTaskOptions] = useState(false);

  const scrollToBottom = () => {
    tasksEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };
  const toggleTaskOptions = () => {
    setShowTaskOptions(!showTaskOptions);
  };
  const handleDeleteTask = (taskId: string) => {
    deleteTaskMutate(taskId);
    setShowDeletePopup(null);
    setSelectedTask(null);
  };
  const handleDeleteFlow = (flowId: string) => {
    deleteFlowMutate(flowId);
    setShowDeletePopup(null);
    setSelectedFlow(null);
  };
  const { data: flows, refetch: refetchFlows } = useQuery<any>({
    queryKey: ['flows-list', studyId],
    queryFn: () => fetchOnboardingFlows(studyId),
    refetchOnMount: 'always',
  });
  localFlows = flows;
  useEffect(() => {
    const savedFlow = localStorage.getItem('selectedFlow');
    const savedTask = localStorage.getItem('selectedTask');
    if (savedFlow) setSelectedFlow(JSON.parse(savedFlow));
    if (savedTask) setSelectedTask(JSON.parse(savedTask));
  }, []);
  useEffect(() => {
    // if (!selected) {
    //   localStorage.removeItem('selectedTask');
    // }
    if (selectedTaskIdFromUrl && flows) {
      const fndFlow = flows.find((flw: IFlow) => flw.id === Number(selectedFlowIdFromUrl));
      const fndTask = fndFlow?.tasks?.find(
        (tsk: ITask) => tsk.id === Number(selectedTaskIdFromUrl),
      );
      if (fndTask) {
        setSelectedTask(fndTask);
        localStorage.setItem('selectedTask', JSON.stringify(fndTask));
      }
    }
  }, [selectedTaskIdFromUrl, flows, selectedFlowIdFromUrl]);

  useEffect(() => {
    const fndFlow = flows?.find((flw: IFlow) => flw?.id === selectedFlow?.id);
    const fndTask = fndFlow?.tasks?.find((tsk: ITask) => tsk?.id === selectedTask?.id);
    const savedFlow = localStorage.getItem('selectedFlow');
    if (!savedFlow && flows?.length) setSelectedFlow(flows?.[0]);
    if (
      (fndTask?.attachments?.length &&
        fndTask?.attachments?.length > Number(selectedTask?.attachments?.length)) ||
      fndTask?.questionnaire?.questions?.length !== selectedTask?.questionnaire?.questions?.length
      // fndTask?.questionnaire?.questions?.some(
      //   (q: any, index: number) =>
      //     q.questionType !== selectedTask?.questionnaire?.questions?.[index]?.questionType,
      // )
    ) {
      setSelectedFlow(fndFlow);
      setSelectedTask(fndTask);
    }
  }, [flows]);

  useEffect(() => {
    if (selectedTaskIdFromUrl && flows) {
      const fndFlow = flows.find((flw: IFlow) =>
        flw.tasks.some((tsk: ITask) => tsk.id === Number(selectedTaskIdFromUrl)),
      );
      const fndTask = fndFlow?.tasks.find((tsk: ITask) => tsk.id === Number(selectedTaskIdFromUrl));
      if (fndFlow && fndTask) {
        setSelectedFlow(fndFlow);
        setSelectedTask(fndTask);
      }
    }
  }, [selectedTaskIdFromUrl, flows]);

  useEffect(() => {
    if (selectedFlowIdFromUrl && flows) {
      const fndFlow = flows.find((flw: IFlow) => flw.id === Number(selectedFlowIdFromUrl));
      if (fndFlow) {
        setSelectedFlow(fndFlow);
      }
    }
  }, [selectedFlowIdFromUrl, flows]);

  useEffect(() => {
    if (
      selectedFlow &&
      ((selectedFlowIdFromUrl && selectedFlow.id === Number(selectedFlowIdFromUrl)) ||
        !selectedFlowIdFromUrl)
    ) {
      localStorage.setItem('selectedFlow', JSON.stringify(selectedFlow));
      navigate({
        search: `?selectedFlowId=${selectedFlow.id}&selectedTaskId=${selectedTask?.id ?? ''}`,
      });
    }
    if (selectedTask) localStorage.setItem('selectedTask', JSON.stringify(selectedTask));
    // else localStorage.removeItem('selectedTask');
  }, [selectedFlow, selectedTask]);

  const handleCreateNewFlow = () => {
    setSelectedFlow(null);
    setCreateNewFlow(true);
    setSelectedTask(null);
    setTimeout(() => {
      document.getElementById('new-flow')?.focus();
    }, 200);
  };
  const { mutate: createNewFlowMutate } = useMutation<INewFlow, unknown, INewFlow, unknown>(
    createNewFlowFC,
    {
      onSuccess: (newFlow: INewFlow) => {
        setNewFlow(null);
        setSelectedFlow(newFlow); // Set the newly created flow as the selected flow
        refetchFlows();
        setSelectedTask(null);
        navigate({
          search: `?selectedFlowId=${newFlow?.id}&selectedTaskId=${''}`,
        });
      },
      onError(error: any) {
        toast.error(
          error?.response?.data?.message ?? intl.formatMessage({ id: 'error.questionAdd' }),
        );
      },
    },
  );
  const { mutate: addTaskToFlowMutate } = useMutation<INewTaskFlow, unknown, INewTaskFlow, unknown>(
    (data: INewTaskFlow) => addTaskToFlowFC(data),
    {
      onSuccess: (latestTask: any) => {
        refetchFlows();
        setTimeout(() => {
          setSelectedTask(latestTask);

          scrollToBottom();
        }, 500);
      },
      onError(error: any) {
        toast.error(
          error?.response?.data?.message ?? intl.formatMessage({ id: 'error.questionAdd' }),
        );
      },
    },
  );
  const { mutate: reOrderTasksMutate } = useMutation<any, unknown, any, unknown>(
    (data: any) => reOrderTasksFC(data),
    {
      onSuccess: () => {
        refetchFlows();
      },
      onError(error: any) {
        toast.error(
          error?.response?.data?.message ?? intl.formatMessage({ id: 'error.questionAdd' }),
        );
      },
    },
  );
  const { mutate: editFlowTitleMutate } = useMutation<any, unknown, any, unknown>(
    (data: any) => editFlowTitleFC(data),
    {
      onSuccess: () => {
        refetchFlows();
        setEditFlowTitle(null);
      },
      onError(error: any) {
        toast.error(
          error?.response?.data?.message ?? intl.formatMessage({ id: 'error.questionAdd' }),
        );
      },
    },
  );
  const { mutate: deleteTaskMutate } = useMutation<any, unknown, any, unknown>(
    (id: string) => deleteTaskFC(id),
    {
      onSuccess: () => {
        toast.success(intl.formatMessage({ id: 'study-builder.flows-task-success-task-delete' }));
        localStorage.removeItem('selectedTask');
        refetchFlows();
      },
      onError(error: any) {
        toast.error(
          error?.response?.data?.message ?? intl.formatMessage({ id: 'error.questionAdd' }),
        );
      },
    },
  );
  const { mutate: deleteFlowMutate } = useMutation<any, unknown, any, unknown>(
    (id: string) => deleteFlowFC(id),
    {
      onSuccess: (data: any, flowId: any) => {
        toast.success(intl.formatMessage({ id: 'study-builder.flows-task-success-task-delete' }));
        refetchFlows().then((result) => {
          const newFlows = result.data;
          if (newFlows && newFlows.length > 0) {
            const deletedFlowId = flowId;
            const prevFlow =
              newFlows.find((flow: IFlow) => flow?.id !== deletedFlowId) || newFlows[0];
            setSelectedFlow(prevFlow);
            navigate({
              search: `?selectedFlowId=${prevFlow.id}&selectedTaskId=${prevFlow.tasks?.[0]?.id ?? ''}`,
            });
          } else {
            setSelectedFlow(null);
            setSelectedTask(null);
            navigate({
              search: '',
            });
          }
        });
      },
      onError(error: any) {
        toast.error(
          error?.response?.data?.message ?? intl.formatMessage({ id: 'error.questionAdd' }),
        );
      },
    },
  );
  const handleNewFlowKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      //
      createNewFlowMutate({ title: newFlow, studyId: studyId });
    }
  };
  const handleEditFlowKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      editFlowTitleMutate({ id: selectedFlow.id, newTitle: editFlowTitle });
    }
  };
  const handleOutsideClick = useCallback((e: any, clickedOut: boolean) => {
    if (clickedOut) {
      if (newFlow) createNewFlowMutate({ title: newFlow, studyId: studyId });
      else {
        setCreateNewFlow(false);
        // if (flows?.length) setSelectedFlow(flows?.[0]);
      }
    }
  }, []);
  const handleOptClick = (option: string) => {
    const maxSortOrder = selectedFlow?.tasks?.length
      ? Math.max(...selectedFlow.tasks.map((task: ITask) => task.sortOrder)) + 1
      : 1;

    addTaskToFlowMutate({
      workflowId: selectedFlow?.id ?? '',
      type: option
        .split(' ')
        .map((word) => word.toUpperCase())
        .join('_'),
      sortOrder: maxSortOrder,
    });
  };
  const filterTasksTypes = (firstArray: string[], secondArray: string[]) => {
    return firstArray?.filter(
      (item) =>
        !secondArray?.includes(
          item
            .split(' ')
            .map((word) => word.toUpperCase())
            .join('_'),
        ),
    );
  };
  const handleOutsideEditFlowTitleClick = () => {
    if (editFlowTitle !== selectedFlow.title)
      editFlowTitleMutate({ id: selectedFlow.id, newTitle: editFlowTitle });
  };
  useOutsideClick(newFlowContainerRef, handleOutsideClick);
  useOutsideClick(editFlowTitleRef, handleOutsideEditFlowTitleClick);

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    const items = Array.from(selectedFlow.tasks);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setSelectedFlow({
      ...selectedFlow,
      tasks: items,
    });
    const newOrders: any = items.map((task: any) => task.id);
    handleReorderTasks(newOrders);
  };
  const handleReorderTasks = (orders: number[]) => {
    reOrderTasksMutate({ flowId: selectedFlow.id, newOrders: orders });
  };
  const handleEditFlowTitle = (flowTitle: string) => {
    setEditFlowTitle(flowTitle);
  };

  const handleTaskSelection = (task: ITask, index: number) => {
    setSelectedTask({
      ...task,
      taskIndex: `TASK ${index + 1}`,
    });

    navigate({
      search: `?selectedFlowId=${selectedFlow?.id}&selectedTaskId=${task.id}`,
    });
  };
  const handleFlowSelect = (e: any) => {
    const fndFlow = (flows ?? localFlows)?.find((flw: IFlow) => flw?.title === e);
    console.log('here fndFlow:', fndFlow, flows,localFlows);
    if (e !== selectedFlow?.title && e) {
      setSelectedFlow(fndFlow);
      setSelectedTask(null);
      navigate({
        search: `?selectedFlowId=${fndFlow?.id}&selectedTaskId=${selectedTask?.id ?? ''}`,
      });
    } else setSelectedFlow(flows?.find((flw: IFlow) => flw?.title === selectedFlow?.title));
  };
  
  return (
    <div className='mt-11 w-full h-[calc(100vh-190px)] flex flex-1 flex-col bg-white max-w-screen-2xl mx-auto px-6 xxl:px-0'>
      <div className='pl-6 flex justify-between items-center pb-1'>
        <h2 className='cursor-pointer text-left text-blue-ocean-deep text-[40px] leading-[34px] font-light'>
          <FormattedMessage id='study-builder.workflowtasks' />
        </h2>
      </div>
      <div className='flex gap-x-6 mt-4 pb-10'>
        <div className='w-[289px] bg-[#FEE] rounded-md border-[1px] border-gray-300 h-[825px]'>
          <div
            className={clsx(
              'border-1 pt-3',
              newFlowCollapse ? 'h-[calc(100%-9rem)]' : 'h-[calc(100%-4rem)]',
            )}
          >
            {flows?.length && selectedFlow?.title ? (
              <>
                {editFlowTitle ? (
                  <div className='' ref={editFlowTitleRef}>
                    <TextInput
                      name='edit-flow'
                      control={{
                        value: editFlowTitle,
                        onChange: (e) => {
                          setEditFlowTitle(e.target.value);
                        },
                      }}
                      className='!mt-0 border-b-1 border-[rgba(0,0,0,0.10)] border-dashed w-[95%] mx-auto'
                      placeholder='New flow ...'
                      inputClassName='border-none bg-[#FEE] focus:outline-none focus:bg-transparent'
                      onKeyDown={handleEditFlowKeyDown}
                    />
                  </div>
                ) : (
                  <h3
                    onMouseEnter={() => setHoveredFlowId(selectedFlow.id.toString())}
                    onMouseLeave={() => setHoveredFlowId(null)}
                    onDoubleClick={() => handleEditFlowTitle(selectedFlow.title)}
                    className='flex pb-[9px] w-[95%] mx-auto text-blue-oil text-[20px] font-semibold leading-[28px] text-center'
                  >
                    <p className='w-[90%]'>{selectedFlow?.title}</p>
                    {hoveredFlowId === selectedFlow.id.toString() && (
                      <div className='relative -mr-[15px] w-[10%]'>
                        <button
                          onClick={() => setShowDeletePopup(selectedFlow.id.toString())}
                          className='text-gray-500 hover:text-gray-700'
                        >
                          <FiMoreVertical className='text-blue-ocean-deep' />
                        </button>
                        {showDeletePopup === selectedFlow.id.toString() && (
                          <div className='absolute right-0 mt-0 bg-white rounded'>
                            <button
                              onClick={() => handleDeleteFlow(selectedFlow?.id.toString())}
                              className='flex items-center px-1 py-1 text-sm text-white bg-blue-ocean-deep rounded z-100'
                            >
                              Delete
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                  </h3>
                )}
              </>
            ) : createNewFlow ? (
              <div ref={newFlowContainerRef}>
                <TextInput
                  name='new-flow'
                  control={{
                    value: newFlow,
                    onChange: (e) => {
                      setNewFlow(e.target.value);
                    },
                  }}
                  className='!mt-0 border-b-1 border-[rgba(0,0,0,0.10)] border-dashed w-[95%] mx-auto'
                  placeholder='New flow ...'
                  inputClassName='border-none bg-[#FEE] focus:outline-none focus:bg-transparent'
                  onKeyDown={handleNewFlowKeyDown}
                />
              </div>
            ) : (
              <></>
            )}
            <>
              {flows?.length && selectedFlow ? (
                <div className='border-t-1 border-t-gray-300 border-dashed pt-[9px] w-full h-[95%]'>
                  <div className='h-full relative pt-6 pl-[9px] pr-[8px]'>
                    <h2 className='absolute top-0 w-full text-blue-ocean-deep text-[20px] font-semibold leading-[28px] text-left'>
                      Tasks Library
                    </h2>
                    <div className='mt-3 h-[calc(100%-40px)] flex flex-col'>
                      <div
                        className={clsx(
                          'overflow-y-auto pr-1 overflow-x-hidden',
                          selectedFlow?.tasks?.length > 6 ? 'flex-grow' : '',
                        )}
                      >
                        <DragDropContext onDragEnd={onDragEnd}>
                          <Droppable droppableId='tasks'>
                            {(provided) => (
                              <div {...provided.droppableProps} ref={provided.innerRef}>
                                {selectedFlow?.tasks?.map((task: ITask, index: number) => (
                                  <Draggable
                                    key={task.id}
                                    draggableId={task.id.toString()}
                                    index={index}
                                  >
                                    {(provided) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        className={clsx(
                                          'z-2 flex justify-start items-center mb-4 bg-white rounded-lg px-4 py-2 w-full',
                                          selectedTask?.id === task.id && 'border-1 border-purple',
                                        )}
                                        onMouseEnter={() => setHoveredTaskId(task.id.toString())}
                                        onMouseLeave={() => setHoveredTaskId(null)}
                                      >
                                        <button
                                          onClick={() => handleTaskSelection(task, index)}
                                          className='z-1 flex justify-start items-center flex-1 w-full'
                                        >
                                          <div className='flex flex-col flex-1 w-full'>
                                            <h4 className='w-full text-left text-xs text-blue-oil font-light'>
                                              TASK {index + 1}
                                            </h4>
                                            <h3
                                              className={clsx(
                                                'text-sm text-blue-oil font-bold m-0 mt-1 text-left w-full truncate  text-ellipsis flex justify-start',
                                                MANDATORY_ATTACHMENTS.includes(task.title) &&
                                                  'pr-5',
                                              )}
                                            >
                                              <span className='truncate'>{task.title}</span>
                                              {MANDATORY_ATTACHMENTS.includes(task.type) ? (
                                                <span className='inline-flex'>
                                                  <FiPaperclip className='mt-1 w-3 h-3 ml-1 text-sm shrink-0' />
                                                  {!task?.attachments?.length &&
                                                  ((task?.actionType &&
                                                    task?.actionType !== 'CHOOSE_RESPONSE') ||
                                                    !task?.actionType) ? (
                                                    <InfoIcon />
                                                  ) : null}
                                                </span>
                                              ) : null}
                                            </h3>
                                          </div>
                                        </button>
                                        {hoveredTaskId === task.id.toString() && (
                                          <div className='relative -mr-[15px]'>
                                            <button
                                              onClick={() => setShowDeletePopup(task.id.toString())}
                                              className='text-gray-500 hover:text-gray-700'
                                            >
                                              <FiMoreVertical className='text-blue-ocean-deep' />
                                            </button>
                                            {showDeletePopup === task.id.toString() && (
                                              <div className='absolute right-0 mt-0 bg-white rounded'>
                                                <button
                                                  onClick={() =>
                                                    handleDeleteTask(task.id.toString())
                                                  }
                                                  className='flex items-center px-1 py-1 text-sm text-white bg-blue-ocean-deep rounded z-100'
                                                >
                                                  Delete
                                                </button>
                                              </div>
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                                <div ref={tasksEndRef} />
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                      </div>

                      <div className='mt-1 relative'>
                        <button
                          onClick={toggleTaskOptions}
                          className='relative w-full bg-white rounded-md h-9 px-4 py-[10px] flex justify-between items-center'
                        >
                          <span className='text-blue-ocean-deep text-sm font-bold text-left'>
                            Add task
                          </span>
                          <span className='text-blue-ocean-deep text-sm text-right w-6 h-6 flex justify-end items-center'>
                            +
                          </span>
                        </button>

                        {showTaskOptions && (
                          <div className='absolute bottom-full left-[250px] -right-[250px] bg-white -mb-[100px] border border-purple rounded-2xl shadow-md z-100 py-2'>
                            {filterTasksTypes(
                              TASKS_TYPES.map((taskType: ITaskType) => taskType.label),
                              selectedFlow?.tasks
                                ?.map((tsk: ITask) => tsk.type)
                                .filter((tsk: string) => tsk !== 'GENERIC_DOCUMENT'),
                            ).map((option: string) => (
                              <button
                                key={option}
                                onClick={() => {
                                  handleOptClick(option);
                                  setShowTaskOptions(false);
                                }}
                                className='block w-full text-left px-1 pl-2 py-2 text-sm text-blue-ocean-deep font-light hover:bg-blue-very-light bg-white z-100 rounded-2xl'
                              >
                                {option}
                              </button>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </>
          </div>
          <div
            className={clsx(
              'cursor-pointer w-full border-t-1 border-dashed flex flex-col justify-center items-center',
              newFlowCollapse ? 'h-[9rem]' : 'h-[4rem]',
            )}
          >
            {newFlowCollapse ? (
              <>
                <Button
                  onClick={handleCreateNewFlow}
                  className='text-blue-ocean-deep text-[20px] font-semibold leading-[28px]'
                >
                  + {intl.formatMessage({ id: 'study-builder.flows-task-new-workflow' })}
                </Button>
                <Select
                  className='w-[174px] h-[30px] mt-1'
                  buttonClassName='bg-white h-[20px] !rounded-[4px]'
                  name='studyStatus'
                  mainClass='!rounded-[4px]'
                  defaultValue={''}
                  optWrapperClassName='border border-blue-ocean-deep border-t-gray-light'
                  customControl={{
                    onChange: handleFlowSelect,
                    value: selectedFlow?.title ?? '',
                  }}
                  noTranslation={true}
                  selectOptClass='text-blue-ocean-deep text-sm font-light'
                  placeholder='Choose workflow'
                  options={flows?.map((flow: IFlow) => ({ label: flow.title, value: flow.title }))}
                />
                <FiChevronDown
                  onClick={() => setNewFlowCollapse((prev) => !prev)}
                  className={clsx(
                    'transition-all w-8 h-8 text-blue-ocean-deep mt-2 shrink-0 cursor-pointer',
                    // newFlowCollapse && '-rotate-180',
                  )}
                />
              </>
            ) : (
              <>
                <FiChevronUp
                  onClick={() => setNewFlowCollapse((prev) => !prev)}
                  className={clsx(
                    'transition-all w-8 h-8 text-blue-ocean-deep cursor-pointer',
                    // newFlowCollapse && '-rotate-180',
                  )}
                />
              </>
            )}
          </div>
        </div>
        <div className='flex flex-1 grow border-purple rounded-md border-[1px] relative min-h-[825px] '>
          <div className='w-full h-full z-2'>
            {selectedTask ? (
              <RightContent
                refetchFlows={refetchFlows}
                selectedTask={selectedTask}
                {...{ information, setTaskInformation, setSelectedTask }}
              />
            ) : null}
          </div>

          <img
            src={process.env.PUBLIC_URL + '/assets/images/site-select/site-select-list-bg.png'}
            alt='action more'
            className='w-[389px] h-[423px] absolute bottom-1 right-1 z-1'
          />
        </div>
      </div>
    </div>
  );
};
export default SiteSelect;
export const TASKS_TYPES: ITaskType[] = [
  { label: 'Verify Site Profile Information', value: 'VERIFY_SITE_PROFILE_INFORMATION' },
  { label: 'Sign and Return CDA', value: 'SIGN_AND_RETURN_CDA' },
  { label: 'Agree to Confidentiality Statement', value: 'AGREE_TO_CONFIDENTIALITY_STATEMENT' },
  { label: 'View Protocol', value: 'VIEW_PROTOCOL' },
  { label: 'Verify Site Interest', value: 'VERIFY_SITE_INTEREST' },
  { label: 'Verify Site Interest Again', value: 'VERIFY_SITE_INTEREST_AGAIN' },
  { label: 'Complete Feasibility Survey', value: 'COMPLETE_FEASIBILITY_SURVEY' },
  { label: 'Enter Date Available for Site Visit', value: 'ENTER_DATE_AVAILABLE_FOR_SITE_VISIT' },
  { label: 'Enter Estimated Patient Count', value: 'ENTER_ESTIMATED_PATIENT_COUNT' },
  { label: 'Generic Document', value: 'GENERIC_DOCUMENT' },
];
export const MANDATORY_ATTACHMENTS: string[] = [
  'AGREE_TO_CONFIDENTIALITY_STATEMENT',
  'SIGN_AND_RETURN_CDA',
  'VIEW_PROTOCOL',
  'GENERIC_DOCUMENT',
];
