import { useEffect, useState } from 'react';
import { FiBriefcase, FiFolderPlus, FiInbox } from 'react-icons/fi';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useUserStore } from 'store/usersStore';
import { useQuery } from '@tanstack/react-query';
import { getRoles } from 'services/api/users';
import { FormattedMessage } from 'react-intl';
import clsx from 'classnames';

const Sites: React.FC = () => {
  const navigate = useNavigate();
  const [tab, setTab] = useState('all');
  const { setRolesOpt } = useUserStore();
  const { studyId } = useParams();

  const handleTab = (item: string) => {
    setTab(item);
    navigate(item);
  };

  const {
    isSuccess: roleSuccess,
    data: roles,
    refetch: roleRefetch,
  } = useQuery({
    queryKey: ['roles'],
    queryFn: () => getRoles('STUDY', studyId),
  });

  useEffect(() => {
    roleSuccess &&
      roles?.length > 0 &&
      setRolesOpt(
        roles
          ?.filter((role) => role?.category === 'SITE_STAFF')
          ?.map((item: any) => {
            return {
              value: item.id,
              label: item.name,
            };
          }),
      );
  }, [roles]);

  useEffect(() => {
    studyId && roleRefetch();
  }, [studyId]);

  useEffect(() => {
    switch (location.pathname.split('/')[4]) {
      case 'all':
        setTab('all');
        break;
      case 'onboarding':
        setTab('onboarding');
        break;
      default:
        setTab('all');
    }
  }, [location]);

  const isStudyOnboarding = `${studyId}` === process.env.REACT_APP_STUDY_ID;

  return (
    <div className='flex bg-background-secondary flex-1 gap-[2.9375rem] pr-11'>
      <div className='w-[84px] h-fit font-serif mt-[36px] border-2 border-blue-ocean-deep bg-white rounded-r-lg flex flex-col justify-start items-center gap-[1.625rem] py-[1.625rem]'>
        <div
          onClick={() => handleTab('all')}
          className={clsx(
            'h-[3.75rem] w-full text-xs flex flex-col justify-center items-center cursor-pointer',
            tab === 'all'
              ? 'bg-[rgba(202,199,199,.22)] bg-opacity-[0.22] font-bold'
              : 'font-normal',
          )}
        >
          <FiBriefcase
            className={clsx(
              'text-blue-ocean-deep w-6 h-6 mb-1.5',
              tab === 'all' ? 'stroke-[3px]' : 'stroke-[1px]',
            )}
          />
          <span className='text-blue-ocean-deep'>
            <FormattedMessage id='allStudies.all' />
          </span>
        </div>
        <div
          onClick={() => isStudyOnboarding ? handleTab('onboarding') : null}
          className={clsx(
            'h-[3.75rem] w-full text-xs flex flex-col justify-center items-center',
            isStudyOnboarding ? 'cursor-pointer' : 'cursor-not-allowed pointer-events-none !bg-[rgba(255,255,255,0.22)] opacity-40',
            tab === 'onboarding'
              ? 'bg-[rgba(202,199,199,.22)] bg-opacity-[0.22] font-bold'
              : 'font-normal',
          )}
        >
          <FiFolderPlus
            className={clsx(
              'text-blue-ocean-deep w-6 h-6 mb-1.5',
              tab === 'onboarding' ? 'stroke-[3px]' : 'stroke-[1px]',
            )}
          />
          <span className='text-xs text-blue-ocean-deep'>
            <FormattedMessage id='site.onboarding' />
          </span>
        </div>
        <div
          // onClick={() => handleTab('requests')}
          className={clsx(
            'h-[3.75rem] w-full text-xs flex flex-col justify-center items-center cursor-not-allowed pointer-events-none !bg-[rgba(255,255,255,0.22)] opacity-40',
            tab === 'requests'
              ? 'bg-[rgba(202,199,199,.22)] bg-opacity-[0.22] font-bold'
              : 'font-normal',
          )}
        >
          <FiInbox
            className={clsx(
              'text-blue-ocean-deep w-6 h-6 mb-1.5',
              tab === 'requests' ? 'stroke-[3px]' : 'stroke-[1px]',
            )}
          />
          <span className='text-xs text-blue-ocean-deep'>
            <FormattedMessage id='site.requests' />
          </span>
        </div>
      </div>
      <div className='flex-1 grow pt-11 container mx-auto'>
        <Outlet />
      </div>
    </div>
  );
};
export default Sites;
