import { useCallback, useRef, useState } from 'react';
import QueryInput from './QueryInput';
import { IPutQueryForm, deleteQueryAnswer, deleteQuestionQuery, resolveQuestionQuery, updateQueryAnswer, updateQuestionQuery } from 'services/api/questionQuery';
import { useMutation } from '@tanstack/react-query';
import { useOutsideClick } from 'hooks/useOutsideClick';
import { IQueryQuestion } from 'store/queryQuestionStore';
import { SvgIcon } from '@mui/material';
import { ResolveIcon, ThreeDotIcon } from 'components/common/Icons';
import { ThreeDotsDropdown, Tooltip } from 'components/common';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';
import { useStudyStore } from '../../../../store/StudyStore';

interface IQProps {
  id: number;
  methods?: any;
  refetch?: any;
  isReply?: boolean;
  isResolved?: boolean;
  isAction?: boolean;
  showQuery?: boolean;
  setShowQuery: (query: boolean) => void;
  query: IQueryQuestion;
}

const QueryItem = ({ id, methods, setShowQuery, isReply, refetch, query, isResolved, isAction }: IQProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [hoverId, setHoverId] = useState(-1);
  const [isEdit, setIsEdit] = useState(false);
  const { loggedInStudyUser } = useStudyStore();
  const intl = useIntl();

  const { mutate: resolveQuery } = useMutation<
    any,
    unknown,
    number,
    unknown
  >(
    (id: number) => resolveQuestionQuery(id),
    {
      onSuccess: () => {
        refetch();
      },
      onError(e: any) {
        toast.error(e?.response?.data?.message);
      },
    },
  );

  const { mutate: deleteQuery } = useMutation<any, unknown, number, unknown>(
    (id: number) => deleteQuestionQuery(id),
    {
      onSuccess: () => {
        refetch();
      },
      onError(e: any) {
        toast.error(e?.response?.data?.message);
      },
    },
  );

  const { mutate: updateQuery } = useMutation<any, unknown, { id: number, data: IPutQueryForm }, unknown>(
    ({ id, data }) => updateQuestionQuery(id, data),
    {
      onSuccess: () => {
        refetch();
        setIsEdit(false);
      },
      onError(e: any) {
        toast.error(e?.response?.data?.message);
      },
    },
  );

  const { mutate: deleteQueryReply } = useMutation<any, unknown, number, unknown>(
    (id: number) => deleteQueryAnswer(id),
    {
      onSuccess: () => {
        refetch();
      },
      onError(e: any) {
        toast.error(e?.response?.data?.message);
      },
    },
  );

  const { mutate: updateQueryReply } = useMutation<any, unknown, { id: number, data: IPutQueryForm }, unknown>(
    ({ id, data }) => updateQueryAnswer(id, data),
    {
      onSuccess: () => {
        refetch();
        setIsEdit(false);
      },
      onError(e: any) {
        toast.error(e?.response?.data?.message);
      },
    },
  );

  const handleOutsideClick = useCallback((e: any, clickedOut: boolean) => {
    if (clickedOut) {
      methods.setValue(`query${id}`, '');
      setShowQuery?.(false);
    }
  },
    [setShowQuery],
  );

  const handleclick = (type: string) => {
    if (type === 'edit' && query?.id) {
      methods.setValue(`query${query?.id}`, query.comment);
      setIsEdit(true);
    } else if (type === 'delete' && query?.id) {
      isReply ? 
        deleteQueryReply(query?.id)
        :
        deleteQuery(query.id);
    }
  };

  const handleResolve = () => {
    resolveQuery(query.id);
  }

  const handleUpdateQuery = (data: any) => {
    isReply ? 
      updateQueryReply({ id: query?.id, data: { comment: data } })
      :
      updateQuery({ id: query?.id, data: { comment: data } })
  }

  useOutsideClick(containerRef, handleOutsideClick);

  const isAutoQuery = query?.comment === 'This question is mandatory. Please enter a value'

  return (
    <div className={`mb-1 z-20 ${hoverId === id && 'group-query'}`} onMouseEnter={() => setHoverId(id)}>
      <div className='flex items-center justify-between h-5'>
        <p className={`text-[0.7rem] ${isResolved ? 'text-gray-light' : 'text-blue'} leading-4 font-semibold`}>{isAutoQuery ? 'Milo' : `${query?.centerUser?.user?.firstName || ''} ${query?.centerUser?.user?.lastName || ''}`}</p>
        {isAction && loggedInStudyUser?.id !== -1 && `${loggedInStudyUser?.id}` === `${query?.centerUser?.id}` && !isResolved && (
          <div className='items-center group-query-hover:flex flex'>
            <ThreeDotsDropdown
              classes={{
                content: '!bg-blue-oil !w-14 !text-white !mt-0 [&>ul]:!pl-0',
                button: isReply ? '!px-0' : '',
                options: 'hover:!bg-blue-ocean-deep text-white text-[0.7rem] !px-2 !py-1 leading-normal',
              }}
              dotIcon={<SvgIcon className='cursor-pointer !h-2.5 text-blue-ocean-deep' component={ThreeDotIcon} />}
              options={[
                { action: () => handleclick('edit'), label: 'edit' },
                {
                  action: () => handleclick('delete'),
                  label: 'delete',
                },
              ]}
            />
            {!isReply && (
              <Tooltip className='ml-2' text={intl.formatMessage({id : 'study.resolve'})} position='top'>
                <SvgIcon
                  className='!w-2.5 !h-2.5 cursor-pointer'
                  onClick={handleResolve}
                  component={ResolveIcon}
                />
              </Tooltip>
            )}
          </div>
        )}
      </div>
      {isEdit ? (
        <QueryInput name={`query${query?.id}`} methods={methods} onSubmitQuery={(e: any) => handleUpdateQuery(e)} />
      ) : (
        <p className={`text-[0.7rem] ${isReply ? 'pl-1.5' : `${query?.replies && query.replies?.length > 0 && 'text-gray-medium'}`}`}>{query?.comment}</p>
      )}
    </div>
  )
}

export default QueryItem;