import { useOutsideClick } from 'hooks/useOutsideClick';
import { useEffect, useRef } from 'react';
import ScheduleEproRepeat from './ScheduleEproRepeat';
import { FormattedMessage, useIntl } from 'react-intl';
import useEproSchedule from 'hooks/useEproSchedule';
import _ from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';
import { Select } from 'components/common';
import { isSameMinute } from 'date-fns';

type ScheduleEproProps = {
  setEproModal: React.Dispatch<React.SetStateAction<boolean>>;
  eproModal: boolean;
  hasEpro?: any;
};

const initialValues = {
  repeat: '',
  frequency: '1',
  endDate: '',
  ends: 'AFTER',
  endCriteria: 'OCCURRENCES',
  weeklyOnDays: [],
  monthlyOnDay: '',
  endNumber: '',
};

const ScheduleEpro = ({ setEproModal, eproModal, hasEpro }: ScheduleEproProps) => {
  const intl = useIntl();
  const ref = useRef<HTMLDivElement>(null);
  const mutation = useEproSchedule();
  const formMethods = useForm({
    defaultValues: { ...initialValues },
  });
  const { setValue } = formMethods;

  const values = formMethods?.watch();

  const options: any = [
    { value: 'DO_NOT_REPEAT', label: 'appointment.doesNotrepeat' },
    { value: 'DAILY', label: 'appointment.daily' },
    { value: 'WEEKLY', label: 'appointment.weekly' },
    { value: 'MONTHLY', label: 'appointment.monthly' },
  ];

  const formatDate = (date: any) => {
    if (!date) return null;
    const newDate = new Date(date);
    const tzoffset = newDate.getTimezoneOffset() * 60000;
    return new Date(newDate.valueOf() - tzoffset).toISOString();
  };

  const handleChange = (e: any) => {
    setValue('repeat', e);
    e === 'WEEKLY' ? setValue('weeklyOnDays', ['MONDAY'] as any) : setValue('weeklyOnDays', []);
    handleMutation();
  };

  const handleMutation = () => {
    const { repeat, frequency, endDate, ends, endCriteria, weeklyOnDays, monthlyOnDay, endNumber } =
      formMethods.watch();

    if (repeat && repeat !== 'DO_NOT_REPEAT') {
      let formattedWeeklyOnDays: any =
        repeat === 'WEEKLY'
          ? weeklyOnDays?.length > 0 && weeklyOnDays?.filter((item: any) => item !== '')
          : [];
      if (repeat === 'WEEKLY') {
        formattedWeeklyOnDays = weeklyOnDays?.length > 0 ? _.uniq(weeklyOnDays) : ['MONDAY'];
      }
      const formattedEndDate = formatDate(endDate);
      const mutationData = {
        id: hasEpro?.id,
        data: {
          repeat,
          endDate: ends === 'AFTER' ? null : formattedEndDate,
          frequency,
          ends,
          endNumber: ends === 'ON_THIS_DAY' ? '1' : endNumber,
          endCriteria,
          weeklyOnDays: formattedWeeklyOnDays,
          monthlyOnDay,
        },
      };
      if (repeat != 'WEEKLY') {
        mutation.mutate(mutationData);
      }
      if (repeat === 'WEEKLY' && formattedWeeklyOnDays?.length >= 1) {
        mutation.mutate(mutationData);
      }
    }
    if (repeat && repeat === 'DO_NOT_REPEAT') {
      const mutationData = {
        id: hasEpro?.id,
        data: {
          repeat,
          endDate: null,
          frequency: null,
          ends: null,
          endNumber: null,
          endCriteria: null,
          weeklyOnDays: [],
          monthlyOnDay: null,
        },
      };
      mutation.mutate(mutationData);
    }
  };

  useEffect(() => {
    if (hasEpro) {
      setValue('repeat', hasEpro?.repeat);
    }
  }, [hasEpro]);

  const { repeat } = values;

  const handleClickoutside = () => {
    setEproModal(false);
    if (
      values?.endNumber != hasEpro?.endNumber ||
      values?.frequency != hasEpro?.frequency ||
      !isSameMinute(new Date(values?.endDate), new Date(hasEpro?.endDate))
    ) {
      handleMutation();
    }
  };

  useOutsideClick(ref, handleClickoutside);
  return (
    <div
      className={`min-w-[180px] w-auto h-auto rounded-xl border border-purple p-5 absolute top-[-20px] right-[100px] bg-white z-100 ${eproModal ? 'block' : 'hidden'}`}
      ref={ref}
    >
      <p className='font-sans font-normal text-left text-[#0973D4]'>
        <FormattedMessage id='study-builder.scheduleEPro' />
      </p>
      <FormProvider {...formMethods}>
        <div className='flex items-center gap-2 justify-start'>
          {repeat !== 'DO_NOT_REPEAT' && (
            <p className='text-[#737373] font-normal font-sans text-sm'>
              <FormattedMessage id='study-builder.repeat' />
            </p>
          )}
          <Select
            customControl={{
              onChange: handleChange,
              value: repeat,
            }}
            defaultValue={hasEpro?.repeat || 'DO_NOT_REPEAT'}
            options={options}
            name='repeat'
            label=''
            iconClass='text-gray-medium text-lg stroke-[3px]'
            placeholder={intl.formatMessage({ id: 'appointment.doesNotrepeat' })}
            selectOptClass='!text-sm !text-gray-medium border-b border-gray-medium'
            optionsClassName='!text-sm !px-2 !py-1 !font-normal !bg-blue-oil hover:!bg-[#3e5f7d] !text-white'
            buttonClassName='!px-0 !py-1 min-w-32'
            className='selectEpro'
          />
        </div>
        <ScheduleEproRepeat
          hasEpro={hasEpro}
          formMethods={formMethods}
          type={repeat}
          handleMutation={handleMutation}
        />
      </FormProvider>
    </div>
  );
};

export default ScheduleEpro;
