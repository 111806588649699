import { axiosClient } from 'config/axios';

export const retrieveAuditTrials = async (
  studyId = '',
  filterStr = '',
  pageNum = '',
  pageSize = '',
) => {
  const { data } = await axiosClient.get(
    `/audits/studies/${studyId}?${filterStr}page=${pageNum}&size=${pageSize}`,
  );
  return data;
};

export const retrieveAuditTriggers = async (studyId = '') => {
  const { data } = await axiosClient.get(`/audits/usernames/${studyId}`);
  return data;
};
