import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import {
  fetchAdverseEvents,
  fetchBiologicalData,
  fetchConcoMedicationFormHistory,
  fetchDiseaseHistory,
  fetchMedicalHistory, fetchPatientData,
  fetchSurgicalHistory,
  fetchSymptoms, IPagable,
} from '../services/api/patientProfile';
import { useEffect, useState } from 'react';

const useGetPatientData = () => {
  const { participantId } = useParams();
  const [pageData, setPageData ] = useState<IPagable>({
    page: 0,
    size: 100,
    sort: [''],
  })

  const { data: adverseEventData, refetch: refetchAdverseEventData  } = useQuery({
    queryKey: ['adverseEventData', participantId],
    queryFn: () => fetchAdverseEvents(parseInt(`${participantId}`, 10), pageData),
  });

  const { data: patientData, refetch: refetchPatientData } = useQuery({
    queryKey: ['patientData', participantId],
    queryFn: () => fetchPatientData(parseInt(`${participantId}`, 10)),
  });

  useEffect(() => {
    participantId && refetchAdverseEventData();
    participantId && refetchPatientData();
  }, [participantId]);


  const { data: medicalHistoryData } = useQuery({
    queryKey: ['medicalHistoryForm', participantId],
    queryFn: () => fetchMedicalHistory(parseInt(`${participantId}`, 10)),
  });

  const { data: surgicalHistoryData } = useQuery({
    queryKey: ['surgicalHistoryForm', participantId],
    queryFn: () => fetchSurgicalHistory(parseInt(`${participantId}`, 10), pageData),
  });

  const { data: concoMedicationData } = useQuery({
    queryKey: ['concoMedication', participantId],
    queryFn: () => fetchConcoMedicationFormHistory(parseInt(`${participantId}`, 10), pageData),
  });

  const { data: diseaseHistoryData } = useQuery({
    queryKey: ['diseaseHistory', participantId],
    queryFn: () => fetchDiseaseHistory(parseInt(`${participantId}`, 10), pageData),
  });

  const { data: biologicalData } = useQuery({
    queryKey: ['biologicalData', participantId],
    queryFn: () => fetchBiologicalData(parseInt(`${participantId}`, 10), pageData),
  });

  const { data: symptomsData } = useQuery({
    queryKey: ['symptomsData', participantId],
    queryFn: () => fetchSymptoms(parseInt(`${participantId}`, 10), pageData),
  });

  return {
    setPageData, pageData,
    adverseEventData, patientData, medicalHistoryData,
    surgicalHistoryData, concoMedicationData, diseaseHistoryData,
    biologicalData, symptomsData,
  };
};

export default useGetPatientData