import { axiosClient } from 'config/axios';

export interface IQueryForm {
  baseQuestionId: number;
  patientStepId: number;
  folderId: number | null;
  centerUserId: number;
  comment: string;
}

export interface IQueryAnsForm {
  queryId: number;
  centerUserId: number;
  comment: string
}

export interface IPutQueryForm {
  comment: string
}

export const addQuestionQuery = async (payload: IQueryForm) => {
  const { data } = await axiosClient.post('queries', payload);
  return data;
};

export const getQuestionQueries = async (studyId: number, centerId: number, patientId: number) => {
  const { data } = await axiosClient.get(`queries?studyId=${studyId}${centerId >= 0 ? `&centerId=${centerId}`: ''}${patientId >= 0 ? `&patientId=${patientId}`: ''}`);
  return data;
};

export const updateQuestionQuery = async (id: number, payload: IPutQueryForm) => {
  const { data } = await axiosClient.put(`queries/${id}`, payload);
  return data;
};

export const resolveQuestionQuery = async (id: number) => {
  const { data } = await axiosClient.put(`queries/${id}/resolve`);
  return data;
};

export const deleteQuestionQuery = async (id: number) => {
  const { data } = await axiosClient.delete(`queries/${id}`);
  return data;
};

export const addQueryAnswer = async (payload: IQueryAnsForm) => {
  const { data } = await axiosClient.post('query-replies', payload);
  return data;
};

export const deleteQueryAnswer = async (id: number) => {
  const { data } = await axiosClient.delete(`query-replies/${id}`);
  return data;
};

export const updateQueryAnswer = async (id: number, payload: IPutQueryForm) => {
  const { data } = await axiosClient.put(`query-replies/${id}`, payload);
  return data;
};