import { useEffect, useState } from 'react';
import { IQuestion } from 'types/questionnaire';
import { insertAtIndex } from 'utils/array';
import { getMaxSortId } from 'utils/number';
import DragDropList from '../DragDropList';
import { useQuestionnaire } from '../useQuestionnaire';
import { useParams } from 'react-router-dom';
interface IProps {
  questions?: any;
  handleRepeatedOnSaveQs?: any;
  parentQuestionId?: any;
  allQuestions?: IQuestion[] | undefined;
}
const RepeatedQuestion = ({ questions, allQuestions, parentQuestionId }: IProps) => {
  const { handleAddQSToQuestionnaire } = useQuestionnaire({});
  const { questionnaireId: questionnaireIdStr } = useParams();
  const qssId = questionnaireIdStr?.split('-')?.[1];
  const [subQuestions, setSubQuestions] = useState(questions);
  useEffect(() => {
    const fndEmptyItem: any = questions?.findIndex((it: IQuestion) => !it?.questionTitle);

    if (fndEmptyItem === -1) {
      setSubQuestions(
        insertAtIndex(
          [...questions],
          {
            id: 0,
            questionTitle: '',
            sortOrder: getMaxSortId(questions) + 1,
            parentQuestionId: parentQuestionId,
            qssId: qssId,
          },
          questions?.length - 1,
        ),
      );
    }
  }, [questions]);

  const handleOnSaveQuestion = (tmpQuestion: IQuestion & { optionalProps?: any }) => {
    const { id, questionTitle, optionalProps = {} } = tmpQuestion;

    const fndItem: any = questions.find((it: IQuestion) => it?.id === id && it?.id !== 0);

    if (fndItem) {
      handleAddQSToQuestionnaire({
        id: `${id}`,
        questionTitle: questionTitle,
        selectionType: tmpQuestion?.questionType ? tmpQuestion?.questionType : 'TEXT_INPUT',
        required: true,
        maxCharacters: 100,
        ...(optionalProps?.isScored != null ? { isScored: optionalProps?.isScored } : {}),
        inputPlaceHolder: 'placeholder ...',
        sortOrder: fndItem?.sortOrder,
        qssId: tmpQuestion?.qssId,
        optionalProps: {
          ...optionalProps,
          ...(tmpQuestion?.subtitle ? { subtitle: tmpQuestion?.subtitle } : {}),
          ...(tmpQuestion?.numberType ? { numberType: tmpQuestion?.numberType } : {}),
          ...(tmpQuestion?.inputType ? { inputType: tmpQuestion?.inputType } : {}),
          ...(tmpQuestion?.dateTimeType ? { dateTimeType: tmpQuestion?.dateTimeType } : {}),
          ...(tmpQuestion?.optionalProps?.numberType
            ? { numberType: tmpQuestion?.optionalProps?.numberType }
            : {}),
          ...(tmpQuestion?.optionalProps?.inputType
            ? { inputType: tmpQuestion?.optionalProps?.inputType }
            : {}),
          ...(tmpQuestion?.optionalProps?.dateTimeType
            ? { dateTimeType: tmpQuestion?.optionalProps?.dateTimeType }
            : {}),
        },
        ...(tmpQuestion?.parentQuestionId
          ? { parentQuestionId: tmpQuestion?.parentQuestionId }
          : {}),
      });
    } else {
      handleAddQSToQuestionnaire({
        questionTitle: questionTitle,
        selectionType: tmpQuestion?.questionType ? tmpQuestion?.questionType : 'TEXT_INPUT',
        required: true,
        maxCharacters: 100,
        ...(optionalProps?.isScored != null ? { isScored: optionalProps?.isScored } : {}),
        inputPlaceHolder: 'placeholder ...',
        qssId: tmpQuestion?.qssId,
        optionalProps: {
          ...optionalProps,
          ...(tmpQuestion?.subtitle ? { subtitle: tmpQuestion?.subtitle } : {}),
        },
        ...(tmpQuestion?.parentQuestionId
          ? { parentQuestionId: tmpQuestion?.parentQuestionId }
          : {}),
        ...(tmpQuestion?.numberType ? { numberType: tmpQuestion?.numberType } : {}),
        ...(tmpQuestion?.inputType ? { inputType: tmpQuestion?.inputType } : {}),
        ...(tmpQuestion?.dateTimeType ? { dateTimeType: tmpQuestion?.dateTimeType } : {}),
        ...(tmpQuestion?.optionalProps?.numberType
          ? { numberType: tmpQuestion?.optionalProps?.numberType }
          : {}),
          ...(tmpQuestion?.optionalProps?.inputType
            ? { inputType: tmpQuestion?.optionalProps?.inputType }
            : {}),
        ...(tmpQuestion?.optionalProps?.dateTimeType
          ? { dateTimeType: tmpQuestion?.optionalProps?.dateTimeType }
          : {}),
        ...(tmpQuestion?.parentQuestionId
          ? { parentQuestionId: tmpQuestion?.parentQuestionId }
          : {}),
      });
    }
  };
  if (!subQuestions?.length) return null;
  return (
    <div className='pl-6 pb-4 pr-2'>
      <DragDropList
        questions={subQuestions
          ?.sort((a: any, b: any) => a.sortOrder - b.sortOrder)
          .map((qs: IQuestion) => ({ ...qs, qssId: qssId }))}
        handleOnSaveQuestion={handleOnSaveQuestion}
        repeatedQuestion={true}
        allQuestions={allQuestions}
        filterRepeatedInput={true}
        filterTitleInput={true}
        filterFileInput={true}
        filterRequired={false}
      />
    </div>
  );
};

export default RepeatedQuestion;
