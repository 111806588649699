import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

interface AuthStore {
  accessToken: string;
  userData?: any;
  setAccessToken: (accessToken: string, userData?: any) => void;
}

const useAuthStore = create<AuthStore>()(
  persist(
    (set) => ({
      accessToken: '',
      userData: {},
      setAccessToken: (token: string, userData = {}) => set(() => ({ accessToken: token, userData })),
    }),
    {
      name: 'auth-store',
      storage: createJSONStorage(() => localStorage),
    },
  ),
);

export default useAuthStore;
