import { format, parse } from 'date-fns';

export function camelize(str: string) {
  return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m: any, chr: any) => chr.toUpperCase());
}


export const addAsterisk = (str: string) => {
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = str;

  // Function to find the last text node recursively
  const findLastTextNode: any = (node: any) => {
    if (node.nodeType === Node.TEXT_NODE && node.nodeValue.trim() !== '') {
      return node; // Return the text node if it contains text
    }
    for (let i = node.childNodes.length - 1; i >= 0; i--) {
      const child = node.childNodes[i];
      const result = findLastTextNode(child);
      if (result) {
        return result;
      }
    }
    return null;
  };

  // Find the last text node
  const lastTextNode = findLastTextNode(tempDiv);

  // Append an asterisk with no design but in black color
  if (lastTextNode) {
    const asteriskHtml = '<span style="color: black; font-weight: 400"> *</span>';
    const newSpan = document.createElement('span');
    newSpan.innerHTML = asteriskHtml;
    lastTextNode.parentNode.insertBefore(newSpan.firstChild, lastTextNode.nextSibling); // Insert after the text
  }

  return tempDiv.innerHTML;
};


export const getFormat = (dateType: string) => {
  switch (dateType) {
    case 'DATE':
      return 'dd/MM/yyyy';
    case 'TIME':
      return 'hh:mm aa';
    case 'YEAR':
      return 'yyyy';
    case 'YEAR_AND_MONTH':
      return 'MM/yyyy';
    case 'DATE_AND_TIME':
      return 'dd/MM/yyyy hh:mm aa';
    default:
      return 'dd/MM/yyyy';
  }
}

export const formatAnsDate = (dateStr: any, dateTimeType: string) => {
  const today = new Date();
  let fullDateStr = dateStr
  let  dateTimeFormat = 'MM/dd/yyyy';
  if (dateStr && !dateStr?.includes(':') && dateStr?.split('/')?.length > 0 && dateStr?.split('/')?.length === 3) {
    if (dateTimeType === 'DATE') dateTimeFormat = 'dd/MM/yyyy';
    const parsedDate = parse(fullDateStr, dateTimeFormat, new Date());
    return parsedDate && `${new Date(parsedDate)}` !== 'Invalid Date' ? new Date(parsedDate) : '';
  }
  if (dateTimeType === 'YEAR') return new Date(dateStr);
  if (dateTimeType === 'DATE_AND_TIME') {
    dateTimeFormat = dateStr?.includes('-') ? 'MM/dd/yyyy-hh:mm a' : 'dd/MM/yyyy hh:mm a';
  }
  if (dateTimeType === 'TIME') {
    fullDateStr = `${today.getMonth() + 1}/${today.getDate()}/${today.getFullYear()}-${dateStr}`;
    dateTimeFormat = 'MM/dd/yyyy-hh:mm a';
  } else if (dateTimeType === 'YEAR_AND_MONTH') {
    fullDateStr = `${today.getDate()}/${dateStr}`;
    dateTimeFormat = 'dd/MM/yyyy';
  }
  const parsedDate = parse(fullDateStr, dateTimeFormat, new Date());
  return parsedDate && `${new Date(parsedDate)}` !== 'Invalid Date' ? format(parsedDate, 'EEE MMM dd yyyy HH:mm:ss') : '';
}
