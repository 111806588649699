import { useMutation, useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
// import { toast } from 'react-toastify';
import { fetchStepsByStudyId } from 'services/api/steps';
import { updateEproSchedule } from 'services/api/study';
import { IStep } from 'types/step';

const useEproSchedule = () => {
  const { studyId } = useParams();
  const {
    refetch,
  } = useQuery<IStep[]>({
    queryKey: ['steps'],
    queryFn: () => fetchStepsByStudyId(studyId as string),
  });

  const mutation = useMutation(
    (params: { id: any; data: any }) => {
      return updateEproSchedule(params.id, params.data);
    },
    {
      onSuccess: () => {
        refetch();
      },
    },
  );

  return mutation;
};

export default useEproSchedule;
