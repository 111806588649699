import Roles from './components/Roles';
import { useState } from 'react';
import { FiBriefcase, FiKey, FiLoader } from 'react-icons/fi';
import clsx from 'classnames';
import { IRoleItem } from 'types/roles';
import useRoles from './useRoles';
import UsersList from './components/UsersList';
import { useQuery } from '@tanstack/react-query';
import { getRoles } from 'services/api/users';
import { useParams } from 'react-router-dom';
import { getCountries } from 'services/api/countries';
import { FormattedMessage } from 'react-intl';
import usePermissions from '../../../hooks/usePermissions';

const Users: React.FC = () => {
  const [tab, setTab] = useState('all');
  const [selectedPermissions, setSelectedPermissions] = useState<any>([]);
  const { studyId } = useParams();
  const { hasNotAccess } = usePermissions();

  const isViewRole = !hasNotAccess('VIEW_ROLES')

  const {
    data: roles,
    isLoading: isRolesLoading,
    refetch: rolesRefetch,
  } = useQuery({
    queryKey: ['get-user-roles' + studyId],
    queryFn: () => getRoles('CLINICAL_TRIAL', studyId),
    refetchOnMount: 'always',
  });
  const { data: countries } = useQuery({
    queryKey: ['get-countries'],
    queryFn: () => getCountries(),
  });
  const { permissions } = useRoles({ rolesRefetch });

  const groupItemsByCategory: any = (items: IRoleItem[]) => {
    if (!Array.isArray(items)) return [];
    const groupedItems: { [key in IRoleItem['category']]: IRoleItem[] } = {
      FULL_ADMINISTRATIVE: [],
      DATA_ACTIVITIES: [],
      CLINICAL_OPERATIONS: [],
      SITE_STAFF: [],
    };

    items.forEach((item) => {
      if (item?.category) groupedItems?.[item?.category].push(item);
    });
    const formatedList: any = [];
    let idIdx = 1;
    for (const groupKey in groupedItems) {
      formatedList.push({ id: idIdx++, category: groupKey, list: groupedItems[groupKey] });
    }

    return formatedList;
  };

  return (
    <div className='flex bg-white flex-1 w-full gap-[2.9375rem] pr-11'>
      <div className='w-[84px] font-serif'>
        <div className='mt-[42px] rounded-r-lg h-[180px] flex flex-col justify-start items-center py-3 border-blue-ocean-deep border-2'>
          <div
            onClick={() => setTab('all')}
            className={clsx(
              'h-1/2 w-full flex flex-col justify-center items-center cursor-pointer',
              tab === 'all' ? '!bg-[rgba(202,199,199,0.22)]' : '',
            )}
          >
            <FiBriefcase
              className={clsx(
                'text-blue-ocean-deep w-6 h-6 mb-2',
                tab === 'all' ? 'stroke-[3px]' : 'stroke-[1px]',
              )}
            />
            <span
              className={clsx('text-blue-ocean-deep', tab === 'all' ? 'font-medium' : 'font-extralight')}
            >
              <FormattedMessage id='allStudies.all' />
            </span>
          </div>
          <div
            onClick={(e) => {
              if (!isViewRole) {
                e.preventDefault();
                return;
              }
              setTab('permissions');
              // navigate({ pathname: '/users/permissions' });
            }}
            className={clsx(
              'h-1/2 w-full flex flex-col justify-center items-center ',
              isViewRole ? 'cursor-pointer' : 'cursor-not-allowed pointer-events-none opacity-40',
              tab !== 'all' ? '!bg-[rgba(202,199,199,0.22)]' : ' ',
            )}
          >
            <FiKey
              className={clsx(
                'text-blue-ocean-deep w-6 h-6 mb-2',
                tab !== 'all' ? 'stroke-[3px]' : 'stroke-[1px]',
              )}
            />
            <span
              className={clsx(
                'text-xs text-blue-ocean-deep',
                tab !== 'all' ? 'font-medium' : 'font-extralight',
              )}
            >
              <FormattedMessage id='users.permissions' />
            </span>
          </div>
        </div>
      </div>
      {tab === 'all' ? (
        <div className='bg-white flex-1 grow mt-11 ml-auto container mx-auto h-max	px-4 md:px-0'>
          <h2 className='font-sans text-blue-ocean-deep text-[40px] text-left leading-[34px] font-normal pl-6'>
            <FormattedMessage id='study-builder.users' /> - <FormattedMessage id='allStudies.all' />
          </h2>
          <UsersList roles={roles} countries={countries ?? []} />
        </div>
      ) : (
        <div className='bg-white flex-1 grow mt-11 ml-auto container mx-auto h-max	px-4 md:px-0'>
          <h2 className='font-sans text-blue-ocean-deep text-[40px] text-left leading-[34px] font-normal pl-6'>
            <FormattedMessage id='users.rolesPermissions' />
          </h2>
          {isRolesLoading == false && !roles ? (
            <div className='flex justify-center items-center flex-1 w-full h-full'>
              <p className='text-blue-ocean-deep'>
                <FormattedMessage id='error.errorGettingData' />
              </p>
            </div>
          ) : !roles ? (
            <div className='flex justify-center items-center'>
              <FiLoader className='w-20 h-20 animate-spin place-self-center text-blue-ocean-deep' />
            </div>
          ) : (
            <Roles
              selectedPermissions={selectedPermissions}
              setSelectedPermissions={setSelectedPermissions}
              permissions={permissions}
              roles={roles ? groupItemsByCategory(roles) : []}
              rolesRefetch={rolesRefetch}
            />
          )}
        </div>
      )}
    </div>
  );
};
export default Users;
