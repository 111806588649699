import React, { useState, useEffect } from 'react';
import clsx from 'classnames';
import ProgressBar from 'components/common/ProgressBar';
import { HeaderItem } from 'components/common/Table/Table.types';
import { format } from 'date-fns';
import { FiLoader } from 'react-icons/fi';

interface ITableProps {
  headerItems: HeaderItem[];
  columns: any;
  onRowClick?: (data: any) => void;
  rowSelect?: any;
  onScroll?: any;
  scrollContainerRef?: any;
  isMoreLoading?: boolean;
}

const EproScheduleTable: React.FC<ITableProps> = (props: ITableProps) => {
  const { headerItems, columns, onRowClick, onScroll, scrollContainerRef, isMoreLoading } = props;
  const [data, setData] = useState<any>(columns);

  useEffect(() => {
    setData(columns);
  }, [columns]);

  return (
    <div
      onScroll={onScroll}
      ref={scrollContainerRef}
      className='h-max w-full max-h-[16.5rem] overflow-auto'
    >
      <table className='min-w-full w-full pl-4'>
        <thead className='sticky top-0 bg-white z-[39]'>
          <tr>
            {headerItems.map((r, i: number) => (
              <th
                key={i}
                className={clsx(
                  'text-left px-4 group cursor-pointer h-14 text-blue-ocean-deep text-base font-semibold',
                  r.width,
                )}
              >
                <div className='flex items-center'>
                  {r.title}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.map((item: any, i: any) => (
            <tr
              onClick={() => onRowClick?.(item)}
              key={item.id}
              className={`${`${props.rowSelect?.id}` === `${item?.id}` ? 'bg-blue-light-100 border-purple border-y' : 'bg-white border-gray-very-light border-t'} hover:bg-blue-light-100 hover:border-purple align-text-top group cursor-pointer`}
            >
              <td className='px-4 text-gray-dark text-sm py-2.5 text-left table-cell'>
                <p>{`${item.occurence} ${i + 1}`}</p>
              </td>
              <td className='px-4 text-left text-gray-dark text-sm py-2.5 table-cell'>
                <ProgressBar current={item?.progress} max={item?.totalQuestion} fullColor='blue-ocean-deep' />
              </td>
              <td className='px-4 text-left text-gray-dark text-sm py-2.5 table-cell'>
                <p className='text-grey-medium text-sm'>{item?.progress}/{item?.totalQuestion}</p>
              </td>
              <td className='px-4 text-left text-gray-dark text-sm py-2.5 table-cell'>
                <p className='text-grey-medium text-sm'>{item?.score}</p>
              </td>
              <td className='px-4 text-left text-gray-dark text-sm py-2.5 table-cell'>
                {item.date && format(new Date(item.date), 'dd/MM/yyyy')}
              </td>
            </tr>
          ))}
          {isMoreLoading && (
            <tr className='bg-white border-gray-very-light border-t'>
              <td colSpan={5} className='text-center py-2'>
                <FiLoader className='w-6 h-6 animate-spin self-center mx-auto' />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default EproScheduleTable;
