import { Calendar } from 'components/common';
import { format } from 'date-fns';
import { useState } from 'react';
import { useCalendar } from 'react-hook-calendar';
import { FiChevronDown } from 'react-icons/fi';

const DatePick: React.FC = () => {
  const { viewPeriod, setDate } = useCalendar();
  const [dateTime, setDateTime] = useState<any>(format(viewPeriod.start, 'MMMM yyyy'));

  const handleChange = (e: any) => {
    setDate(e);
    setDateTime(e)
  }

  return (
    <div className='flex items-center gap-5 h-12 -ml-3 max-w-[11rem] w-max'>
      <Calendar
        name='date'
        label=''
        customControl={{
          value: dateTime,
          onChange: (e) => handleChange(e),
        }}
        slots={{
          openPickerIcon: FiChevronDown
        }}
        className='!border-none no-border-mui [&>div]!flex-reverse [&>div>div]:pr-0 [&>div>div>input]:!text-left'
        inputDisable={true}
        type='yearmonth'
      />
    </div>
  );
};
export default DatePick;
