import React, { useState } from 'react';
import clsx from 'classnames';
import { IQuestion } from 'types/questionnaire';
import { useQuestionnaireStore } from 'store/QuestionnaireStore';
import { Radio } from 'components/common';

interface IProps {
  showScore?: boolean;
  onSaveQuestion: any;
  question: IQuestion;
  setOptionalPropsPopover: any;
}

const DateTimeProps: React.FC<IProps> = ({
  onSaveQuestion,
  question,
  setOptionalPropsPopover,
}: IProps) => {
  const { addQuestionError } = useQuestionnaireStore();
  const [dateTimeType, setDateTimeType] = useState<any>(question?.dateTimeType ?? 'DATE');

  return (
    <div
      className={clsx(
        'w-[203px] absolute z-10 bg-white rounded-[12px] top-0 mt-6 mr-0 -right-[10px] border-[1px] border-purple',
        addQuestionError
          ? '[&>*]:!text-error-feedback [&>button]:!border-error-feedback border-error-feedback'
          : 'border-blue-normal',
      )}
    >
      <div className='flex justify-start items-center flex-col'>
        <div className='w-full pt-2 pb-2 px-3 max-h-[211px] overflow-y-auto'>
          <Radio
            options={[
              { label: 'DD/MM/YYYY', value: 'DATE' },
              { label: 'MM/YYYY', value: 'YEAR_AND_MONTH' },
              { label: 'YYYY', value: 'YEAR' },
              { label: 'DD/MM/YYYY, HH:MM', value: 'DATE_AND_TIME' },
              { label: 'HH:MM', value: 'TIME' },
            ]}
            optionsClassName='w-full flex-col [&>div]:w-full'
            control={{
              value: dateTimeType,
              onChange: (e: any) => {
                console.log('call onchange: ', e);
                setDateTimeType(e);
                onSaveQuestion({
                  optionalProps: {
                    dateTimeType: e,
                  },
                });
                setOptionalPropsPopover(false);
              },
            }}
            noOnchangeCall={true}
            defaultValue={question?.dateTimeType}
            selectedValue={question?.dateTimeType}
            labelClassName='text-sm text-base-black w-full min-w-[75px] truncate text-left w-full'
            className={clsx('border-none !py-1.5 !pl-0 !pr-0 gap-x-[4px]')}
            wrapperClassName='[&>div]:px-[2px] relative -left-[1px]'
            radioClassName='!w-4 !h-4 gap-x-2'
          />
        </div>
      </div>
    </div>
  );
};

export default DateTimeProps;
